/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InlineSnackbar } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { useCallback } from "react";
import * as React from "react";
import { GitCredentialDrawer } from "~/areas/library/components/GitCredentials/GitCredentialDrawer";
import { createAddGitCredentialsAnalyticsEvent } from "~/areas/library/components/GitCredentials/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import styles from "./style.module.less";
type GitCredentialSelectProps = FormFieldProps<string> & {
    fixedLabel?: boolean;
    items: GitCredentialResource[];
    placeholder?: string;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
    onFilterChanged?(value: string): void;
};
export const GitCredentialSelect = ({ error: propsError, items, onChange, onRequestRefresh: propsOnRequestRefresh, onValidate, validate, value }: GitCredentialSelectProps) => {
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [refreshing, setRefreshing] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const trackEvent = useTrackEvent();
    const handleChange = (gitCredentialId: string | undefined) => {
        const value = gitCredentialId === "" ? null : gitCredentialId;
        if (validate) {
            const result = validate(value!);
            setError(result);
            if (onValidate) {
                onValidate(result);
            }
        }
        onChange!(value!);
    };
    const getItems = () => {
        const map = (credential: GitCredentialResource) => ({ value: credential.Id, text: credential.Name });
        return items.map(map);
    };
    const selectionRenderer = (gitCredentialId: string) => {
        const credential = items.find((credential) => credential.Id === gitCredentialId);
        if (!credential) {
            return gitCredentialId;
        }
        return <div className={styles.innerContainer}>{credential.Name}</div>;
    };
    const onRequestRefresh = useCallback(async () => {
        setRefreshing(true);
        try {
            await propsOnRequestRefresh();
        }
        finally {
            setRefreshing(false);
        }
    }, [propsOnRequestRefresh]);
    const handleAddGitCredential = () => {
        trackEvent(createAddGitCredentialsAnalyticsEvent());
        setIsOpen(true);
    };
    const onClose = () => {
        setIsOpen(false);
    };
    const handleOnSave = useCallback(async (gitCredential: GitCredentialResource) => {
        await onRequestRefresh();
        onChange(gitCredential.Id);
        setIsOpen(false);
        setTimeout(() => setShowSnackbar(true), 300);
    }, [onRequestRefresh, onChange]);
    return (<div className={styles.outerContainer}>
            <SelectWithAddRefresh value={value} addUrl={links.gitCredentialsPage.generateUrl({ spaceId: repository.spaceId! })} addAction={handleAddGitCredential} onRequestRefresh={onRequestRefresh} label="Select Git credential" allowClear={true} allowFilter={true} onChange={handleChange} error={error || propsError} items={getItems()} selectionRenderer={selectionRenderer} inlineAddButtonLabel="Add Git Credentials"/>
            <div className={cn({ snackbarContainerStyles: showSnackbar })}>
                <InlineSnackbar variant="success" show={showSnackbar} content={"Git Credential Created"} onClose={() => setShowSnackbar(false)} autoHideDuration={3000}/>
            </div>
            <GitCredentialDrawer isOpen={isOpen} onClose={onClose} onSave={handleOnSave}/>
        </div>);
};
export const BoundGitCredentialSelect = withBoundField(GitCredentialSelect);
export default GitCredentialSelect;
