import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function OutOfSyncIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#C45317"/>
            <g clipPath="url(#clip0_1155_35502)">
                <path d="M25.7854 11.9287C25.0823 11.2256 23.9404 11.2256 23.2373 11.9287L14.2373 20.9287C13.5342 21.6319 13.5342 22.7737 14.2373 23.4769C14.9404 24.18 16.0823 24.18 16.7854 23.4769L22.7142 17.5425V34.8C22.7142 35.7956 23.5186 36.6 24.5142 36.6C25.5098 36.6 26.3142 35.7956 26.3142 34.8V17.5425L32.2429 23.4712C32.9461 24.1744 34.0879 24.1744 34.7911 23.4712C35.4942 22.7681 35.4942 21.6262 34.7911 20.9231L25.7911 11.9231L25.7854 11.9287Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1155_35502">
                    <rect width="21.6" height="28.8" fill="white" transform="translate(13.7142 9.60001)"/>
                </clipPath>
            </defs>
        </svg>);
}
