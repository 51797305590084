import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function ProgressingIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#1A77CA"/>
            <path d="M22.1269 11.1457C22.4081 12.0964 21.8681 13.1032 20.9175 13.3845C16.4512 14.7176 13.2 18.852 13.2 23.7401C13.2 29.7026 18.0375 34.5401 24 34.5401C29.9625 34.5401 34.8 29.7026 34.8 23.7401C34.8 18.852 31.5487 14.7176 27.0881 13.3845C26.1375 13.1032 25.5919 12.0964 25.8787 11.1457C26.1656 10.1951 27.1669 9.64949 28.1175 9.93637C34.0631 11.7082 38.4 17.2151 38.4 23.7401C38.4 31.6939 31.9537 38.1401 24 38.1401C16.0462 38.1401 9.59998 31.6939 9.59998 23.7401C9.59998 17.2151 13.9369 11.7082 19.8881 9.93637C20.8387 9.65512 21.8456 10.1951 22.1269 11.1457Z" fill="white"/>
        </svg>);
}
