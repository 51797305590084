import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, Action as AnalyticsAction } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { PageContent } from "~/components/PageContent/PageContent";
import { PermissionCheck } from "~/components/PermissionCheck";
import { Summary } from "~/components/form/Sections/index";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form/index";
import { GitHubUserSectionWithDataLoading } from "./GitHubUserSection";
import styles from "./style.module.less";
interface GitPageInternalProps {
    dispatchAction: AnalyticActionDispatcher;
}
interface GitPageState {
    hasClearedCache: boolean;
}
class GitPageInternal extends DataBaseComponent<GitPageInternalProps, GitPageState> {
    constructor(props: GitPageInternalProps) {
        super(props);
        this.state = {
            hasClearedCache: false,
        };
    }
    private onClearCacheClick = async () => {
        this.props.dispatchAction("Clear Local Cache", { resource: "Features", action: AnalyticsAction.Clear });
        this.setState({ hasClearedCache: false });
        await this.doBusyTask(async () => {
            logger.info("Clearing local git cache");
            await repository.ServerConfiguration.clearCache();
            this.setState({ hasClearedCache: true });
        });
        return;
    };
    render() {
        return (<PageContent header={{ title: "Git Configuration" }} errors={this.errors} busy={this.state.busy}>
                <PermissionCheck permission={[Permission.AdministerSystem, Permission.ConfigureServer]}>
                    <FormSectionHeading title="Git"/>
                    <ExpandableFormSection errorKey={"ClearGitCache"} title={"Clear Git Cache"} summary={Summary.summary("Purge all cloned repositories and force Octopus to re-clone them the next time it needs them")} help="Octopus keeps local clones of git repositories used in version controlled projects. This will completely purge all cloned repositories and force Octopus to re-clone them the next time it needs them.">
                        {this.state.hasClearedCache && (<div className={styles.successCallout}>
                                <Callout type={"success"} title={"Cache Cleared"}/>
                            </div>)}
                        <ActionButton type={ActionButtonType.Secondary} title="Clear local git cache" label="Clear local cache" onClick={() => this.onClearCacheClick()} busyLabel={"Clearing..."} disabled={this.state.busy}/>
                    </ExpandableFormSection>
                </PermissionCheck>
                <FeatureToggleVisibility toggle="GitHubConnectionsFeatureToggle">
                    <FormSectionHeading title="GitHub App"/>
                    <GitHubUserSectionWithDataLoading busy={this.state.busy} doBusyTask={this.doBusyTask}/>
                </FeatureToggleVisibility>
            </PageContent>);
    }
    static displayName = "GitPageInternal";
}
// Wrap the component and inject the analytic dispatch action.
export function GitPage() {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitPageInternal dispatchAction={dispatchAction}/>;
}
