import { css } from "@emotion/css";
import { Button, PopoverBasicHelp, Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form/index";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
export const parseGitProtectionPatterns = (patterns: string[]) => {
    const branches: string[] = [];
    const tags: string[] = [];
    const other: string[] = [];
    patterns.forEach((entry) => {
        if (entry.startsWith("refs/heads/")) {
            branches.push(entry);
        }
        else if (entry.startsWith("refs/tags/")) {
            tags.push(entry);
        }
        else {
            other.push(entry);
        }
    });
    return { branches, tags, other };
};
export const userFriendlyBranchAndTagsDisplay = (patterns: string[]) => patterns.map((pattern) => pattern.replace(/refs\/(heads|tags)\//, "")).join(", ");
type GitProtectionRulesInputProps = {
    patterns: string[];
    onPatternsChanged: (list: string[]) => void;
};
export const GitProtectionRules = ({ patterns, onPatternsChanged }: GitProtectionRulesInputProps) => {
    const { branches, tags, other } = parseGitProtectionPatterns(patterns);
    const [branchPatterns, setBranchPatterns] = useState(userFriendlyBranchAndTagsDisplay(branches));
    const [tagPatterns, setTagPatterns] = useState(userFriendlyBranchAndTagsDisplay(tags));
    const [advancedPatterns, setAdvancedPatterns] = useState(patterns.join(", "));
    const [advancedMode, setAdvancedMode] = useState(other.length > 0);
    const parsePatternInput = (input: string): string[] => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return input
            .split(",")
            .map((entry) => (entry.trim().length > 0 ? entry.trim() : undefined))
            .filter((entry) => entry !== undefined) as string[];
    };
    const parseAndFullyQualifyPatternInput = (input: string, refType: string): string[] => parsePatternInput(input).map((entry) => `refs/${refType}/${entry}`);
    const onAdvancedPatternsChanged = (input: string) => {
        const parsed = parsePatternInput(input);
        setAdvancedPatterns(input);
        onPatternsChanged(parsed);
    };
    const onSwitchToAdvancedMode = () => {
        setAdvancedMode(true);
        setAdvancedPatterns(patterns.join(", "));
    };
    const onSwitchToBasicMode = () => {
        setAdvancedMode(false);
        const parsed = parsePatternInput(advancedPatterns);
        const { branches, tags } = parseGitProtectionPatterns(parsed);
        setBranchPatterns(userFriendlyBranchAndTagsDisplay(branches));
        setTagPatterns(userFriendlyBranchAndTagsDisplay(tags));
    };
    const onBranchPatternsChanged = (input: string) => {
        setBranchPatterns(input);
        onPatternsChanged([...parseAndFullyQualifyPatternInput(input, "heads"), ...parseAndFullyQualifyPatternInput(tagPatterns, "tags")]);
    };
    const onTagPatternsChanged = (input: string) => {
        setTagPatterns(input);
        onPatternsChanged([...parseAndFullyQualifyPatternInput(branchPatterns, "heads"), ...parseAndFullyQualifyPatternInput(input, "tags")]);
    };
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
        }),
        inputWithContextualHelp: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }),
        switchModeButtonContainer: css({
            marginBottom: space[16],
        }),
    };
    const canSwitchBackToBasicMode = advancedPatterns
        .split(",")
        .map((entry) => (entry.trim().length > 0 ? entry.trim() : undefined))
        .filter((entry) => entry !== undefined)
        .every((entry) => entry !== undefined && (entry.startsWith("refs/heads/") || entry.startsWith("refs/tags/")));
    return (<div className={styles.container}>
            <Note>
                Use wildcard syntax to match on multiple branches, tags of other Git references. See our documentation on <ExternalLink href="ChannelGitProtectionRulesGlobPatterns">using glob patterns in Git protection rules</ExternalLink> for more
                information.
            </Note>
            {!advancedMode && (<>
                    <div className={styles.inputWithContextualHelp}>
                        <DebounceText value={branchPatterns} debounceDelay={500} onChange={onBranchPatternsChanged} label="Branches" autoFocus={false}/>
                        <PopoverBasicHelp placement="right-start" description={<div>
                                    Branch names do not need to be fully qualified. For example, <code>main</code> will match <code>refs/heads/main</code>.
                                </div>}/>
                    </div>
                    <div className={styles.inputWithContextualHelp}>
                        <DebounceText value={tagPatterns} debounceDelay={500} onChange={onTagPatternsChanged} label="Tags" autoFocus={false}/>
                        <PopoverBasicHelp placement="right-start" description={<div>
                                    Tag names do not need to be fully qualified. For example, <code>v1</code> will match <code>refs/tags/v1</code>.
                                </div>}/>
                    </div>
                    <div className={styles.switchModeButtonContainer}>
                        <Tooltip content="Switch to advanced mode to match custom Git references such as pull request merge branches">
                            <Button label="Advanced" onClick={onSwitchToAdvancedMode} importance="quiet"/>
                        </Tooltip>
                    </div>
                </>)}
            {advancedMode && (<>
                    <div className={styles.inputWithContextualHelp}>
                        <DebounceText value={advancedPatterns} debounceDelay={500} onChange={onAdvancedPatternsChanged} label="Git reference patterns" autoFocus={true} multiline={true} placeholder="Enter fully qualified Git reference patterns e.g. refs/heads/feature/*"/>
                        <PopoverBasicHelp placement="right-start" description={<div>
                                    Git reference patterns entered in advanced mode need to be <strong>fully qualified</strong>, for example <code>refs/pulls/*/merge</code>.
                                </div>}/>
                    </div>
                    <div className={styles.switchModeButtonContainer}>
                        <Tooltip content={canSwitchBackToBasicMode ? "Switch to basic mode to enter branch and tag patterns" : "Cannot switch to basic mode as advanced patterns are configured"}>
                            <Button label="Basic" onClick={onSwitchToBasicMode} importance="quiet" disabled={!canSwitchBackToBasicMode}/>
                        </Tooltip>
                    </div>
                </>)}
        </div>);
};
