import { Avatar } from "@octopusdeploy/design-system-components";
import type { BlueprintResource, IconMetadataResource, IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import BlueprintIconEditLayout from "~/areas/blueprints/BlueprintIconEditLayout";
import { getProcessTemplateIconUrl } from "~/areas/blueprints/ProcessTemplatesPage";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import { FormSection, Summary } from "~/components/form/Sections/index";
import { ExpandableFormSection, required } from "~/components/form/index";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
interface EditProcessTemplateSettingsTabProps {
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource | undefined;
    processTemplate: BlueprintResource;
    onChange: (processTemplate: BlueprintResource) => void;
}
export function EditProcessTemplateSettingsTab({ iconSvgResources, iconMetadata, onChange, processTemplate }: EditProcessTemplateSettingsTabProps) {
    const setName = (value: string) => onChange({ ...processTemplate, Name: value });
    const setDescription = (value: string) => onChange({ ...processTemplate, Description: value });
    const setIconId = (value: string) => onChange({
        ...processTemplate,
        Icon: {
            Color: processTemplate.Icon?.Color ?? IconEditorDefaultColor,
            Id: value,
        },
    });
    const setIconColor = (value: string) => onChange({
        ...processTemplate,
        Icon: {
            Id: processTemplate.Icon?.Id ?? "",
            Color: value,
        },
    });
    return (<div>
            <FormSection title="Name">
                <DebounceText value={processTemplate.Name} onChange={setName} label="Process template name" validate={required("Please enter a name")}/>
            </FormSection>
            <FormSection title="Description">
                <DebounceText value={processTemplate.Description ?? ""} label="Process template description" onChange={setDescription}/>
            </FormSection>
            <ExpandableFormSection errorKey="icon" title="Icon" summary={iconSummary(processTemplate)} help="Choose an icon.">
                {iconSvgResources && iconMetadata ? (<>
                        <BlueprintIconEditLayout iconEditor={<IconEditor icons={iconSvgResources} iconMetadata={iconMetadata} selectedIconId={processTemplate.Icon?.Id ?? ""} selectedIconColor={processTemplate.Icon?.Color ?? IconEditorDefaultColor} onIconIdChange={setIconId} onIconColorChange={setIconColor}/>}/>
                    </>) : null}
            </ExpandableFormSection>
        </div>);
}
function iconSummary(processTemplate: BlueprintResource) {
    return Summary.summary(<Avatar shape={"squared"} src={getProcessTemplateIconUrl(processTemplate)} size={32} alt={`${processTemplate.Slug} icon`}/>);
}
