import { css } from "@emotion/css";
import type { DeploymentFreezeScopeOverview, SummaryView } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { EnvironmentChip } from "~/components/Chips/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeDataTableEnvironmentsCellProps {
    projects: SummaryView<DeploymentFreezeScopeOverview>;
    tenants: SummaryView<DeploymentFreezeScopeOverview>;
}
export function DeploymentFreezeDataTableEnvironmentsCell({ projects, tenants }: DeploymentFreezeDataTableEnvironmentsCellProps) {
    const findUniqueEnvironments = (scope: SummaryView<DeploymentFreezeScopeOverview>): string[] => {
        const allEnvironments = scope?.Items.flatMap((p) => p.Environments.Items) || [];
        const uniqueIds: string[] = [];
        const uniqueEnvironments: string[] = [];
        if (allEnvironments.length > 0) {
            allEnvironments.forEach((e) => {
                if (e && !uniqueIds.includes(e.Id)) {
                    uniqueIds.push(e.Id);
                    uniqueEnvironments.push(e.Name);
                }
            });
        }
        return uniqueEnvironments;
    };
    const isDeploymentFreezeByTenantEnable = isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle");
    const projectScopeEnvironments = findUniqueEnvironments(projects);
    const tenantScopeEnvironments = findUniqueEnvironments(tenants);
    const projectCount = projectScopeEnvironments.length > 1 ? projectScopeEnvironments.length - 2 : null;
    const tenantCount = tenantScopeEnvironments.length > 1 ? tenantScopeEnvironments.length - 2 : null;
    return (<DataTableRowColumn className={cellStyles}>
            <div>
                {(!isDeploymentFreezeByTenantEnable || projects?.Items.length > 0) && (<div style={{ display: "flex" }}>
                        {projectScopeEnvironments.length > 0 ? (<>
                                {projectScopeEnvironments.slice(0, 2).map((e) => (<EnvironmentChip environmentName={e} key={e}/>))}
                                <Leftovers count={projectCount} name={"environment"} style={{ alignSelf: "center" }}/>
                            </>) : (<div style={{ margin: "4px" }}>No environments</div>)}
                    </div>)}
                {isDeploymentFreezeByTenantEnable && tenants?.Items.length > 0 && (<div style={{ display: "flex" }}>
                        {tenantScopeEnvironments.length > 0 ? (<>
                                {tenantScopeEnvironments.slice(0, 2).map((e) => (<EnvironmentChip environmentName={e} key={e}/>))}
                                <Leftovers count={tenantCount} name={"environment"} style={{ alignSelf: "center" }}/>
                            </>) : (<div style={{ margin: "4px" }}>No environments</div>)}
                    </div>)}
                {tenants?.Items.length == 0 && projects?.Items.length == 0 && <div style={{ margin: "4px" }}>No environments</div>}
            </div>
        </DataTableRowColumn>);
}
const cellStyles = css({ width: "35%" });
