/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import SvgRoleIcon from "@material-ui/icons/LocalOffer";
import { PopoverBasicWithTrigger } from "@octopusdeploy/design-system-components";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import type { ReactNode } from "react";
import * as React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { DeploymentTargetsTable } from "~/areas/projects/components/Process/Common/DeploymentTargetsTable";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/index";
export const RoleChipTextWithPopover = (props: {
    role: string;
    triggerElement: ReactNode;
}) => {
    const [machines, setMachines] = React.useState<DeploymentTargetResource[]>([]);
    const [environments, setEnvironments] = React.useState<EnvironmentResource[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const projectContext = useOptionalProjectContext();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 79.9rem)` });
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext?.state.model.Id);
    const numMachinesToList = 4;
    const onOpen = () => {
        dispatchAction("Role Chip Contextual Help Opened", { action: Action.Toggle, resource: "Role Chip Contextual Help" });
    };
    const container = css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    });
    const textContainer = css({
        width: "274px", // ensures `<RoleIconWithText />` does not render over two lines when total count is 0
    });
    const roleIcon = css({ position: "relative", top: "4px", display: "inline" });
    const RoleIconWithText = (props: {
        role: string;
    }) => (<span>
            <div className={roleIcon}>
                <SvgRoleIcon style={{ width: "16px", height: "16px" }}/>
            </div>{" "}
            <b>{props.role}</b>
        </span>);
    const description = (<div className={container}>
            <div className={cx({ [textContainer]: totalCount == 0 })}>
                <span>
                    {totalCount > 0 && (<>
                            <RoleIconWithText role={props.role}/> {`is assigned to ${totalCount} deployment ${pluralize("target", totalCount)}:`}
                        </>)}
                    {totalCount === 0 && (<>
                            <RoleIconWithText role={props.role}/> isn’t assigned to deployment targets. Assign in{" "}
                            <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                                Deployment Targets
                            </InternalLink>
                            .
                        </>)}
                </span>
            </div>
            {totalCount > 0 && environments && machines && <DeploymentTargetsTable targetTags={[props.role]} deploymentTargetsTotal={totalCount} deploymentTargets={machines} environments={environments} size="medium"/>}
        </div>);
    useEffect(() => {
        (async () => {
            const loadedMachines = await repository.Machines.list({ roles: props.role, take: numMachinesToList });
            setTotalCount(loadedMachines.TotalResults);
            // get all distinct environments
            const environmentIds = new Set<string>();
            loadedMachines.Items.forEach((m) => {
                m.EnvironmentIds.forEach((e) => environmentIds.add(e));
            });
            const environments = await repository.Environments.all({ ids: Array.from(environmentIds) });
            // sort by SortOrder
            environments.sort((a, b) => a.SortOrder - b.SortOrder);
            setMachines(loadedMachines.Items);
            setEnvironments(environments);
        })();
    }, [props.role]);
    return <PopoverBasicWithTrigger description={description} placement={isLargerThanIpad ? "left-end" : "top-end"} onOpen={onOpen} width={totalCount > 0 ? "extra-wide" : "wide"} triggerElelment={props.triggerElement}/>;
};
