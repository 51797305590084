import { css } from "@emotion/css";
import type { PopoverPlacement } from "@octopusdeploy/design-system-components/src/components/Popover/Popover";
import { PopoverBasicHelp } from "@octopusdeploy/design-system-components/src/components/Popover/PopoverBasicHelp";
import { space } from "@octopusdeploy/design-system-tokens";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "../Navigation/InternalLink";
import lifecyclesImage from "./assets/img-lifecycle.svg";
export const TargetTagsProcessEditorContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                During a deployment, this step will run on targets matching any of these <strong>target tags</strong>.
            </div>
            <div>
                Specify one or more tags here, and add them to {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                    Deployment Targets
                </InternalLink>{" "}
                later.
            </div>
        </>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Target Tags" });
    };
    const link1 = <ExternalVideoLink href="MachineRolesVideo">Video (4 min)</ExternalVideoLink>;
    const link2 = <ExternalLink href="TargetRoles">Learn more</ExternalLink>;
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1} link2={link2}/>;
};
export const TargetTagsNewDeploymentTargetContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const description = (<div>
            Deployment and runbooks processes uses <strong>target tags</strong> to select deployment targets at run time.
        </div>);
    const link1 = <ExternalVideoLink href="MachineRolesVideo">Video (4 min)</ExternalVideoLink>;
    const link2 = <ExternalLink href="TargetRoles">Learn more</ExternalLink>;
    return <PopoverBasicHelp placement={position} description={description} link1={link1} link2={link2}/>;
};
export const RollingDeploymentContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<div>
            <strong>Rolling deployments</strong> let you slowly roll out a release by deploying to each target one-by-one, instead of all targets at once. In load balanced scenarios, this reduces overall downtime.
        </div>);
    const link1 = <ExternalLink href="rolling-deployments">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Rolling Deployment" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1}/>;
};
export const WindowSizeContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                <strong>Window size</strong> controls how many deployment targets can be deployed to at once, e.g:
            </div>

            <ul className={listStyles}>
                <li>
                    A window size of <strong>1</strong> will deploy to a single target at a time. Octopus will wait for the step to finish running on target A before starting on target B
                </li>
                <li>
                    A window size of <strong>3</strong> will deploy to three targets at a time. Octopus will wait for the step to finish running on targets A, B or C before starting on target D
                </li>
            </ul>
        </>);
    const link1 = <ExternalLink href="rolling-deployments">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Window Size" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1}/>;
};
export const LifecycleContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                <img src={lifecyclesImage}/>
            </div>
            <div>
                <strong>Lifecycles</strong> give you control over the way releases of your software are promoted between your environments.
            </div>
        </>);
    const link1 = <ExternalVideoLink href="OnboardingLifecyclesVideo">Video (4 min)</ExternalVideoLink>;
    const link2 = <ExternalLink href="OnboardingLifecycles">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Lifecycle" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1} link2={link2}/>;
};
export const CloudConnectionsContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                <strong>Cloud connections</strong> are stored as project variables. This makes those credentials available across your entire project. Additionally, cloud targets can be discoverable at deployment time.
            </div>
            <div>Use project variables to scope your variables to environments and more.</div>
        </>);
    const link1 = <ExternalLink href="CloudTargetDiscovery">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Cloud Connections" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1}/>;
};
export const WorkerPoolsContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<div>
            When a task is assigned to a worker pool, the task will be executed by one of the workers in the <strong>worker pool</strong> you've configured.
        </div>);
    const link1 = <ExternalVideoLink href="WorkerPoolsVideo">Video (7 min)</ExternalVideoLink>;
    const link2 = <ExternalLink href="WorkerPools">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Worker Pools" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1} link2={link2}/>;
};
export const WorkerPoolsVariableContextualHelp = ({ position = "top-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                It’s a common practice to use different worker pools for different environments. This enables scenarios where workers are used for authentication (e.g. AWS EC2 instance roles) or network segregation (e.g. a jump box used to access a
                database).
            </div>
        </>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Worker Pools Variable" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
export const DeploymentTargetsContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                <strong>Deployment targets</strong> are the physical and virtual machines and cloud services that your applications are deployed to.
            </div>

            <div>You can manage your deployment targets in Infrastructure.</div>
        </>);
    const link1 = <ExternalVideoLink href="OnboardingDeploymentTargetsVideo">Video (5 min)</ExternalVideoLink>;
    const link2 = <ExternalLink href="DeploymentTargets">Learn more</ExternalLink>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Deployment Targets" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description} link1={link1} link2={link2}/>;
};
export const GitResourceReleaseSelectionContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = <div>The commit at the tip of this branch or tag will be snapshotted and used in all deployments.</div>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Git resource release selection" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
export const GitResourceReleaseGitReferenceContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = <div>We’ve snapshotted this commit, and it will be used in all deployments of this release.</div>;
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Git resource release git reference" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
export const GitResourcePathContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Git resource path" });
    };
    const description = (<>
            <div>Files included in a glob pattern will be applied with a single apply command.</div>
            <div>If you’ve used semicolons to specify multiple paths, we’ll apply each path separately and in order from left to right.</div>
            <div>Octopus won’t wait for objects to be fully created or updated before moving on to the next file.</div>
        </>);
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
export const InlineScriptEditorContextualHelp = ({ position = "right-start", isPowerShellOnly, syntax }: {
    position?: PopoverPlacement;
    isPowerShellOnly: boolean;
    syntax: ScriptingLanguage;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<div>
            Scripts can contain <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink> below and <ExternalLink href="DocumentationVariables">variables</ExternalLink> can be added by the <em>Insert a variable</em> button.
            Script modules included in this project
            {isPowerShellOnly || syntax === ScriptingLanguage.PowerShell ? (<>
                    will be <ExternalLink href="ImportingScriptModules">automatically imported</ExternalLink>.
                </>) : (<>
                    can be <ExternalLink href="ImportingScriptModules">imported</ExternalLink> into your script.
                </>)}
        </div>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Inline Script Editor" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} title={"Inline Source Code"} description={description}/>;
};
export const ExecutionContainerImageContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<>
            <div>
                To make life easier, we’ve published images to a DockerHub repository (<ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink>) that contain many common deployment tools. We regularly publish updates with
                the latest versions of the contained tools.
            </div>
            <div>
                Find out <ExternalLink href="ExecutionContainersWhichImage">which image you can use</ExternalLink>.
            </div>
        </>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Execution Container Image" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
export const KubernetesAgentTargetTagsContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const description = <div>Your deployment process will use the same target tag to select and deploy to this Kubernetes cluster at run time.</div>;
    return <PopoverBasicHelp placement={position} description={description}/>;
};
export const CloudTargetDiscoveryContextualHelp = ({ position = "bottom-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const description = (<div>
            At deployment time, Octopus will dynamically create deployment targets for steps that use Target Discovery. If you haven’t already, please follow this <ExternalLink href="CloudTargetDiscovery">setup guide</ExternalLink> to make your
            targets discoverable.
        </div>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Execution Container Image" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
const listStyles = css({
    listStyleType: "disc",
    listStylePosition: "inside",
    marginLeft: space["12"],
    color: "inherit",
});
const stylingContainerForPopoverBetweenText = css({
    position: "relative",
    bottom: "-6px",
    display: "inline-block",
    "& > div > div": {
        marginLeft: "0",
    },
});
