import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
export interface DataTableRowProps {
    displayNoBorder?: boolean;
    className?: string;
    disabled?: boolean | null;
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
}
export class DataTableRow extends React.Component<DataTableRowProps> {
    render() {
        const { displayNoBorder, className, disabled, ...otherProps } = this.props;
        return (<tr {...otherProps} className={cn(className, styles.dataTableRow, displayNoBorder ? styles.noBorder : "", disabled ? styles.disabled : "")}>
                {this.props.children}
            </tr>);
    }
    static displayName = "DataTableRow";
}
