import type { Dictionary } from "lodash";
import * as React from "react";
import { K8sStatusIcon } from "~/areas/projects/components/Observability/KubernetesStatuses";
import { ChipIcon, FilterTextChip, MissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
interface KubernetesStatusMultiSelectProps extends FormFieldProps<string[]> {
    items: Dictionary<() => React.ReactElement>;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const KubernetesStatusTypedMultiSelect = MultiSelect<SelectItem>();
const KubernetesStatusMultiSelect: React.FC<KubernetesStatusMultiSelectProps> = (props) => {
    const { onChange, items, ...otherProps } = props;
    const selectItems = Object.keys(items).map((i) => ({
        Id: i,
        Name: i,
        Icon: items[i](),
    }));
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => (<Lookup lookupCollection={selectItems} lookupId={r.Id} getIdFromElement={(element: SelectItem) => element.Id} render={(item: SelectItem) => <FilterTextChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} filterText={item.Name} icon={<K8sStatusIcon status={item.Id}/>}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle}/>}/>);
    return <KubernetesStatusTypedMultiSelect items={selectItems} fieldName="status" renderChip={chipRenderer} onChange={(value) => onChange(value)} {...otherProps}/>;
};
KubernetesStatusMultiSelect.displayName = "KubernetesStatusMultiSelect"
export default KubernetesStatusMultiSelect;
