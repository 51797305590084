/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable no-eq-null */
import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { DeploymentSettingsResource, GitRefResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import { flatten } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ProcessTemplateOnboarding from "~/areas/blueprints/processEditor/ProcessTemplateOnboarding";
import { getProcessTemplateIdFromAction } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import ProcessListPageListItemForBlueprint from "~/areas/projects/components/Process/ListItems/ProcessListPageListItemForBlueprint";
import type { DeploymentProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, isDeploymentProcessIdentifier, isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import { NoResults } from "~/components/Images/NoResults/NoResults";
import InternalLink from "~/components/Navigation/InternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Select, UnstructuredFormSection } from "~/components/form";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import { isVersioningConfigurationValid } from "../DeploymentProcessSettings/InvalidConfigurationCallout";
import RunbookOnboarding from "../Runbooks/RunbookOnboarding";
import { RunbookProcessOverviewSidebar } from "../Runbooks/RunbookProcessOverviewSidebar";
import Onboarding from "./Common/Onboarding";
import SideBar from "./Common/SideBar";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import type { ProcessContextProps } from "./Contexts/ProcessContext";
import { useProcessContext } from "./Contexts/ProcessContext";
import type { ProcessEditorSearchFilterNamedResource } from "./Contexts/ProcessSearchFilter";
import { useProcessSearchFilterContext } from "./Contexts/ProcessSearchFilter/ProcessSearchFilterContext";
import processListItemStyles from "./ListItems/ProcessListItem.module.less";
import type { ProcessSearchFilter } from "./ListItems/ProcessListItemContextMenu";
import ProcessListPageListItemForAction from "./ListItems/ProcessListPageListItemForAction";
import ProcessListPageListItemForParentStep from "./ListItems/ProcessListPageListItemForParentStep";
import type { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
class FilterLayout extends AdvancedFilterLayout<ProcessSearchFilter> {
}
export interface ProcessListProps {
    lookups: ProcessListLayoutLoaderLookupData;
    doBusyTask: DoBusyTask;
    location: H.Location;
    history: H.History;
    gitRefResource: GitRefResource | undefined;
}
const ProcessList: React.FC<ProcessListProps> = ({ lookups, doBusyTask, location, history, gitRefResource }) => {
    const processContext = useProcessContext();
    const { state: processContextState } = processContext;
    const processIdentifier = processContextState.processIdentifier;
    const processType = processContext.selectors.getProcessType();
    const processSearchFilterContext = useProcessSearchFilterContext();
    const actionTemplates = useActionTemplatesFromContext();
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    const blueprints = useProcessBlueprintsFromContext();
    useEffect(() => {
        if (sampleProjectTourContext !== undefined) {
            sampleProjectTourContext.continueSampleProjectTour();
        }
    }, [sampleProjectTourContext]);
    const [deploymentSettings, setDeploymentSettings] = useState<DeploymentSettingsResource | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const result = isDeploymentProcessIdentifier(processIdentifier) ? await repository.ProjectDeploymentSettings.get(processIdentifier.projectId, gitRefResource?.CanonicalName) : undefined;
        setDeploymentSettings(result);
    }, [processIdentifier, gitRefResource?.CanonicalName]);
    const { state: processSearchFilterContextLookupsState, actions: processSearchFilterContextActions } = processSearchFilterContext;
    const channelsById = lookups.channelsById;
    const hasValidProcess: boolean = processContext.selectors.hasValidProcess();
    const hasSteps: boolean = processContext.selectors.hasSteps();
    return (<>
            {hasValidProcess &&
            !hasSteps &&
            (isDeploymentProcessIdentifier(processIdentifier) ? (<Onboarding location={location} history={history} processIdentifier={processIdentifier} gitRef={gitRefResource?.CanonicalName}/>) : isBlueprintProcessIdentifier(processIdentifier) ? (<ProcessTemplateOnboarding spaceId={processIdentifier.spaceId} slug={processIdentifier.slug} gitRef={gitRefResource?.CanonicalName}/>) : (<RunbookOnboarding />))}
            {hasValidProcess && hasSteps && (<>
                    <SidebarLayout sideBar={isDeploymentProcessIdentifier(processIdentifier) ? (<SideBar processContextState={processContextState} includedScriptModules={lookups.includedScriptModules} lifecyclePreview={lookups.lifecyclePreview} environments={Object.values(lookups.environmentsById || {})} onDataChanged={() => processContext.actions.refreshLookupData()}/>) : isRunbookProcessIdentifier(processIdentifier) ? (<RunbookProcessOverviewSidebar spaceId={processIdentifier.spaceId} projectId={processIdentifier.projectId} onDataChanged={() => processContext.actions.refreshLookupData()} includedScriptModules={lookups.includedScriptModules}/>) : null} hideTopDivider={processType === ProcessType.Runbook} // Our tabs already provide a dividing line in runbooks.
        >
                        <FilterLayout filter={processSearchFilterContextLookupsState.searchFilter} filterSections={[
                {
                    render: (<>
                                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                <EnvironmentSelect value={processSearchFilterContextLookupsState.searchFilter.environment?.Id} onChange={(environmentId) => {
                            let environment: ProcessEditorSearchFilterNamedResource;
                            if (environmentId) {
                                const resource = lookups.environmentsById[environmentId];
                                environment = {
                                    Id: resource.Id,
                                    Name: resource.Name,
                                };
                            }
                            processSearchFilterContextActions.onFilterChange((t) => ({ ...t, environment }));
                        }} environments={Object.values(lookups.environmentsById)} allowClear={true} allowFilter={true} fieldName="environment"/>
                                            </PermissionCheck>
                                            {processType === ProcessType.Deployment && channelsById && Object.keys(channelsById).length > 1 && (<Select value={processSearchFilterContextLookupsState.searchFilter.channel?.Id} onChange={(channelId) => {
                                let channel: ProcessEditorSearchFilterNamedResource;
                                if (channelId) {
                                    const resource = channelsById[channelId];
                                    channel = {
                                        Id: resource.Id,
                                        Name: resource.Name,
                                    };
                                }
                                processSearchFilterContextActions.onFilterChange((t) => ({ ...t, channel }));
                            }} items={Object.values(channelsById).map((e) => ({ value: e.Id, text: e.Name }))} allowClear={true} allowFilter={true} fieldName="channel"/>)}
                                            <Checkbox label="Include unscoped steps" value={!!processSearchFilterContextLookupsState.searchFilter.includeUnscoped} onChange={(includeUnscoped) => {
                            processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, includeUnscoped }));
                        }}/>
                                        </>),
                },
            ]} onFilterReset={(filter) => processSearchFilterContextActions.onFilterChange(() => filter)} defaultFilter={processSearchFilterContext.getEmptyFilter()} initiallyShowFilter={processSearchFilterContext.isFiltering} additionalHeaderFilters={[
                <FilterSearchBox placeholder="Filter by name..." value={processSearchFilterContextLookupsState.searchFilter.filterKeyword} onChange={(filterKeyword) => processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, filterKeyword }))} autoFocus={true}/>,
            ]} renderContent={() => (<>
                                    <SampleProjectTourStep stepId={"DeploymentProcess"}>
                                        {isDeploymentProcessIdentifier(processIdentifier) && deploymentSettings && (<InvalidConfigurationCallouts processContext={processContext} deploymentSettings={deploymentSettings} processIdentifier={processIdentifier}/>)}
                                        <div className={processListItemStyles.stepList}>
                                            {processSearchFilterContext.filteredSteps.steps.length > 0 ? (processSearchFilterContext.filteredSteps.steps
                    .filter((x) => x.filtered)
                    .map(({ step: filteredStep, index }) => {
                    const step = processContext.selectors.getStepById(filteredStep.Id);
                    if (!step) {
                        logger.info("Failed to find step with name {stepName}", { stepName: filteredStep.Name });
                        return null;
                    }
                    if (step.ActionIds.length === 1) {
                        const action = processContext.selectors.getActionById(step.ActionIds[0]);
                        if (action.ActionType === "Octopus.Blueprint") {
                            const blueprint = blueprints.find((b) => b.Id === getProcessTemplateIdFromAction(action));
                            if (blueprint) {
                                return (<ProcessListPageListItemForBlueprint key={action.Id} step={step} action={action} lookups={lookups} doBusyTask={doBusyTask} blueprint={blueprint} gitRefResource={gitRefResource}/>);
                            }
                        }
                        return (<ProcessListPageListItemForAction key={action.Id} actionTemplates={actionTemplates} step={step} action={action} actionIndex={index} lookups={lookups} gitRefResource={gitRefResource}/>);
                    }
                    else {
                        return <ProcessListPageListItemForParentStep key={step.Id} actionTemplates={actionTemplates} step={step} stepIndex={index} lookups={lookups} gitRefResource={gitRefResource}/>;
                    }
                })) : (<NoResults />)}
                                        </div>
                                    </SampleProjectTourStep>
                                </>)}/>
                    </SidebarLayout>
                </>)}
        </>);
};
ProcessList.displayName = "ProcessList"
function getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps, project: ProjectResource, processIdentifier: DeploymentProcessIdentifier) {
    if (project.AutoCreateRelease) {
        if (project.ReleaseCreationStrategy == null || project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
            return (<div>
                    This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the{" "}
                    <InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug })}>Automatic Release Creation</InternalLink> configuration.
                </div>);
        }
        else {
            const action = flatten(processContext.selectors.getAllActions()).filter((a) => a.Name === project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (<div>
                        <span>
                            Step <InternalLink to={links.deploymentProcessStepsPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug }, { actionId: action[0].Id })}>{action[0].Name}</InternalLink> is
                            currently used for Automatic Release Creation, but it has been disabled.
                        </span>
                        <span>
                            Please re-enable the step, or adjust the{" "}
                            <InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug })}>Automatic Release Creation</InternalLink> configuration.
                        </span>
                    </div>);
            }
        }
    }
    return null;
}
interface InvalidConfigurationCalloutsProps {
    processContext: ProcessContextProps;
    deploymentSettings: DeploymentSettingsResource;
    processIdentifier: DeploymentProcessIdentifier;
}
function InvalidConfigurationCallouts({ processContext, deploymentSettings, processIdentifier }: InvalidConfigurationCalloutsProps) {
    // Using projectContext here since these callouts are only ever shown on deployment processes
    const { state: { model: project }, } = useProjectContext();
    const arcCallout = getInvalidAutomaticReleaseCreationConfigurationCallout(processContext, project, processIdentifier);
    const actions = flatten(processContext.selectors.getAllActions());
    const versioningCallout = isVersioningConfigurationValid(project, deploymentSettings, actions);
    if (arcCallout || versioningCallout) {
        return (<UnstructuredFormSection stretchContent={true}>
                <Callout type={"warning"} title="Invalid Configuration">
                    {arcCallout}
                    {versioningCallout}
                </Callout>
            </UnstructuredFormSection>);
    }
    return null;
}
export default ProcessList;
