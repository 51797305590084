import type { ServerConfigurationSettingsSetResource, ServerConfigurationSettingsValueResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import { Section } from "~/components/Section/Section";
import { DataTable, DataTableBody, DataTableRowHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
interface ServerSettingsState extends DataBaseComponentState {
    settings?: ServerConfigurationSettingsSetResource[];
}
export class ServerSettingsPage extends DataBaseComponent<{}, ServerSettingsState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settings = await repository.ServerConfiguration.settings();
            this.setState({ settings });
        });
    }
    render() {
        const valueMap = (value: ServerConfigurationSettingsValueResource) => (<DataTableRow key={value.Key}>
                <DataTableRowHeaderColumn>{value.Description}</DataTableRowHeaderColumn>
                <DataTableRowColumn>{value.Value}</DataTableRowColumn>
            </DataTableRow>);
        const setMap = (set: ServerConfigurationSettingsSetResource) => (<Section key={set.ConfigurationSet} sectionHeader={set.ConfigurationSet}>
                <DataTable>
                    <DataTableBody>{set.ConfigurationValues.map(valueMap)}</DataTableBody>
                </DataTable>
            </Section>);
        const body = this.state.settings && <div>{this.state.settings.map(setMap)}</div>;
        return (<PageContent header={{ title: "Server Settings", breadcrumbs: [{ label: "Nodes", pageUrl: links.nodesPage.generateUrl() }] }} busy={this.state.busy} errors={this.errors}>
                {body}
            </PageContent>);
    }
    static displayName = "ServerSettingsPage";
}
