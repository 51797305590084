/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { GetAllTenantTagSetsAcrossAllSpacesBffResponse, TagResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { repository } from "~/clientInstance";
const tagSeparator = "/";
const getAll = (): Promise<TagSetResource[]> => repository.TagSets.all();
const getAllTagSetsAcrossAllSpacesBff = (): Promise<GetAllTenantTagSetsAcrossAllSpacesBffResponse> => repository.TagSets.allTagSetsAcrossAllSpaces();
interface TagSetGroup {
    name: string;
    tags: string[];
}
export interface TagSetGroupWithTagResource {
    id: string;
    name: string;
    tags: TagResource[];
}
interface TagIndex {
    [canonicalTagName: string]: TagResource;
}
const getTagIndex = async (): Promise<TagIndex> => {
    const tagSets = await getAll();
    return getTagIndexForTagSets(tagSets);
};
const getTagIndexForTagSets = (tagSets: TagSetResource[]): TagIndex => {
    const idx: {
        [canonicalTagName: string]: TagResource;
    } = {};
    tagSets.forEach((t) => t.Tags.forEach((tag) => {
        if (tag.CanonicalTagName) {
            idx[tag.CanonicalTagName] = tag;
        }
        if (tag.Id) {
            idx[tag.Id] = tag;
        }
    }));
    return idx;
};
const groupByTagSet = (canonicalTagNames: string[]): TagSetGroup[] => {
    const tagSetMap: Map<string, string[]> = new Map();
    canonicalTagNames.forEach((canonicalTagName: string) => {
        const [tagSetName] = canonicalTagName.split(tagSeparator);
        const tagSet = tagSetMap.get(tagSetName);
        if (tagSet) {
            tagSet.push(canonicalTagName);
        }
        else {
            tagSetMap.set(tagSetName, [canonicalTagName]);
        }
    });
    return Array.from(tagSetMap, ([tagSetName, tags]) => ({ name: tagSetName, tags }));
};
const groupAndOrderByTagSet = (canonicalTagNames: string[], tagSets: TagSetResource[]): TagSetGroup[] => {
    const unorderedTagSets = groupByTagSet(canonicalTagNames);
    return unorderedTagSets.sort((a, b) => tagSets.find((x) => x.Name === a.name)!.SortOrder - tagSets.find((x) => x.Name === b.name)!.SortOrder);
};
const groupAndOrderByTagSetAndTag = (canonicalTagNames: string[], tagSets: TagSetResource[]): TagSetGroup[] => {
    const tagSetMap = new Map(tagSets.map((tagSet) => [tagSet.Name, tagSet]));
    const orderedTagSets = groupAndOrderByTagSet(canonicalTagNames, tagSets);
    orderedTagSets.forEach((tagSet, i) => {
        orderedTagSets[i].tags = tagSet.tags.sort((a, b) => {
            const tags = tagSetMap.get(tagSet.name)!.Tags;
            return tags.find((x) => x.CanonicalTagName === a)!.SortOrder - tags.find((x) => x.CanonicalTagName === b)!.SortOrder;
        });
    });
    return orderedTagSets;
};
const flattenGroup = (groups: TagSetGroup[]): string[] => groups.reduce<string[]>((a, group) => a.concat(group.tags), []);
const groupByTagSetWithTagResource = (tags: TagResource[]): TagSetGroupWithTagResource[] => {
    const unorderedTagSets: Map<string, TagSetGroupWithTagResource> = new Map();
    tags.forEach((tag: TagResource) => {
        const [id] = tag.Id.split(tagSeparator);
        const tagSet = unorderedTagSets.get(id);
        if (tagSet) {
            tagSet.tags.push(tag);
        }
        else {
            const [tagSetName] = tag.CanonicalTagName.split(tagSeparator);
            unorderedTagSets.set(id, { id, name: tagSetName, tags: [tag] });
        }
    });
    return Array.from(unorderedTagSets, ([, tagSetGroupWithTagResource]) => tagSetGroupWithTagResource);
};
const groupByTagSetWithTagResourceAndOrderByTagSetAndTag = (tags: TagResource[], tagSets: TagSetResource[]): TagSetGroupWithTagResource[] => {
    const tagSetMap = new Map(tagSets.map((tagSet) => [tagSet.Id, tagSet]));
    const unorderedTagSets = groupByTagSetWithTagResource(tags);
    const orderedTagSets = unorderedTagSets.sort((a, b) => tagSets.find((x) => x.Name === a.name)!.SortOrder - tagSets.find((x) => x.Name === b.name)!.SortOrder);
    orderedTagSets.forEach((tagSet, i) => {
        orderedTagSets[i].tags = tagSet.tags.sort((a, b) => {
            const tags = tagSetMap.get(tagSet.id)!.Tags;
            return tags.find((x) => x.CanonicalTagName === a.CanonicalTagName)!.SortOrder - tags.find((x) => x.CanonicalTagName === b.CanonicalTagName)!.SortOrder;
        });
    });
    return orderedTagSets;
};
interface TenantTagsLookup {
    tagSets: Map<string, TagSetResource>;
    tags: Map<string, TagWithSet>;
}
interface TagWithSet {
    tag: TagResource;
    set: TagSetResource;
}
const loadTenantTagsLookup = async (): Promise<TenantTagsLookup> => {
    const tagSets = await getAll();
    const setLookup = tagSets.reduce((lookup, ts) => lookup.set(ts.Id, ts), new Map());
    const tagLookup = tagSets.flatMap((ts) => ts.Tags.map((tag) => ({ tag, set: ts }))).reduce((lookup, item) => lookup.set(item.tag.Id, item), new Map<string, TagWithSet>());
    return { tagSets: setLookup, tags: tagLookup };
};
export { getAll, getAllTagSetsAcrossAllSpacesBff, getTagIndex, getTagIndexForTagSets, groupByTagSet, groupAndOrderByTagSet, groupAndOrderByTagSetAndTag, groupByTagSetWithTagResource, groupByTagSetWithTagResourceAndOrderByTagSetAndTag, flattenGroup, loadTenantTagsLookup, TagIndex, TenantTagsLookup, TagWithSet, };
