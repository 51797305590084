/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Checkbox, Tooltip } from "@octopusdeploy/design-system-components";
import type { TenantResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import FilterSearchBox from "~/components/FilterSearchBox";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import TagsList from "~/components/TagsList/TagsList";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import { withTheme } from "~/components/Theme";
import type { TagIndex } from "~/components/tenantTagsets";
import * as tenantTagsets from "~/components/tenantTagsets";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import styles from "./style.module.less";
interface TenantTagDesignDialogLayoutProps {
    hideTenantsByName?: boolean;
    selectedTags?: string[];
    selectedTenants?: string[];
    availableTenants?: string[];
    emptyFilterMeansAllTenants?: boolean;
    onUpdate(selectedTenants: string[], selectedTags: string[]): void;
}
interface TenantTagDesignDialogLayoutState extends DataBaseComponentState {
    selectedTags: string[];
    selectedTenants: string[];
    matchCount: number;
    matchResults: MatchResult[];
    allTenants: TenantResource[];
    tenantIndex: {
        [tenantId: string]: TenantResource;
    };
    allTags: TagSetResource[];
    tagIndex: TagIndex;
    searchText: string;
    onlyShowMatching: boolean;
}
interface MatchResult {
    id: string;
    name: string;
    reason: string;
    isMatched: boolean;
    isMatchedByName: boolean;
    missingTags: string[];
    matchedTags: string[];
    isDisabled: boolean | null;
}
class TenantTagDesignDialogLayout extends DataBaseComponent<TenantTagDesignDialogLayoutProps, TenantTagDesignDialogLayoutState> {
    constructor(props: TenantTagDesignDialogLayoutProps) {
        super(props);
        this.state = {
            selectedTags: this.props.selectedTags || [],
            selectedTenants: this.props.selectedTenants || [],
            matchCount: 0,
            allTenants: undefined!,
            allTags: undefined!,
            tagIndex: null!,
            matchResults: [],
            searchText: "",
            onlyShowMatching: true,
            tenantIndex: {},
        };
    }
    tagChanged = (tags: string[]) => {
        this.setState({ selectedTags: tags }, () => this.runTagTest());
    };
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const [allTenants, allTags, tagIndex] = await Promise.all([tenantsPromise, tenantTagsets.getAll(), tenantTagsets.getTagIndex()]);
            const tenantIndex = createTenantIndex(allTenants);
            let availableTenants = allTenants;
            if (this.props.availableTenants) {
                availableTenants = allTenants.filter((t) => this.props.availableTenants!.indexOf(t.Id) !== -1);
            }
            this.setState({ allTenants: availableTenants, allTags, tenantIndex, tagIndex }, () => this.runTagTest());
        });
    }
    tenantChanged = (tags: string[]) => {
        this.setState({ selectedTenants: tags }, () => this.runTagTest());
    };
    async runTagTest() {
        await this.doBusyTask(async () => {
            const result = await repository.Tenants.tagTest(this.state.selectedTenants, this.state.selectedTags);
            let matchCount = 0;
            const matchResults = Object.keys(result)
                .map((tenantId): MatchResult => {
                const tenantTestResult = result[tenantId];
                const tenant = this.state.tenantIndex[tenantId];
                const isAvailable = !this.props.availableTenants || this.props.availableTenants.indexOf(tenantId) !== -1;
                if (this.props.emptyFilterMeansAllTenants && this.state.selectedTenants.length === 0 && this.state.selectedTags.length === 0) {
                    tenantTestResult.IsMatched = true;
                }
                const isMatch = tenantTestResult.IsMatched && isAvailable;
                if (isMatch) {
                    matchCount++;
                }
                const matchedTags = tenant.TenantTags.filter((tag) => this.state.selectedTags.indexOf(tag) !== -1);
                return {
                    id: tenantId,
                    name: tenant.Name,
                    reason: isAvailable ? tenantTestResult.Reason : "Tenant not available in this context",
                    missingTags: tenantTestResult.MissingTags,
                    isMatchedByName: this.state.selectedTenants.indexOf(tenantId) !== -1,
                    matchedTags,
                    isMatched: isMatch,
                    isDisabled: tenant.IsDisabled,
                };
            })
                .sort((resultA, resultB) => {
                if (resultA.isMatched && !resultB.isMatched) {
                    return -1;
                }
                else if (!resultA.isMatched && resultB.isMatched) {
                    return 1;
                }
                if (!resultA.isDisabled && resultB.isDisabled) {
                    return -1;
                }
                else if (resultA.isDisabled && !resultB.isDisabled) {
                    return 1;
                }
                if (resultA.name < resultB.name) {
                    return -1;
                }
                else if (resultA.name > resultB.name) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
            this.setState({ matchCount, matchResults });
        });
    }
    render() {
        if (!this.state.allTenants) {
            return null;
        }
        const showTenantsByTag = this.state.allTags.some((ts) => ts.Tags.length > 0);
        const searchText = this.state.searchText.toLowerCase();
        return withTheme((theme) => (<OkDialogLayout title={"Tenant Preview"} errors={this.errors} busy={this.state.busy} onOkClick={() => {
                this.props.onUpdate(this.state.selectedTenants, this.state.selectedTags);
                return true;
            }}>
                <div>
                    {!this.props.hideTenantsByName && <h3>Include tenants by name</h3>}
                    {!this.props.hideTenantsByName && <TenantMultiSelect onChange={this.tenantChanged} value={this.state.selectedTenants} items={this.state.allTenants}/>}

                    {showTenantsByTag && (<>
                            <h3>Include tenants by tags</h3>
                            <TenantTagMultiSelect onChange={this.tagChanged} value={this.state.selectedTags} doBusyTask={this.doBusyTask} items={this.state.allTags}/>
                        </>)}

                    <h3>
                        Preview - {this.state.matchCount} matching tenant{this.state.matchCount === 1 ? "" : "s"}
                    </h3>
                    <div className={styles.filterResults}>
                        <FilterSearchBox placeholder="Filter results..." onChange={(value) => this.setState({ searchText: value })}/>
                        <Checkbox label="Only show matching" value={this.state.onlyShowMatching} onChange={(e) => this.setState({ onlyShowMatching: !this.state.onlyShowMatching })} className={styles.checkBox}/>
                    </div>
                </div>
                <div>
                    <TenantMatchResultsPreview matches={this.state.matchResults.filter((t) => (t.isMatched || !this.state.onlyShowMatching) && (this.state.searchText.length < 1 || t.name.toLowerCase().includes(searchText)))} tagIndex={this.state.tagIndex}/>
                </div>
            </OkDialogLayout>));
    }
    static displayName = "TenantTagDesignDialogLayout";
}
function createTenantIndex(tenants: TenantResource[]) {
    return tenants.reduce<Record<string, TenantResource>>((prev, current) => {
        prev[current.Id] = current;
        return prev;
    }, {});
}
interface TenantMatchResultsPreviewProps {
    matches: MatchResult[];
    tagIndex: TagIndex;
}
function TenantMatchResultsPreview({ matches, tagIndex }: TenantMatchResultsPreviewProps) {
    return (<DataTable className={tenantResultsTableStyles}>
            <DataTableBody>
                {matches.map((row, index) => (<DataTableRow key={index} disabled={row.isDisabled}>
                        <DataTableRowColumn>{row.isMatched && <em className="fa-solid fa-check"/>}</DataTableRowColumn>
                        <DataTableRowColumn>{row.name}</DataTableRowColumn>
                        <DataTableRowColumn>
                            <TenantMatchReason match={row} tagIndex={tagIndex}/>{" "}
                        </DataTableRowColumn>
                    </DataTableRow>))}
            </DataTableBody>
        </DataTable>);
}
const tenantResultsTableStyles = css({
    tableLayout: "fixed",
});
function TenantMatchReason({ match, tagIndex }: {
    match: MatchResult;
    tagIndex: TagIndex;
}) {
    if (match.isMatchedByName) {
        return null;
    }
    if (match.isMatched) {
        return <TagsList canonicalNames={match.matchedTags} tagIndex={tagIndex}/>;
    }
    const content = match.reason === "Missing Tags" ? (<div>
                <div>Missing Tags</div>
                <TenantTagsList tags={match.missingTags}/>
            </div>) : (match.reason);
    return content ? (<Tooltip content={content}>
            <em className="fa-solid fa-circle-info"/>
        </Tooltip>) : null;
}
export default TenantTagDesignDialogLayout;
