import type { ActionTemplateUsageResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
export const ActionTemplateUsageProcessLink: React.FC<{
    spaceId: string;
    usage: ActionTemplateUsageResource;
}> = ({ spaceId, usage }) => {
    switch (usage.ProcessType) {
        case ProcessType.Runbook:
            return <InternalLink to={links.projectRunbookProcessListPage.generateUrl({ spaceId, projectSlug: usage.ProjectSlug, runbookId: usage.RunbookId, processId: usage.ProcessId })}>{`Runbook: ${usage.RunbookName}`}</InternalLink>;
        case ProcessType.Deployment:
            return <InternalLink to={links.deploymentProcessPage.generateUrl({ spaceId, projectSlug: usage.ProjectSlug })}>{usage.ProcessType}</InternalLink>;
        case ProcessType.Blueprint:
            throw new Error("Blueprint is not supported in ActionTemplateUsage");
    }
    exhaustiveCheck(usage.ProcessType, "Not all process types have been handled.");
};
ActionTemplateUsageProcessLink.displayName = "ActionTemplateUsageProcessLink"
export const ActionTemplateUsageStepLink: React.FC<{
    spaceId: string;
    usage: ActionTemplateUsageResource;
}> = ({ spaceId, usage }) => {
    const to = getProcessStepLinkForUsage(spaceId, usage);
    return (<InternalLink to={to} openInSelf={false}>
            {usage.ActionName}
        </InternalLink>);
};
ActionTemplateUsageStepLink.displayName = "ActionTemplateUsageStepLink"
function getProcessStepLinkForUsage(spaceId: string, usage: ActionTemplateUsageResource) {
    if (usage.ProcessType === ProcessType.Deployment) {
        return usage.Branch
            ? links.branchDeploymentProcessStepsPage.generateUrl({ spaceId, projectSlug: usage.ProjectSlug, branchName: usage.Branch }, { actionId: usage.ActionId })
            : links.deploymentProcessStepsPage.generateUrl({ spaceId, projectSlug: usage.ProjectSlug }, { actionId: usage.ActionId });
    }
    else {
        return links.projectRunbookProcessStepsPage.generateUrl({ spaceId, projectSlug: usage.ProjectSlug, runbookId: usage.RunbookId, processId: usage.ProcessId }, { actionId: usage.ActionId });
    }
}
