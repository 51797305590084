import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function UnknownIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#757575"/>
            <path d="M18.8571 17.8286C18.8571 15.5593 20.7021 13.7143 22.9714 13.7143H25.0285C27.2978 13.7143 29.1428 15.5593 29.1428 17.8286V18.0601C29.1428 19.4615 28.4292 20.7665 27.2528 21.5186L24.5399 23.2608C22.9199 24.3022 21.9428 26.0958 21.9428 28.0179V28.1143C21.9428 29.2522 22.8621 30.1715 23.9999 30.1715C25.1378 30.1715 26.0571 29.2522 26.0571 28.1143V28.0243C26.0571 27.4972 26.3271 27.0086 26.7642 26.7258L29.4771 24.9836C31.8299 23.4665 33.2571 20.8629 33.2571 18.0601V17.8286C33.2571 13.2836 29.5735 9.60005 25.0285 9.60005H22.9714C18.4264 9.60005 14.7428 13.2836 14.7428 17.8286C14.7428 18.9665 15.6621 19.8858 16.7999 19.8858C17.9378 19.8858 18.8571 18.9665 18.8571 17.8286ZM23.9999 38.4001C24.6819 38.4001 25.336 38.1291 25.8182 37.6469C26.3005 37.1647 26.5714 36.5106 26.5714 35.8286C26.5714 35.1466 26.3005 34.4926 25.8182 34.0103C25.336 33.5281 24.6819 33.2572 23.9999 33.2572C23.318 33.2572 22.6639 33.5281 22.1817 34.0103C21.6994 34.4926 21.4285 35.1466 21.4285 35.8286C21.4285 36.5106 21.6994 37.1647 22.1817 37.6469C22.6639 38.1291 23.318 38.4 23.9999 38.4001Z" fill="white"/>
        </svg>);
}
