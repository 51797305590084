import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function DegradedIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#D63D3D"/>
            <path d="M16.3162 11.7953C17.6269 11.5759 18.9487 11.6884 20.175 12.0934L22.9762 16.4528L18.735 20.6941C18.6506 20.7784 18.6 20.8966 18.6056 21.0203C18.6112 21.1441 18.6618 21.2566 18.7519 21.3409L25.0519 27.1909C25.215 27.3428 25.4681 27.3541 25.6425 27.2078C25.8169 27.0616 25.8562 26.8141 25.7381 26.6228L22.3406 21.1047L27.4425 16.8522C27.5887 16.7341 27.6394 16.5316 27.5775 16.3572L26.295 12.7909C27.8981 11.8516 29.805 11.4859 31.6781 11.8009C35.5593 12.4422 38.4 15.7947 38.4 19.7266V20.0528C38.4 22.3872 37.4325 24.6203 35.7225 26.2122L25.5581 35.7016C25.1362 36.0953 24.5794 36.3147 24 36.3147C23.4206 36.3147 22.8637 36.0953 22.4419 35.7016L12.2775 26.2122C10.5675 24.6203 9.59998 22.3872 9.59998 20.0528V19.7266C9.59998 15.7947 12.4406 12.4422 16.3162 11.7953Z" fill="white"/>
        </svg>);
}
