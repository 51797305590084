/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, UnavailableIcon } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { AzureCloudServiceEndpointResource, AzureWebAppEndpointResource, CommunicationStyle, DeploymentTargetResource, EndpointResource, EnvironmentResource, KubernetesTentacleEndpointResource, KubernetesEndpointResource, ListeningTentacleEndpointResource, MachineResource, OfflineDropEndpointResource, PollingTentacleEndpointResource, SshEndpointResource, TenantResource, WorkerMachineResource, WorkerPoolResource, } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper, isDeploymentTarget, isStepPackageEndpointResource, isWorkerMachine, OfflineDropDestinationType, WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import * as React from "react";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import type { EndpointLogoProps, EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { BuiltInEndpointLogo, createStepPackageEndpointLogo } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import Chip from "~/components/Chips/Chip";
import { EnvironmentChip, environmentChipList, RoleChip, TenantChip, tenantChipList, WorkerPoolChip, workerPoolChipList } from "~/components/Chips/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import Tag from "~/components/Tag";
import { withTheme } from "~/components/Theme";
import type { TagIndex } from "~/components/tenantTagsets";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import styles from "./style.module.less";
interface MachineRowProps {
    machine: MachineResource;
    tenants?: TenantResource[];
    tagIndex?: TagIndex;
    environments?: EnvironmentResource[];
    workerPools?: WorkerPoolResource[];
    needsUpgrading?: boolean;
    registrations: EndpointRegistration[];
    showV2Page?: boolean;
}
type MachineRowInternalProps = MachineRowProps & {
    navigate: (to: string) => void;
};
interface MachineRowState {
    showAllChips: boolean;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    workerPools: WorkerPoolResource[];
}
const noEndpointSummary = (): React.ReactNode => null;
const endpointSummaries: {
    [index in CommunicationStyle]: (resource: EndpointResource) => React.ReactNode;
} = {
    TentaclePassive: ((endpoint: ListeningTentacleEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    TentacleActive: ((endpoint: PollingTentacleEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    AzureWebApp: ((endpoint: AzureWebAppEndpointResource) => {
        const slotName = endpoint.WebAppSlotName ? "/" + endpoint.WebAppSlotName : "";
        return endpoint.WebAppName + slotName;
    }) as (endpoint: EndpointResource) => React.ReactNode,
    None: noEndpointSummary,
    AzureCloudService: ((endpoint: AzureCloudServiceEndpointResource) => endpoint.CloudServiceName) as (endpoint: EndpointResource) => React.ReactNode,
    OfflineDrop: ((endpoint: OfflineDropEndpointResource) => {
        return endpoint.Destination.DestinationType === OfflineDropDestinationType.FileSystem ? endpoint.Destination.DropFolderPath : "Octopus Artifact";
    }) as (endpoint: EndpointResource) => React.ReactNode,
    Ssh: ((endpoint: SshEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    Kubernetes: ((endpoint: KubernetesEndpointResource) => endpoint.ClusterUrl) as (endpoint: EndpointResource) => React.ReactNode,
    KubernetesTentacle: ((endpoint: KubernetesTentacleEndpointResource) => endpoint.TentacleEndpointConfiguration.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    AzureServiceFabricCluster: noEndpointSummary,
    StepPackage: noEndpointSummary,
};
class MachineRowInternal extends BaseComponent<MachineRowInternalProps, MachineRowState> {
    private machineChipsDisplayThreshold = 3; //Show 3 chips max, then "show all" link.
    constructor(props: MachineRowInternalProps) {
        super(props);
        this.state = {
            showAllChips: false,
            tenants: props.tenants || [],
            tagIndex: props.tagIndex || {},
            environments: props.environments || [],
            workerPools: props.workerPools || [],
        };
    }
    render() {
        const machine = this.props.machine;
        const RegistrationLogo = isStepPackageEndpointResource(machine.Endpoint) ? createStepPackageEndpointLogo(machine.Endpoint) : BuiltInEndpointLogo;
        if (this.props.showV2Page) {
            if (isWorkerMachine(machine)) {
                return this.renderWorkerRow(machine, RegistrationLogo);
            }
            else if (isDeploymentTarget(machine)) {
                return this.renderDeploymentTargetRow(machine, RegistrationLogo);
            }
        }
        return (<div className={styles.machineRow} key={`${machine.Id}-${this.state.showAllChips.toString()}`}>
                <RegistrationLogo machine={machine}/>
                <div className={styles.machineNameContainer}>
                    <div className={styles.machineName}>{machine.Name}</div>
                    <div className={styles.machineType}>{EndpointsHelper.getFriendlyName(machine)}</div>
                    <div className={styles.machineSummary}>{this.getSummaryForEndpoint(machine.Endpoint)}</div>
                </div>
                <div className={styles.healthStatusIconContainer}>{machine.IsDisabled ? <DisabledMachineIcon /> : <MachineHealthStatusIcon healthStatus={machine.HealthStatus}/>}</div>
                <div className={styles.chipContainer}>{this.renderMachineChips(machine)}</div>
                {this.props.needsUpgrading ? (<div className={styles.upgradeAvailable}>
                        <span>Upgrade available</span>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Update}/>
                    </div>) : ("")}
            </div>);
    }
    private renderWorkerRow(worker: WorkerMachineResource, RegistrationLogo: React.ComponentClass<EndpointLogoProps> | React.FunctionComponent<EndpointLogoProps>) {
        const redirectToPage = (target: WorkerMachineResource) => () => {
            const link = links.workerMachineSettingsPage.generateUrl({
                spaceId: target.SpaceId,
                machineId: target.Id,
            });
            this.props.navigate(link.resolveWithSpaceId(target.SpaceId));
        };
        return (<tr>
                <td className={machineNameColumnStyles}>
                    <div className={deploymentTargetRowTitleStyles} onClick={redirectToPage(worker)}>
                        <RegistrationLogo machine={worker} variant="medium"/>
                        <div className={deploymentTargetRowTitleInnerStyles}>
                            <div className={deploymentTargetNameStyles}>{worker.Name}</div>
                            <div className={deploymentTargetSubTitleStyles}>{EndpointsHelper.getFriendlyName(worker)}</div>
                            <div className={deploymentTargetSummaryStyles}>{this.getSummaryForEndpoint(worker.Endpoint)}</div>
                            {this.props.needsUpgrading && (<div className={upgradeAvailableStyles}>
                                    <ThirdPartyIcon iconType={ThirdPartyIconType.Update}/>
                                    <span>Upgrade available</span>
                                </div>)}
                        </div>
                    </div>
                </td>
                <td className={machineStatusColumnStyles}>{worker.IsDisabled ? <DisabledMachineIcon iconSize={"medium"}/> : <MachineHealthStatusIcon healthStatus={worker.HealthStatus} iconSize={"medium"}/>}</td>
                <td className={environmentListColumnStyles}>{this.renderWorkerPoolListV2(worker.WorkerPoolIds)}</td>
            </tr>);
    }
    private renderDeploymentTargetRow(target: DeploymentTargetResource, RegistrationLogo: React.ComponentClass<EndpointLogoProps> | React.FunctionComponent<EndpointLogoProps>) {
        const redirectToPage = (target: DeploymentTargetResource) => () => {
            const link = links.deploymentTargetSettingsPage.generateUrl({
                spaceId: target.SpaceId,
                machineId: target.Id,
            });
            this.props.navigate(link.resolveWithSpaceId(target.SpaceId));
        };
        return (<tr>
                <td className={machineNameColumnStyles}>
                    <div className={deploymentTargetRowTitleStyles} onClick={redirectToPage(target)}>
                        <RegistrationLogo machine={target} variant="medium"/>
                        <div className={deploymentTargetRowTitleInnerStyles}>
                            <div className={deploymentTargetNameStyles}>{target.Name}</div>
                            <div className={deploymentTargetSubTitleStyles}>{EndpointsHelper.getFriendlyName(target)}</div>
                            <div className={deploymentTargetSummaryStyles}>{this.getSummaryForEndpoint(target.Endpoint)}</div>
                            {this.props.needsUpgrading && (<div className={upgradeAvailableStyles}>
                                    <ThirdPartyIcon iconType={ThirdPartyIconType.Update}/>
                                    <span>Upgrade available</span>
                                </div>)}
                        </div>
                    </div>
                </td>
                <td className={machineStatusColumnStyles}>{target.IsDisabled ? <DisabledMachineIcon iconSize={"medium"}/> : <MachineHealthStatusIcon healthStatus={target.HealthStatus} iconSize={"medium"}/>}</td>
                <td className={environmentListColumnStyles}>{this.renderEnvironmentsListV2(target.EnvironmentIds)}</td>
                <td className={tagsColumnStyles}>{this.renderRolesListV2(target.Roles)}</td>
                <td className={tenantsColumnStyles}>{target.TenantIds.length > 0 ? this.renderTenantsListV2(target.TenantIds) : <NoData label="No tenants"/>}</td>
                <td className={tenantTagsColumnStyles}>{target.TenantTags.length > 0 ? this.renderTenantTagsListV2(target.TenantTags) : <NoData label="No tenant tags"/>}</td>
            </tr>);
    }
    private renderWorkerPoolListV2(workerPoolIds: string[]) {
        if (this.state.showAllChips) {
            return this.renderWorkerPoolsList(workerPoolIds);
        }
        const workerPools = workerPoolIds.map((id) => this.state.workerPools.find((wp) => wp.Id === id));
        if (workerPools.length === 0) {
            return <NoData label="No worker pools"/>;
        }
        return (<>
                {this.renderWorkerPoolsList([workerPoolIds[0]])}
                {workerPoolIds.length > 1 && (<a className={expandStyles} onClick={() => this.setState({ showAllChips: !this.state.showAllChips })}>
                        +{workerPoolIds.length - 1} {pluralize("worker pool", workerPoolIds.length - 1)}
                    </a>)}
            </>);
    }
    private renderEnvironmentsListV2(environmentIds: string[]) {
        if (this.state.showAllChips) {
            return this.renderEnvironmentsList(environmentIds);
        }
        const environments = environmentIds.map((id) => this.state.environments.find((e) => e.Id === id));
        if (environments.length === 0) {
            return <NoData label="No environments"/>;
        }
        return (<>
                {this.renderEnvironmentsList([environmentIds[0]])}
                {environmentIds.length > 1 && (<a className={expandStyles} onClick={() => this.setState({ showAllChips: !this.state.showAllChips })}>
                        +{environmentIds.length - 1} {pluralize("environment", environmentIds.length - 1)}
                    </a>)}
            </>);
    }
    private renderRolesListV2(roles: string[]) {
        if (this.state.showAllChips) {
            return this.renderRolesList(roles);
        }
        if (roles.length === 0) {
            return <NoData label="No tags"/>;
        }
        return (<>
                {this.renderRolesList(roles, 1)}
                {roles.length > 1 && (<a className={expandStyles} onClick={() => this.setState({ showAllChips: !this.state.showAllChips })}>
                        +{roles.length - 1} {pluralize("tag", roles.length - 1)}
                    </a>)}
            </>);
    }
    private renderTenantsListV2(tenantIds: string[]) {
        if (this.state.showAllChips) {
            return tenantChipList(this.state.tenants, tenantIds);
        }
        const tenant = this.state.tenants.find((t) => t.Id === tenantIds[0]);
        if (tenant === undefined) {
            return <NoData label="No tenants"/>;
        }
        return (<>
                <TenantChip tenantName={tenant.Name} isDisabled={!!tenant.IsDisabled}/>
                {tenantIds.length > 1 && (<a className={expandStyles} onClick={() => this.setState({ showAllChips: !this.state.showAllChips })}>
                        +{tenantIds.length - 1} {pluralize("tenant", tenantIds.length - 1)}
                    </a>)}
            </>);
    }
    private renderTenantTagsListV2(tenantTags: string[]) {
        if (this.state.showAllChips) {
            return this.renderTenantTagsList(tenantTags);
        }
        const tags = tenantTags.map((name) => this.state.tagIndex[name]);
        if (tags.length === 0) {
            return <NoData label="No tenant tags"/>;
        }
        return (<>
                <Tag tagName={tags[0].Name} description={tags[0].Description} tagColor={tags[0].Color}/>
                {tags.length > 1 && (<a className={expandStyles} onClick={() => this.setState({ showAllChips: !this.state.showAllChips })}>
                        +{tags.length - 1} tenant {pluralize("tag", tags.length - 1)}
                    </a>)}
            </>);
    }
    private renderMachineChips(machine: MachineResource) {
        if (isWorkerMachine(machine)) {
            return this.renderWorkerChips(machine);
        }
        else if (isDeploymentTarget(machine)) {
            return this.renderDeploymentTargetResourceChips(machine);
        }
    }
    private renderWorkerChips(worker: WorkerMachineResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const includeShowHideControl = this.state.workerPools && worker.WorkerPoolIds.length >= chipThreshold;
        const chipsToDisplay = [];
        if (this.state.workerPools) {
            let environmentsChipsList: React.ReactElement | React.ReactElement[];
            if (worker.WorkerPoolIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <WorkerPoolChip workerPoolType={WorkerPoolType.Static} workerPoolName={`${worker.WorkerPoolIds.length.toLocaleString()} workerPools`} key="workerPoolsChipsList"/>;
            }
            else {
                environmentsChipsList = this.renderWorkerPoolsList(worker.WorkerPoolIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }
        if (includeShowHideControl) {
            return (<div className={styles.chipContainer}>
                    {chipsToDisplay}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} onClick={(e: React.MouseEvent<Element, MouseEvent>) => this.setState({ showAllChips: !this.state.showAllChips })}/>
                </div>);
        }
        return chipsToDisplay;
    }
    private renderDeploymentTargetResourceChips(deploymentTarget: DeploymentTargetResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const tags = deploymentTarget.TenantTags.map((name) => this.state.tagIndex[name]);
        const tagCount = tags.length;
        const includeShowHideControl = (this.state.environments && deploymentTarget.EnvironmentIds.length >= chipThreshold) || deploymentTarget.Roles.length >= chipThreshold || deploymentTarget.TenantIds.length >= chipThreshold || tagCount >= chipThreshold;
        const chipsToDisplay = [];
        if (this.state.environments) {
            let environmentsChipsList: React.ReactElement | React.ReactElement[];
            if (deploymentTarget.EnvironmentIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <EnvironmentChip environmentName={`${deploymentTarget.EnvironmentIds.length.toLocaleString()} environments`} key="environmentsChipsList"/>;
            }
            else {
                environmentsChipsList = this.renderEnvironmentsList(deploymentTarget.EnvironmentIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }
        let roleChipsList: React.ReactElement | React.ReactElement[];
        // eslint-disable-next-line:prefer-conditional-expression
        if (deploymentTarget.Roles.length >= chipThreshold && !this.state.showAllChips) {
            roleChipsList = <RoleChip role={`${deploymentTarget.Roles.length.toLocaleString()} roles`} key="roleChipsList"/>;
        }
        else {
            roleChipsList = this.renderRolesList(deploymentTarget.Roles);
        }
        chipsToDisplay.push(roleChipsList);
        let tenantChipsList: React.ReactElement | React.ReactElement[];
        if (deploymentTarget.TenantIds.length >= chipThreshold && !this.state.showAllChips) {
            tenantChipsList = <TenantChip tenantName={`${deploymentTarget.TenantIds.length.toLocaleString()} tenants`} key="tenantChipsList"/>;
        }
        else {
            tenantChipsList = this.renderTenantsList(deploymentTarget.TenantIds);
        }
        chipsToDisplay.push(tenantChipsList);
        let tenantTagChipsList: React.ReactElement | React.ReactElement[];
        if (tagCount >= chipThreshold && !this.state.showAllChips) {
            const tagsList = deploymentTarget.TenantTags.map((tt) => {
                const tagResource = this.state.tagIndex[tt];
                return <Tag key={tt} tagName={tagResource.Name} description={tagResource.Description} tagColor={tagResource.Color} small={true}/>;
            });
            tenantTagChipsList = withTheme((theme) => (<Chip labelColor={theme.primaryText} backgroundColor={theme.secondaryBackground} key="tenantTagChipsList">
                    {`${tagCount.toLocaleString()} tags`} {tagsList}
                </Chip>));
        }
        else {
            tenantTagChipsList = this.renderTenantTagsList(deploymentTarget.TenantTags);
        }
        chipsToDisplay.push(tenantTagChipsList);
        if (includeShowHideControl) {
            return (<div className={styles.chipContainer}>
                    {chipsToDisplay}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} onClick={(e: React.MouseEvent<Element, MouseEvent>) => this.setState({ showAllChips: !this.state.showAllChips })}/>
                </div>);
        }
        return chipsToDisplay;
    }
    private renderEnvironmentsList(environmentIds: string[]) {
        return environmentChipList(this.state.environments, environmentIds);
    }
    private renderWorkerPoolsList(workerPoolIds: string[]) {
        return workerPoolChipList(this.state.workerPools, workerPoolIds);
    }
    private renderRolesList(roles: string[], maxRolesToShow?: number) {
        if (maxRolesToShow) {
            return roles.slice(0, maxRolesToShow).map((r) => <RoleChip role={r} key={"role-" + r}/>);
        }
        return roles.map((r) => <RoleChip role={r} key={"role-" + r}/>);
    }
    private renderTenantsList(tenantIds: string[]) {
        return tenantChipList(this.state.tenants, tenantIds);
    }
    private renderTenantTagsList(tenantTags: string[]) {
        const tags = tenantTags.map((name) => this.state.tagIndex[name]);
        return tags.map((tag) => <Tag tagName={tag.Name} description={tag.Description} tagColor={tag.Color} key={tag.Name}/>);
    }
    private getSummaryForEndpoint(endpoint: EndpointResource) {
        const summary = endpointSummaries[endpoint.CommunicationStyle];
        if (summary) {
            return summary(endpoint);
        }
        return null;
    }
    static displayName = "MachineRowInternal";
}
function MachineRow(props: MachineRowProps) {
    const { navigate } = useSpaceAwareNavigation();
    return <MachineRowInternal navigate={navigate} {...props}/>;
}
export default MachineRow;
const deploymentTargetRowTitleStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: space["12"],
    padding: space["8"],
    cursor: "pointer",
    borderRadius: "4px",
    border: `1px solid transparent`,
    "&:hover": {
        border: `1px solid ${themeTokens.color.border.selected}`,
        background: themeTokens.color.background.secondary.default,
        color: themeTokens.color.text.link.default,
    },
});
const deploymentTargetRowTitleInnerStyles = css({
    display: "flex",
    flexDirection: "column",
});
const deploymentTargetNameStyles = css({
    font: text.regular.bold.medium,
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
});
const deploymentTargetSubTitleStyles = css({
    font: text.regular.bold.xSmall,
    color: themeTokens.color.text.secondary,
});
const deploymentTargetSummaryStyles = css({
    font: text.regular.default.xSmall,
    color: themeTokens.color.text.secondary,
});
const noDataStyles = css({
    display: "flex",
    columnGap: space["6"],
    font: text.regular.bold.medium,
    color: themeTokens.color.text.disabled,
    alignItems: "center",
    padding: space["6"],
});
const expandStyles = css({
    cursor: "pointer",
    color: themeTokens.color.text.link.default,
    marginLeft: space["8"],
    font: text.regular.bold.xSmall,
    fontWeight: 700,
});
interface NoDataProps {
    label: string;
}
const machineNameColumnStyles = css({
    width: "33%",
});
const machineStatusColumnStyles = css({
    width: "3%",
    minWidth: "40px",
});
const environmentListColumnStyles = css({
    width: "16%",
});
const tagsColumnStyles = css({
    width: "16%",
});
const tenantsColumnStyles = css({
    width: "16%",
});
const tenantTagsColumnStyles = css({
    width: "16%",
});
const upgradeAvailableStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["4"],
    font: text.regular.bold.small,
    color: themeTokens.color.text.info,
    "& svg": {
        fill: themeTokens.color.text.secondary,
        height: text.regular.bold.small,
    },
});
function NoData({ label }: NoDataProps) {
    return (<div className={noDataStyles}>
            <UnavailableIcon /> {label}
        </div>);
}
