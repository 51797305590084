import type { GitPersistenceSettings, GitRef, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import type { GitRefQueryParams, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useEffect, useRef, useState } from "react";
import { lastAccessedPlatformHubGitRef } from "~/areas/blueprints/LastAccessedPlatformHubGitRef";
import { repository } from "~/clientInstance";
export function useRequiredPlatformHubGitRef(gitPersistenceSettings: GitPersistenceSettings, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>, initialValidationResult: ValidateGitRefV2Response | undefined): [
    GitRef,
    (gitRef: string) => void,
    ValidateGitRefV2Response | undefined
] {
    const [gitRef, setGitRef, validationResult] = usePlatformHubGitRef(gitPersistenceSettings, queryParams, setQueryParams, initialValidationResult);
    if (!gitRef) {
        throw new Error("No GitRef found. Please ensure that Platform Hub version control settings are configured");
    }
    return [gitRef, setGitRef, validationResult];
}
export function usePlatformHubGitRef(gitPersistenceSettings: GitPersistenceSettings, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>, initialValidationResult: ValidateGitRefV2Response | undefined): [
    GitRef | undefined,
    (gitRef: string) => void,
    ValidateGitRefV2Response | undefined
] {
    const validatedGitRef = useValidatedGitRef(queryParams.gitRef, initialValidationResult);
    useEffect(() => {
        if (validatedGitRef && validatedGitRef.GitRef) {
            // If we do have a GitRef in the query parameters, but it's different to our validated one, update it.
            const gitRef = validatedGitRef.GitRef.CanonicalName;
            setQueryParams((prev) => ({ ...prev, gitRef }));
            // Keep the last accessed GitRef up to date
            lastAccessedPlatformHubGitRef.save(validatedGitRef.GitRef.CanonicalName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedGitRef]);
    if (!gitPersistenceSettings.Url) {
        // No version control configured, don't try to set the gitRef
        return [undefined, () => { }, undefined];
    }
    if (!queryParams.gitRef) {
        // If we don't have the GitRef in the query parameter, fill it with the last accessed one.
        setQueryParams((prev) => ({ ...prev, gitRef: lastAccessedPlatformHubGitRef.get(gitPersistenceSettings) }));
    }
    return [
        // Hierarchy of GitRef preference:
        //   1. If we have a validated result, that is the best answer we're going to get
        //   2. If we have a GitRef in the query parameters, they've provided it, so use that
        //   3. If we don't have anything yet, use the last accessed GitRef (because that will be getting put
        //   in the query parameter on the next load)
        validatedGitRef?.GitRef?.CanonicalName ?? queryParams.gitRef ?? lastAccessedPlatformHubGitRef.get(gitPersistenceSettings),
        (gitRef: string) => {
            setQueryParams((prev) => ({ ...prev, gitRef }));
        },
        validatedGitRef,
    ];
}
const useValidatedGitRef = (gitRef: string, initialValidationResult: ValidateGitRefV2Response | undefined) => {
    const [validationResult, setValidationResult] = useState<ValidateGitRefV2Response | undefined>(initialValidationResult);
    const isInitialLoad = useRef(true);
    useEffect(() => {
        const execEffect = () => {
            const fetch = async () => {
                const validationResult = await repository.PlatformHubRepository.validateGitRef(gitRef);
                if (!stale) {
                    setValidationResult(validationResult);
                }
            };
            let stale = false;
            fetch();
            return () => {
                stale = true;
            };
        };
        if (!gitRef) {
            return;
        }
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
            if (initialValidationResult) {
                return;
            }
            return execEffect();
        }
        return execEffect();
    }, [gitRef, initialValidationResult]);
    return validationResult;
};
