/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, BooleanRadioButtonGroup, BooleanRadioButton, Callout } from "@octopusdeploy/design-system-components";
import type { StatsResourceCollection, TaskResource, TelemetryConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { TaskName } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import moment from "moment";
import React from "react";
import { client, repository } from "~/clientInstance";
import type { Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { PageContent } from "~/components/PageContent/PageContent";
import { PagingList } from "~/components/PagingList/PagingList";
import Section from "~/components/Section";
import TaskDetails from "~/components/TaskDetails";
import { withTheme } from "~/components/Theme";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import styles from "./style.module.less";
interface TelemetryPageState extends OptionalFormBaseComponentState<TelemetryConfigurationResource> {
    lastRunTask?: TaskResource;
    taskHistory?: StatsResourceCollection;
}
class TelemetryTasksList extends PagingList<TaskResource<{}>> {
}
const buildTaskRow = (task: TaskResource<{}>) => <TaskDetails task={task} stripTopBottomPadding={true}/>;
export class TelemetryPage extends FormBaseComponent<{}, TelemetryPageState, TelemetryConfigurationResource> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        localStorage.setItem(TelemetryHasBeenIntroducedLocalStorageKey, "true");
        await this.doBusyTask(async () => {
            const configuration = await repository.TelemetryConfiguration.get();
            const task = await repository.Telemetry.getLatestTelemetryTask();
            const taskHistory = await repository.Tasks.list({ name: TaskName.SendTelemetry, skip: 0, take: 10 });
            this.doRefresh = await this.startRefreshLoop(() => this.refreshLatestTask(), 5000);
            this.setState({
                model: configuration,
                cleanModel: cloneDeep(configuration),
                lastRunTask: task,
                taskHistory,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async loadTaskHistory(skip: number = 0) {
        const taskHistory = await repository.Tasks.list({ taskName: TaskName.SendTelemetry, skip: skip, take: 10 });
        this.setState({ taskHistory });
    }
    async refreshLatestTask() {
        const lastRunTask = await repository.Telemetry.getLatestTelemetryTask();
        const taskHistory = await repository.Tasks.list({ name: TaskName.SendTelemetry, skip: 0, take: 10 });
        return {
            ...this.state,
            lastRunTask,
            taskHistory,
        };
    }
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.TelemetryConfiguration.modify(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
        });
    };
    sendTelemetry = async () => {
        const task = await repository.Tasks.createSendTelemetryTask();
        this.setState({ lastRunTask: task });
    };
    renderLastTelemetryTaskDetails = () => {
        const { lastRunTask } = this.state;
        if (!lastRunTask)
            return <span>Telemetry task has not run</span>;
        if (lastRunTask.StartTime)
            return (<span>
                    Telemetry task last ran{` `}
                    <InternalLink to={links.taskPage.generateUrl({ taskId: lastRunTask.Id })} className={styles.taskTime}>
                        {DateFormatter.momentAgo(lastRunTask.StartTime!)}
                    </InternalLink>
                </span>);
        return <span>Telemetry task is in the task queue</span>;
    };
    renderActions = () => {
        const { model } = this.state;
        return (<>
                {!model?.Enabled && (<div className={styles.actionButtonWrap}>
                        <p>Send your telemetry to Octopus one-time</p>
                        <ActionButton label="Send telemetry data" disabled={this.state.busy} onClick={this.sendTelemetry}/>
                    </div>)}
                <div className={styles.actionButtonWrap}>
                    <p>Preview the telemetry data that has been collected by your instance.</p>
                    <ExternalLink href={client.resolveLinkTemplate("TelemetryDownload", {})} download showIcon={false}>
                        <ActionButton label="Download preview" type={ActionButtonType.Secondary}/>
                    </ExternalLink>
                </div>
            </>);
    };
    renderTelemetryDetails = () => {
        return (<ExpandableFormSection key="IsTelemetryReportingEnabled" errorKey="IsTelemetryReportingEnabled" title="Telemetry Reporting" summary={this.state.model!.Enabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Send telemetry data to Octopus" isExpandedByDefault expandable>
                <BooleanRadioButtonGroup accessibleName="enabled" value={this.state.model!.Enabled} onChange={(Enabled) => this.setModelState({ Enabled })}>
                    <BooleanRadioButton value={true} accessibleName="true" label="Enabled" isDefault={true}/>
                    <BooleanRadioButton value={false} accessibleName="false" label="Disabled" disabled={this.state.model?.IsTelemetryEnforced}/>
                </BooleanRadioButtonGroup>

                {!this.state.model?.Enabled && (<Note>
                        <p>When telemetry reporting is disabled, some data is still collected locally, but it is not sent automatically to Octopus.</p>
                    </Note>)}
                {this.state.model?.IsTelemetryEnforced && (<Note>
                        <p>Telemetry reporting cannot be disabled due to the conditions of your current license. For license upgrade options, please contact sales@octopus.com.</p>
                    </Note>)}
                {this.renderActions()}
            </ExpandableFormSection>);
    };
    renderTaskHistory = () => {
        return (<ExpandableFormSection key="TelemetryHistory" errorKey="TelemetryHistory" title="Telemetry History" summary={Summary.summary(this.renderLastTelemetryTaskDetails())} help={"View a history of telemetry tasks"} isExpandedByDefault={true} expandable>
                <TelemetryTasksList initialData={this.state.taskHistory} onRow={(item: TaskResource<{}>) => buildTaskRow(item)} onRowRedirectUrl={(task: TaskResource<{}>) => links.taskPage.generateUrl({ taskId: task.Id })} showPagingInNumberedStyle={true} currentPageIndex={0} onPageSelected={async (skip: number) => {
                await this.loadTaskHistory(skip);
            }}/>
            </ExpandableFormSection>);
    };
    renderNotification = () => {
        const now = moment();
        const ShowAsNewUntil = moment(this.state.model?.ShowAsNewUntil);
        return (ShowAsNewUntil.isAfter(now) && (<Callout type={"new-feature"} title={"Telemetry changes"}>
                    <p>We've changed the way Octopus Deploy collects and sends telemetry{";"} for better transparency and visibility.</p>
                    <p>This now occurs via a system task. You can enable or disable telemetry reporting, view the status, and preview your data on this page.</p>
                    <p>Telemetry reporting is enabled by default. Data will be sent from {ShowAsNewUntil.locale("en").format("dddd[,] DD MMMM YYYY[,] HH:mm:ss [GMT]ZZ")} unless you disable it before then.</p>
                </Callout>));
    };
    render() {
        return withTheme(() => (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick}>
                {({ FormContent, createSaveAction }) => (<PageContent header={{ primaryAction: createSaveAction({}), title: "Telemetry" }} busy={this.state.busy}>
                        <FormContent expandAllOnMount={false}>
                            {this.state.model && (<TransitionAnimation>
                                    {this.renderNotification()}
                                    <Section>
                                        <p>Octopus collects telemetry data on user actions, feature usage and performance to improve the user experience and make roadmap decisions.</p>
                                        <p>We do not collect personal data or sensitive information, such as your source code, configuration files, variables or user PII (personally identifiable information).</p>
                                        <p>
                                            You can preview your telemety data below or <ExternalLink href="Telemetry">learn more</ExternalLink> in our documentation.
                                        </p>
                                    </Section>
                                    <Section>{this.renderTelemetryDetails()}</Section>
                                    <Section>{this.renderTaskHistory()}</Section>
                                </TransitionAnimation>)}
                        </FormContent>
                    </PageContent>)}
            </LegacyForm>));
    }
    private doRefresh: Refresh = () => Promise.resolve();
    static displayName = "TelemetryPage";
}
export const TelemetryHasBeenIntroducedLocalStorageKey = "TelemetryHasBeenIntroduced";
