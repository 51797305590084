/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip } from "@octopusdeploy/design-system-components";
import { VariableType } from "@octopusdeploy/octopus-server-client";
import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import { flatMap, flatten, isEqual, sum } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import AccountDisplay from "~/areas/infrastructure/components/AccountDisplay";
import { fetchAllAccounts } from "~/areas/infrastructure/reducers/accounts";
import { SingleVariableRowHeight } from "~/areas/variables/SingleVariableRow/SingleVariableRow";
import { isLibraryVariableSetSource, isProjectVariableSource, isTenantProjectVariableSource } from "~/areas/variables/SourceLink";
import type { ValueSource } from "~/areas/variables/SourceLink/SourceLink";
import SourceLink, { getSourceLinkName } from "~/areas/variables/SourceLink/SourceLink";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import VariableCellIcon, { CellIcons } from "~/areas/variables/VariableCellIcon/VariableCellIcon";
import { VariableEditorHeadings } from "~/areas/variables/VariableEditorHeadings/VariableEditorHeadings";
import type { FilterableValue } from "~/areas/variables/VariableFilter/VariableFilter";
import type { ValueMessages, VariableMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import VariableNameAndDescriptionCell from "~/areas/variables/VariableNameAndDescriptionCell/VariableNameAndDescriptionCell";
import { FocusManagedVariableScope } from "~/areas/variables/VariableScope/VariableScope";
import { compareScopes, compareValues } from "~/areas/variables/VariableSorting/sortVariables";
import { collectCertificateAndWorkerPoolIndexes } from "~/areas/variables/collectCertificateAndWorkerPoolIndexes";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import ReadonlyAccount from "~/components/ReadonlyAccount";
import ReadonlyCertificate from "~/components/ReadonlyCertificate";
import ReadonlySensitive from "~/components/ReadonlySensitive/ReadonlySensitive";
import ReadonlyText from "~/components/ReadonlyText/ReadonlyText";
import { withTheme } from "~/components/Theme";
import type { CertificateIndex } from "~/components/certificates";
import { getTagIndex, type TagIndex } from "~/components/tenantTagsets";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import ScrollTable from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import ToolTipMessages from "~/primitiveComponents/dataDisplay/ToolTipMessages";
import type { BorderCss } from "~/utils/BorderCss/BorderCss";
import ReadonlyWorkerPool from "../../../components/ReadonlyWorkerPool";
import type { WorkerPoolIndex } from "../../../components/workerPools";
import type { VariableRowRenderer } from "../VariableRowRenderer";
import groupVariablesByName from "../groupVariablesByName";
import { isAccountType } from "../isAccountType";
import { isAnyValueScopedToTenantTags } from "../isAnyValueScopedToTenantTags";
import styles from "./style.module.less";
interface VariableDisplayerProps {
    availableScopes: ScopeValues;
    isDisplayingFullWidth: boolean;
    variableSections: ReadonlyArray<ReadonlyArray<FilteredVariable>>;
    hideSource?: boolean;
    hideScope?: boolean;
    doBusyTask: DoBusyTask;
    sectionHeader?: {
        renderSectionHeader: (sectionIndex: number, cellAligner: CellAligner) => React.ReactNode;
        sectionHeaderRowHeight: number;
    };
    onLoad?(): void;
}
interface VariableDisplayerState {
    tagIndex?: TagIndex;
    certificateIndex?: CertificateIndex;
    poolIndex?: WorkerPoolIndex;
    relativeColumnWidths: ReadonlyArray<number>;
    measuredScopeCellWidth: number | undefined;
}
export interface AdditionalFilter {
    value: string;
    fieldName: string;
    onValueChanged(value: string): void;
}
export interface ValueWithSource extends FilterableValue {
    source: ValueSource;
}
export interface VariableWithSource {
    name: string;
    values: ReadonlyArray<ValueWithSource>;
}
const rowHeight = 48;
export interface FilteredVariable {
    name: string;
    variableMessages: VariableMessages;
    values: ReadonlyArray<FilteredValue>;
}
interface FilteredValue extends ValueWithSource {
    messages: ValueMessages;
}
type Row = VariableRowRenderer | number; // number is for a section heading, and represents the index of the section
class VariableDisplayer extends React.Component<VariableDisplayerProps, VariableDisplayerState> {
    constructor(props: VariableDisplayerProps) {
        super(props);
        this.state = {
            relativeColumnWidths: this.getRelativeColumnWidths(props.hideScope!, props.hideSource!),
            measuredScopeCellWidth: undefined,
        };
    }
    flattenVariableSections = (variableSection: ReadonlyArray<ReadonlyArray<FilteredVariable>>) => flatMap(flatten(variableSection), (v) => v.values);
    setIndexes = async (values: FilteredValue[]) => {
        if (isAnyValueScopedToTenantTags(values)) {
            this.setState({ tagIndex: await getTagIndex() });
        }
        this.setState(await collectCertificateAndWorkerPoolIndexes(values));
    };
    async componentDidMount() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
        await this.props.doBusyTask(async () => {
            const values = this.flattenVariableSections(this.props.variableSections);
            await this.setIndexes(values);
        });
    }
    async componentDidUpdate(prevProps: VariableDisplayerProps) {
        if (!isEqual(prevProps.variableSections, this.props.variableSections)) {
            const values = this.flattenVariableSections(this.props.variableSections);
            await this.setIndexes(values);
        }
    }
    render() {
        return withTheme((theme) => {
            const orderedRows: ReadonlyArray<Row> = flatten(this.props.variableSections.map((section, index) => [index, ...flatMap<FilteredVariable, VariableRowRenderer>(section, (variable) => [...this.getVariableRowRenderers(variable)])]));
            const rows: ReadonlyArray<Row> = this.props.sectionHeader ? orderedRows : orderedRows.filter((r) => !isSectionHeaderRow(r));
            return (<ScrollTable relativeColumnWidths={this.state.relativeColumnWidths} minimumColumnWidthsInPx={[150, 150, 200, 150]} onColumnWidthsChanged={(relativeColumnWidths) => this.setState({ relativeColumnWidths })} rowCount={rows.length} overscanRowCount={10} rowHeight={(index) => this.getHeightForRow(rows[index])} shouldVirtualize={sum(this.props.variableSections.map((variables) => sum(variables.map((v) => v.values.length)))) > 100} headers={({ cellAligner, borderStyle, columnWidthsInPercent }) => [
                    <div style={{ borderBottom: borderStyle.borderCssString, width: "100%", backgroundColor: theme.paper1 }}>
                            <VariableEditorHeadings isDisplayedFullWidth={this.props.isDisplayingFullWidth} columnWidths={columnWidthsInPercent} onWidthMeasured={(index, width) => {
                            if (index === 2) {
                                this.setState({ measuredScopeCellWidth: width });
                            }
                        }} cellAligner={cellAligner} cells={[<span>Name</span>, <span>Value</span>, this.props.hideScope ? null! : <span>Scope</span>, this.props.hideSource ? null! : <span>Source</span>].filter((c) => !!c)}/>
                        </div>,
                ]} rowRenderer={({ cellAligner, index, isVisible, columnWidthsInPercent, borderStyle }) => {
                    const row = rows[index];
                    if (isSectionHeaderRow(row)) {
                        return <div style={{ width: "100%", borderBottom: borderStyle.borderCssString }}>{this.props.sectionHeader!.renderSectionHeader(row, cellAligner)}</div>;
                    }
                    return row.render(cellAligner, isVisible, this.props.isDisplayingFullWidth, borderStyle, columnWidthsInPercent);
                }}/>);
        });
    }
    private getRelativeColumnWidths(hideScope: boolean, hideSource: boolean) {
        const scopeAndSourceHidden = hideScope && hideSource;
        const scopeOrSourceHidden = hideScope || hideSource;
        if (scopeAndSourceHidden) {
            return [1, 4];
        }
        else if (scopeOrSourceHidden) {
            return [1, 4, 1];
        }
        else {
            return [3, 5, 3, 2];
        }
    }
    private getVariableRowRenderers(variable: FilteredVariable): ReadonlyArray<VariableRowRenderer> {
        return variable.values.map((value, index) => ({
            height: rowHeight,
            render: (cellAligner: CellAligner, isVisible: boolean, isDisplayingFullWidth: boolean, borderStyle: BorderCss) => (<div key={index} style={{ height: rowHeight, borderBottom: borderStyle.borderCssString }}>
                    {cellAligner([
                    <VariableNameAndDescriptionCell name={this.renderNameCell(variable.name, index, variable.variableMessages)} description={value.description ? <VariableCellIcon type={CellIcons.description} description={value.description}/> : undefined}/>,
                    <VariableCell>
                                <div className={styles.value}>
                                    {this.renderPromptedVariableValueIcon(value)}
                                    {value.type === VariableType.Sensitive && <ReadonlySensitive hasValue={true} monoSpacedFont={true}/>}
                                    {value.type === VariableType.String && <ReadonlyText text={value.value!} monoSpacedFont={true}/>}
                                    {value.type === VariableType.Certificate && <ReadonlyCertificate certificateIndex={this.state.certificateIndex!} certificateId={value.value!}/>}
                                    {value.type === VariableType.WorkerPool && <ReadonlyWorkerPool poolIndex={this.state.poolIndex!} poolId={value.value!}/>}
                                    {isAccountType(value.type) && (<AccountDisplay accountId={value.value!} render={({ account, accountId }) => (<div className={styles.account} style={{ height: `${SingleVariableRowHeight}px` }}>
                                                    <ReadonlyAccount accountId={value.value!} accountName={account ? account.name : accountId ? accountId : value.value!}/>
                                                </div>)}/>)}
                                </div>
                            </VariableCell>,
                    this.props.hideScope ? (null!) : (<FocusManagedVariableScope scope={value.scope} availableScopes={this.props.availableScopes} tagIndex={this.state.tagIndex ? this.state.tagIndex : {}} minHeight={rowHeight} showClickIndicator={false} containerWidth={this.state.measuredScopeCellWidth!}/>),
                    this.props.hideSource ? (null!) : (<VariableCell style={value.source.isDisabled ? { opacity: 0.4 } : {}}>
                                    <SourceLink source={value.source}/>
                                </VariableCell>),
                ].filter((c) => !!c))}
                </div>),
        }));
    }
    private renderPromptedVariableValueIcon(v: FilteredValue) {
        return (v.isPrompted && (<div className={styles.promptedVariablePositionContainer}>
                    <div className={styles.promptedVariableIconSizeContainer}>
                        <Tooltip content="You will be prompted for a value during a deployment">
                            <ThirdPartyIcon iconType={ThirdPartyIconType.RateReview} className={styles.promptedVariableIcon}/>
                        </Tooltip>
                    </div>
                </div>));
    }
    private renderNameCell(name: string, index: number, variableMessages: VariableMessages) {
        const allWarningMessages = variableMessages && variableMessages.variableWarningMessages;
        return (<VariableCell className={styles.nameCellContent}>
                {index === 0 ? <ReadonlyText text={name} monoSpacedFont={true}/> : <div className={styles.spacer}/>}
                <ToolTipMessages warningMessages={allWarningMessages}/>
            </VariableCell>);
    }
    private getHeightForRow(row: Row) {
        if (isSectionHeaderRow(row)) {
            return this.props.sectionHeader!.sectionHeaderRowHeight;
        }
        return row.height;
    }
    static displayName = "VariableDisplayer";
}
function isSectionHeaderRow(row: Row): row is number {
    return typeof row === "number";
}
export function mergeAndSortVariables(variables: ReadonlyArray<VariableWithSource>, availableScopes: ScopeValues): ReadonlyArray<VariableWithSource> {
    const groupedByName = groupVariablesByName(variables, (v) => v.name);
    const merged = Object.keys(groupedByName).map((name) => ({ name, values: flatten(groupedByName[name].map((v) => [...v.values])) }));
    return merged.sort(compareVariablesName).map((v) => ({ name: v.name, values: [...v.values].sort(compareValuesWithSource) }));
    function compareVariablesName(l: VariableWithSource, r: VariableWithSource): number {
        return l.name.toLowerCase().localeCompare(r.name.toLowerCase());
    }
    function compareValuesWithSource(l: ValueWithSource, r: ValueWithSource) {
        return compareScopes(l.scope, r.scope, availableScopes) || compareSources(l.source, r.source) || compareValues(l, r);
    }
}
export function compareSources(l: ValueSource, r: ValueSource) {
    return compareSourceType(l, r) || compareSourceName(l, r);
}
function compareSourceType(l: ValueSource, r: ValueSource) {
    return getSourceTypeOrder(l) - getSourceTypeOrder(r);
}
function getSourceTypeOrder(source: ValueSource) {
    if (isProjectVariableSource(source)) {
        return 1;
    }
    else if (isLibraryVariableSetSource(source)) {
        return 2;
    }
    else if (isTenantProjectVariableSource(source)) {
        return 3;
    }
    else {
        // Tenant variable set case
        return 4;
    }
}
function compareSourceName(l: ValueSource, r: ValueSource) {
    return getSourceLinkName(l).localeCompare(getSourceLinkName(r));
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapGlobalStateToProps = (state: GlobalState, props: any) => ({});
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => bindActionCreators({ onLoad: fetchAllAccounts }, dispatch);
const ConnectedVariableDisplayer = connect<{}, {}, VariableDisplayerProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(VariableDisplayer);
export default ConnectedVariableDisplayer;
