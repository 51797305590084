/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { LicenseStatusResource, UserRoleResource } from "@octopusdeploy/octopus-server-client";
import { PermissionsMode, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import { SimplePagingList } from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { restrictedPermissionsCalloutConfig } from "~/components/RestrictedPermissionsCallout";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UserRolesPageState extends DataBaseComponentState {
    roles?: UserRoleResource[];
    licenseStatus?: LicenseStatusResource;
}
class RoleList extends SimplePagingList<UserRoleResource> {
}
export class UserRolesPage extends DataBaseComponent<{}, UserRolesPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const roles = await repository.UserRoles.all();
            const licenseStatus = await repository.Licenses.getCurrentStatus();
            this.setState({
                roles,
                licenseStatus,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    renderRole(role: UserRoleResource): ReactNode {
        return (<div key={role.Id}>
                <ListTitle>{role.Name}</ListTitle>
                <div>{role.Description}</div>
            </div>);
    }
    render() {
        const addCustomRolePageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.UserRoleEdit }),
            label: "Add custom role",
            path: links.createUserRolePage.generateUrl(),
        };
        return (<PageContent header={{ title: "User Roles", primaryAction: addCustomRolePageAction }} busy={this.state.busy} errors={this.errors} callout={(this.state.licenseStatus && this.state.licenseStatus.PermissionsMode === PermissionsMode.Restricted)! ? restrictedPermissionsCalloutConfig : undefined}>
                <RoleList items={this.state.roles!} onRowRedirectUrl={(role: UserRoleResource) => links.editUserRolePage.generateUrl({ roleId: role.Id })} onRow={this.renderRole} autoFocusOnFilterSearch={true} onFilter={(filter, item) => item.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1}/>
            </PageContent>);
    }
    static displayName = "UserRolesPage";
}
