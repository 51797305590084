import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeScopeDetail, DeploymentFreezeScopeType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import DeploymentFreezeScopeCell from "~/areas/configuration/components/DeploymentFreezes/EditScopeTable/DeploymentFreezeScopeCell";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import Logo from "~/components/Logo/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import styles from "./style.module.less";
interface DeploymentFreezeScopeTableRowProps {
    scopeItem: DeploymentFreezeScopeDetail;
    onRemove: (item: DeploymentFreezeScopeDetail) => void;
    onEnvironmentUpdate: (item: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => void;
    scopeType: DeploymentFreezeScopeType;
    doBusyTask: DoBusyTask;
    focussedScopeCellId: string;
    onFocusedScopeCell: (id: string) => void;
    onScopeCellLostFocus: () => void;
}
export const DeploymentFreezeScopeTableRow = ({ scopeItem, scopeType, onRemove, onEnvironmentUpdate, doBusyTask, focussedScopeCellId, onFocusedScopeCell, onScopeCellLostFocus }: DeploymentFreezeScopeTableRowProps) => (<DataTableRow>
        <DataTableRowColumn className={styles.projectNameCell}>
            <InternalLink to={links.deploymentsPage.generateUrl({ spaceId: scopeItem.SpaceId, projectSlug: scopeItem.Id })}>
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "12px" }}>
                        <Logo url={scopeItem.LogoLink} size="2.5em"/>
                    </div>
                    <div style={{ overflowWrap: "anywhere", alignSelf: "center" }}>{scopeItem.Name}</div>
                </div>
            </InternalLink>
        </DataTableRowColumn>
        <DataTableRowColumn className={styles.environmentNameCell}>
            <DeploymentFreezeScopeCell scope={scopeItem} scopeType={scopeType} onEnvironmentUpdate={onEnvironmentUpdate} doBusyTask={doBusyTask} onScopeCellLostFocus={onScopeCellLostFocus} onFocusedScopeCell={onFocusedScopeCell} isEditing={focussedScopeCellId === scopeItem.Id}/>
        </DataTableRowColumn>
        <DataTableRowColumn>
            <div className={styles.overflowMenu}>
                <OverflowMenu accessibleName="deploymentFreezesScopeOverflowMenu" menuItems={[
        OverflowMenuItems.deleteItem("Remove", `Are you sure you want to disassociate ${scopeType.toLocaleLowerCase()} '${scopeItem.Name}'?`, async () => onRemove(scopeItem), <div>
                                <p>Do you wish to continue?</p>
                            </div>),
    ]}/>
            </div>
        </DataTableRowColumn>
    </DataTableRow>);
