import type { TenantResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MissingChip, TenantChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface TenantMultiSelectProps extends FormFieldProps<string[]> {
    items: {
        Id: string;
        Name: string;
        IsDisabled?: boolean | null;
    }[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const TenantTypedMultiSelect = MultiSelect<{
    Id: string;
    Name: string;
    IsDisabled?: boolean | null;
}>();
export const TenantMultiSelect: React.FC<TenantMultiSelectProps> = (props) => {
    const chipRenderer = (r: TenantResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <TenantChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} tenantName={item.Name} isDisabled={!!item.IsDisabled}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Tenant} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return (<TenantTypedMultiSelect fieldName="tenants" renderChip={chipRenderer} renderItem={(item) => {
            return {
                primaryText: item.Name,
                secondaryText: item.IsDisabled ? "Disabled" : "",
            };
        }} {...props}/>);
};
TenantMultiSelect.displayName = "TenantMultiSelect"
