import type { AccountResource, ActionTemplateSearchResource, BlueprintResource, FeedResource, GitCredentialResource, GitRef, ModifyProcessCommand, PersistenceSettings, ProcessResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks";
import { useBoundDispatch } from "~/utils/Reducers";
import type { ProcessContextModelState, ProcessIdentifier, ProcessPageSupportedActions } from "../types";
import { isBlueprintProcessIdentifier, isDeploymentProcessIdentifier, isRunbookProcessIdentifier } from "../types";
import type { ProcessActions, ProcessStateSelectors } from "./ProcessContextState";
import { actions } from "./ProcessContextState";
export interface ProcessContextLookupState {
    actionTemplates: ActionTemplateSearchResource[] | "NotLoaded";
    feeds: FeedResource[] | "NotLoaded";
    accounts: AccountResource[];
    gitCredentials: GitCredentialResource[];
    blueprints: BlueprintResource[] | "NotLoaded";
}
export type ProcessContextProps = {
    state: ProcessContextModelState;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
};
export const ProcessContext = React.createContext<ProcessContextProps | undefined>(undefined);
export const useProcessContext = () => {
    return useRequiredContext(ProcessContext, "Process");
};
export const useOptionalProcessContext = () => {
    return React.useContext(ProcessContext);
};
export const loadProcess = (processIdentifier: ProcessIdentifier, persistenceSettings: PersistenceSettings, gitRef?: GitRef): Promise<ProcessResource> => {
    if (isDeploymentProcessIdentifier(processIdentifier)) {
        return repository.DeploymentProcesses.get(processIdentifier.projectId, gitRef);
    }
    else if (isRunbookProcessIdentifier(processIdentifier)) {
        const isGitRunbook = HasRunbooksInGit(persistenceSettings) && gitRef;
        if (isGitRunbook) {
            return repository.Runbooks.getRunbookProcess(processIdentifier.projectId, processIdentifier.spaceId, processIdentifier.id, gitRef);
        }
        else {
            return repository.Runbooks.getRunbookProcess(processIdentifier.projectId, processIdentifier.spaceId, processIdentifier.id);
        }
    }
    else if (isBlueprintProcessIdentifier(processIdentifier)) {
        return repository.Blueprints.get(processIdentifier.slug, gitRef);
    }
    exhaustiveCheck(processIdentifier, "Unknown process type");
};
export interface ProcessContextProviderSetup {
    lookupsState: ProcessContextLookupState;
    state: ProcessContextModelState;
    setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
}
type BoundActionsType = Omit<ReturnType<typeof useBoundProcessActions>, "setProcess" | "conflictDetected">;
export interface ProcessContextProviderSetupActions extends ProcessPageSupportedActions, BoundActionsType {
    saveOnServer: (process: ModifyProcessCommand, onError: (errors: Errors) => void, onSuccess: () => void, gitRef?: string) => Promise<boolean>;
    refreshFromServer: () => void;
    setProcess: (process: ProcessResource, updateCleanModel: boolean) => Promise<void>;
    conflictDetected: (serverProcess: ProcessResource, stagedProcess: ProcessResource) => Promise<void>;
}
export const useBoundProcessActions = (dispatch: React.Dispatch<ProcessActions>) => {
    return useBoundDispatch(dispatch, actions);
};
export interface WithProcessContextInjectedProps {
    processContext: ProcessContextProps;
}
export function withProcessContext<T>(Component: React.ComponentType<T & WithProcessContextInjectedProps>) {
    const WithProcessContext: React.FC<T> = (props) => {
        const context = useProcessContext();
        return <Component processContext={context} {...props}/>;
    };
    WithProcessContext.displayName = "WithProcessContext"
    return WithProcessContext;
}
export type WithOptionalProcessContextInjectedProps = Partial<WithProcessContextInjectedProps>;
export function withOptionalProcessContext<T>(Component: React.ComponentType<T & WithOptionalProcessContextInjectedProps>) {
    const WithOptionalProcessContext: React.FC<T> = (props) => {
        const context = useOptionalProcessContext();
        return <Component processContext={context} {...props}/>;
    };
    WithOptionalProcessContext.displayName = "WithOptionalProcessContext"
    return WithOptionalProcessContext;
}
