/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeScopeDetail, DeploymentFreezeScopeType, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { repository } from "~/clientInstance";
import { EnvironmentChip } from "~/components/Chips/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import PopoverWhenFocused from "~/components/PopoverWhenFocused/index";
import ReadonlyText from "~/components/ReadonlyText/index";
import styles from "./style.module.less";
interface DeploymentFreezeScopeCellState {
    showAllChips: boolean;
    environments: EnvironmentResource[];
    isLoadingEnvironments: boolean;
}
interface DeploymentFreezeScopeCellProps {
    scope: DeploymentFreezeScopeDetail;
    scopeType: DeploymentFreezeScopeType;
    tenantId?: string;
    onEnvironmentUpdate: (item: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[], tenantId?: string) => void;
    doBusyTask: DoBusyTask;
    isEditing: boolean;
    onFocusedScopeCell: (id: string) => void;
    onScopeCellLostFocus: () => void;
}
export default class DeploymentFreezeScopeCell extends React.Component<DeploymentFreezeScopeCellProps, DeploymentFreezeScopeCellState> {
    private scopeCell: HTMLElement | null = undefined!;
    private tabbingElement: HTMLElement | null = undefined!;
    constructor(props: DeploymentFreezeScopeCellProps, state: DeploymentFreezeScopeCellState) {
        super(props);
        this.state = {
            environments: [],
            showAllChips: false,
            isLoadingEnvironments: false,
        };
    }
    private async loadEnvironments() {
        if (this.state.environments.length > 0) {
            return;
        }
        if (this.state.isLoadingEnvironments) {
            return;
        }
        this.setState({ isLoadingEnvironments: true });
        await this.props.doBusyTask(async () => {
            try {
                if (this.props.scopeType === "Project") {
                    const response = await repository.Projects.getAvailableEnvironmentsForProjectsAcrossAllSpaces([this.props.scope.Id]);
                    this.setState({ environments: response.Environments });
                }
                else if (this.props.scopeType === "Tenant") {
                    const response = await repository.DeploymentFreezes.searchAvailableEnvironments([{ tenantId: this.props.tenantId!, projectId: this.props.scope.Id }]);
                    const tenantProjectEnvironment = response.TenantProjectEnvironments.filter((tpe) => tpe.TenantId == this.props.tenantId! && tpe.ProjectId == this.props.scope.Id!);
                    this.setState({ environments: tenantProjectEnvironment.flatMap((t) => t.Environment) });
                }
            }
            finally {
                this.setState({ isLoadingEnvironments: false });
            }
        });
    }
    componentDidUpdate(prevProps: DeploymentFreezeScopeCellProps) {
        if (!prevProps.isEditing && this.props.isEditing) {
            this.loadEnvironments();
        }
    }
    render() {
        const tooManyChips = this.props.scope.Environments.length > 3;
        const environmentsChipsList = tooManyChips && !this.state.showAllChips ? (<EnvironmentChip environmentName={`${this.props.scope.Environments.length.toLocaleString()} environments`} key="environmentsChipsList"/>) : (this.props.scope.Environments.map((e) => <EnvironmentChip environmentName={e.Name} key={e.Id}/>));
        return (<div ref={(cell) => (this.scopeCell = cell)} onClick={() => (this.props.scopeType === "Project" ? this.props.onFocusedScopeCell(this.props.scope.Id) : this.props.onFocusedScopeCell(`${this.props.tenantId}-${this.props.scope.Id}`))}>
                {!this.props.isEditing && <div ref={(tabbingElement) => (this.tabbingElement = tabbingElement)} tabIndex={0}/>}
                {!this.props.isEditing && this.props.scope.Environments.length === 0 && <ReadonlyText text={"Define scope"} className={styles.defineScope} monoSpacedFont={true}/>}
                {!this.props.isEditing && (<div className={styles.environmentChips}>
                        {environmentsChipsList}
                        {tooManyChips && (<ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                        e.stopPropagation();
                        this.setState({ showAllChips: !this.state.showAllChips });
                    }}/>)}
                    </div>)}
                {this.props.isEditing && (<PopoverWhenFocused isFocused={this.props.isEditing} position={{ left: 0, top: 0, right: 0 }} onClickOutside={() => this.props.onScopeCellLostFocus()}>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                            {this.state.isLoadingEnvironments ? (<div>Loading environments...</div>) : (<EnvironmentMultiSelect label={"Select environments"} environments={this.state.environments} onChange={(environmentIds) => {
                        this.props.onEnvironmentUpdate(this.props.scope, this.state.environments.filter((e) => environmentIds.includes(e.Id)), this.props.tenantId);
                    }} value={this.props.scope.Environments.map((e) => e.Id)}/>)}
                        </div>
                    </PopoverWhenFocused>)}
            </div>);
    }
    static displayName = "DeploymentFreezeScopeCell";
}
