import { css } from "@emotion/css";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Dialog, Callout, useShowSnackbar, SimpleDataTable } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useAggregateAPIOperationStatus, useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { ActionTemplateParameterResource, BlueprintResource, CreateProcessTemplateVersionBffCommand, GitBranchResource, GitPersistenceSettings, GitRef, GitRefResource, IconMetadataResource, IconSvgResource, IProcessResource, ModifyProcessTemplateCommand, PlatformHubConnectionConfigurationResource, ProcessTemplateVersionResource, Repository, ValidateGitRefV2Response, WorkerPoolsSummaryResource, } from "@octopusdeploy/octopus-server-client";
import { canCommitTo, GetPrimaryPackageReference, Permission } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { links, optionalStringQueryParam, useQueryStringParam } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import { isEqual, keyBy } from "lodash";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BlueprintParametersTab, buildBlueprintParametersActions } from "~/areas/blueprints/BlueprintParametersTab";
import { EditProcessTemplateSettingsTab } from "~/areas/blueprints/EditProcessTemplateSettingsTab";
import { lastAccessedPlatformHubGitRef } from "~/areas/blueprints/LastAccessedPlatformHubGitRef";
import { PlatformHubPageHeaderBranchSelector } from "~/areas/blueprints/PlatformHubPageHeaderBranchSelector";
import { PublishTemplateDialog } from "~/areas/blueprints/PublishTemplateDialog";
import { useRequiredPlatformHubGitRef } from "~/areas/blueprints/usePlatformHubGitRef";
import type { ProcessBlueprintContextProps } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import { buildProcessBlueprintContextProps, ProcessBlueprintContext } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import { isRunOnServerOrWorkerPool, loadAvailableWorkerPools, whereConfiguredToRun } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { ProcessController } from "~/areas/projects/components/Process/Contexts/ProcessController";
import { useProcessErrorActions } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { createDefaultFilter, ProcessQueryStringController, useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessWarningActions } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { hasSteps } from "~/areas/projects/components/Process/Contexts/processModelSelectors";
import ProcessListLayout from "~/areas/projects/components/Process/ProcessListLayout";
import ProcessStepsLayout, { intentFromFilter, ProcessPageIntent } from "~/areas/projects/components/Process/ProcessStepsLayout";
import type { ProcessStepsLayoutLoaderLookupData } from "~/areas/projects/components/Process/ProcessStepsLayoutLoader";
import { emptyVariableSetResource } from "~/areas/projects/components/Process/ProcessStepsLayoutLoader";
import type { BlueprintProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, ExecutionLocation, toBlueprintProcessIdentifier } from "~/areas/projects/components/Process/types";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import { ContextAddStepButton } from "~/areas/projects/components/Steps/index";
import type { GetCommitButtonProps } from "~/areas/projects/components/VersionControl/CommitButton";
import { GetCommitButton } from "~/areas/projects/components/VersionControl/CommitButton";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { PersistenceSettingsContextProvider, usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { repository } from "~/clientInstance";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import Chip from "~/components/Chips/Chip";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import useLocalStorage from "~/hooks/useLocalStorage";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs/index";
import type { GlobalState } from "~/store/reducers";
import DateFormatter from "~/utils/DateFormatter/index";
interface EditProcessTemplateProps {
    loaderData: LoaderData;
    slug: string;
    spaceId: string;
    queryParams: EditProcessTemplateQueryParams;
    setQueryParams: QueryParamValuesSetter<EditProcessTemplateQueryParams>;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
}
export interface EditProcessTemplateQueryParams {
    gitRef: string;
}
interface EditWithAllContextProps extends EditProcessTemplateProps {
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
}
interface EditProcessTemplateInternalProps {
    cleanModel: BlueprintResource;
    model: BlueprintResource;
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
    lookupData: ProcessStepsLayoutLoaderLookupData;
    icons: IconSvgResource[];
    iconMetadata: IconMetadataResource | undefined;
    onChange: (blueprint: BlueprintResource) => void;
    gitRefResource: GitRefResource | undefined;
    gitRef: GitRef;
    setGitRef: (gitRef: string) => void;
    gitPersistenceSettings: GitPersistenceSettings;
}
function EditWithAllContext({ loaderData, slug, doBusyTask, busy, errors, spaceId, queryParams, setQueryParams }: EditWithAllContextProps) {
    const [gitRef, setGitRef, validatedGitRef] = useRequiredPlatformHubGitRef(loaderData.platformHubConnectionConfiguration.PersistenceSettings, queryParams, setQueryParams, loaderData.initialValidationResult);
    const [lookupData, setLookupData] = useState<ProcessStepsLayoutLoaderLookupData>({ ...defaultLookups, templates: loaderData.processTemplate.Parameters });
    const [icons, setIcons] = useState<IconSvgResource[]>([]);
    const [iconMetadata, setIconMetadata] = useState<IconMetadataResource>();
    const { result: cleanModel, refetch: reloadProcessTemplate } = useQuery(async (repository) => {
        const result = await repository.Blueprints.get(slug, gitRef);
        setModel(result);
        return result;
    }, [slug, gitRef], "Get process template", {
        initialResult: loaderData.processTemplate,
    });
    const [model, setModel] = useState<BlueprintResource>(cleanModel);
    const [blueprintContextProps, setBlueprintContextProps] = useState<ProcessBlueprintContextProps>(buildProcessBlueprintContextProps(cleanModel));
    const refreshLookups = useCallback(async () => {
        const environments = repository.Environments.all();
        const workerPools = repository.WorkerPools.all();
        const tagSets = tenantTagsets.getAll();
        setLookupData({
            ...defaultLookups,
            environmentsById: keyBy(await environments, "Id"),
            tagSets: await tagSets,
            workerPoolsById: keyBy(await workerPools, "Id"),
            machineRoles: await repository.MachineRoles.all(),
            tagIndex: await tenantTagsets.getTagIndex(),
            workerPoolsSummary: await repository.WorkerPools.summary(),
        });
    }, []);
    const onChange = useCallback((blueprint: BlueprintResource) => {
        const context = buildProcessBlueprintContextProps(blueprint);
        setBlueprintContextProps(context);
        setLookupData({ ...lookupData, templates: blueprint.Parameters });
        setModel(blueprint);
    }, [lookupData]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        await refreshLookups();
        const icons = await repository.Icons.getIcons();
        setIcons(icons);
        const iconMetadata = await repository.Icons.getIconMetadata();
        setIconMetadata(iconMetadata);
    }, [refreshLookups]);
    const processIdentifier: BlueprintProcessIdentifier = useMemo(() => toBlueprintProcessIdentifier(slug, gitRef, spaceId), [slug, gitRef, spaceId]);
    if (!validatedGitRef) {
        return <PaperLayoutVNext busy={true} fullWidth={true}></PaperLayoutVNext>;
    }
    return (<ProcessBlueprintContext.Provider value={blueprintContextProps}>
            <PersistenceSettingsContextProvider {...loaderData.platformHubConnectionConfiguration.PersistenceSettings}>
                <ProcessController layoutActions={{ refreshLookupData: refreshLookups }} doBusyTask={doBusyTask} processIdentifier={processIdentifier} process={cleanModel} reloadProcess={reloadProcessTemplate} // Only used when updating step templates which blueprints can't do yet, so this is actually unused
     modifyProcess={() => Promise.resolve()} // This function is never used for blueprints since the save logic is handled outside the process context
    >
                    {() => {
            return (<ProcessQueryStringController initialQueryFilter={createDefaultFilter()}>
                                {() => {
                    return (<EditProcessTemplatePageInternal cleanModel={cleanModel} model={model} doBusyTask={doBusyTask} busy={busy} errors={errors} lookupData={lookupData} gitRefResource={validatedGitRef?.GitRef} gitRef={gitRef} setGitRef={setGitRef} gitPersistenceSettings={loaderData.platformHubConnectionConfiguration.PersistenceSettings} icons={icons} iconMetadata={iconMetadata} onChange={onChange}/>);
                }}
                            </ProcessQueryStringController>);
        }}
                </ProcessController>
            </PersistenceSettingsContextProvider>
        </ProcessBlueprintContext.Provider>);
}
const defaultLookups: ProcessStepsLayoutLoaderLookupData = {
    includedScriptModules: [],
    lifecyclePreview: null,
    environmentsById: {},
    channelsById: null,
    tagSets: [],
    workerPoolsById: {},
    machineRoles: [],
    tagIndex: {},
    userOnboarding: null,
    workerPoolsSummary: {
        WorkerPoolSummaries: [],
        TotalMachines: 0,
        TotalDisabledMachines: 0,
        MachineHealthStatusSummaries: {
            Healthy: 0,
            Unavailable: 0,
            Unknown: 0,
            HasWarnings: 0,
            Unhealthy: 0,
        },
        MachineEndpointSummaries: {
            None: 0,
            TentaclePassive: 0,
            TentacleActive: 0,
            Ssh: 0,
            OfflineDrop: 0,
            AzureWebApp: 0,
            AzureCloudService: 0,
            AzureServiceFabricCluster: 0,
            Kubernetes: 0,
            KubernetesTentacle: 0,
            StepPackage: 0,
        },
        DeploymentTargetSummaries: {
            Healthy: 0,
            Unavailable: 0,
            Unknown: 0,
            HasWarnings: 0,
            Unhealthy: 0,
        },
        TentacleUpgradesRequired: false,
        MachineIdsForCalamariUpgrade: [],
        MachineIdsForTentacleUpgrade: [],
    },
    projectTriggers: [],
    processVariableSet: emptyVariableSetResource(""), // Empty spaceId while variable sets are space-scoped
    libraryVariableSets: [],
    templates: [],
};
interface ProcessStepsOrListLayoutProps {
    lookupData: ProcessStepsLayoutLoaderLookupData;
    doBusyTask: DoBusyTask;
    errors: Error[];
    busy: boolean;
    gitRefResource: GitRefResource | undefined;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
}
function ProcessStepsOrListLayout({ lookupData, busy, doBusyTask, errors, gitRefResource, scrollAreaRef }: ProcessStepsOrListLayoutProps) {
    const processQueryStringContext = useProcessQueryStringContext();
    const isBuiltInWorkerEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
    const intent = intentFromFilter(processQueryStringContext.state.queryFilter);
    if (intent === ProcessPageIntent.Unknown) {
        return (<ProcessListLayout gitRef={gitRefResource?.CanonicalName} onProcessSave={() => Promise.resolve()} titleAccessory={undefined} releaseCreationPackageStepId={undefined} lookups={lookupData} errors={errors} busy={busy} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} gitRefResource={gitRefResource}/>);
    }
    return (<ProcessStepsLayout gitRef={gitRefResource?.CanonicalName} titleAccessory={undefined} stepSlug={undefined} setShowK8sStatusItem={undefined} onProcessSave={() => Promise.resolve()} changeGitRef={noOp} // todo
     refreshModel={() => Promise.resolve(true)} projectStatus={undefined} includedLibraryVariableSetIds={[]} releaseCreationPackageStepId={undefined} lookups={lookupData} errors={errors} busy={busy} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} gitRefResource={gitRefResource} isTenanted={true} onValidationError={() => scrollAreaRef?.current?.scrollTo({ top: 0, behavior: "smooth" })}/>);
}
function applyCommonLogicToProcessResource(process: IProcessResource, workerPoolsSummary: WorkerPoolsSummaryResource, getPluginForAction: (actionId: string) => ActionPlugin) {
    const availableWorkerPools = loadAvailableWorkerPools(workerPoolsSummary);
    process.Steps.forEach((step) => {
        step.Actions.forEach((action) => {
            const plugin = getPluginForAction(action.Id);
            const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin);
            if (runOn) {
                if (!isRunOnServerOrWorkerPool(runOn)) {
                    action.Container = { FeedId: null, Image: null, GitUrl: null, Dockerfile: null };
                }
                else {
                    if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                        step.Properties["Octopus.Action.TargetRoles"] = "";
                    }
                    if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        action.WorkerPoolId = null!;
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        action.WorkerPoolVariable = null!;
                    }
                    action.Container = runOn.container;
                }
            }
            if (!action.Name || action.Name.length === 0) {
                const primaryPackage = GetPrimaryPackageReference(action.Packages);
                if (primaryPackage) {
                    action.Name = primaryPackage.PackageId;
                }
            }
        });
    });
}
function EditProcessTemplatePageInternal({ cleanModel, model, doBusyTask, busy, errors, lookupData, icons, iconMetadata, onChange, gitRefResource, gitRef, setGitRef, gitPersistenceSettings }: EditProcessTemplateInternalProps) {
    const persistenceSettings = usePersistenceSettingsContext();
    const process = useProcessContext();
    const processIdentifier = process.state.processIdentifier;
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const navigation = useSpaceAwareNavigation();
    const [commitMessage, setCommitMessage] = useState<CommitMessageWithDetails>({ summary: "", details: "" });
    const [disableDirtyFormChecking, setDisableDirtyFormChecking] = useState<boolean>(false);
    const showSnackbar = useShowSnackbar();
    const { openDialog: openPublishDialog, isOpen: isPublishDialogOpen } = useDialogTrigger();
    const [dismissedShareAndPublishCallout, setDismissed] = useLocalStorage(`Octopus.Callout.ProcessTemplate.ShareAndPublish.${cleanModel.Slug}.Dismissed`, false);
    const activeTabQueryParameter = optionalStringQueryParam("activeTab");
    const [activeTab, _] = useQueryStringParam(activeTabQueryParameter);
    const { result: publishedVersions, refetch: reloadPublishedVersions } = useQuery((repository) => repository.Blueprints.getVersions(cleanModel.Slug), [cleanModel.Slug], "Get process template versions");
    const { execute: handleSave } = useMutation({
        name: "Publish Process Template Version",
        action: async (repository: Repository, version: string, isPreRelease: boolean) => {
            const publishVersion: CreateProcessTemplateVersionBffCommand = {
                ProcessTemplateId: model.Id,
                Version: version,
                IsPreRelease: isPreRelease,
            };
            await repository.Blueprints.publishVersion(publishVersion);
        },
        afterComplete: async () => {
            showSnackbar("Process template version published");
            reloadPublishedVersions();
        },
    });
    const latestVersionForGitRef = publishedVersions?.find((v) => v.GitRef === gitRef && !v.IsPreRelease);
    const publishButton: PageAction = {
        type: "button",
        buttonType: "secondary",
        label: "Publish",
        onClick: openPublishDialog,
        hasPermissions: isAllowed({ permission: Permission.ConfigureServer }),
    };
    const onParametersChanged = useCallback((parameters: ActionTemplateParameterResource[]) => {
        const resource: BlueprintResource = { ...model, Parameters: parameters };
        onChange(resource);
    }, [onChange, model]);
    let secondaryActions: PageAction[] = [addStepButton, publishButton];
    if (activeTab === "process") {
        secondaryActions = [addStepButton, publishButton];
    }
    else if (activeTab === "settings") {
        secondaryActions = [publishButton];
    }
    else if (activeTab === "parameters") {
        const parametersActions = buildBlueprintParametersActions({ parameters: model.Parameters || [], onChange: onParametersChanged });
        secondaryActions = [...parametersActions, publishButton];
    }
    const onSettingsChange = useCallback((settings: BlueprintResource) => {
        const resource: BlueprintResource = { ...model, ...settings };
        onChange(resource);
    }, [onChange, model]);
    const processFromContext = process.state.model.process ? process.selectors.getProcessResource() : null;
    if (processFromContext) {
        if (!isEqual(model.Steps, processFromContext.Steps)) {
            const resource: BlueprintResource = { ...model, Steps: processFromContext.Steps };
            onChange(resource);
        }
    }
    let openCommitDialog: (() => void) | undefined = undefined;
    function getDefaultCommitMessage(): string {
        return "Update process template";
    }
    const saveProcess = async (isNavigationConfirmation: boolean, newBranch?: GitBranchResource) => {
        let isValid = true;
        const onSuccess: () => void = () => {
            errorActions.clearErrors();
            warningActions.clearWarnings();
        };
        const onError: (errors: Errors) => void = (errors) => {
            errorActions.setErrors(errors, process.selectors);
            isValid = false;
            warningActions.clearWarnings();
        };
        if (isNavigationConfirmation && openCommitDialog) {
            openCommitDialog();
            return isValid;
        }
        else {
            await doBusyTask(async () => {
                const { modelProcess: clientProcessResource } = process.selectors.getProcessesForMerge();
                applyCommonLogicToProcessResource(clientProcessResource, lookupData.workerPoolsSummary, process.selectors.getActionPlugin);
                const updatedBlueprint: ModifyProcessTemplateCommand = {
                    ...model,
                    Steps: clientProcessResource.Steps,
                    ChangeDescription: getFormattedCommitMessage(commitMessage, getDefaultCommitMessage()),
                };
                await repository.Blueprints.modify(updatedBlueprint);
                setCommitMessage({ summary: "", details: "" });
                process.actions.refreshFromServer();
                showSnackbar("Process template details updated");
            }, { onError, onSuccess });
            if (isValid && !hasSteps(process.state.model)()) {
                if (!isBlueprintProcessIdentifier(processIdentifier)) {
                    throw new Error("This page only supports process templates");
                }
                const gitRef = model.GitRef;
                navigation.navigate(links.editProcessTemplatePage.generateUrl({ slug: processIdentifier.slug, spaceId: processIdentifier.spaceId }, { gitRef }));
            }
            return isValid;
        }
    };
    const onNewBranchCreating = async (branchName: string) => {
        throw new Error("Process templates don't support branch creating yet");
        // let newBranchResource: GitBranchResource | null = null;
        //
        // try {
        //     newBranchResource = await repository.ProjectBranches.createBranch(process.state.ownerId, branchName, gitRefResource?.CanonicalName ?? "");
        // } catch (ex) {
        //     setDisableDirtyFormChecking(false);
        //     throw ex;
        // }
        //
        // if (newBranchResource) {
        //     setDisableDirtyFormChecking(true);
        //     if (await saveProcess(false, newBranchResource)) {
        //         changeGitRef(branchName);
        //     }
        //     setDisableDirtyFormChecking(false);
        // }
    };
    function getCommitButtonProps(): GetCommitButtonProps {
        return {
            hasPermission: { permission: Permission.ConfigureServer },
            gitRef: gitRefResource?.CanonicalName,
            persistenceSettings: persistenceSettings,
            canCommitToGitRef: canCommitTo(gitRefResource),
            defaultCommitMessage: getDefaultCommitMessage(),
            commitMessage: commitMessage,
            updateCommitMessage: (commitMessage: CommitMessageWithDetails) => setCommitMessage(commitMessage),
            commitMessageAccessibleName: `Commit message for saving the process template process`,
            commitDetailsAccessibleName: `Commit details for saving the process template process`,
            commitButtonAccessibleName: `Commit changes to the process template process`,
            onInitializing: (openDialog) => (openCommitDialog = openDialog),
            label: "Commit",
            busyLabel: "Committing",
            onClick: (e) => saveProcess(false),
            disabled: disableDirtyFormChecking ? false : isEqual(model, cleanModel) || busy,
        };
    }
    const commitAction: PrimaryPageAction = {
        type: "custom",
        content: <GetCommitButton {...getCommitButtonProps()} onNewBranchCreating={onNewBranchCreating}/>,
        key: "Get Commit",
    };
    const titleAccessory = <PlatformHubPageHeaderBranchSelector setGitRef={setGitRef} gitRef={gitRef} gitPersistenceSettings={gitPersistenceSettings}/>;
    // TODO: Add sharing check
    const shouldShowShareAndPublishCallout = !dismissedShareAndPublishCallout && cleanModel.Steps.length > 0 && publishedVersions?.length === 0;
    return (<PaperLayoutVNext callout={shouldShowShareAndPublishCallout ? (<ShareAndPublishCallout onShareClick={() => {
                // todo: sharing
            }} onPublishClick={openPublishDialog} onDismiss={() => setDismissed(true)} templateName={model.Name}/>) : undefined} title={model.Name ?? "Edit process template"} errors={errors} busy={busy} fullWidth={true} primaryAction={commitAction} pageActions={secondaryActions} titleAccessory={titleAccessory}>
            <Dialog open={isPublishDialogOpen}>
                <PublishTemplateDialog busy={busy} onSave={handleSave} errors={errors} gitRef={gitRef} processTemplate={model} latestVersionForGitRef={latestVersionForGitRef}/>
            </Dialog>
            <LegacyForm devToolsDirtyTrackingKey="Process Template" confirmNavigateSaveLabel={"Commit changes..."} disableDirtyFormChecking={disableDirtyFormChecking} onSaveClick={saveProcess} savePermission={{ permission: Permission.ConfigureServer }} model={model} cleanModel={cleanModel} forceDisableFormSaveButton={busy}>
                {({ FormContent }) => (<UrlNavigationTabsContainer defaultValue={"process"}>
                        <TabItem label="Process" value="process">
                            <ProcessStepsOrListLayout lookupData={lookupData} errors={errors} busy={busy} doBusyTask={doBusyTask} gitRefResource={gitRefResource}/>
                        </TabItem>
                        <TabItem label="Parameters" value="parameters">
                            <BlueprintParametersTab parameters={model.Parameters} onChange={onParametersChanged}/>
                        </TabItem>
                        <TabItem label="Settings" value="settings">
                            <FormContent hideExpandAll={false}>
                                <EditProcessTemplateSettingsTab processTemplate={model} onChange={onSettingsChange} iconSvgResources={icons} iconMetadata={iconMetadata}/>
                            </FormContent>
                        </TabItem>
                        <TabItem label="Versions" value="versions">
                            <ProcessTemplateVersions publishedVersions={publishedVersions}/>
                        </TabItem>
                    </UrlNavigationTabsContainer>)}
            </LegacyForm>
        </PaperLayoutVNext>);
}
interface ShareAndPublishCalloutProps {
    templateName: string;
    onShareClick: () => void;
    onPublishClick: () => void;
    onDismiss: () => void;
}
function ShareAndPublishCallout({ templateName, onPublishClick, onShareClick, onDismiss }: ShareAndPublishCalloutProps) {
    return (<Callout type={"success"} title={`${templateName} was successfully updated.`} canClose={true} onClose={onDismiss}>
            To use the process template in other spaces, you'll need to{" "}
            <a href="#" onClick={(e) => {
            e.preventDefault();
            onShareClick();
        }}>
                <strong>share</strong>
            </a>{" "}
            and{" "}
            <a href="#" onClick={(e) => {
            e.preventDefault();
            onPublishClick();
        }}>
                <strong>publish</strong>
            </a>{" "}
            this template.
        </Callout>);
}
function ProcessTemplateVersions({ publishedVersions }: {
    publishedVersions: ProcessTemplateVersionResource[] | null;
}) {
    if (publishedVersions === null) {
        return null;
    }
    if (publishedVersions.length === 0) {
        return (<OnboardingPage title="No versions found for this template" intro="Create versions of process templates on any branch to test and publish to the rest of your team. To get started on publishing a version, select “Publish” in the top right corner." learnMore={null}/>);
    }
    return (<div>
            <SimpleDataTable columns={[
            {
                title: "Version",
                render: (data) => (<div className={styles.versionContainer}>
                                <b>{data.Version}</b>
                                {data.IsPreRelease ? <Chip backgroundColor={themeTokens.color.chip.filled.background.info}>Pre-release</Chip> : null}
                            </div>),
                columnSize: "medium",
            },
            { title: "Git reference", render: (data) => <GitRefChip vcsRef={{ GitRef: data.GitRef, GitCommit: data.GitCommit }} showCommit={true}/>, columnSize: "medium" },
            { title: "Published", render: (data) => DateFormatter.dateToLongFormat(data.PublishedDate) },
        ]} data={publishedVersions} getRowKey={(data) => data.Version}/>
        </div>);
}
const styles = {
    versionContainer: css({
        display: "flex",
        alignItems: "center",
        gap: space[6],
    }),
};
const addStepButton: PageAction = {
    type: "custom",
    content: <ContextAddStepButton />,
    key: "Add Step",
};
export async function editProcessTemplateLoader(repository: Repository, gitRef: string, slug: string): Promise<LoaderData> {
    const platformHubConnectionConfiguration = await repository.PlatformHubConnectionRepository.get();
    if (!gitRef) {
        gitRef = lastAccessedPlatformHubGitRef.get(platformHubConnectionConfiguration.PersistenceSettings);
    }
    const initialValidationResult = await repository.PlatformHubRepository.validateGitRef(gitRef);
    const processTemplate = await repository.Blueprints.get(slug, gitRef);
    return {
        processTemplate,
        platformHubConnectionConfiguration,
        initialValidationResult,
    };
}
interface LoaderData {
    processTemplate: BlueprintResource;
    platformHubConnectionConfiguration: PlatformHubConnectionConfigurationResource;
    initialValidationResult: ValidateGitRefV2Response;
}
export default function EditProcessTemplatePage(props: EditProcessTemplateProps) {
    const { doBusyTask, status: doBusyTaskStatus } = useLegacyDoBusyTask();
    const { isInProgress, errors } = useAggregateAPIOperationStatus(doBusyTaskStatus);
    return <EditWithAllContext loaderData={props.loaderData} spaceId={props.spaceId} slug={props.slug} doBusyTask={doBusyTask} busy={isInProgress} errors={errors} queryParams={props.queryParams} setQueryParams={props.setQueryParams}/>;
}
