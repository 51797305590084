import { getGitRefType, type GitBranchResource, type GitRef, GitRefType, type Permission } from "@octopusdeploy/octopus-server-client";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GitRefDropDown, { type GitRefDropDownMode, RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
export type GitRefSelectorInternalProps = {
    initialGitRef: GitRef | undefined;
    defaultBranch: GitRef | string;
    onChange: (selectedGitRef: GitRef) => void;
    onCreateBranch?: (newBranchName: string, parentGitRef: GitRef) => Promise<GitBranchResource>;
    disabled?: boolean;
    style?: "grey" | "white";
    mode?: GitRefDropDownMode;
    allowBranchCreation?: boolean;
    errorMessage?: string;
    busy?: Promise<void>;
    items: GitRefOption[];
    totalItemCount: number;
    loadItems?: (refType: RefTypes, selectedGitRef: GitRef | undefined) => void;
    searchItems: (refType: RefTypes, searchTerm: string) => Promise<GitRefOption[]>;
    permission: Permission;
};
export const GitRefSelectorInternal = (props: GitRefSelectorInternalProps) => {
    const mapToRefTypes = (gitRefType: GitRefType): RefTypes => {
        switch (gitRefType) {
            case GitRefType.Branch:
                return RefTypes.Branches;
            case GitRefType.Tag:
                return RefTypes.Tags;
            case GitRefType.Commit:
                return RefTypes.Commits;
            case GitRefType.Unknown:
            case GitRefType.Named:
                // We used to throw an error here, but that would cause pages to crash if we ever had a
                // gitRef passed in that was not fully qualified. Instead, we will just default to branches
                // to keep the UI working
                return RefTypes.Branches;
        }
    };
    const { initialGitRef, onChange, onCreateBranch, searchItems, loadItems } = props;
    const [refType, setRefType] = React.useState<RefTypes>(initialGitRef ? mapToRefTypes(getGitRefType(initialGitRef)) : RefTypes.Branches);
    const isFormDirty = useSelector((state: GlobalState) => state.form.dirty);
    useEffect(() => {
        if (loadItems)
            loadItems(refType, initialGitRef);
    }, [initialGitRef, loadItems, refType]);
    const onCreateBranchLocal = async (newBranchName: string, baseGitRef: GitRef): Promise<void> => {
        // If no 'onCreateBranch' handler has been given via props, and somehow
        // we've ended up in here, then something has gone wrong.
        if (!onCreateBranch)
            throw new Error("onCreateBranch was called, but no handler was given via props");
        const gitRefResource = await onCreateBranch(newBranchName, baseGitRef);
        onChange(gitRefResource.CanonicalName);
    };
    const onRefTypeChanged = (refType: RefTypes) => setRefType(refType);
    const onRequestRefresh = async (selectedGitRef: GitRef): Promise<void> => {
        if (loadItems) {
            loadItems(refType, selectedGitRef);
        }
    };
    return (<>
            <GitRefDropDown isBusySearching={props.busy !== null && props.busy !== undefined} style={props.style} value={initialGitRef} items={props.items} totalItems={props.totalItemCount} onChange={(gitRefOption: GitRefOption) => onChange(gitRefOption.value)} onRequestRefresh={onRequestRefresh} onFilterChanged={(searchTerm) => searchItems(refType, searchTerm)} disabled={props.disabled} onCreateBranch={onCreateBranchLocal} defaultBranch={props.defaultBranch} refType={refType} onRefTypeChanged={onRefTypeChanged} mode={props.mode} allowBranchCreation={props.allowBranchCreation} disableBranchCreation={isFormDirty || !initialGitRef} errorMessage={props.errorMessage} permission={props.permission}/>
        </>);
};
