import { UnavailableIcon } from "@octopusdeploy/design-system-components";
import type { Dictionary } from "lodash";
import type * as React from "react";
import { DegradedIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/DegradedIcon";
import { HealthyIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/HealthyIcon";
import { MissingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/MissingIcon";
import { OutOfSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/OutOfSyncIcon";
import { ProgressingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/ProgressingIcon";
import { UnknownIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/UnknownIcon";
export const Statuses: Dictionary<() => React.ReactElement> = {
    Degraded: DegradedIcon,
    Healthy: HealthyIcon,
    OutOfSync: OutOfSyncIcon,
    Progressing: ProgressingIcon,
    Unavailable: UnavailableIcon,
    Unknown: UnknownIcon,
    Missing: MissingIcon,
};
export function K8sStatusIcon({ status }: {
    status: string;
}): React.ReactElement {
    return Statuses[status] === undefined ? UnknownIcon() : Statuses[status]();
}
