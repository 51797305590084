/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ProjectResource, ActionTemplateParameterResource, ControlType, GitRefResource, LibraryVariableSetResource, VariableResource, VariableType, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import { uniq } from "lodash";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { lastAccessedGitRef } from "~/areas/projects/context/LastAccessedGitRef";
import { repository } from "~/clientInstance";
interface LoadedProjectVariables {
    variableSets: VariableSetResource[];
    libraryVariableSets: LibraryVariableSetResource[];
}
export async function loadProjectVariableNames(projectId: string, gitRef: GitRefResource | undefined, variableTypes: VariableType[], controlTypes: ControlType[]) {
    const project = await repository.Projects.get(projectId);
    const variables = await loadVariablesForProject(project, gitRef);
    return collateProjectVariableNames(project, variables, variableTypes, controlTypes);
}
async function loadVariablesForProject(project: ProjectResource, gitRefResource: GitRefResource | undefined) {
    const libraryVariableSets = await Promise.all(project.IncludedLibraryVariableSetIds.map((x) => repository.LibraryVariableSets.get(x)));
    const variableSetsIds = [project.VariableSetId, ...libraryVariableSets.map((x) => x.VariableSetId)];
    const variableSets = await Promise.all(variableSetsIds.map((x) => repository.Variables.get(x)));
    if (HasVariablesInGit(project.PersistenceSettings) && gitRefResource) {
        variableSets.push(await repository.Variables.getForGitRef(gitRefResource));
    }
    else if (HasVariablesInGit(project.PersistenceSettings)) {
        const gitRef = lastAccessedGitRef.get(project);
        variableSets.push(await repository.ProjectVariables.get(project.Id, gitRef));
    }
    return { variableSets, libraryVariableSets };
}
function filterVariablesByTypes(variableSets: VariableSetResource[], variableTypes: VariableType[]) {
    const lookup = new Set(variableTypes);
    return variableSets.reduce<VariableResource[]>((prev, current) => [...prev, ...current.Variables], []).filter((x) => lookup.has(x.Type));
}
function collateProjectVariableNames(project: ProjectResource, variables: LoadedProjectVariables, variableTypes: VariableType[], controlTypes: ControlType[]) {
    const variablesByType = filterVariablesByTypes(variables.variableSets, variableTypes);
    const actionTemplates = getAllProjectActionTemplates(project, variables.libraryVariableSets);
    const templateVariables = filterVariableTemplatesByControlTypes(actionTemplates, controlTypes);
    return uniq([...variablesByType.map((x) => x.Name), ...templateVariables.map((x) => x.Name)]).sort();
}
function getAllProjectActionTemplates(project: ProjectResource, libraryVariableSets: LibraryVariableSetResource[]): ActionTemplateParameterResource[] {
    const actionTemplatesFromLibraryVariableSets = libraryVariableSets.reduce<ActionTemplateParameterResource[]>((prev, current) => [...prev, ...current.Templates], []);
    return [...project.Templates, ...actionTemplatesFromLibraryVariableSets];
}
function filterVariableTemplatesByControlTypes(variableTemplates: ActionTemplateParameterResource[], controlTypes: ControlType[]) {
    const lookup = new Set(controlTypes);
    return variableTemplates.filter((template) => (template.DisplaySettings?.["Octopus.ControlType"] ? lookup.has(template.DisplaySettings["Octopus.ControlType"]) : false));
}
export function collateVariableNames(processVariableSet: VariableSetResource, libraryVariableSets: LoadedLibraryVariableSets[], templates: ActionTemplateParameterResource[], variableTypes: VariableType[], controlTypes: ControlType[]): string[] {
    const variableSets = [processVariableSet, ...libraryVariableSets.map((x) => x.variableSet)];
    const variablesByType = filterVariablesByTypes(variableSets, variableTypes);
    const actionTemplates = getAllTemplates(templates, libraryVariableSets.map((x) => x.libraryVariableSet));
    const templateVariables = filterVariableTemplatesByControlTypes(actionTemplates, controlTypes);
    return uniq([...variablesByType.map((x) => x.Name), ...templateVariables.map((x) => x.Name)]).sort();
}
function getAllTemplates(templates: ActionTemplateParameterResource[], libraryVariableSets: LibraryVariableSetResource[]): ActionTemplateParameterResource[] {
    const actionTemplatesFromLibraryVariableSets = libraryVariableSets.reduce<ActionTemplateParameterResource[]>((prev, current) => [...prev, ...current.Templates], []);
    return [...templates, ...actionTemplatesFromLibraryVariableSets];
}
