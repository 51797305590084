import type { PageAction, PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { CodeIcon } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { isVersionControlledProcess } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { BlueprintProcessIdentifier, DeploymentProcessIdentifier, RunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import RunbooksNavigationTabs from "~/areas/projects/components/Runbooks/RunbooksNavigationTabs";
import { AddDeploymentBlueprintStepButton, AddRunbookBlueprintStepButton } from "~/areas/projects/components/Steps/AddBlueprintStepButton";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { useProjectContext } from "~/areas/projects/context/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed, isAllowedToRunGitRunbook } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import StringHelper from "~/utils/StringHelper";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import { getPermissionDeniedTooltipText, RunNowButton } from "../../Runbooks/RunNowButton";
import { useRunbookContext } from "../../Runbooks/RunbookContext";
import { ContextAddStepButton } from "../../Steps";
import { useDispatchSwitchToCodeEditor } from "../CodeEditor/ProcessCodeEditorAnalytics";
import { isProcessCodeEditorEnabled } from "../CodeEditor/isProcessCodeEditorEnabled";
import { useProcessContext } from "../Contexts/ProcessContext";
type ProcessContextFormPaperLayoutProps = {
    disableDirtyFormChecking?: boolean;
    busy?: boolean | Promise<unknown>;
    doBusyTask: DoBusyTask;
    errors?: Errors | Error[];
    model: object | undefined;
    cleanModel?: object;
    savePermission?: PermissionCheckProps | undefined;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    saveButtonLabel: string;
    overflowActions?: SimpleMenuItem[];
    customPrimaryAction?: (props: {
        isDisabled: boolean;
        onClick: () => Promise<boolean>;
    }) => PrimaryPageAction;
    confirmNavigateSaveLabel?: string;
    hideAddStepButton?: boolean;
    titleAccessory?: React.ReactElement;
    gitRefResource?: GitRefResource | undefined;
};
export const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = ({ children, disableDirtyFormChecking, busy, confirmNavigateSaveLabel, saveButtonLabel, hideAddStepButton, doBusyTask, customPrimaryAction, onSaveClick, overflowActions, savePermission, model, errors, cleanModel, titleAccessory, gitRefResource, }) => {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const type = processContext.selectors.getProcessType();
    const hasSteps = processContext.selectors.hasSteps();
    if (isRunbookProcessIdentifier(processIdentifier)) {
        return (<RunbooksProcessEditorFormPaperLayout hasSteps={hasSteps} busy={busy} errors={errors} hideAddStepButton={hideAddStepButton} disableDirtyFormChecking={disableDirtyFormChecking} confirmNavigateSaveLabel={confirmNavigateSaveLabel} doBusyTask={doBusyTask} saveButtonLabel={saveButtonLabel} savePermission={savePermission} customPrimaryAction={customPrimaryAction} cleanModel={cleanModel} model={model} onSaveClick={onSaveClick} overflowActions={overflowActions} titleAccessory={titleAccessory} processIdentifier={processIdentifier} gitRefResource={gitRefResource}>
                <GitCallouts isRunbooksPage gitRefResource={gitRefResource}/>
                {children}
            </RunbooksProcessEditorFormPaperLayout>);
    }
    else if (isBlueprintProcessIdentifier(processIdentifier)) {
        return (<BlueprintsProcessEditorFormPaperLayout busy={busy} errors={errors} hideAddStepButton={hideAddStepButton} disableDirtyFormChecking={disableDirtyFormChecking} confirmNavigateSaveLabel={confirmNavigateSaveLabel} saveButtonLabel={saveButtonLabel} savePermission={savePermission} customPrimaryAction={customPrimaryAction} cleanModel={cleanModel} model={model} onSaveClick={onSaveClick} overflowActions={overflowActions} processIdentifier={processIdentifier} gitRefResource={gitRefResource}>
                {children}
            </BlueprintsProcessEditorFormPaperLayout>);
    }
    else {
        return (<DeploymentsProcessEditorFormPaperLayout busy={busy} errors={errors} hideAddStepButton={hideAddStepButton} disableDirtyFormChecking={disableDirtyFormChecking} confirmNavigateSaveLabel={confirmNavigateSaveLabel} saveButtonLabel={saveButtonLabel} savePermission={savePermission} customPrimaryAction={customPrimaryAction} cleanModel={cleanModel} model={model} onSaveClick={onSaveClick} overflowActions={overflowActions} processIdentifier={processIdentifier} gitRefResource={gitRefResource}>
                <GitCallouts gitRefResource={gitRefResource}/>
                {children}
            </DeploymentsProcessEditorFormPaperLayout>);
    }
};
ProcessContextFormPaperLayout.displayName = "ProcessContextFormPaperLayout"
interface CommonProcessEditorFormPaperLayoutProps {
    disableDirtyFormChecking: boolean | undefined;
    busy: boolean | Promise<unknown> | undefined;
    errors: Errors | Error[] | undefined;
    model: object | undefined;
    cleanModel: object | undefined;
    savePermission: PermissionCheckProps | undefined;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    saveButtonLabel: string;
    overflowActions: SimpleMenuItem[] | undefined;
    customPrimaryAction: ((props: {
        isDisabled: boolean;
        onClick: () => Promise<boolean>;
    }) => PrimaryPageAction) | undefined;
    confirmNavigateSaveLabel: string | undefined;
    hideAddStepButton: boolean | undefined;
    gitRefResource?: GitRefResource | undefined;
}
interface DeploymentsProcessEditorFormPaperLayoutProps extends CommonProcessEditorFormPaperLayoutProps {
    processIdentifier: DeploymentProcessIdentifier;
}
interface RunbooksProcessEditorFormPaperLayoutProps extends CommonProcessEditorFormPaperLayoutProps {
    hasSteps: boolean;
    titleAccessory?: React.ReactElement;
    processIdentifier: RunbookProcessIdentifier;
    doBusyTask: DoBusyTask;
}
interface BlueprintsProcessEditorFormPaperLayoutProps extends CommonProcessEditorFormPaperLayoutProps {
    processIdentifier: BlueprintProcessIdentifier;
}
const DeploymentsProcessEditorFormPaperLayout: React.FC<DeploymentsProcessEditorFormPaperLayoutProps> = ({ children, hideAddStepButton, confirmNavigateSaveLabel, saveButtonLabel, busy, disableDirtyFormChecking, customPrimaryAction, onSaveClick, overflowActions, savePermission, model, cleanModel, errors, processIdentifier, gitRefResource, }) => {
    const pageActions: PageAction[] = [];
    const { state: { model: project }, } = useProjectContext();
    const processContext = useProcessContext();
    const hasSteps: boolean = processContext.selectors.hasStepsInCleanModel();
    const hasUnsavedSteps = processContext.selectors.hasUnsavedSteps();
    const isCodeEditorEnabled = isProcessCodeEditorEnabled(project, processContext.selectors.getProcessType());
    const dispatchAction = useProjectScopedAnalyticActionDispatch(processIdentifier.projectId);
    const dispatchSwitchToCodeEditor = useDispatchSwitchToCodeEditor();
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    if (isCodeEditorEnabled) {
        const switchToCodeViewButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Code",
            extraContext: "Switch to the code editor to edit the OCL for your deployment process directly",
            icon: <CodeIcon />,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            path: links.branchDeploymentProcessCodePage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug, branchName: gitRefResource!.CanonicalName }),
            onClick: () => dispatchSwitchToCodeEditor(),
            hasPermissions: isAllowed({ permission: Permission.ProcessView, project: processIdentifier.projectId, tenant: "*" }),
        };
        pageActions.push(switchToCodeViewButton);
    }
    const createReleaseIsAllowed = hasSteps || project?.IsVersionControlled;
    const createReleaseButton: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        label: "Create Release",
        path: links.createReleasePage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug }),
        onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
        hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: processIdentifier.projectId, tenant: "*" }),
        disabled: !createReleaseIsAllowed,
        titleAltText: createReleaseIsAllowed ? undefined : "Please add steps before creating a release",
    };
    pageActions.push(createReleaseButton);
    if (hideAddStepButton === false) {
        const addStepButton: PageAction = {
            type: "custom",
            content: <ContextAddStepButton />,
            key: "Add Step",
            hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: processIdentifier.projectId, wildcard: true }),
        };
        pageActions.push(addStepButton);
    }
    if (isBlueprintsEnabled) {
        const addBlueprintStepButton: PageAction = {
            type: "custom",
            content: <AddDeploymentBlueprintStepButton projectId={processIdentifier.projectId}/>,
            key: "Add Blueprint",
            hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: processIdentifier.projectId, wildcard: true }),
        };
        pageActions.push(addBlueprintStepButton);
    }
    return (<LegacyForm devToolsDirtyTrackingKey="Deployment Process" confirmNavigateSaveLabel={confirmNavigateSaveLabel} disableDirtyFormChecking={disableDirtyFormChecking} onSaveClick={onSaveClick} savePermission={savePermission} model={model} cleanModel={cleanModel} forceDisableFormSaveButton={!!busy}>
            {({ FormContent, createSaveAction, isDisabled, save }) => (<PaperLayoutVNext busy={busy} errors={errors} overflowActions={overflowActions} hideErrors={true} // We have custom error handling for our process form.
         primaryAction={hasUnsavedSteps ? (customPrimaryAction ? customPrimaryAction({ isDisabled, onClick: () => save() }) : createSaveAction({ saveButtonLabel })) : undefined} title={"Process Editor"} breadcrumbsItems={[{ label: "Process", pageUrl: links.deploymentProcessPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug }) }]} pageActions={[...pageActions]} fullWidth={true} titleAccessory={<ProjectPageTitleAccessory />}>
                    <FormContent hideExpandAll={true} // We position these manually due to a custom process layout.
        >
                        <ProjectGitHubAppAuthCheck project={project} permission={Permission.ProcessEdit}/>
                        {children}
                    </FormContent>
                </PaperLayoutVNext>)}
        </LegacyForm>);
};
DeploymentsProcessEditorFormPaperLayout.displayName = "DeploymentsProcessEditorFormPaperLayout"
const BlueprintsProcessEditorFormPaperLayout: React.FC<BlueprintsProcessEditorFormPaperLayoutProps> = ({ children }) => {
    return <>{children}</>;
};
BlueprintsProcessEditorFormPaperLayout.displayName = "BlueprintsProcessEditorFormPaperLayout"
const RunbooksProcessEditorFormPaperLayout: React.FC<RunbooksProcessEditorFormPaperLayoutProps> = ({ hasSteps, doBusyTask, busy, disableDirtyFormChecking, saveButtonLabel, confirmNavigateSaveLabel, onSaveClick, overflowActions, savePermission, hideAddStepButton, customPrimaryAction, errors, cleanModel, model, children, titleAccessory, processIdentifier, gitRefResource, }) => {
    const { state: { model: project }, } = useProjectContext();
    const runbooksContext = useRunbookContext();
    const processContext = useProcessContext();
    const runbook = runbooksContext.state.runbook;
    const hasUnsavedSteps = processContext.selectors.hasUnsavedSteps();
    const gitRef = gitRefResource?.CanonicalName;
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const persistenceSettings = usePersistenceSettingsContext();
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    const allowedToRunOnCurrentBranch = isAllowedToRunGitRunbook(project, gitRef);
    const hasGitRunbooks = HasRunbooksInGit(persistenceSettings);
    const publishAction: PageAction = {
        type: "custom",
        content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
        key: "Publish",
        hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: processIdentifier.projectId, wildcard: true }),
    };
    const pageActions: PageAction[] = [
        ...(isVersionControlledProcess(persistenceSettings, processContext.state.processIdentifier.type) ? [] : [publishAction]),
        {
            type: "custom",
            content: (<RunNowButton spaceId={runbook.SpaceId} isDisabled={!hasSteps || (hasGitRunbooks && !allowedToRunOnCurrentBranch)} tooltip={hasGitRunbooks && !allowedToRunOnCurrentBranch ? getPermissionDeniedTooltipText(gitRef) : undefined} projectSlug={processIdentifier.projectSlug} projectId={processIdentifier.projectId} runbookId={runbook.Id}/>),
            key: "Run Now",
            hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: processIdentifier.projectId, wildcard: true }),
        },
    ];
    if (hideAddStepButton === false) {
        const addStepButton: PageAction = { type: "custom", content: <ContextAddStepButton />, key: "Add Step", hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: processIdentifier.projectId, wildcard: true }) };
        pageActions.push(addStepButton);
    }
    if (isBlueprintsEnabled) {
        const addBlueprintStepButton: PageAction = {
            type: "custom",
            content: <AddRunbookBlueprintStepButton projectId={processIdentifier.projectId}/>,
            key: "Add Blueprint",
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: processIdentifier.projectId, wildcard: true }),
        };
        pageActions.push(addBlueprintStepButton);
    }
    return (<LegacyForm devToolsDirtyTrackingKey="Runbook Process" disableDirtyFormChecking={disableDirtyFormChecking} confirmNavigateSaveLabel={confirmNavigateSaveLabel} onSaveClick={onSaveClick} savePermission={savePermission} cleanModel={cleanModel} model={model} forceDisableFormSaveButton={!!busy}>
            {({ FormContent, createSaveAction, save, isDisabled }) => (<PaperLayoutVNext fullWidth={true} hideErrors={true} // We have custom error handling for our process form.
         title={runbooksContext.state.runbook?.Name ?? StringHelper.ellipsis} titleChip={titleChip} breadcrumbsItems={[{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: processIdentifier.spaceId, projectSlug: processIdentifier.projectSlug }) }]} pageActions={pageActions} primaryAction={hasUnsavedSteps ? (customPrimaryAction ? customPrimaryAction({ isDisabled, onClick: () => save() }) : createSaveAction({ saveButtonLabel })) : undefined} busy={busy} overflowActions={overflowActions} errors={errors} titleAccessory={titleAccessory ?? <ProjectPageTitleAccessory />}>
                    <RunbooksNavigationTabs />
                    <FormContent hideExpandAll={true} // We position these manually due to a custom process layout.
        >
                        {children}
                    </FormContent>
                </PaperLayoutVNext>)}
        </LegacyForm>);
};
RunbooksProcessEditorFormPaperLayout.displayName = "RunbooksProcessEditorFormPaperLayout"
