import { PopoverBasicHelp } from "@octopusdeploy/design-system-components";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import React, { useState } from "react";
import { createHelmCommand } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/NfsDriverHelmCommand";
import { contentContainerStyles } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/Styles";
import { CodeEditor, type Language, type TextFormat } from "~/components/CodeEditor/CodeEditor";
export const NfsDriverInstallationPage = () => {
    const [selectedLanguage, setSelectedLanguage] = useState<ScriptingLanguage | Language | TextFormat>(ScriptingLanguage.Bash);
    const helmCommand = createHelmCommand(selectedLanguage);
    const description = <div>The NFS CSI driver provides capabilities when connecting your Kubernetes cluster to the NFS server.</div>;
    return (<div className={contentContainerStyles}>
            <div>
                Install the required CSI driver <PopoverBasicHelp description={description} placement={"bottom-start"}/> by running this Helm command in the terminal connected to your cluster.
            </div>
            <CodeEditor value={helmCommand} readOnly lineWrapping={false} showCopyButton showLineNumbers={false} language={selectedLanguage} allowFullScreen settingsOverride={{ theme: "default", wordWrap: false }} onChange={noOp} scriptingLanguageSelectorOptions={{
            supportedLanguages: [ScriptingLanguage.Bash, ScriptingLanguage.PowerShell],
            onScriptingLanguageChanged: (syntax: ScriptingLanguage | Language | TextFormat) => setSelectedLanguage(syntax),
        }}/>
        </div>);
};
