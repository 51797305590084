import type { OnceDailyTriggerScheduleResource, ContinuousDailyTriggerScheduleResource } from "@octopusdeploy/octopus-server-client";
import { DayOfWeek } from "@octopusdeploy/octopus-server-client";
import type { DaysPerWeekRecurringSchedule } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import * as React from "react";
export default class DaysDescriptionHelper {
    constructor(private readonly schedule: OnceDailyTriggerScheduleResource | ContinuousDailyTriggerScheduleResource | DaysPerWeekRecurringSchedule) { }
    public getSimpleSummary(emptyText?: string): string {
        if (this.noDaysSelected()) {
            return emptyText || "";
        }
        let summary = "";
        if (this.runsOnWeekdays()) {
            summary = "weekdays";
        }
        else if (this.runsOnWeekends()) {
            summary = "weekends";
        }
        else {
            summary = this.schedule.DaysOfWeek.join(", ");
        }
        return `on ${summary}`;
    }
    public getSummary(emptyText?: string) {
        return <span> {this.getSimpleSummary(emptyText)}</span>;
    }
    private noDaysSelected() {
        return this.schedule.DaysOfWeek.length === 0;
    }
    public runsOnWeekdays(): boolean {
        return (this.schedule.DaysOfWeek.indexOf(DayOfWeek.Monday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Tuesday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Wednesday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Thursday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Friday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Saturday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Sunday) === -1);
    }
    public runsOnWeekends(): boolean {
        return (this.schedule.DaysOfWeek.indexOf(DayOfWeek.Monday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Tuesday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Wednesday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Thursday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Friday) === -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Saturday) !== -1 &&
            this.schedule.DaysOfWeek.indexOf(DayOfWeek.Sunday) !== -1);
    }
}
