import { css } from "@emotion/css";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeScopeDetail, DeploymentFreezeScopeType } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useState } from "react";
import { DeploymentFreezeScopeTableRow } from "~/areas/configuration/components/DeploymentFreezes/EditScopeTable/DeploymentFreezeScopeTableRow";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { DataTable, DataTableBody, DataTableHeader, DataTableRow, DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeScopeTableProps {
    scopeDetails: DeploymentFreezeScopeDetail[];
    onRemove: (item: DeploymentFreezeScopeDetail) => void;
    onEnvironmentUpdate: (item: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => void;
    scopeType: DeploymentFreezeScopeType;
    doBusyTask: DoBusyTask;
    focussedScopeCellId: string;
    onFocusedScopeCell: (id: string) => void;
    onScopeCellLostFocus: () => void;
}
export const DeploymentFreezeScopeTable = ({ scopeDetails, scopeType, onRemove, onEnvironmentUpdate, doBusyTask, focussedScopeCellId, onScopeCellLostFocus, onFocusedScopeCell }: DeploymentFreezeScopeTableProps) => {
    const pageSize = 30;
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [paginatedScopeItems, setPaginatedItems] = useState<DeploymentFreezeScopeDetail[]>(scopeDetails);
    useEffect(() => {
        const pagedItems = scopeDetails.slice(pageSize * currentPageIndex, pageSize * (currentPageIndex + 1));
        setPaginatedItems(pagedItems);
    }, [scopeDetails, currentPageIndex]);
    return (<div>
            <DataTable className={tableStyles} title="Deployment Freeze Scope Table">
                <DataTableHeader>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>{scopeType}</DataTableRowHeaderColumn>
                        <DataTableRowHeaderColumn>Environments</DataTableRowHeaderColumn>
                        <DataTableRowHeaderColumn />
                    </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                    {paginatedScopeItems.map((item) => (<DeploymentFreezeScopeTableRow scopeType={scopeType} key={item.Id} scopeItem={item} onRemove={onRemove} onEnvironmentUpdate={onEnvironmentUpdate} doBusyTask={doBusyTask} focussedScopeCellId={focussedScopeCellId} onFocusedScopeCell={onFocusedScopeCell} onScopeCellLostFocus={onScopeCellLostFocus}/>))}
                </DataTableBody>
            </DataTable>
            <NumberedPagingBar totalItems={scopeDetails.length} currentPageIndex={currentPageIndex} pageSize={pageSize} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>
        </div>);
};
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.regular.default.medium,
        position: "relative",
        width: "auto",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "top",
    },
});
