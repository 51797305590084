/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import React from "react";
interface BlueprintIconEditLayoutProps {
    iconEditor: React.ReactElement;
}
const BlueprintIconEditLayout = ({ iconEditor }: BlueprintIconEditLayoutProps) => {
    return <div className={styles.content}>{iconEditor}</div>;
};
export default BlueprintIconEditLayout;
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "visible",
        width: "375px",
        "@media (max-width: @screen-sm)": {
            width: "100%",
            gridAutoRows: "auto",
        },
    }),
};
