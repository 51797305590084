import { css } from "@emotion/css";
import { PopoverBasicHelp } from "@octopusdeploy/design-system-components/src/components/Popover/PopoverBasicHelp";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
const styles = {
    bulletList: css({
        listStylePosition: "outside",
        listStyleType: "disc",
        paddingLeft: space[24],
    }),
};
export const StorageContextualHelp = (props: {
    machineType: EndpointSelectionScope;
}) => (<PopoverBasicHelp placement="right-start" title={"Storage Class"} description={<>
                <div>
                    The Kubernetes {props.machineType === EndpointSelectionScope.Worker ? "worker" : "agent"} uses NFS by default, but you can provide any Storage Class that supports the <code>ReadWriteMany</code> access mode.
                </div>
                <div>
                    Consider using your own Storage Class for scenarios where:
                    <ul className={styles.bulletList}>
                        <li>You remove or restart cluster nodes often</li>
                        <li>Privileged containers aren't allowed</li>
                    </ul>
                </div>
            </>} link1={<ExternalLink href={"KubernetesAgentStorage"}>Learn more</ExternalLink>}/>);
