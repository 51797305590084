import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { OpenFeature } from "@openfeature/web-sdk";
const slug = "kos-for-helm";
export function useKOSForHelm() {
    return useBooleanFlagValue(slug, false);
}
export function isKOSForHelmEnabled() {
    const client = OpenFeature.getClient();
    return !client.getBooleanValue(slug, false);
}
