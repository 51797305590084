import type { Dictionary } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { K8sResourceIcon } from "~/areas/projects/components/Observability/KubernetesResources";
import { ChipIcon, FilterTextChip, MissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
interface KubernetesResourceTypeMultiSelectProps extends FormFieldProps<string[]> {
    items: Dictionary<() => ReactElement>;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const KubernetesResourceTypeTypedMultiSelect = MultiSelect<SelectItem>();
const KubernetesResourceTypeMultiSelect: React.FC<KubernetesResourceTypeMultiSelectProps> = (props) => {
    const { onChange, items, ...otherProps } = props;
    const selectItems = Object.keys(items).map((i) => ({
        Id: i,
        Name: i,
    }));
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => (<Lookup lookupCollection={selectItems} lookupId={r.Id} getIdFromElement={(element: SelectItem) => element.Id} render={(item: SelectItem) => <FilterTextChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} filterText={item.Name} icon={<K8sResourceIcon resourceType={item.Id}/>}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle}/>}/>);
    return <KubernetesResourceTypeTypedMultiSelect items={selectItems} fieldName="kind" renderChip={chipRenderer} onChange={(value) => onChange(value)} {...otherProps}/>;
};
KubernetesResourceTypeMultiSelect.displayName = "KubernetesResourceTypeMultiSelect"
export default KubernetesResourceTypeMultiSelect;
