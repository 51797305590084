import { css } from "@emotion/css";
import { Avatar, Checkbox, ExternalLink } from "@octopusdeploy/design-system-components";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { BlueprintResource, GitRef, ProcessTemplateVersionResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { getProcessTemplateIconUrl } from "~/areas/blueprints/ProcessTemplatesPage";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { Note, required, Text } from "~/components/form/index";
interface PublishTemplateDialogProps {
    busy: BusyState;
    onSave: (version: string, isPreRelease: boolean) => Promise<void>;
    errors: Error[];
    processTemplate: BlueprintResource;
    gitRef: GitRef;
    latestVersionForGitRef: ProcessTemplateVersionResource | undefined;
}
export function PublishTemplateDialog({ busy, onSave, errors, processTemplate, gitRef, latestVersionForGitRef }: PublishTemplateDialogProps) {
    const [publishVersion, setPublishVersion] = useState<string>("");
    const [isPreRelease, setIsPreRelease] = useState<boolean>(false);
    return (<SaveDialogLayout errors={errors} title={`Publish`} busy={busy} onSaveClick={async () => {
            await onSave(publishVersion, isPreRelease);
            return true;
        }} saveButtonLabel={"Publish"}>
            <div className={styles.dialogContainer}>
                <div className={styles.publishInfoContainer}>
                    <Avatar src={getProcessTemplateIconUrl(processTemplate)} shape={"squared"} size={40} alt={"Process template icon"}/>
                    <div className={styles.innerContainer}>
                        <div className={styles.versionContainer}>
                            <div className={styles.name}>{processTemplate.Name}</div>
                            <div>{latestVersionForGitRef ? `Current version ${latestVersionForGitRef.Version}` : "No published versions on this branch"}</div>
                        </div>
                        {latestVersionForGitRef ? <GitRefChip vcsRef={{ GitRef: gitRef, GitCommit: latestVersionForGitRef.GitCommit }} showCommit={true}/> : <GitRefChip vcsRef={{ GitRef: gitRef }}/>}
                    </div>
                </div>
                <div>
                    <Text label="Template version" value={publishVersion} onChange={setPublishVersion} autoFocus={true} validate={required("Please enter a version")}/>
                    <Note>
                        <ExternalLink href="SemVer" label="Semantic versioning"/> is recommended.
                    </Note>
                </div>
                <div>
                    <Checkbox value={isPreRelease} onChange={() => setIsPreRelease(!isPreRelease)} label="Pre-release" noMargin={true}/>
                </div>
            </div>
        </SaveDialogLayout>);
}
const styles = {
    dialogContainer: css({
        display: "flex",
        flexDirection: "column",
        gap: space[24],
    }),
    publishInfoContainer: css({
        display: "flex",
        alignItems: "center",
        gap: space[12],
        backgroundColor: themeTokens.color.background.secondary.default,
        borderRadius: borderRadius.small,
        padding: space[12],
    }),
    innerContainer: css({
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
    }),
    versionContainer: css({
        display: "flex",
        flexDirection: "column",
    }),
    name: css({
        font: text.regular.bold.medium,
    }),
};
