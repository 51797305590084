import type { PopoverPlacement } from "@octopusdeploy/design-system-components";
import { PopoverBasicHelp } from "@octopusdeploy/design-system-components/src/components/Popover/PopoverBasicHelp";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink";
export const ProjectGitRepositorySourceContextualHelp = ({ position = "right-start", project }: {
    position?: PopoverPlacement;
    project?: ProjectResource;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const versionControlSettingsElement = project ? <InternalLink to={links.projectVersionControlSettingsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>version control settings</InternalLink> : "version control settings";
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Project git repository" });
    };
    return <PopoverBasicHelp onOpen={onOpen} placement={position} description={<div>The commit associated with the release will be used to obtain the files from the repository configured in {versionControlSettingsElement}.</div>}/>;
};
export const ExternalGitRepositorySourceContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const popupOpenSideEffects = () => {
        dispatchAction("View Contextual Help Popover", { context: "External git repository" });
    };
    return <PopoverBasicHelp onOpen={popupOpenSideEffects} placement={position} description={<div>The tip of the selected repository and branch will be used to obtain the files.</div>}/>;
};
