/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ActivityElement, ScheduledTaskDetailsResource } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { activityElementUniqueIdFromId } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TaskLogBlock from "~/components/TaskLogLines/TaskLogBlock";
import Select from "~/primitiveComponents/form/Select/Select";
import styles from "./style.module.less";
interface LogsBaseState extends DataBaseComponentState {
    detail?: ScheduledTaskDetailsResource;
    activityElement?: UniqueActivityElement;
    verbose: boolean;
    tail: boolean;
    hasLoadedOnce?: boolean;
}
export class LogsBase extends DataBaseComponent<{}, LogsBaseState> {
    private name: string;
    private title: string;
    constructor(name: string, title: string, props: {}) {
        super(props);
        this.name = name;
        this.title = title;
        this.state = { verbose: false, tail: true };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(this.state.verbose, this.state.tail), 5000);
        });
    }
    async refresh(verbose: boolean, tail: boolean) {
        this.setState({ verbose, tail });
        const args = { verbose, tail: tail ? 30 : null! };
        const detail = await repository.Scheduler.getDetails(this.name, args);
        return {
            detail,
            activityElement: this.setIdPrefix(detail.ActivityLog, 0),
            hasLoadedOnce: true,
        };
    }
    // This is a bit hacky since auto-deploys that kick off from same deployment will have the same task prefix
    setIdPrefix(element: ActivityElement, n: number): UniqueActivityElement {
        return {
            ...element,
            uniqueId: activityElementUniqueIdFromId(element.Id, n),
            Children: element.Children ? element.Children.map((c) => this.setIdPrefix(c, n)) : null!,
        };
    }
    setVerbose(value: boolean) {
        this.setState({ verbose: value }, async () => this.doRefresh());
    }
    setTail(value: boolean) {
        this.setState({ tail: value }, async () => this.doRefresh());
    }
    render() {
        const detail = this.state.detail;
        const pageActions: PageAction[] = [];
        if (detail) {
            const rawLogPageAction: PageAction = {
                type: "navigate",
                buttonType: "secondary",
                label: "Download",
                path: detail.Links["Raw"],
                external: true,
            };
            pageActions.push(rawLogPageAction);
        }
        const body = detail && (<div className={styles.body}>
                <div className={styles.filters}>
                    <div>
                        <Select value={this.state.verbose.toString()} onChange={(verbose) => this.setVerbose(verbose === "true")} items={[
                { text: "Info", value: "false" },
                { text: "Verbose", value: "true" },
            ]} label="Log level"/>
                    </div>
                    <div>
                        <Select value={this.state.tail.toString()} onChange={(tail) => this.setTail(tail === "true")} items={[
                { text: "Last 20", value: "true" },
                { text: "All", value: "false" },
            ]} label="Log tail"/>
                    </div>
                </div>
                <TaskLogBlock element={this.state.activityElement!} showTimestamps={true} parentDuration={0} taskState={TaskState.Executing} expandedIds={null!} collapsible={false} showRunTime={false} setExpanded={noOp} onFetchRange={() => this.setTail(false)} isFetchDisabled={true}/>
            </div>);
        return (<PageContent header={{ title: this.title, breadcrumbs: [{ label: "Diagnostics", pageUrl: links.diagnosticsPage.generateUrl() }], pageActions }} busy={this.state.busy} errors={this.errors}>
                {body}
            </PageContent>);
    }
    private doRefresh: Refresh = () => Promise.resolve();
    static displayName = "LogsBase";
}
export default LogsBase;
