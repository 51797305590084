import { PopoverBasicHelp } from "@octopusdeploy/design-system-components/src/components/Popover/PopoverBasicHelp";
import type { PathToInput } from "@octopusdeploy/step-runtime-inputs";
import { getPathToArrayInput, getPathToInput, getPathToInputObject, isInputPathToObject } from "@octopusdeploy/step-runtime-inputs";
import type { CommonInputProps, NoteExpression, StepInputComponent } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { getErrorPathsForContainerImageReferences } from "~/components/StepPackageEditor/Inputs/Components/ContainerImageSelector/ContainerImageSelector";
import { getErrorPathForPackageReference } from "~/components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelector";
import { noteToElement } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { getPathAsString } from "~/components/StepPackageEditor/Inputs/getPathAsString";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form/Sections";
export interface InputComponentProps<StepInputs, TInputComponents> {
    content: TInputComponents;
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}
export function Input<StepInputs, TInputComponents extends CommonInputProps>(props: InputComponentProps<StepInputs, TInputComponents>) {
    const { isExpandedByDefault } = props;
    const { label, helpText, expandoContextualHelp, expandoLabel } = props.content;
    const { renderedComponent, summary } = props.getRenderedComponentAndSummary(props.content);
    return (<ExpandableFormSection title={expandoLabel ?? label} isExpandedByDefault={isExpandedByDefault} summary={getSummary(summary)} errorKey={`todo-step-ui${label}`} help={helpText} contextualHelp={expandoContextualHelp && <ContextualHelp note={expandoContextualHelp}/>}>
            {renderedComponent}
        </ExpandableFormSection>);
}
export function ContextualHelp(props: {
    note: NoteExpression[] | undefined;
}) {
    if (props.note === undefined || props.note.length === 0) {
        return null;
    }
    return <PopoverBasicHelp placement={"right-start"} description={noteToElement(props.note)}/>;
}
function getSummary(summary: InputSummary): SummaryNode {
    if (summary === "empty" || summary.value === "") {
        return Summary.placeholder("Not provided");
    }
    if (summary.isDefaultValue) {
        return Summary.default(summary.value);
    }
    return Summary.summary(summary.value);
}
export function getInputContentReactKey<TInputComponents extends CommonInputProps>(content: TInputComponents) {
    return `${content.label}`;
}
function getPossibleInputPaths(content: StepInputComponent): PathToInput[] {
    switch (content.type) {
        case "sensitive":
            return [getPathToInput(content.input)];
        case "package":
            return getErrorPathForPackageReference(content);
        case "container-image":
            return getErrorPathsForContainerImageReferences(content);
        case "text":
            return [getPathToInput(content.input)];
        case "multiline-text":
            return [getPathToInput(content.input)];
        case "radio-buttons":
            return [isInputPathToObject(content.input) ? getPathToInputObject(content.input) : getPathToInput(content.input)];
        case "list":
            return [getPathToArrayInput(content.input)];
        case "inline-list":
            return [getPathToArrayInput(content.input)];
        case "checkbox":
            return [getPathToInput(content.input)];
        case "select":
            return [isInputPathToObject(content.input) ? getPathToInputObject(content.input) : getPathToInput(content.input)];
        case "number":
            return [getPathToInput(content.input)];
        case "account":
            return [getPathToInput(content.input)];
        case "code-editor":
            return [getPathToInput(content.input)];
        case "kubernetes-object-status":
            return [getPathToInputObject(content.input)];
        case "git-source":
            return [getPathToInput(content.input)];
        default:
            return exhaustiveCheck(content, `Section content type not yet implemented`);
    }
}
function getInputErrorKey(content: StepInputComponent): string {
    //todo: Fix up how we deal with errors, most likely as part of data loading. Ultimately, we want to deal
    //with an object of scoped errors instead of these magic strings, that way we never needed tp do any of this
    //nor did we need to magically reference inputs.
    return getPossibleInputPaths(content)
        .map((path) => `inputs.${getPathAsString(path)}`)
        .join("|");
}
