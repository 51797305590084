import { AccountType, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import type { ProjectInsightsFilterQueryParams } from "@octopusdeploy/portal-routes";
import { allRoutes, generateQueryString, insightsCadenceParam, insightsSplitParam, insightsTenantTagSetIdParam, projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, projectInsightsTenantIdParam } from "@octopusdeploy/portal-routes";
import * as React from "react";
import EditProcessTemplatePage, { editProcessTemplateLoader } from "~/areas/blueprints/EditProcessTemplatePage";
import { platformHubConnectionLoader, PlatformHubConnectionPage } from "~/areas/blueprints/PlatformHubConnectionPage";
import { ProcessTemplatesPage, processTemplatesPageLoader } from "~/areas/blueprints/ProcessTemplatesPage";
import { AuditArchivePage } from "~/areas/configuration/components/AuditLayout/AuditArchivePage";
import { AuditPage, auditPageLoader, auditPageTitle } from "~/areas/configuration/components/AuditLayout/AuditPage";
import { BackupPage } from "~/areas/configuration/components/Backup/BackupPage";
import { CreateDeploymentFreezePage } from "~/areas/configuration/components/DeploymentFreezes/CreateDeploymentFreezePage";
import DeploymentFreezesListPage from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezesListPage";
import { EditDeploymentFreezePage } from "~/areas/configuration/components/DeploymentFreezes/EditDeploymentFreezePage";
import { DetailedServerLogsPage } from "~/areas/configuration/components/Diagnostics/DetailedServerLogsPage";
import { DiagnosticsPage } from "~/areas/configuration/components/Diagnostics/DiagnosticsPage";
import { AutoDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/AutoDeployLogsPage";
import { ScheduledDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogsPage";
import { SubscriptionLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/SubscriptionLogsPage";
import { FeaturesPage } from "~/areas/configuration/components/FeaturesLayout/FeaturesPage";
import { GitPage } from "~/areas/configuration/components/Git/GitPage";
import { ConfigureLetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/ConfigureLetsEncryptPage";
import { LetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/LetsEncryptPage";
import { LicensePage } from "~/areas/configuration/components/License/LicensePage";
import { LicenseUsagePage } from "~/areas/configuration/components/LicenseUsage/LicenseUsagePage";
import { MaintenancePage } from "~/areas/configuration/components/Maintenance/MaintenancePage";
import { NodesPage, nodesPageLoader, nodesPageTitle } from "~/areas/configuration/components/Nodes/NodesPage";
import { ServerSettingsPage } from "~/areas/configuration/components/Nodes/ServerSettingsPage";
import { PerformancePage } from "~/areas/configuration/components/Performance/PerformancePage";
import { UserRolePage } from "~/areas/configuration/components/Roles/UserRolePage";
import { UserRolesPage } from "~/areas/configuration/components/Roles/UserRolesPage";
import { ActiveDirectorySettingsPage } from "~/areas/configuration/components/Settings/ActiveDirectorySettingsPage";
import { ArchiveLimitsSettingsPage } from "~/areas/configuration/components/Settings/ArchiveLimitsSettingsPage";
import { AuthenticationSettingsPage } from "~/areas/configuration/components/Settings/AuthenticationSettingsPage";
import { AzureADSettingsPage } from "~/areas/configuration/components/Settings/AzureADSettingsPage";
import { AzureDevOpsIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/AzureDevOpsIssueTrackerSettingsPage";
import { EventRetentionSettingsPage } from "~/areas/configuration/components/Settings/EventRetentionSettingsPage";
import { GithubIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/GithubIssueTrackerSettingsPage";
import { GoogleAppsSettingsPage } from "~/areas/configuration/components/Settings/GoogleAppsSettingsPage";
import { GuestLoginSettingsPage } from "~/areas/configuration/components/Settings/GuestLoginSettingsPage";
import { JiraIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraIntegrationSettingsPage";
import { JiraServiceManagementIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraServiceManagementIntegrationSettingsPage";
import { LDAPSettingsPage } from "~/areas/configuration/components/Settings/LDAPSettingsPage";
import { OctopusIdSettingsPage } from "~/areas/configuration/components/Settings/OctopusIdSettingsPage";
import { OktaSettingsPage } from "~/areas/configuration/components/Settings/OktaSettingsPage";
import { RetentionPoliciesSettingsPage } from "~/areas/configuration/components/Settings/RetentionPoliciesSettingsPage";
import { ServerFoldersSettingsPage } from "~/areas/configuration/components/Settings/ServerFoldersSettingsPage";
import { ServiceNowIntegrationSettingsPage } from "~/areas/configuration/components/Settings/ServiceNowIntegrationSettingsPage";
import { SettingsListPage } from "~/areas/configuration/components/Settings/SettingsListPage";
import { UpdatesSettingsPage } from "~/areas/configuration/components/Settings/UpdatesSettingsPage";
import { UsernamePasswordSettingsPage } from "~/areas/configuration/components/Settings/UsernamePasswordSettingsPage";
import { WebPortalSettingsPage } from "~/areas/configuration/components/Settings/WebPortalSettingsPage";
import { WebRequestProxySettingsPage } from "~/areas/configuration/components/Settings/WebRequestProxySettingsPage";
import { SigningKeysPage, signingKeysPageLoader, signingKeysPageTitle } from "~/areas/configuration/components/SigningKeys/SigningKeysPage";
import { SmtpPage, smtpPageLoader, smtpPageTitle } from "~/areas/configuration/components/Smtp/SmtpPage";
import { EditSpacePage } from "~/areas/configuration/components/Spaces/EditSpacePage";
import { SpacesPage } from "~/areas/configuration/components/Spaces/SpacesPage";
import { SubscriptionPage } from "~/areas/configuration/components/Subscription/SubscriptionPage";
import { SubscriptionsPage } from "~/areas/configuration/components/Subscriptions/SubscriptionsPage";
import { EditTeamPage, editTeamPageLoader } from "~/areas/configuration/components/Teams/EditTeamPage";
import { TeamsPage } from "~/areas/configuration/components/Teams/TeamsPage";
import { TelemetryPage } from "~/areas/configuration/components/Telemetry/TelemetryPage";
import { TestPermissionsPage } from "~/areas/configuration/components/TestPermissions/TestPermissionsPage";
import { ThumbprintPage } from "~/areas/configuration/components/Thumbprint/ThumbprintPage";
import { UserInvitePage } from "~/areas/configuration/components/Users/UserInvitePage";
import { CreateUserPage, EditUserPage } from "~/areas/configuration/components/Users/UserPage";
import { UsersPage } from "~/areas/configuration/components/Users/UsersPage";
import { DashboardConfigurationPage } from "~/areas/dashboard/DashboardConfiguration/DashboardConfigurationPage";
import { AccountPage } from "~/areas/infrastructure/components/AccountEdit/AccountPage";
import { AccountsPage } from "~/areas/infrastructure/components/AccountsLayout/AccountsPage";
import { DeploymentTargetSettingsPage } from "~/areas/infrastructure/components/DeploymentTargetSettings/DeploymentTargetSettingsPage";
import { DynamicEnvironmentPage } from "~/areas/infrastructure/components/DynamicEnvironmentLayout/DynamicEnvironmentPage";
import { DynamicEnvironmentsPage } from "~/areas/infrastructure/components/DynamicEnvironmentsLayout/DynamicEnvironmentsPage";
import { EnvironmentPage } from "~/areas/infrastructure/components/EnvironmentLayout/EnvironmentPage";
import { EnvironmentsPage } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentsPage";
import InfrastructureDashboardPage from "~/areas/infrastructure/components/InfrastructureDashboard/index";
import InfrastructureLayout from "~/areas/infrastructure/components/InfrastructureLayout/index";
import { DeploymentTargetConnectionsPage, WorkerMachineConnectionsPage } from "~/areas/infrastructure/components/MachineConnection/MachineConnection";
import { MachineDeploymentsPage } from "~/areas/infrastructure/components/MachineDeployments/MachineDeploymentsPage";
import { DeploymentTargetDiscoveryPage, WorkerMachineDiscoveryPage } from "~/areas/infrastructure/components/MachineDiscovery/MachineDiscovery";
import { MachineEventsPage } from "~/areas/infrastructure/components/MachineEvents/MachineEventsPage";
import { MachineLayout, WorkerMachineLayout } from "~/areas/infrastructure/components/MachineLayout/index";
import { MachinePoliciesPage } from "~/areas/infrastructure/components/MachinePoliciesLayout/MachinePoliciesPage";
import { MachinePolicyPage } from "~/areas/infrastructure/components/MachinePolicyLayout/MachinePolicyPage";
import { MachineRunbookRunsPage } from "~/areas/infrastructure/components/MachineRunbookRuns/MachineRunbookRunsPage";
import { MachineTargetNewPage } from "~/areas/infrastructure/components/MachineSettings/MachineTargetNewPage";
import { DeploymentTargetsPage } from "~/areas/infrastructure/components/MachinesLayout/DeploymentTargetsPage";
import { WorkerMachinesPage } from "~/areas/infrastructure/components/MachinesLayout/WorkerMachinesPage";
import { ProxiesPage } from "~/areas/infrastructure/components/ProxiesLayout/ProxiesPage";
import { ProxyPage } from "~/areas/infrastructure/components/ProxyLayout/ProxyPage";
import { WorkerMachineNewPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineNewPage";
import { WorkerMachineSettingsPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineSettingsPage";
import { WorkerPoolPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolLayout/WorkerPoolPage";
import { WorkerPoolsPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolsLayout/WorkerPoolsPage";
import { InsightsReportDeploymentDurationPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentDurationPage";
import { InsightsReportDeploymentFailureRatePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFailureRatePage";
import { InsightsReportDeploymentFrequencyPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFrequencyPage";
import { InsightsReportDeploymentLeadTimePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentLeadTimePage";
import { InsightsReportDeploymentMeanTimeToRecoveryPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentMeanTimeToRecoveryPage";
import { InsightsReportSettingsPage } from "~/areas/insights/components/Reports/InsightsReportSettingsPage";
import { InsightsReportListPage } from "~/areas/insights/components/Reports/ReportList/InsightsReportListPage";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import { InsightsReportOverviewPage } from "~/areas/insights/components/Reports/ReportOverview/InsightsReportOverviewPage";
import { ReportUpsell } from "~/areas/insights/components/Reports/ReportUpsell/ReportUpsell";
import { ActionTemplatePage } from "~/areas/library/components/ActionTemplates/ActionTemplate/ActionTemplatePage";
import ActionTemplateLayout from "~/areas/library/components/ActionTemplates/ActionTemplateLayout/ActionTemplateLayout";
import { ActionTemplateUsagePage } from "~/areas/library/components/ActionTemplates/ActionTemplateUsagePage";
import { ActionTemplatesPage } from "~/areas/library/components/ActionTemplates/ActionTemplatesPage";
import { BuiltinTemplatesPage } from "~/areas/library/components/ActionTemplates/BuiltinTemplatesPage";
import { CommunityTemplatePage } from "~/areas/library/components/ActionTemplates/CommunityTemplatePage";
import { CommunityTemplatesPage } from "~/areas/library/components/ActionTemplates/CommunityTemplatesPage";
import { RunActionTemplatePage } from "~/areas/library/components/ActionTemplates/RunActionTemplatePage";
import { BuildInformationDetailPage } from "~/areas/library/components/BuildInformation/BuildInformationDetailPage";
import { BuildInformationOverviewPage } from "~/areas/library/components/BuildInformation/BuildInformationOverviewPage";
import { BuildInformationVersionsPage } from "~/areas/library/components/BuildInformation/BuildInformationVersionsPage";
import { BuiltInRepositoryPage } from "~/areas/library/components/BuiltInRepository/BuiltInRepositoryPage";
import { PackageDetailsPage } from "~/areas/library/components/BuiltInRepository/PackageDetailPage";
import { PackageVersionsListPage } from "~/areas/library/components/BuiltInRepository/PackageVersionsListPage";
import { CertificatePage } from "~/areas/library/components/Certificates";
import ArchivedCertificatesPage from "~/areas/library/components/Certificates/ArchivedCertificatesPage";
import CertificatesPage from "~/areas/library/components/Certificates/CertificatesPage";
import { ExternalFeedPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedPage";
import { ExternalFeedTestPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedTestPage";
import { ExternalFeedsPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedsPage";
import { AddGitHubConnectionPage } from "~/areas/library/components/GitConnections/AddGitHubConnectionPage";
import { GitConnectionsPage } from "~/areas/library/components/GitConnections/GitConnectionsPage";
import { GitHubConnectionPage } from "~/areas/library/components/GitConnections/GitHubConnectionPage";
import { GitCredentialPage } from "~/areas/library/components/GitCredentials/GitCredentialPage";
import { GitCredentialsPage } from "~/areas/library/components/GitCredentials/GitCredentialsPage";
import { LifecyclePage } from "~/areas/library/components/Lifecycle/Lifecycle/LifecyclePage";
import { LifecyclesPage } from "~/areas/library/components/Lifecycle/Lifecycles/LifecyclesPage";
import { ScriptModulePage } from "~/areas/library/components/Scripts/ScriptModulePage";
import { ScriptModulesPage } from "~/areas/library/components/Scripts/ScriptModulesPage";
import { TagSetPage } from "~/areas/library/components/TagSets/TagSetPage";
import { TagSetsPage } from "~/areas/library/components/TagSets/TagSetsPage";
import { EditVariableSetPage } from "~/areas/library/components/VariableSets/EditVariableSetPage";
import { VariableSetsPage } from "~/areas/library/components/VariableSets/VariableSetsPage";
import { ChannelsPage, channelsPageLoader, channelsPageTitle } from "~/areas/projects/components/Channels/ChannelsPage";
import { CreateChannelPage, createChannelPageLoader, createChannelPageTitle } from "~/areas/projects/components/Channels/CreateChannelPage";
import { EditChannelPage, editChannelPageLoader } from "~/areas/projects/components/Channels/EditChannelPage";
import DeploymentProcessSettings from "~/areas/projects/components/DeploymentProcessSettings/DeploymentProcessSettings";
import DeploymentsOverview from "~/areas/projects/components/DeploymentsOverview";
import { FeatureTogglePage } from "~/areas/projects/components/FeatureToggles/FeatureTogglePage";
import { FeatureTogglesPage } from "~/areas/projects/components/FeatureToggles/FeatureTogglesPage";
import { ExportProjectTaskPage } from "~/areas/projects/components/ImportExport/ExportProjectTaskPage";
import { ExportProjectsPage } from "~/areas/projects/components/ImportExport/ExportProjectsPage";
import { ImportExportProjectTasksPage } from "~/areas/projects/components/ImportExport/ImportExportProjectTasksPage";
import { ImportProjectTaskPage } from "~/areas/projects/components/ImportExport/ImportProjectTaskPage";
import { ImportProjectsPage } from "~/areas/projects/components/ImportExport/ImportProjectsPage";
import { LiveStatusPage, liveStatusPageLoader } from "~/areas/projects/components/Observability/LiveStatusPage";
import { DeploymentProcessCodePage } from "~/areas/projects/components/Process/DeploymentProcessCodePage";
import { DeploymentProcessListPage, deploymentProcessLoader } from "~/areas/projects/components/Process/DeploymentProcessListPage";
import { DeploymentProcessStepsPage } from "~/areas/projects/components/Process/DeploymentProcessStepsPage";
import { ProjectInsightsDeploymentDurationPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsDeploymentDuration/ProjectInsightsDeploymentDurationPage";
import { ProjectInsightsFailureRatePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFailureRate/ProjectInsightsFailureRatePage";
import { ProjectInsightsFrequencyPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFrequency/ProjectInsightsFrequencyPage";
import { ProjectInsightsLeadTimePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsLeadTime/ProjectInsightsLeadTimePage";
import { ProjectInsightsOverviewPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsOverview/ProjectInsightsOverviewPage";
import { ProjectInsightsTimeToRecoveryPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsTimeToRecovery/ProjectInsightsTimeToRecoveryPage";
import { DynamicEnvironmentSettingsPage } from "~/areas/projects/components/ProjectSettings/DynamicEnvironments/DynamicEnvironmentSettingsPage";
import { ITSMProvidersSettingsPage } from "~/areas/projects/components/ProjectSettings/ITSMProvidersSettingsPage";
import { ProjectSettingsPage } from "~/areas/projects/components/ProjectSettings/ProjectSettingsPage";
import { VersionControlSettingsPage } from "~/areas/projects/components/ProjectSettings/VersionControl/VersionControlSettingsPage";
import ProjectTaskDetails from "~/areas/projects/components/ProjectTaskDetails/ProjectTasks";
import ProjectTasks from "~/areas/projects/components/ProjectTasks/ProjectTasks";
import ProjectTenantsPage from "~/areas/projects/components/ProjectTenants/ProjectTenantsPage";
import { ProjectsPage, projectsPageLoader } from "~/areas/projects/components/Projects/ProjectsPage";
import type { CreateDeploymentGoal } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { CreateDeploymentPage } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { DeploymentDetailsPage } from "~/areas/projects/components/Releases/Deployments/DeploymentDetailsPage";
import { CreateOrEditReleasePage } from "~/areas/projects/components/Releases/Edit/CreateOrEditReleasePage";
import { ReleasePage } from "~/areas/projects/components/Releases/ReleasePage";
import { ReleasesPage } from "~/areas/projects/components/Releases/ReleasesPage";
import { OperationsOverviewPage } from "~/areas/projects/components/Runbooks/OperationsOverviewPage";
import RunbookOverviewPage from "~/areas/projects/components/Runbooks/RunbookOverviewPage";
import { RunbookSettingsPage } from "~/areas/projects/components/Runbooks/RunbookPages/RunbookSettingsPage/RunbookSettingsPage";
import { RunbookProcessListPage, runbookProcessLoader } from "~/areas/projects/components/Runbooks/RunbookProcessListPage";
import { RunbookProcessStepsPage } from "~/areas/projects/components/Runbooks/RunbookProcessStepsPage";
import { RunbookRunDetailsPage } from "~/areas/projects/components/Runbooks/RunbookRunDetailsPage";
import type { RunGoal } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import { RunbookRunNowPage } from "~/areas/projects/components/Runbooks/RunbookRunNowPage";
import { RunbookRunsListPage } from "~/areas/projects/components/Runbooks/RunbookRunsListPage";
import { CreateRunbookRunForSnapshotPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/CreateRunbookRunForSnapshotPage";
import { RunbookSnapshotEditPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotEditPage";
import { RunbookSnapshotInfoPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotInfoPage";
import { RunbookSnapshotsPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotsPage";
import { RunbooksPage } from "~/areas/projects/components/Runbooks/RunbooksPage";
import { CreateRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/CreateRunbookScheduledTriggersPage";
import { EditRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/EditRunbookScheduledTriggersPage";
import { RunbookTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/RunbookTriggersPage";
import { DeploymentTriggerPage } from "~/areas/projects/components/Triggers/DeploymentTriggerPage";
import { CreateBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/Feed/CreateBuiltInFeedTriggerPage";
import { CreateReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/Feed/CreateReleaseCreationFeedTriggersPage";
import { EditBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/Feed/EditBuiltInFeedTriggerPage";
import { EditReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/Feed/EditReleaseCreationFeedTriggersPage";
import { CreateReleaseCreationGitTriggersPage } from "~/areas/projects/components/Triggers/Git/CreateReleaseCreationGitTriggersPage";
import { EditReleaseCreationGitTriggersPage } from "~/areas/projects/components/Triggers/Git/EditReleaseCreationGitTriggersPage";
import { CreateDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/CreateDeploymentScheduledTriggerPage";
import { EditDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/EditDeploymentScheduledTriggerPage";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
import AllVariables from "~/areas/projects/components/Variables/AllVariables";
import CommonVariableTemplates from "~/areas/projects/components/Variables/CommonVariableTemplates";
import LibraryVariableSets from "~/areas/projects/components/Variables/LibraryVariableSets/LibraryVariableSets";
import ProjectVariableTemplates from "~/areas/projects/components/Variables/ProjectVariableTemplates";
import ProjectVariables from "~/areas/projects/components/Variables/ProjectVariables/ProjectVariables";
import ProjectTenantCommonTemplates from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantCommonTemplates";
import ProjectTenantProjectTemplates from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantProjectTemplates";
import VariablePreview from "~/areas/projects/components/Variables/VariablePreview/VariablePreview";
import { RawLogPage } from "~/areas/tasks/components/RawLog/RawLogPage";
import { ScriptConsolePage } from "~/areas/tasks/components/ScriptConsole/ScriptConsolePage";
import { TaskPage } from "~/areas/tasks/components/Task/TaskPage";
import { TenantOverviewPage, tenantOverviewPageLoader, tenantOverviewPageTitle } from "~/areas/tenants/TenantOverview/TenantOverviewPage";
import { TenantSettingsPage } from "~/areas/tenants/TenantSettings/TenantSettingsPage";
import { TenantTasksPage } from "~/areas/tenants/TenantTasks/TenantTasksPage";
import { TenantVariablesPage } from "~/areas/tenants/TenantVariables/TenantVariablesPage";
import { TenantsPage } from "~/areas/tenants/Tenants/TenantsPage";
import UserApiKeysPage from "~/areas/users/UserApiKeys/UserApiKeysPage";
import UserLoginsPage from "~/areas/users/UserLogins/UserLoginsPage";
import { UserProfilePage, userProfilePageLoader, userProfilePageTitle } from "~/areas/users/UserProfile/UserProfilePage";
import UserRecentActivityPage from "~/areas/users/UserRecentActivity/UserRecentActivityPage";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { StyleGuidePage } from "~/components/StyleGuide/StyleGuidePage";
import { createPlatformHubPageRegistration } from "~/routing/pageRegistrations/BlueprintsPageRegistration";
import { createInfrastructurePageRegistration } from "~/routing/pageRegistrations/InfrastructurePageRegistration";
import { createInsightsPageRegistration, createInsightsReportPageRegistration } from "~/routing/pageRegistrations/InsightsPageRegistration";
import { createLevel1TenantPageRegistration, createLevel2TenantPageRegistration } from "~/routing/pageRegistrations/TenantPageRegistration";
import type { PageGroup } from "~/routing/pageRegistrations/createPageMap";
import { createPageMap } from "~/routing/pageRegistrations/createPageMap";
import { ProjectGroupPage, projectGroupPageLoader, ProjectGroupPageLoadingContent } from "../../areas/projects/components/ProjectGroupLayout/ProjectGroupPage";
import { TasksPage } from "../../areas/tasks/components/Tasks/TasksPage";
import { createConfigurationPageRegistration } from "./ConfigurationPageRegistration";
import { createCurrentUserPageRegistration } from "./CurrentUserPageRegistration";
import { createLibraryPageRegistration } from "./LibraryPageRegistration";
import type { UnknownPageRegistration } from "./PageRegistration";
import { createLevel1ProjectPageRegistration, createLevel2ProjectPageRegistration } from "./ProjectPageRegistration";
import { createSpaceRootPageRegistration } from "./SpaceRootPageRegistration";
import { createLevel1TaskPageRegistration, createLevel2TaskPageRegistration } from "./TaskPageRegistration";
export const allPages = createPageMap(allRoutes, {
    partialRoute: (r) => r,
    pages: {},
    childGroups: {
        system: {
            pages: {
                tasksPage: (r) => createLevel1TaskPageRegistration(r, { render: () => <TasksPage />, pageIdentity: { pageArea: "Tasks", pageId: "Tasks", pageName: "Tasks" } }),
                taskPage: (r) => createLevel2TaskPageRegistration(r, { render: ({ routeParams }) => <TaskPage taskId={routeParams.taskId}/>, pageIdentity: { pageArea: "Tasks", pageId: "Task", pageName: "Task" } }),
                taskRawLogPage: (r) => createLevel2TaskPageRegistration(r, { render: ({ routeParams }) => <RawLogPage taskId={routeParams.taskId}/>, pageIdentity: { pageArea: "Tasks", pageId: "Task.Raw", pageName: "Raw Task Log" } }),
                scriptConsolePage: (r) => createLevel2TaskPageRegistration(r, { render: () => <ScriptConsolePage />, pageIdentity: { pageArea: "Tasks", pageId: "Task.ScriptConsole", pageName: "Script Console" } }),
                styleGuidePage: (r) => createLevel2TaskPageRegistration(r, { render: () => <StyleGuidePage />, pageIdentity: { pageArea: "Dev Tools", pageId: "StyleGuide", pageName: "Style guide" } }),
            },
            partialRoute: (r) => r,
            childGroups: {
                configuration: {
                    partialRoute: (r) => r,
                    childGroups: {
                        settings: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                settingsListPage: (r) => createConfigurationPageRegistration(r, { render: () => <SettingsListPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Settings", pageName: "Settings" } }),
                                activeDirectorySettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <ActiveDirectorySettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-directoryservices`, pageName: "Active Directory Settings" },
                                }),
                                archiveLimitsSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <ArchiveLimitsSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.archive-limit`, pageName: "Archive Limits Settings" },
                                }),
                                authenticationSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <AuthenticationSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication`, pageName: "Authentication Settings" },
                                }),
                                azureADSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <AzureADSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-aad`, pageName: "Azure AD Settings" } }),
                                azureDevOpsIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <AzureDevOpsIssueTrackerSettingsPage />,
                                    pageIdentity: {
                                        pageArea: "Configuration",
                                        pageId: `Configuration.Setting.issuetracker-azuredevops-v2`,
                                        pageName: "Azure DevOps Issue Tracker Settings",
                                    },
                                }),
                                eventRetentionSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <EventRetentionSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.eventretention`, pageName: "Event Retention Settings" },
                                }),
                                githubIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <GithubIssueTrackerSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.issuetracker-github`, pageName: "GitHub Issue Tracker Settings" },
                                }),
                                googleAppsSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <GoogleAppsSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-googleapps`, pageName: "Google Apps Settings" },
                                }),
                                guestLoginSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <GuestLoginSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-guest`, pageName: "Guest Login Settings" },
                                }),
                                jiraIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <JiraIntegrationSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.jira-integration`, pageName: "Jira Integration Settings" },
                                }),
                                jiraServiceManagementIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <JiraServiceManagementIntegrationSettingsPage />,
                                    pageIdentity: {
                                        pageArea: "Configuration",
                                        pageId: `Configuration.Setting.jiraservicemanagement-integration`,
                                        pageName: "Jira Service Management Integration Settings",
                                    },
                                }),
                                ldapSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <LDAPSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-ldap`, pageName: "LDAP Settings" } }),
                                octopusIdSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <OctopusIdSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-octopusid`, pageName: "Octopus ID Settings" },
                                }),
                                oktaSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <OktaSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-od`, pageName: "Okta Settings" } }),
                                retentionPoliciesSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <RetentionPoliciesSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.retentionpolicies`, pageName: "Retention Policies Settings" },
                                }),
                                serverFoldersSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <ServerFoldersSettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.server-folders`, pageName: "Server Folders Settings" },
                                }),
                                serviceNowIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <ServiceNowIntegrationSettingsPage />,
                                    pageIdentity: {
                                        pageArea: "Configuration",
                                        pageId: `Configuration.Setting.servicenow-integration`,
                                        pageName: "ServiceNow Integration Settings",
                                    },
                                }),
                                updatesSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <UpdatesSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.upgrades`, pageName: "Updates Settings" } }),
                                usernamePasswordSettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <UsernamePasswordSettingsPage />,
                                    pageIdentity: {
                                        pageArea: "Configuration",
                                        pageId: `Configuration.Setting.authentication-usernamepassword`,
                                        pageName: "Username / Password Settings",
                                    },
                                }),
                                webPortalSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <WebPortalSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.webportal`, pageName: "Web Portal Settings" } }),
                                webRequestProxySettingsPage: (r) => createConfigurationPageRegistration(r, {
                                    render: () => <WebRequestProxySettingsPage />,
                                    pageIdentity: { pageArea: "Configuration", pageId: `Configuration.Setting.server-proxy`, pageName: "Web Request Proxy Settings" },
                                }),
                            },
                        },
                    },
                    pages: {
                        auditPage: (r) => createConfigurationPageRegistration(r, {
                            loader: (repository) => auditPageLoader(repository),
                            loadingTitle: auditPageTitle,
                            render: ({ loaderData, queryParams }) => <AuditPage loaderData={loaderData} queryParams={queryParams}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Audit", pageName: "Audit" },
                        }),
                        auditArchivePage: (r) => createConfigurationPageRegistration(r, { render: () => <AuditArchivePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.AuditArchive", pageName: "Audit Archive" } }),
                        backupPage: (r) => createConfigurationPageRegistration(r, { render: () => <BackupPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Backup", pageName: "Backup" } }),
                        diagnosticsPage: (r) => createConfigurationPageRegistration(r, { render: () => <DiagnosticsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Diagnostics", pageName: "Diagnostics" } }),
                        diagnosticLogsPage: (r) => createConfigurationPageRegistration(r, { render: () => <DetailedServerLogsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogs", pageName: "Diagnostic Logs" } }),
                        diagnosticAutoDeployLogsPage: (r) => createConfigurationPageRegistration(r, { render: () => <AutoDeployLogsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsAutoDeploy", pageName: "Auto Deploy Diagnostic Logs" } }),
                        diagnosticScheduledDeployLogsPage: (r) => createConfigurationPageRegistration(r, {
                            render: () => <ScheduledDeployLogsPage />,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsScheduledDeploy", pageName: "Scheduled Deploy Diagnostic Logs" },
                        }),
                        diagnosticSubscriptionLogsPage: (r) => createConfigurationPageRegistration(r, { render: () => <SubscriptionLogsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsSubscription", pageName: "Subscription Diagnostic Logs" } }),
                        featuresPage: (r) => createConfigurationPageRegistration(r, { render: () => <FeaturesPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Features", pageName: "Features" } }),
                        letsEncryptPage: (r) => createConfigurationPageRegistration(r, { render: () => <LetsEncryptPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.LetsEncrypt", pageName: "Let's Encrypt" } }),
                        configureLetsEncryptPage: (r) => createConfigurationPageRegistration(r, { render: () => <ConfigureLetsEncryptPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.LetsEncryptConfigure", pageName: "Configure Let's Encrypt" } }),
                        maintenancePage: (r) => createConfigurationPageRegistration(r, { render: () => <MaintenancePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Maintenance", pageName: "Maintenance" } }),
                        performancePage: (r) => createConfigurationPageRegistration(r, { render: () => <PerformancePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Performance", pageName: "Performance" } }),
                        serverSettingsPage: (r) => createConfigurationPageRegistration(r, { render: () => <ServerSettingsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.NodesServerSettings", pageName: "Node Server Settings" } }),
                        nodesPage: (r) => createConfigurationPageRegistration(r, {
                            loader: (repository) => nodesPageLoader(repository),
                            loadingTitle: nodesPageTitle,
                            render: ({ loaderData }) => <NodesPage loaderData={loaderData}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Nodes", pageName: "Nodes" },
                        }),
                        licensePage: (r) => createConfigurationPageRegistration(r, { render: () => <LicensePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.License", pageName: "License" } }),
                        licenseUsagePage: (r) => createConfigurationPageRegistration(r, {
                            render: () => <LicenseUsagePage />,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.LicenseUsage", pageName: "License Usage Dashboard" },
                        }),
                        smtpPage: (r) => createConfigurationPageRegistration(r, {
                            loader: (repository) => smtpPageLoader(repository),
                            loadingTitle: smtpPageTitle,
                            render: ({ loaderData }) => <SmtpPage loaderData={loaderData}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Smtp", pageName: "SMTP" },
                        }),
                        gitPage: (r) => createConfigurationPageRegistration(r, { render: () => <GitPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Git", pageName: "Git" } }),
                        subscriptionsPage: (r) => createConfigurationPageRegistration(r, { render: () => <SubscriptionsPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Subscriptions", pageName: "Subscriptions" } }),
                        editSubscriptionPage: (r) => createConfigurationPageRegistration(r, {
                            render: ({ routeParams }) => <SubscriptionPage subscriptionId={routeParams.subscriptionId}/>,
                            pageIdentity: {
                                pageArea: "Configuration",
                                pageId: "Configuration.Subscription",
                                pageName: "Subscription",
                            },
                        }),
                        createSubscriptionPage: (r) => createConfigurationPageRegistration(r, { render: () => <SubscriptionPage create/>, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.SubscriptionsNew", pageName: "SubscriptionsNew" } }),
                        testPermissionsPage: (r) => createConfigurationPageRegistration(r, { render: () => <TestPermissionsPage userId={undefined}/>, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.TestPermission", pageName: "Test Permission" } }),
                        testPermissionsForUserPage: (r) => createConfigurationPageRegistration(r, {
                            render: ({ routeParams }) => <TestPermissionsPage userId={routeParams.userId}/>,
                            pageIdentity: {
                                pageArea: "Configuration",
                                pageId: "Configuration.TestPermissions",
                                pageName: "Test Permissions",
                            },
                        }),
                        thumbprintPage: (r) => createConfigurationPageRegistration(r, { render: () => <ThumbprintPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Thumbprint", pageName: "Thumbprint" } }),
                        userRolesPage: (r) => createConfigurationPageRegistration(r, { render: () => <UserRolesPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Roles", pageName: "User Roles" } }),
                        createUserRolePage: (r) => createConfigurationPageRegistration(r, { render: () => <UserRolePage roleId={undefined}/>, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.RolesNew", pageName: "Add New User Role" } }),
                        editUserRolePage: (r) => createConfigurationPageRegistration(r, { render: ({ routeParams }) => <UserRolePage roleId={routeParams.roleId}/>, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Role", pageName: "User Role" } }),
                        spacesPage: (r) => createConfigurationPageRegistration(r, { render: () => <SpacesPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Spaces", pageName: "Spaces" } }),
                        editSpacePage: (r) => createConfigurationPageRegistration(r, {
                            render: ({ routeParams }) => <EditSpacePage currentSpaceId={routeParams.currentSpaceId}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Space", pageName: "Space" },
                        }),
                        teamsPage: (r) => createConfigurationPageRegistration(r, {
                            render: ({ queryParams, setQueryParams }) => <TeamsPage queryParams={queryParams} setQueryParams={setQueryParams}/>,
                            pageIdentity: {
                                pageArea: "Configuration",
                                pageId: "Configuration.Teams",
                                pageName: "Teams",
                            },
                        }),
                        editTeamPage: (r) => createConfigurationPageRegistration(r, {
                            loader: (repository, { routeParams }) => editTeamPageLoader(repository, routeParams.teamId),
                            render: ({ routeParams, loaderData, queryParams, setQueryParams }) => <EditTeamPage teamId={routeParams.teamId} loaderData={loaderData} queryParams={queryParams} setQueryParams={setQueryParams}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Team", pageName: "Team" },
                        }),
                        usersPage: (r) => createConfigurationPageRegistration(r, { render: () => <UsersPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Users", pageName: "Users" } }),
                        createUserPage: (r) => createConfigurationPageRegistration(r, { render: () => <CreateUserPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.UsersNew", pageName: "Add New User" } }),
                        editUserPage: (r) => createConfigurationPageRegistration(r, { render: ({ routeParams }) => <EditUserPage userId={routeParams.userId}/>, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.User", pageName: "User" } }),
                        signingKeysPage: (r) => createConfigurationPageRegistration(r, {
                            loader: (repository) => signingKeysPageLoader(repository),
                            render: ({ loaderData }) => <SigningKeysPage loaderData={loaderData}/>,
                            pageIdentity: { pageArea: "Configuration", pageId: "Configuration.SigningKeys", pageName: "Signing Keys" },
                            loadingTitle: signingKeysPageTitle,
                        }),
                        telemetryPage: (r) => createConfigurationPageRegistration(r, { render: () => <TelemetryPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.Telemetry", pageName: "Telemetry" } }),
                        userInvitesPage: (r) => createConfigurationPageRegistration(r, { render: () => <UserInvitePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.UserInvites", pageName: "Invite Users" } }),
                        deploymentFreezesListPage: (r) => createConfigurationPageRegistration(r, { render: () => <DeploymentFreezesListPage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DeploymentFreezes", pageName: "Deployment Freezes" } }),
                        deploymentFreezesCreatePage: (r) => createConfigurationPageRegistration(r, { render: () => <CreateDeploymentFreezePage />, pageIdentity: { pageArea: "Configuration", pageId: "Configuration.DeploymentFreezesNew", pageName: "Add Deployment Freeze" } }),
                        deploymentFreezesEditPage: (r) => createConfigurationPageRegistration(r, {
                            render: ({ routeParams }) => <EditDeploymentFreezePage deploymentFreezeId={routeParams.deploymentFreezeId}/>,
                            pageIdentity: {
                                pageArea: "Configuration",
                                pageId: "Configuration.DeploymentFreeze",
                                pageName: "Edit Deployment Freeze",
                            },
                        }),
                    },
                },
                currentUser: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        currentUserDetailsPage: (r) => createCurrentUserPageRegistration(r, {
                            loader: (repository, { context }) => userProfilePageLoader(repository, context),
                            loadingTitle: userProfilePageTitle,
                            render: ({ loaderData }) => <UserProfilePage loaderData={loaderData}/>,
                            pageIdentity: { pageArea: "User Profile", pageId: "UserProfile.Details", pageName: "User Profile Details" },
                        }),
                        currentUserLoginsPage: (r) => createCurrentUserPageRegistration(r, { render: () => <UserLoginsPage />, pageIdentity: { pageArea: "User Profile", pageId: "UserProfile.Logins", pageName: "User Profile Logins" } }),
                        currentUserApiKeysPage: (r) => createCurrentUserPageRegistration(r, { render: () => <UserApiKeysPage />, pageIdentity: { pageArea: "User Profile", pageId: "UserProfile.ApiKeys", pageName: "User Profile API Keys" } }),
                        currentUserActivityPage: (r) => createCurrentUserPageRegistration(r, { render: () => <UserRecentActivityPage />, pageIdentity: { pageArea: "User Profile", pageId: "UserProfile.Activity", pageName: "User Profile Activity" } }),
                    },
                },
            },
        },
        space: {
            partialRoute: (r) => r,
            pages: {
                dashboardConfigurationPage: (r) => createSpaceRootPageRegistration(r, {
                    render: ({ routeParams }) => <DashboardConfigurationPage spaceId={routeParams.spaceId}/>,
                    pageIdentity: { pageArea: "Dashboard", pageName: "Configure Dashboard", pageId: "Dashboard.Configure" },
                    verticalNavigationPageArea: "Projects",
                }),
                editProjectGroupPage: (r) => createSpaceRootPageRegistration(r, {
                    loader: (repository, { routeParams: { projectGroupId } }) => projectGroupPageLoader(repository, projectGroupId),
                    loadingContent: (state) => <ProjectGroupPageLoadingContent state={state}/>,
                    render: ({ routeParams, loaderData }) => <ProjectGroupPage spaceId={routeParams.spaceId} loaderData={loaderData}/>,
                    pageIdentity: { pageArea: "Project", pageName: "Project Group", pageId: "ProjectGroup.Edit" },
                    verticalNavigationPageArea: "Projects",
                }),
            },
            childGroups: {
                projects: {
                    partialRoute: (r) => r,
                    pages: {
                        projectsPage: (r) => createLevel1ProjectPageRegistration(r, {
                            loader: (repository) => projectsPageLoader(repository),
                            loadingContent: (state) => <SkeletonLoadingLayout errors={state === "is-loading" ? undefined : [state]}/>,
                            render: ({ routeParams, queryParams, setQueryParams, loaderData, context: { projectStatus } }) => (<ProjectsPage spaceId={routeParams.spaceId} queryParams={queryParams} setQueryParams={setQueryParams} loaderData={loaderData} projectStatus={projectStatus}/>),
                            pageIdentity: { pageArea: "Project", pageName: "Projects", pageId: "Projects" },
                        }),
                    },
                    childGroups: {
                        specificProject: {
                            partialRoute: (r) => r,
                            pages: {},
                            childGroups: {
                                branch: {
                                    partialRoute: (r) => r,
                                    pages: {},
                                    childGroups: {
                                        variables: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchAllVariablesPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <AllVariables />, pageIdentity: { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" } }),
                                                branchVariablesPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectVariables />, pageIdentity: { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" } }),
                                                branchVariablesPreviewPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <VariablePreview />, pageIdentity: { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" } }),
                                            },
                                        },
                                        deployments: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchDeploymentProcessPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    loader: (repository, { context: { projectContext } }) => deploymentProcessLoader(repository, projectContext),
                                                    render: ({ loaderData, context: { projectContext } }) => <DeploymentProcessListPage loaderData={loaderData} projectContext={projectContext}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" },
                                                }),
                                                branchDeploymentProcessCodePage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <DeploymentProcessCodePage />, pageIdentity: { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process Code" } }),
                                                branchDeploymentProcessStepsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    loader: (repository, { context: { projectContext } }) => deploymentProcessLoader(repository, projectContext),
                                                    render: ({ loaderData, context: { setShowK8sStatusItem, projectStatus, projectContext, scrollAreaRef } }) => (<DeploymentProcessStepsPage setShowK8sStatusItem={setShowK8sStatusItem} projectStatus={projectStatus} loaderData={loaderData} projectContext={projectContext} scrollAreaRef={scrollAreaRef}/>),
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" },
                                                }),
                                                branchDeploymentProcessSettingsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <DeploymentProcessSettings />, pageIdentity: { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" } }),
                                            },
                                        },
                                    },
                                },
                                withoutBranch: {
                                    partialRoute: (r) => r,
                                    pages: {
                                        untenantedLiveStatusPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            loader: (repository, { routeParams, context: { projectContext } }) => liveStatusPageLoader(repository, routeParams.environmentId, projectContext),
                                            render: ({ loaderData, context: { projectContext } }) => <LiveStatusPage loaderData={loaderData} project={projectContext.state.model}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Observability.LiveStatus.Untenanted",
                                                pageName: "Application Live Status",
                                            },
                                        }),
                                        tenantedLiveStatusPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            loader: (repository, { routeParams, context: { projectContext } }) => liveStatusPageLoader(repository, routeParams.environmentId, projectContext, routeParams.tenantId),
                                            render: ({ routeParams, loaderData, context: { projectContext } }) => <LiveStatusPage loaderData={loaderData} project={projectContext.state.model} tenantId={routeParams.tenantId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Observability.LiveStatus.Tenanted",
                                                pageName: "Application Live Status",
                                            },
                                        }),
                                        projectInsightsOverviewPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectInsightsOverviewPage />, pageIdentity: { pageArea: "Project", pageId: "Project.Insights.Overview", pageName: "Insights Overview" } }),
                                        projectInsightsLeadTimePage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectInsightsLeadTimePage />, pageIdentity: { pageArea: "Project", pageId: "Project.Insights.LeadTime", pageName: "Lead Time" } }),
                                        projectInsightsFrequencyPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectInsightsFrequencyPage />, pageIdentity: { pageArea: "Project", pageId: "Project.Insights.Frequency", pageName: "Deployment Frequency" } }),
                                        projectInsightsFailureRatePage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectInsightsFailureRatePage />, pageIdentity: { pageArea: "Project", pageId: "Project.Insights.FailureRate", pageName: "Failure Rate" } }),
                                        projectInsightsTimeToRecoveryPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: () => <ProjectInsightsTimeToRecoveryPage />,
                                            pageIdentity: { pageArea: "Project", pageId: "Project.Insights.TimeToRecovery", pageName: "Mean Time To Recovery" },
                                        }),
                                        projectInsightsDeploymentDurationPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: () => <ProjectInsightsDeploymentDurationPage />,
                                            pageIdentity: { pageArea: "Project", pageId: "Project.Insights.DeploymentDuration", pageName: "Deployment Duration" },
                                        }),
                                        projectTasksPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectTasks />, pageIdentity: { pageArea: "Project", pageId: "Project.Tasks", pageName: "Tasks" } }),
                                        projectTaskDetailsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <ProjectTaskDetails taskId={routeParams.taskId}/>,
                                            pageIdentity: { pageArea: "Project", pageId: "Project.TaskDetails", pageName: "Task" },
                                        }),
                                        projectTenantsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <ProjectTenantsPage spaceId={routeParams.spaceId}/>,
                                            pageIdentity: { pageArea: "Project", pageId: "Project.Tenants", pageName: "Tenants" },
                                        }),
                                        projectOperationsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <OperationsOverviewPage />, pageIdentity: { pageArea: "Project", pageId: "Project.Operations", pageName: "Operations" } }),
                                        projectRunbookTriggersPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <RunbookTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Runbook.Triggers",
                                                pageName: "Runbook Triggers",
                                            },
                                        }),
                                        projectRunbookCreateScheduledTriggerForRunbookPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <CreateRunbookScheduledTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} runbookId={routeParams.runbookId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Runbook.ScheduledTriggerNew",
                                                pageName: "Add New Runbook Scheduled Trigger",
                                            },
                                        }),
                                        projectRunbookCreateScheduledTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <CreateRunbookScheduledTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Runbook.ScheduledTrigger",
                                                pageName: "Runbook Scheduled Trigger",
                                            },
                                        }),
                                        projectRunbookEditScheduledTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <EditRunbookScheduledTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} triggerId={routeParams.triggerId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Runbook.Triggers",
                                                pageName: "Runbook Triggers",
                                            },
                                        }),
                                        deploymentTriggersPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <TriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} triggerActionCategory={TriggerActionCategory.Deployment}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.Triggers",
                                                pageName: "Triggers",
                                            },
                                        }),
                                        createDeploymentTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <DeploymentTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} branchName={undefined} create={true}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.DeploymentTargetTriggerNew",
                                                pageName: "Add New Deployment Target Trigger",
                                            },
                                        }),
                                        editDeploymentTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <DeploymentTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} branchName={undefined} triggerId={routeParams.triggerId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.DeploymentTargetTrigger",
                                                pageName: "Deployment Target Trigger",
                                            },
                                        }),
                                        createDeploymentScheduledTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <CreateDeploymentScheduledTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ScheduledTriggerNew",
                                                pageName: "Add New Scheduled Trigger",
                                            },
                                        }),
                                        editDeploymentScheduledTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <EditDeploymentScheduledTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} triggerId={routeParams.triggerId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ScheduledTrigger",
                                                pageName: "Scheduled Trigger",
                                            },
                                        }),
                                        projectSettingsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectSettingsPage />, pageIdentity: { pageArea: "Project", pageId: "Project.Settings", pageName: "Settings" } }),
                                        projectVersionControlSettingsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <VersionControlSettingsPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} branchName={undefined}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.VersionControlSettings",
                                                pageName: "Version Control Settings",
                                            },
                                        }),
                                        projectDynamicEnvironmentSettingsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: () => <DynamicEnvironmentSettingsPage />,
                                            pageIdentity: { pageArea: "Project", pageId: "Project.DynamicEnvironmentSettings", pageName: "Dynamic Environment Settings" },
                                        }),
                                        projectITSMProvidersControlSettingsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ITSMProvidersSettingsPage />, pageIdentity: { pageArea: "Project", pageId: "Project.ITSMProvidersSettings", pageName: "ITSM Providers Settings" } }),
                                        createReleaseCreationFeedTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <CreateReleaseCreationFeedTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ReleaseCreationFeedTriggerNew",
                                                pageName: "Add New Release Creation Feed Trigger",
                                            },
                                        }),
                                        editReleaseCreationFeedTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <EditReleaseCreationFeedTriggersPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} triggerId={routeParams.triggerId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ReleaseCreationFeedTrigger",
                                                pageName: "Release Creation Feed Trigger",
                                            },
                                        }),
                                        createReleaseCreationGitTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: () => <CreateReleaseCreationGitTriggersPage />,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ReleaseCreationGitTriggerNew",
                                                pageName: "Add New Release Creation Git Trigger",
                                            },
                                        }),
                                        editReleaseCreationGitTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <EditReleaseCreationGitTriggersPage triggerId={routeParams.triggerId}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.ReleaseCreationGitTrigger",
                                                pageName: "Release Creation Git Trigger",
                                            },
                                        }),
                                        createBuiltInFeedTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <CreateBuiltInFeedTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.BuiltInFeedTriggerNew",
                                                pageName: "Add New Built-in Feed Trigger",
                                            },
                                        }),
                                        editBuiltInFeedTriggerPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <EditBuiltInFeedTriggerPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.BuiltInFeedTrigger",
                                                pageName: "Built-in Feed Trigger",
                                            },
                                        }),
                                        featureTogglesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: () => <FeatureTogglesPage />,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.FeatureToggles",
                                                pageName: "Feature Toggles",
                                            },
                                        }),
                                        featureTogglePage: (r) => createLevel2ProjectPageRegistration(r, {
                                            render: ({ routeParams }) => <FeatureTogglePage spaceId={routeParams.spaceId} featureToggleSlug={routeParams.featureToggleSlug}/>,
                                            pageIdentity: {
                                                pageArea: "Project",
                                                pageId: "Project.FeatureToggle",
                                                pageName: "Feature Toggle",
                                            },
                                        }),
                                    },
                                    childGroups: {
                                        deployments: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                channelsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    loader: (repository, { context: { projectContext } }) => channelsPageLoader(repository, projectContext),
                                                    loadingTitle: channelsPageTitle,
                                                    render: ({ loaderData, context: { projectContext } }) => <ChannelsPage loaderData={loaderData} project={projectContext.state.model}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" },
                                                }),
                                                createChannelPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    loader: (repository, { context: { projectContext } }) => createChannelPageLoader(repository, projectContext),
                                                    loadingTitle: createChannelPageTitle,
                                                    render: ({ loaderData, context: { projectContext: { state: { model: project, gitRef, gitRefValidationError }, }, }, }) => <CreateChannelPage loaderData={loaderData} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.ChannelNew", pageName: "Add New Channels" },
                                                }),
                                                editChannelPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    loader: (repository, { routeParams: { channelId }, context: { projectContext } }) => editChannelPageLoader(repository, channelId, projectContext),
                                                    render: ({ loaderData, context: { projectContext: { state: { model: project, gitRef, gitRefValidationError }, }, }, }) => <EditChannelPage loaderData={loaderData} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" },
                                                }),
                                                deploymentsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <DeploymentsOverview />, pageIdentity: { pageArea: "Project", pageId: "Project.Overview", pageName: "Overview" } }),
                                                releasesPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ReleasesPage />, pageIdentity: { pageArea: "Project", pageId: "Project.Releases", pageName: "Releases" } }),
                                                createReleasePage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <CreateOrEditReleasePage />, pageIdentity: { pageArea: "Project", pageId: "Project.ReleaseNew", pageName: "Add New Release" } }),
                                            },
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            loader: (repository, { context: { projectContext } }) => deploymentProcessLoader(repository, projectContext),
                                                            render: ({ loaderData, context: { projectContext } }) => <DeploymentProcessListPage loaderData={loaderData} projectContext={projectContext}/>,
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" },
                                                        }),
                                                        deploymentProcessStepsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            loader: (repository, { context: { projectContext } }) => deploymentProcessLoader(repository, projectContext),
                                                            render: ({ loaderData, context: { setShowK8sStatusItem, projectStatus, projectContext, scrollAreaRef } }) => {
                                                                return (<DeploymentProcessStepsPage setShowK8sStatusItem={setShowK8sStatusItem} projectStatus={projectStatus} loaderData={loaderData} projectContext={projectContext} scrollAreaRef={scrollAreaRef}/>);
                                                            },
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" },
                                                        }),
                                                    },
                                                },
                                                settings: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessSettingsPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <DeploymentProcessSettings />, pageIdentity: { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" } }),
                                                    },
                                                },
                                                specificRelease: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        releasePage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: ({ routeParams }) => <ReleasePage releaseVersion={routeParams.releaseVersion}/>,
                                                            pageIdentity: {
                                                                pageArea: "Project",
                                                                pageId: "Project.ReleaseVersion",
                                                                pageName: "Release Version",
                                                            },
                                                        }),
                                                        createDeploymentPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: ({ routeParams, queryParams, context: { projectContext } }) => (<CreateDeploymentPage goal={createDeploymentGoal(queryParams)} releaseVersion={routeParams.releaseVersion} project={projectContext.state.model}/>),
                                                            pageIdentity: {
                                                                pageArea: "Project",
                                                                pageId: "Project.DeploymentNew",
                                                                pageName: "Add New Deployment",
                                                            },
                                                        }),
                                                        editReleasePage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: ({ routeParams }) => <CreateOrEditReleasePage releaseVersion={routeParams.releaseVersion}/>,
                                                            pageIdentity: {
                                                                pageArea: "Project",
                                                                pageId: "Project.ReleaseVersionEdit",
                                                                pageName: "Edit Release Version",
                                                            },
                                                        }),
                                                        deploymentDetailsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: ({ routeParams }) => <DeploymentDetailsPage deploymentId={routeParams.deploymentId}/>,
                                                            pageIdentity: {
                                                                pageArea: "Project",
                                                                pageId: "Project.Deployment",
                                                                pageName: "Deployment",
                                                            },
                                                        }),
                                                    },
                                                },
                                            },
                                        },
                                        variables: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                commonVariableTemplatesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <CommonVariableTemplates projectSlug={routeParams.projectSlug}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.VariableCommonTemplates",
                                                        pageName: "Common Variables Templates",
                                                    },
                                                }),
                                                libraryVariablesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <LibraryVariableSets spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.VariableLibrary",
                                                        pageName: "Variable Sets",
                                                    },
                                                }),
                                                variableTemplatesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <ProjectVariableTemplates projectSlug={routeParams.projectSlug}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.VariableTemplates",
                                                        pageName: "Variables Templates",
                                                    },
                                                }),
                                            },
                                            childGroups: {
                                                all: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        allVariablesPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <AllVariables />, pageIdentity: { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" } }),
                                                    },
                                                },
                                                preview: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPreviewPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <VariablePreview />, pageIdentity: { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" } }),
                                                    },
                                                },
                                                wrapper: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPage: (r) => createLevel2ProjectPageRegistration(r, { render: () => <ProjectVariables />, pageIdentity: { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" } }),
                                                    },
                                                },
                                                tenant: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        projectTenantProjectTemplatesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: () => <ProjectTenantProjectTemplates />,
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.TenantProjectTemplates", pageName: "Project Templates" },
                                                        }),
                                                        projectTenantCommonTemplatesPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            render: () => <ProjectTenantCommonTemplates />,
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.TenantCommonTemplates", pageName: "Common Templates" },
                                                        }),
                                                    },
                                                },
                                            },
                                        },
                                        projectRunbooks: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                projectRunbooksPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ queryParams, setQueryParams }) => <RunbooksPage queryParams={queryParams} setQueryParams={setQueryParams}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Operations.Runbooks", pageName: "Runbooks" },
                                                }),
                                            },
                                        },
                                        specificRunbook: {
                                            partialRoute: (r) => r,
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        projectRunbookProcessListPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            loader: (repository, { routeParams, queryParams, context: { projectContext } }) => runbookProcessLoader(repository, routeParams.spaceId, routeParams.processId, projectContext, queryParams.gitRef),
                                                            render: ({ routeParams, queryParams, setQueryParams, loaderData, context: { projectContext } }) => (<RunbookProcessListPage processId={routeParams.processId} spaceId={routeParams.spaceId} loaderData={loaderData} projectContext={projectContext} queryParams={queryParams} setQueryParams={setQueryParams}/>),
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" },
                                                        }),
                                                        projectRunbookProcessStepsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                            loader: (repository, { routeParams, queryParams, context: { projectContext } }) => runbookProcessLoader(repository, routeParams.spaceId, routeParams.processId, projectContext, queryParams.gitRef),
                                                            render: ({ routeParams, queryParams, setQueryParams, loaderData, context: { projectContext, scrollAreaRef } }) => (<RunbookProcessStepsPage processId={routeParams.processId} spaceId={routeParams.spaceId} loaderData={loaderData} projectContext={projectContext} queryParams={queryParams} setQueryParams={setQueryParams} scrollAreaRef={scrollAreaRef}/>),
                                                            pageIdentity: { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" },
                                                        }),
                                                    },
                                                },
                                            },
                                            pages: {
                                                projectRunbookOverviewPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <RunbookOverviewPage spaceId={routeParams.spaceId} projectSlug={routeParams.projectSlug} runbookId={routeParams.runbookId}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.Runbook.Overview",
                                                        pageName: "Runbook Overview",
                                                    },
                                                }),
                                                projectRunbookRunsListPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams, queryParams, setQueryParams }) => <RunbookRunsListPage spaceId={routeParams.spaceId} queryParams={queryParams} setQueryParams={setQueryParams}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Runbook.RunsList", pageName: "Runbook Runs List" },
                                                }),
                                                projectRunbookSettingsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams, queryParams, setQueryParams }) => <RunbookSettingsPage runbookId={routeParams.runbookId} queryParams={queryParams} setQueryParams={setQueryParams}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.Runbook.Settings",
                                                        pageName: "Runbook Settings",
                                                    },
                                                }),
                                                projectRunbookSnapshotsPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <RunbookSnapshotsPage projectSlug={routeParams.projectSlug}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.RunbookSnapshots",
                                                        pageName: "RunbookSnapshots",
                                                    },
                                                }),
                                                projectRunbookSnapshotCreatePage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: () => <RunbookSnapshotEditPage />,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.RunbookSnapshotNew", pageName: "Add New RunbookSnapshot" },
                                                }),
                                                projectRunbookSnapshotEditPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <RunbookSnapshotEditPage runbookSnapshotId={routeParams.runbookSnapshotId}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.RunbookSnapshotEdit",
                                                        pageName: "Edit RunbookSnapshot",
                                                    },
                                                }),
                                                projectRunbookSnapshotInfoPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <RunbookSnapshotInfoPage runbookSnapshotId={routeParams.runbookSnapshotId}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.RunbookSnapshot",
                                                        pageName: "RunbookSnapshot",
                                                    },
                                                }),
                                                projectRunbookRunDetailPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams }) => <RunbookRunDetailsPage runbookRunId={routeParams.runbookRunId}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.RunbookRun",
                                                        pageName: "RunbookRun",
                                                    },
                                                }),
                                                createRunbookRunForSnapshotPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams, queryParams, setQueryParams }) => (<CreateRunbookRunForSnapshotPage runbookSnapshotId={routeParams.runbookSnapshotId} goal={createRunbookRunGoal(queryParams)} queryParams={queryParams} setQueryParams={setQueryParams}/>),
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.RunbookRunNew",
                                                        pageName: "Add New RunbookRun",
                                                    },
                                                }),
                                                runbookRunNowPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ queryParams, setQueryParams }) => <RunbookRunNowPage queryParams={queryParams} setQueryParams={setQueryParams}/>,
                                                    pageIdentity: { pageArea: "Project", pageId: "Project.Runbook.Run", pageName: "Runbook Run" },
                                                }),
                                                runbookRunSnapshotNowPage: (r) => createLevel2ProjectPageRegistration(r, {
                                                    render: ({ routeParams, queryParams, setQueryParams }) => <RunbookRunNowPage runbookSnapshotId={routeParams.runbookSnapshotId} queryParams={queryParams} setQueryParams={setQueryParams}/>,
                                                    pageIdentity: {
                                                        pageArea: "Project",
                                                        pageId: "Project.Runbook.Run",
                                                        pageName: "Runbook Run",
                                                    },
                                                }),
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        importExports: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                importExportProjectTasksPage: (r) => createLevel1ProjectPageRegistration(r, {
                                    render: ({ routeParams }) => <ImportExportProjectTasksPage spaceId={routeParams.spaceId}/>,
                                    pageIdentity: { pageArea: "Project", pageId: "ImportExportTasks", pageName: "Import/Export" },
                                }),
                                importProjectsPage: (r) => createLevel1ProjectPageRegistration(r, { render: ({ routeParams }) => <ImportProjectsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Project", pageId: "ImportProjects", pageName: "Import" } }),
                                importProjectTaskPage: (r) => createLevel1ProjectPageRegistration(r, {
                                    render: ({ routeParams }) => <ImportProjectTaskPage spaceId={routeParams.spaceId} taskId={routeParams.taskId}/>,
                                    pageIdentity: { pageArea: "Project", pageId: "ImportTask", pageName: "Import Task" },
                                }),
                                exportProjectsPage: (r) => createLevel1ProjectPageRegistration(r, { render: ({ routeParams }) => <ExportProjectsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Project", pageId: "ExportProjects", pageName: "Export" } }),
                                exportProjectTaskPage: (r) => createLevel1ProjectPageRegistration(r, {
                                    render: ({ routeParams }) => <ExportProjectTaskPage spaceId={routeParams.spaceId} taskId={routeParams.taskId}/>,
                                    pageIdentity: { pageArea: "Project", pageId: "ExportTask", pageName: "Export Task" },
                                }),
                            },
                        },
                    },
                },
                infrastructure: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        infrastructureOverviewPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <InfrastructureDashboardPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Overview", pageName: "Overview" },
                        }),
                        infrastructureEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <EnvironmentsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Environments", pageName: "Environments" },
                        }),
                        infrastructureEnvironmentPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <EnvironmentPage environmentId={routeParams.environmentId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Environment", pageName: "Environment" },
                        }),
                        workerPoolsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <WorkerPoolsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPools", pageName: "Worker Pools" },
                        }),
                        editWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <WorkerPoolPage workerPoolId={routeParams.workerPoolId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPool", pageName: "Worker Pool" },
                        }),
                        deploymentTargetsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <DeploymentTargetsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.DeploymentTargets",
                                pageName: "Deployment Targets",
                            },
                        }),
                        newDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={routeParams.spaceId} environmentId={undefined}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" },
                            searchKeywords: [
                                "Windows",
                                "Linux",
                                "Mac",
                                "Azure",
                                "Listening Tentacle",
                                "Polling Tentacle",
                                "SSH Connection",
                                "Offline Package Drop",
                                "Azure Web App",
                                "Azure Cloud Service",
                                "Service Fabric Cluster",
                                "Kubernetes (K8s) Cluster",
                                "Cloud Region",
                                "Step Package",
                            ],
                        }),
                        newDeploymentTargetWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={routeParams.spaceId} environmentId={routeParams.environmentId}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" },
                        }),
                        createDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <DeploymentTargetSettingsPage spaceId={routeParams.spaceId}/>
                                    </InfrastructureLayout>),
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.DeploymentTargetsNew",
                                pageName: "Add New Deployment Target",
                            },
                        }),
                        deploymentTargetConnectionPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<MachineLayout>
                                        <DeploymentTargetConnectionsPage machineId={routeParams.machineId}/>
                                    </MachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetConnectivity", pageName: "Deployment Target Connectivity" },
                        }),
                        deploymentTargetSettingsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<MachineLayout>
                                        <DeploymentTargetSettingsPage spaceId={routeParams.spaceId} machineId={routeParams.machineId}/>
                                    </MachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetSettings", pageName: "Deployment Target Settings" },
                        }),
                        deploymentTargetDeploymentsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<MachineLayout>
                                        <MachineDeploymentsPage machineId={routeParams.machineId}/>
                                    </MachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetDeployments", pageName: "Deployment Target Deployments" },
                        }),
                        deploymentTargetRunbookRunsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<MachineLayout>
                                        <MachineRunbookRunsPage machineId={routeParams.machineId}/>
                                    </MachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetRunbookRuns", pageName: "Deployment Target Runbook Runs" },
                        }),
                        deploymentTargetEventsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<MachineLayout>
                                        <MachineEventsPage machineId={routeParams.machineId}/>
                                    </MachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetEvents", pageName: "Deployment Target Events" },
                        }),
                        discoverMachinePage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={routeParams.spaceId} endpointKey={routeParams.endpointKey}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" },
                        }),
                        discoverMachineWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={routeParams.spaceId} endpointKey={routeParams.endpointKey} environmentId={routeParams.environmentId}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" },
                        }),
                        createWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <WorkerMachineSettingsPage spaceId={routeParams.spaceId}/>
                                    </InfrastructureLayout>),
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.WorkersNew",
                                pageName: "Add New Worker",
                            },
                        }),
                        workerMachinesPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <WorkerMachinesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Workers", pageName: "Workers" },
                        }),
                        newWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={routeParams.spaceId} workerPoolId={undefined}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" },
                            searchKeywords: ["Windows", "Linux", "Mac", "Listening Tentacle", "Polling Tentacle", "SSH Connection"],
                        }),
                        newWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={routeParams.spaceId} workerPoolId={routeParams.workerPoolId}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" },
                        }),
                        discoverWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={routeParams.spaceId} endpointKey={routeParams.endpointKey}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" },
                        }),
                        discoverWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={routeParams.spaceId} workerPoolId={routeParams.workerPoolId} endpointKey={routeParams.endpointKey}/>
                                    </InfrastructureLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" },
                        }),
                        workerMachineConnectionPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<WorkerMachineLayout>
                                        <WorkerMachineConnectionsPage machineId={routeParams.machineId}/>
                                    </WorkerMachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerConnectivity", pageName: "Worker Connectivity" },
                        }),
                        workerMachineSettingsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<WorkerMachineLayout>
                                        <WorkerMachineSettingsPage spaceId={routeParams.spaceId} machineId={routeParams.machineId}/>
                                    </WorkerMachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerSettings", pageName: "Worker Settings" },
                        }),
                        workerMachineEventsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => (<WorkerMachineLayout>
                                        <MachineEventsPage machineId={routeParams.machineId}/>
                                    </WorkerMachineLayout>),
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerEvents", pageName: "Worker Events" },
                        }),
                        machinePoliciesPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <MachinePoliciesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.MachinePolicies", pageName: "Machine Policies" },
                        }),
                        editMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <MachinePolicyPage spaceId={routeParams.spaceId} machinePolicyId={routeParams.machinePolicyId}/>,
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.MachinePolicy",
                                pageName: "Machine Policy",
                            },
                        }),
                        createMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <MachinePolicyPage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.MachinePoliciesNew",
                                pageName: "Add New Machine Policy",
                            },
                        }),
                        proxiesPage: (r) => createInfrastructurePageRegistration(r, { render: ({ routeParams }) => <ProxiesPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Proxies", pageName: "Proxies" } }),
                        editProxyPage: (r) => createInfrastructurePageRegistration(r, { render: ({ routeParams }) => <ProxyPage proxyId={routeParams.proxyId}/>, pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Proxy", pageName: "Proxy" } }),
                        createProxyPage: (r) => createInfrastructurePageRegistration(r, { render: () => <ProxyPage create={true}/>, pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.ProxiesNew", pageName: "Add New Proxy" } }),
                        infrastructureAccountsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <AccountsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Accounts", pageName: "Accounts" },
                        }),
                        editInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <AccountPage createNew={false} accountId={routeParams.accountId}/>,
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.Account", pageName: "Account" },
                        }),
                        createInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams, queryParams }) => {
                                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                const accountType = AccountType[queryParams.accountType as keyof typeof AccountType];
                                return <AccountPage createNew={true} accountType={accountType}/>;
                            },
                            pageIdentity: { pageArea: "Infrastructure", pageId: "Infrastructure.AccountsNew", pageName: "Add New Account" },
                        }),
                        dynamicEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <DynamicEnvironmentsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.DynamicEnvironments",
                                pageName: "Dynamic Environments",
                            },
                        }),
                        dynamicEnvironmentPage: (r) => createInfrastructurePageRegistration(r, {
                            render: ({ routeParams }) => <DynamicEnvironmentPage spaceId={routeParams.spaceId} environmentId={routeParams.environmentId}/>,
                            pageIdentity: {
                                pageArea: "Infrastructure",
                                pageId: "Infrastructure.DynamicEnvironment",
                                pageName: "Dynamic Environment",
                            },
                        }),
                    },
                },
                tenants: {
                    partialRoute: (r) => r,
                    childGroups: {
                        specificTenant: {
                            partialRoute: (r) => r,
                            pages: {
                                tenantOverviewPage: (r) => createLevel2TenantPageRegistration(r, {
                                    loader: (repository, { routeParams }) => tenantOverviewPageLoader(repository, routeParams.tenantId),
                                    render: ({ loaderData }) => <TenantOverviewPage loaderData={loaderData}/>,
                                    pageIdentity: { pageArea: "Tenants", pageId: "Tenant.Overview", pageName: "Tenant Overview" },
                                    loadingTitle: tenantOverviewPageTitle,
                                }),
                                tenantVariablesPage: (r) => createLevel2TenantPageRegistration(r, {
                                    render: ({ routeParams }) => <TenantVariablesPage tenantId={routeParams.tenantId}/>,
                                    pageIdentity: { pageArea: "Tenants", pageId: "Tenant.Variables", pageName: "Tenant Variables" },
                                }),
                                tenantSettingsPage: (r) => createLevel2TenantPageRegistration(r, {
                                    render: ({ routeParams }) => <TenantSettingsPage tenantId={routeParams.tenantId}/>,
                                    pageIdentity: { pageArea: "Tenants", pageId: "Tenant.Settings", pageName: "Tenant Settings" },
                                }),
                                tenantTasksPage: (r) => createLevel2TenantPageRegistration(r, { render: ({ routeParams }) => <TenantTasksPage tenantId={routeParams.tenantId}/>, pageIdentity: { pageArea: "Tenants", pageId: "Tenant.Tasks", pageName: "Tenant Tasks" } }),
                            },
                            childGroups: {},
                        },
                    },
                    pages: {
                        tenantsPage: (r) => createLevel1TenantPageRegistration(r, {
                            render: ({ routeParams, queryParams, setQueryParams }) => <TenantsPage spaceId={routeParams.spaceId} queryParams={queryParams} setQueryParams={setQueryParams}/>,
                            pageIdentity: {
                                pageArea: "Tenants",
                                pageId: "Tenants",
                                pageName: "Tenants",
                            },
                        }),
                    },
                },
                library: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        builtInRepositoryPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <BuiltInRepositoryPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.BuiltInRepository", pageName: "Packages" } }),
                        packageDetailsPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <PackageDetailsPage spaceId={routeParams.spaceId} packageId={routeParams.id}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.BuiltInRepositoryPackage",
                                pageName: "Package Details",
                            },
                        }),
                        packageVersionsListPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <PackageVersionsListPage spaceId={routeParams.spaceId} packageId={routeParams.packageId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.BuiltInRepositoryPackageVersions",
                                pageName: "Package Versions",
                            },
                        }),
                        buildInformationOverviewPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <BuildInformationOverviewPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.BuildInformationRepository", pageName: "Build Information" },
                        }),
                        buildInformationVersionsPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <BuildInformationVersionsPage spaceId={routeParams.spaceId} packageId={routeParams.packageId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.BuildInformationRepositoryPackageVersions",
                                pageName: "Build Information Versions",
                            },
                        }),
                        buildInformationDetailPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <BuildInformationDetailPage spaceId={routeParams.spaceId} id={routeParams.id}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.BuildInformationRepositoryPackage",
                                pageName: "Build Information Details",
                            },
                        }),
                        feedsPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <ExternalFeedsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.Feeds", pageName: "External Feeds" } }),
                        createFeedPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ExternalFeedPage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.FeedsNew", pageName: "Add New External Feed" },
                        }),
                        editFeedPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ExternalFeedPage spaceId={routeParams.spaceId} feedId={routeParams.feedId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.Feed", pageName: "External Feed" },
                        }),
                        testFeedPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ExternalFeedTestPage spaceId={routeParams.spaceId} feedId={routeParams.feedId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.FeedTest",
                                pageName: "Test External Feed",
                            },
                        }),
                        lifecyclesPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <LifecyclesPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.Lifecycles", pageName: "Lifecycles" } }),
                        createLifecyclePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <LifecyclePage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.LifecyclesNew", pageName: "Add New Lifecycle" },
                        }),
                        editLifecyclePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <LifecyclePage spaceId={routeParams.spaceId} lifecycleId={routeParams.lifecycleId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.Lifecycle", pageName: "Lifecycle" },
                        }),
                        scriptModulesPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <ScriptModulesPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.ScriptModules", pageName: "Script Modules" } }),
                        createScriptModulePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ScriptModulePage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.ScriptModulesNew", pageName: "Add New Script Module" },
                        }),
                        editScriptModulePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ScriptModulePage spaceId={routeParams.spaceId} scriptModuleId={routeParams.scriptModuleId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.ScriptModule",
                                pageName: "Script Module",
                            },
                        }),
                        stepTemplatesPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ActionTemplatesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplates", pageName: "Step Templates" },
                        }),
                        builtInStepTemplatesPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <BuiltinTemplatesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplatesBuiltIn", pageName: "Built-in Step Templates" },
                        }),
                        communityStepTemplatesPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <CommunityTemplatesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplatesCommunity", pageName: "Community Step Templates" },
                        }),
                        communityStepTemplatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <CommunityTemplatePage spaceId={routeParams.spaceId} templateId={routeParams.templateId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.StepTemplatesCommunityTemplate",
                                pageName: "Community Step Template",
                            },
                        }),
                        createStepTemplatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={routeParams.spaceId} actionType={routeParams.actionType}/>
                                    </ActionTemplateLayout>),
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplateNew", pageName: "Add New Step Template" },
                        }),
                        editStepTemplatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={routeParams.spaceId} templateId={routeParams.templateId}/>
                                    </ActionTemplateLayout>),
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplate", pageName: "Step Template" },
                        }),
                        runStepTemplatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <RunActionTemplatePage spaceId={routeParams.spaceId} templateId={routeParams.templateId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.StepTemplateRun",
                                pageName: "Run Step Template",
                            },
                        }),
                        stepTemplateUsagePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => (<ActionTemplateLayout>
                                        <ActionTemplateUsagePage spaceId={routeParams.spaceId} templateId={routeParams.templateId}/>
                                    </ActionTemplateLayout>),
                            pageIdentity: { pageArea: "Library", pageId: "Library.StepTemplateUsage", pageName: "Step Template Usage" },
                        }),
                        archiveCertificatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <ArchivedCertificatesPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.CertificatesArchive", pageName: "Certificates Archive" },
                        }),
                        tagSetsPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <TagSetsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.TenantTagSets", pageName: "Tenant Tag Sets" } }),
                        createTagSetPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <TagSetPage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.TenantTagSetsNew", pageName: "Add New Tenant Tag Set" },
                        }),
                        editTagSetPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <TagSetPage spaceId={routeParams.spaceId} tagSetId={routeParams.tagSetId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.TenantTagSet", pageName: "Tenant Tag Set" },
                        }),
                        variableSetsPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <VariableSetsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.VariableSets", pageName: "Variable Sets" } }),
                        editVariableSetPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <EditVariableSetPage spaceId={routeParams.spaceId} variableSetId={routeParams.variableSetId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.VariableSet",
                                pageName: "Variable Set",
                            },
                        }),
                        gitCredentialsPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <GitCredentialsPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.GitCredentials", pageName: "Git Credentials" } }),
                        createGitCredentialPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <GitCredentialPage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.GitCredentialsNew", pageName: "Add New Git Credential" },
                        }),
                        editGitCredentialPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <GitCredentialPage spaceId={routeParams.spaceId} gitCredentialId={routeParams.gitCredentialId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.GitCredential",
                                pageName: "Git Credential",
                            },
                        }),
                        gitConnectionsPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <GitConnectionsPage spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.GitConnections", pageName: "GitHub Connections" },
                        }),
                        createGitHubConnectionPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <AddGitHubConnectionPage spaceId={routeParams.spaceId} installationId={routeParams.installationId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.GitConnectionsAddGitHub",
                                pageName: "Add GitHub Connection",
                            },
                        }),
                        editGitHubConnectionPage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <GitHubConnectionPage spaceId={routeParams.spaceId} connectionId={routeParams.connectionId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.GitConnectionsGetGitHub",
                                pageName: "GitHub Connection",
                            },
                        }),
                        createCertificatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <CertificatePage spaceId={routeParams.spaceId} create={true}/>,
                            pageIdentity: { pageArea: "Library", pageId: "Library.CertificatesNew", pageName: "Add New Certificate" },
                        }),
                        editCertificatePage: (r) => createLibraryPageRegistration(r, {
                            render: ({ routeParams }) => <CertificatePage spaceId={routeParams.spaceId} create={false} certificateId={routeParams.certificateId}/>,
                            pageIdentity: {
                                pageArea: "Library",
                                pageId: "Library.Certificate",
                                pageName: "Certificate",
                            },
                        }),
                        certificatesPage: (r) => createLibraryPageRegistration(r, { render: ({ routeParams }) => <CertificatesPage spaceId={routeParams.spaceId}/>, pageIdentity: { pageArea: "Library", pageId: "Library.Certificates", pageName: "Certificates" } }),
                    },
                },
                insights: {
                    partialRoute: (r) => r,
                    pages: {
                        insightsUpsellPage: (r) => createInsightsPageRegistration(r, { render: () => <ReportUpsell />, pageIdentity: { pageArea: "Insights", pageId: "Insights.Upsell", pageName: "Insights Reports Upsell" } }),
                    },
                    childGroups: {
                        reports: {
                            partialRoute: (r) => r,
                            pages: {
                                insightReportListPage: (r) => createInsightsPageRegistration(r, { render: () => <InsightsReportListPage />, pageIdentity: { pageArea: "Insights", pageId: "Insights.Reports", pageName: "Insights Reports" } }),
                            },
                            childGroups: {
                                specificReport: {
                                    partialRoute: (p) => p,
                                    childGroups: {},
                                    pages: {
                                        insightsReportOverviewPage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportOverviewPage report={report}/>,
                                            pageIdentity: { pageArea: "Insights", pageId: "Insights.Report.Overview", pageName: "Insights Report Overview" },
                                        }),
                                        insightsReportDeploymentFrequencyPage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportDeploymentFrequencyPage report={report}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.DeploymentFrequency",
                                                pageName: "Insights Report Deployment Frequency",
                                            },
                                        }),
                                        insightsReportDeploymentLeadTimePage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportDeploymentLeadTimePage report={report}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.DeploymentLeadTime",
                                                pageName: "Insights Report Deployment Lead Time",
                                            },
                                        }),
                                        insightsReportDeploymentFailureRatePage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportDeploymentFailureRatePage report={report}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.DeploymentFailureRate",
                                                pageName: "Insights Report Failure Rate",
                                            },
                                        }),
                                        insightsReportDeploymentMeanTimeToRecoveryPage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportDeploymentMeanTimeToRecoveryPage report={report}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.MeanTimeToRecovery",
                                                pageName: "Insights Report Mean Time to Recovery",
                                            },
                                        }),
                                        insightsReportDeploymentDurationPage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report } }) => <InsightsReportDeploymentDurationPage report={report}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.DeploymentDuration",
                                                pageName: "Insights Report Deployment Duration",
                                            },
                                        }),
                                        insightsReportSettingsPage: (r) => createInsightsReportPageRegistration(r, {
                                            render: ({ context: { report, refreshReport } }) => <InsightsReportSettingsPage report={report} refreshReport={refreshReport}/>,
                                            pageIdentity: {
                                                pageArea: "Insights",
                                                pageId: "Insights.Report.Settings",
                                                pageName: "Insights Report Settings",
                                            },
                                        }),
                                    },
                                },
                            },
                        },
                    },
                },
                blueprints: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        blueprintsPage: (r) => createPlatformHubPageRegistration(r, {
                            loader: (repository, { queryParams }) => processTemplatesPageLoader(repository, queryParams),
                            render: ({ loaderData, queryParams, setQueryParams, routeParams }) => <ProcessTemplatesPage loaderData={loaderData} queryParams={queryParams} setQueryParams={setQueryParams} spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Platform Hub", pageId: "ProcessTemplates", pageName: "Process Templates" },
                        }),
                        platformHubConnectionPage: (r) => createPlatformHubPageRegistration(r, {
                            loader: (repository) => platformHubConnectionLoader(repository),
                            render: ({ loaderData, routeParams }) => <PlatformHubConnectionPage loaderData={loaderData} spaceId={routeParams.spaceId}/>,
                            pageIdentity: { pageArea: "Platform Hub", pageId: "PlatformHub.VersionControl", pageName: "Version Control Settings" },
                        }),
                        editProcessTemplatePage: (r) => createPlatformHubPageRegistration(r, {
                            loader: (repository, { queryParams, routeParams }) => editProcessTemplateLoader(repository, queryParams.gitRef, routeParams.slug),
                            render: ({ routeParams, queryParams, setQueryParams, loaderData, context: { scrollAreaRef } }) => (<EditProcessTemplatePage loaderData={loaderData} spaceId={routeParams.spaceId} slug={routeParams.slug} queryParams={queryParams} setQueryParams={setQueryParams} scrollAreaRef={scrollAreaRef}/>),
                            pageIdentity: { pageArea: "Platform Hub", pageId: "ProcessTemplate.Edit", pageName: "Edit Process Template" },
                        }),
                    },
                },
            },
        },
    },
});
export const allPagesFlattened = getPagesFromPageMap(allPages);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPagesFromPageMap<ChildGroups extends Record<string, PageGroup<any, any, any>>, PageMap extends PageGroup<any, ChildGroups, any>>(pageMap: PageMap): UnknownPageRegistration[] {
    const childGroupPages = Object.values(pageMap.childGroups).flatMap((childGroup) => getPagesFromPageMap(childGroup));
    const entries: UnknownPageRegistration[] = Object.values(pageMap.pages);
    return [...entries, ...childGroupPages];
}
function generateInsightsReportFilterQueryString(reportFilter: ReportFilter) {
    return generateQueryString([insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam], reportFilter);
}
function generateProjectInsightsReportFilterQueryString(filter: ProjectInsightsFilterQueryParams) {
    return generateQueryString([projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam], filter);
}
function createRunbookRunGoal({ previousRunbookRunId, ...rest }: {
    environmentIds?: string[];
    previousRunbookRunId?: string;
}): RunGoal {
    if (previousRunbookRunId !== undefined) {
        return { previousRunbookRunId };
    }
    return { ...rest };
}
function createDeploymentGoal({ previousDeploymentId, ...rest }: {
    environmentIds?: string[];
    previousDeploymentId?: string;
    tenantIds?: string[];
    tenantTags?: string[];
}): CreateDeploymentGoal {
    if (previousDeploymentId !== undefined) {
        return { previousDeploymentId };
    }
    return { ...rest };
}
