import type { GitBranchResource, GitRef, Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { GenericGitRefSelectorLoadedItems } from "~/areas/projects/components/GitRefSelector/GenericGitRefSelector";
import { GenericGitRefSelector } from "~/areas/projects/components/GitRefSelector/GenericGitRefSelector";
import { useNotifyCreateBranch } from "./Analytics/useNotifyCreateBranch";
export interface BranchSelectorGenericProps {
    gitRef: GitRef | undefined;
    setGitRef: (gitRef: string) => void;
    onCreateBranch: (newBranchName: string, parentGitRef: GitRef) => Promise<GitBranchResource>;
    onLoadItems(refType: RefTypes, selectedGitRef: GitRef | undefined): Promise<GenericGitRefSelectorLoadedItems>;
    onSearchItems(refType: RefTypes, searchTerm: string): Promise<GitRefOption[]>;
    defaultBranch: GitRef | string;
    permission: Permission;
}
export function BranchSelectorGeneric({ gitRef, setGitRef, onCreateBranch, onLoadItems, onSearchItems, defaultBranch, permission }: BranchSelectorGenericProps) {
    const createBranchDispatcher = useNotifyCreateBranch();
    if (!gitRef) {
        return null;
    }
    const onCreateBranchInternal = async (newBranchName: string, parentGitRef: GitRef): Promise<GitBranchResource> => {
        const newBranchResource = await createBranchDispatcher({
            source: "Branch switcher",
            createBranchAction: () => onCreateBranch(newBranchName, parentGitRef),
        });
        setGitRef(newBranchResource.CanonicalName);
        return newBranchResource;
    };
    return <GenericGitRefSelector onChange={setGitRef} initialGitRef={gitRef} onCreateBranch={onCreateBranchInternal} onLoadItems={onLoadItems} onSearchItems={onSearchItems} defaultBranch={defaultBranch} permission={permission}/>;
}
