import { Tooltip } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
export default (props: {
    for: FrozenResourceType;
}) => {
    const frozenResource = getResourceDescriptionText(props.for);
    return (<Tooltip content={`There is a deployment freeze in place for this ${frozenResource}`}>
            <em className={cn(styles.frozenIcon, "fa-regular", "fa-snowflake")}/>
        </Tooltip>);
};
const getResourceDescriptionText = (resource: FrozenResourceType) => {
    switch (resource) {
        case FrozenResourceType.Project:
            return "project";
            break;
        case FrozenResourceType.Environment:
            return "environment";
            break;
        case FrozenResourceType.Tenant:
            return "tenant";
            break;
    }
};
export enum FrozenResourceType {
    Project,
    Environment,
    Tenant
}
