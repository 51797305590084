/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, Checkbox } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, ISnapshotResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { isReleaseResource, isRunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TenantChip } from "~/components/Chips/index";
import Note from "~/primitiveComponents/form/Note/Note";
import styles from "./style.module.less";
interface SkipTenantsPanelProps {
    selectedEnvironmentIds: string[];
    showSkipPanel: boolean;
    tenantsWhoGotThisReleaseAsCurrent: TenantResource[];
    showTenantsWhoGotThisReleaseAsCurrent: boolean;
    skipTenantsWhoHaveTheReleaseAsCurrent: boolean;
    allEnvironments: EnvironmentResource[];
    release: ISnapshotResource;
    onTenantFilterRuleChange(value: boolean): void;
    onTenantsToggled(): void;
}
const SkipTenantsPanel: React.FC<SkipTenantsPanelProps> = (props) => {
    const numOfTenantsGotThisRelease = props.tenantsWhoGotThisReleaseAsCurrent.length;
    const environmentName = props.allEnvironments.find((x) => x.Id === props.selectedEnvironmentIds[0])!.Name;
    let version = "";
    if (isReleaseResource(props.release)) {
        version = props.release.Version;
    }
    else if (isRunbookSnapshotResource(props.release)) {
        version = props.release.Name;
    }
    return (<React.Fragment>
            {props.tenantsWhoGotThisReleaseAsCurrent.length > 0 && props.showSkipPanel && (<div>
                    <Checkbox label={`Skip tenants where ${version} is the current version deployed to ${environmentName} (${numOfTenantsGotThisRelease})`} value={props.skipTenantsWhoHaveTheReleaseAsCurrent} onChange={props.onTenantFilterRuleChange}/>
                    <div className={styles.tenantIntersection}>
                        <Note>
                            {`${numOfTenantsGotThisRelease} ${numOfTenantsGotThisRelease > 1 ? "tenants have" : "tenant has"} this version currently in ${environmentName}`}
                            <ActionButton type={ActionButtonType.Ternary} onClick={props.onTenantsToggled} label={props.showTenantsWhoGotThisReleaseAsCurrent ? "Hide" : "Show"}/>
                            {props.showTenantsWhoGotThisReleaseAsCurrent && (<div>
                                    {props.tenantsWhoGotThisReleaseAsCurrent.map((tenant) => (<TenantChip tenantName={tenant.Name} key={tenant.Id} isDisabled={!!tenant.IsDisabled}/>))}
                                </div>)}
                        </Note>
                    </div>
                </div>)}
        </React.Fragment>);
};
SkipTenantsPanel.displayName = "SkipTenantsPanel"
export default SkipTenantsPanel;
