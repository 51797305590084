/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton } from "@octopusdeploy/design-system-components";
import type { CertificateImportProperties } from "@octopusdeploy/legacy-action-properties";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SimpleDataTable from "~/components/SimpleDataTable";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, FormSectionHeading, Note, Summary } from "~/components/form";
import ProjectCertificateVariableSelect from "~/components/form/CertificateSelect/ProjectCertificateVariableSelect";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { AccessRule } from "./CertificateImportTypes";
import { AccessType, StoreLocations, StoreNames } from "./CertificateImportTypes";
import PrivateKeyAccessRuleDialog from "./PrivateKeyAccessRuleDialog";
import styles from "./style.module.less";
class CertificateImportActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Import Certificate from variable <strong>{this.props.properties[SpecialVariables.Action.Certificate.Variable]}</strong> into Windows Certificate Store{" "}
                <strong>{this.props.properties[SpecialVariables.Action.Certificate.StoreLocation]}</strong>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on deployment targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "CertificateImportActionSummary";
}
interface CertificateImportActionEditState {
    project: ProjectResource;
    storeLocation: string;
    storeName: string;
    customStoreName: string;
    accessRules: AccessRule[];
}
class CertificateImportActionEdit extends BaseComponent<ActionEditProps<CertificateImportProperties>, CertificateImportActionEditState> {
    constructor(props: ActionEditProps<CertificateImportProperties>) {
        super(props);
        this.state = {
            project: null!,
            storeLocation: null!,
            storeName: null!,
            customStoreName: null!,
            accessRules: [],
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (this.props.projectId) {
                const project = await repository.Projects.get(this.props.projectId);
                this.setState({
                    project,
                });
            }
            this.initializeStoreLocation();
            this.initializeStoreName();
            this.initializePrivateKeyAccess();
        });
    }
    initializeStoreLocation() {
        const currentStoreLocation = this.props.properties["Octopus.Action.Certificate.StoreLocation"];
        const currentStoreUser = this.props.properties["Octopus.Action.Certificate.StoreUser"];
        let storeLocation = null;
        storeLocation = currentStoreLocation ? currentStoreLocation : currentStoreUser ? StoreLocations.CustomUser : StoreLocations.LocalMachine;
        this.setState({ storeLocation }, () => this.updateStoreLocation());
    }
    initializeStoreName() {
        const currentStoreName = this.props.properties["Octopus.Action.Certificate.StoreName"];
        let storeName = null;
        let customStoreName = null;
        if (!currentStoreName) {
            // If this is the first time, default the store name to 'My'
            storeName = StoreNames.My;
        }
        else if (currentStoreName in StoreNames) {
            // If the store name has been previously set to one of the pre-defined values, then use it
            storeName = currentStoreName;
        }
        else {
            // Else, a custom value has been used
            storeName = StoreNames.Other;
            customStoreName = currentStoreName;
        }
        this.setState({ storeName: storeName!, customStoreName: customStoreName! }, () => this.updateStoreName());
    }
    initializePrivateKeyAccess() {
        if (this.props.properties["Octopus.Action.Certificate.PrivateKeyExportable"] === undefined) {
            this.props.setProperties({ ["Octopus.Action.Certificate.PrivateKeyExportable"]: "False" }, true);
        }
        if (!this.props.properties["Octopus.Action.Certificate.PrivateKeyAccessRules"]) {
            this.props.setProperties({ ["Octopus.Action.Certificate.PrivateKeyAccessRules"]: "[]" }, true);
        }
        if (this.props.properties["Octopus.Action.Certificate.PrivateKeyAccessRules"]) {
            const privateKeyAccessRules = JSON.parse(this.props.properties["Octopus.Action.Certificate.PrivateKeyAccessRules"]);
            this.setState({ accessRules: privateKeyAccessRules });
        }
    }
    updateStoreLocation() {
        if (this.state.storeLocation === StoreLocations.CustomUser) {
            if (this.props.properties["Octopus.Action.Certificate.StoreLocation"]) {
                this.props.setProperties({ ["Octopus.Action.Certificate.StoreLocation"]: null! });
            }
        }
        else {
            this.props.setProperties({ ["Octopus.Action.Certificate.StoreLocation"]: this.state.storeLocation });
            if (this.props.properties["Octopus.Action.Certificate.StoreUser"]) {
                this.props.setProperties({ ["Octopus.Action.Certificate.StoreUser"]: null! });
            }
        }
    }
    updateStoreName() {
        if (this.state.storeName === StoreNames.Other) {
            this.props.setProperties({ ["Octopus.Action.Certificate.StoreName"]: this.state.customStoreName });
        }
        else {
            this.props.setProperties({ ["Octopus.Action.Certificate.StoreName"]: this.state.storeName });
        }
    }
    updatePrivateKeyAccess() {
        this.props.setProperties({ ["Octopus.Action.Certificate.PrivateKeyAccessRules"]: JSON.stringify(this.state.accessRules) });
    }
    render() {
        return (<div>
                <FormSectionHeading title="Certificate"/>
                <ExpandableFormSection errorKey="Octopus.Action.Certificate.Variable" isExpandedByDefault={this.props.expandedByDefault} title="Certificate Variable" help="The variable representing the certificate to be imported" summary={this.certificateSummary()}>
                    {this.props.projectId ? (<ProjectCertificateVariableSelect projectId={this.props.projectId} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} value={this.props.properties["Octopus.Action.Certificate.Variable"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Certificate.Variable"]: val })} allowClear={true}/>) : (<VariableLookupText label="Certificate variable" localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Certificate.Variable"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Certificate.Variable"]: val })}/>)}
                    <Note>
                        A project variable that refers to a certificate.{" "}
                        <span>
                            <ExternalLink href="CertificatesDocumentation">Learn more</ExternalLink>.
                        </span>
                    </Note>
                </ExpandableFormSection>
                <FormSectionHeading title="Store"/>
                <ExpandableFormSection errorKey="Octopus.Action.Certificate.StoreLocation|Octopus.Action.Certificate.StoreUser" isExpandedByDefault={this.props.expandedByDefault} title="Store Location" help="Select the location of the certificate store." summary={this.storeLocationSummary()}>
                    <BoundSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} label="Store location" value={this.state.storeLocation} items={Object.values(StoreLocations).map((item) => ({ value: item, text: item }))} onChange={(storeLocation) => this.setState({ storeLocation: storeLocation! }, () => this.updateStoreLocation())} error={this.props.getFieldError("Octopus.Action.Certificate.StoreLocation")}/>
                    {this.state.storeLocation === StoreLocations.CustomUser && (<div>
                            <VariableLookupText localNames={this.props.localNames} label="Custom user" placeholder="Enter a user name" value={this.props.properties["Octopus.Action.Certificate.StoreUser"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Certificate.StoreUser"]: val })} error={this.props.getFieldError("Octopus.Action.Certificate.StoreUser")}/>
                            <Note>
                                A user to use as the certificate store location. Examples: <em>MachineA\UserA</em>, <em>DomainB\UserB</em>
                            </Note>
                        </div>)}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Certificate.StoreName" isExpandedByDefault={this.props.expandedByDefault} title="Store Name" help="Select the name of the Windows certificate store." summary={this.storeNameSummary()}>
                    <BoundSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} label="Store name" value={this.state.storeName} items={Object.values(StoreNames).map((item) => ({ value: item, text: item }))} onChange={(storeName) => this.setState({ storeName: storeName! }, () => this.updateStoreName())} error={this.props.getFieldError("Octopus.Action.Certificate.StoreName")}/>
                    <Note>
                        The name of the Windows certificate store. Use one of the pre-defined stores, or choose <em>Other</em> to enter a custom store name.
                    </Note>
                    {this.state.storeName === StoreNames.Other && (<VariableLookupText localNames={this.props.localNames} label="Custom store name" placeholder="Enter a store name" value={this.state.customStoreName} onChange={(customStoreName) => this.setState({ customStoreName }, () => this.updateStoreName())}/>)}
                </ExpandableFormSection>
                <FormSectionHeading title="Private Key"/>
                <ExpandableFormSection errorKey="Octopus.Action.Certificate.PrivateKeyExportable" isExpandedByDefault={this.props.expandedByDefault} title="Private Key Exportable" help="Select whether the private-key is exportable." summary={this.privateKeyExportableSummary()}>
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} label="Private key exportable" value={this.props.properties["Octopus.Action.Certificate.PrivateKeyExportable"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Certificate.PrivateKeyExportable"]: val })} note={<span>If the certificate includes a private-key it will be marked as exportable.</span>}/>
                </ExpandableFormSection>
                {this.state.storeLocation === StoreLocations.LocalMachine && (<ExpandableFormSection errorKey="Octopus.Action.Certificate.PrivateKeyAccessRules" isExpandedByDefault={this.props.expandedByDefault} title="Private Key Access" help="Define who has access to the private-key on the target machine" summary={this.privateKeyAccessSummary()}>
                        <SimpleDataTable data={this.state.accessRules} headerColumns={["Identity", "Access", "Actions"]} headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn]} rowColumnClassName={styles.rowColumn} onRow={(rule: AccessRule) => [
                    rule.Identity,
                    rule.Access,
                    <span>
                                    <OpenDialogButton label="Edit">
                                        <PrivateKeyAccessRuleDialog rule={_.clone(rule)} saveDone={(newRule) => this.setState((prevState) => ({ accessRules: prevState.accessRules.map((r) => (r === rule ? newRule : r)) }), () => this.updatePrivateKeyAccess())}/>
                                    </OpenDialogButton>
                                    <ActionButton label="Delete" onClick={() => this.setState((prevState) => ({ accessRules: prevState.accessRules.filter((r) => r !== rule) }), () => this.updatePrivateKeyAccess())}/>
                                </span>,
                ]}/>
                        <div className={styles.addRule}>
                            <OpenDialogButton label="Add Access Rule">
                                <PrivateKeyAccessRuleDialog rule={{ Identity: null!, Access: AccessType.ReadOnly }} saveDone={(newRule) => this.setState((prevState) => ({ accessRules: [...this.state.accessRules, newRule] }), () => this.updatePrivateKeyAccess())}/>
                            </OpenDialogButton>
                        </div>
                        <Note>By default, both the machine Administrators group and the account the Tentacle Service is executing as will be granted access.</Note>
                    </ExpandableFormSection>)}
            </div>);
    }
    certificateSummary(): SummaryNode {
        const certificateVariable = this.props.properties["Octopus.Action.Certificate.Variable"];
        return certificateVariable
            ? Summary.summary(<span>
                      The certificate <b>{certificateVariable}</b> will be imported
                  </span>)
            : Summary.placeholder("The certificate variable has not been provided");
    }
    storeLocationSummary(): SummaryNode {
        const storeLocation = this.props.properties["Octopus.Action.Certificate.StoreLocation"] ? this.props.properties["Octopus.Action.Certificate.StoreLocation"] : this.props.properties["Octopus.Action.Certificate.StoreUser"];
        return Summary.summary(<span>
                The selected certificate store is <b>{storeLocation}</b>
            </span>);
    }
    storeNameSummary(): SummaryNode {
        const storeName = this.props.properties["Octopus.Action.Certificate.StoreName"];
        return Summary.summary(<span>
                The selected store name is <b>{storeName}</b>
            </span>);
    }
    privateKeyAccessSummary(): SummaryNode {
        return this.state.accessRules && this.state.accessRules.length > 0
            ? this.state.accessRules.length > 1
                ? Summary.summary(<span>
                          There are <b>{this.state.accessRules.length}</b> access rules defined
                      </span>)
                : Summary.summary(<span>
                          There is <b>1</b> access rule defined
                      </span>)
            : Summary.placeholder("There are no access rules defined");
    }
    privateKeyExportableSummary(): SummaryNode {
        const exportable = this.props.properties["Octopus.Action.Certificate.PrivateKeyExportable"];
        return exportable === "True" ? Summary.summary(<span>The private-key is exportable</span>) : Summary.summary(<span>The private-key is not exportable</span>);
    }
    static displayName = "CertificateImportActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    canRunOnWorker: false,
    actionType: "Octopus.Certificate.Import",
    summary: (properties, targetRolesAsCSV) => <CertificateImportActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    editSections: { default: CertificateImportActionEdit },
    docsLink: "importCertificate",
});
