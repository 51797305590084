import { logger } from "@octopusdeploy/logging";
import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import type { ActionTemplateParameterResource, ChannelResource, EnvironmentResource, GitRef, GitRefResource, LifecycleResource, ModifyProcessCommand, PersistenceSettings, ProcessResource, ProjectResource, ResourcesById, TagSetResource, TriggerResource, VariableSetResource, WorkerPoolResource, WorkerPoolsSummaryResource, } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, Permission, ProcessType, TenantedDeploymentMode, TriggerActionCategory, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { keyBy } from "lodash";
import { useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import type { FailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { NoPermission } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { loadLibraryVariableSetVariables } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { lastAccessedGitRef } from "~/areas/projects/context/LastAccessedGitRef";
import { PersistenceSettingsContextProvider } from "~/areas/projects/context/PersistenceSettingsContext";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import StringHelper from "~/utils/StringHelper";
import type { ScriptModule } from "../Process/Common/SideBar";
import type { ProjectStatus } from "../ProjectStatus/useProjectStatus";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { ProcessContextFormPage } from "./Contexts/ProcessContextFormPage";
import { ProcessController } from "./Contexts/ProcessController";
import { createDefaultFilter, ProcessQueryStringController } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import ProcessStepsLayout from "./ProcessStepsLayout";
import type { ProcessIdentifier } from "./types";
interface GlobalConnectedProps {
    isBuiltInWorkerEnabled?: boolean;
}
interface ProcessState extends DataBaseComponentState {
    lookups: ProcessStepsLayoutLoaderLookupData;
}
interface ProcessProps extends GlobalConnectedProps {
    project: ProjectResource;
    gitRef: GitRef | undefined;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: React.ReactElement;
    setShowK8sStatusItem?: (isKubernetesStep: boolean) => void;
    onProcessSave: (gitRef: GitRefResource | string | undefined) => Promise<void>;
    changeGitRef: (gitRef: string) => void;
    refreshModel: (gitRef?: GitRef) => Promise<boolean>;
    onValidationError: () => void;
    projectStatus?: ProjectStatus;
    processIdentifier: ProcessIdentifier;
    process: ProcessResource;
    reloadProcess: () => void;
    modifyProcess: (process: ModifyProcessCommand, gitRef: string | undefined) => Promise<void>;
    includedLibraryVariableSetIds: string[];
    releaseCreationPackageStepId?: string | undefined;
}
type ProcessPageProps = ProcessProps;
type ProcessStepsLayoutLoaderProps = ProcessProps & {
    project: Readonly<ProjectResource>;
    stepSlug?: string;
};
export interface ProcessStepsLayoutLoaderLookupData {
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    channelsById: ResourcesById<ChannelResource> | null;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
    machineRoles: string[];
    tagIndex: tenantTagsets.TagIndex;
    userOnboarding: UserOnboardingResource | null;
    workerPoolsSummary: WorkerPoolsSummaryResource;
    projectTriggers: TriggerResource[];
    processVariableSet: VariableSetResource | FailedPermissionCheck;
    libraryVariableSets: LoadedLibraryVariableSets[] | FailedPermissionCheck;
    templates: ActionTemplateParameterResource[];
}
export const emptyVariableSetResource = (spaceId: string) => ({
    Variables: [],
    OwnerId: "",
    ScopeValues: {
        Actions: [],
        Channels: [],
        Environments: [],
        Machines: [],
        Roles: [],
        TenantTags: [],
        Processes: [],
    },
    Version: 0,
    Id: "",
    Links: { Self: "" },
    SpaceId: spaceId,
});
export async function getProjectVariables(projectId: string, gitRef: GitRef | undefined, spaceId: string, persistenceSettings: PersistenceSettings): Promise<VariableSetResource | FailedPermissionCheck> {
    if (!isAllowed({ permission: Permission.VariableView, project: projectId, environment: "*", tenant: "*" })) {
        return NoPermission;
    }
    try {
        if (HasVariablesInGit(persistenceSettings)) {
            gitRef = gitRef || lastAccessedGitRef.getById(projectId, persistenceSettings);
            const variableSet = await repository.ProjectVariables.get(projectId, gitRef);
            //Not sure if this is how I should deal with this
            const sensitiveVariableSet = await repository.ProjectVariables.getSensitive(projectId, persistenceSettings);
            variableSet.Variables = variableSet.Variables.concat(sensitiveVariableSet.Variables);
            return variableSet;
        }
        else {
            return await repository.ProjectVariables.get(projectId);
        }
    }
    catch (error) {
        //Prevent a missing file error from breaking the page
        if (error.ErrorMessage && error.ErrorMessage.match(/The file '\.octopus\/variables\.ocl' on the '.*?' branch does not exist in the Git repository\./)) {
            logger.error("Failed to get project variables", error);
            return emptyVariableSetResource(spaceId);
        }
        throw error;
    }
}
export async function getLibraryVariables(includedLibraryVariableSets: string[]): Promise<LoadedLibraryVariableSets[] | FailedPermissionCheck> {
    if (!isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })) {
        return NoPermission;
    }
    return await loadLibraryVariableSetVariables(includedLibraryVariableSets);
}
const loadData = async (project: ProjectResource, processType: ProcessType, gitRef: GitRef | undefined): Promise<ProcessStepsLayoutLoaderLookupData> => {
    const hasLibraryVariableSetView = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" });
    const includedScriptModules = hasLibraryVariableSetView
        ? repository.LibraryVariableSets.all({
            contentType: VariableSetContentType.ScriptModule,
        }).then((sm) => sm.filter((x) => project.IncludedLibraryVariableSetIds.includes(x.Id)))
        : Promise.resolve([]);
    const environments = repository.Environments.all();
    const lifecyclePreview = processType === ProcessType.Deployment && isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId).then((x) => repository.Lifecycles.preview(x)) : Promise.resolve(null);
    const channelsById = processType === ProcessType.Deployment &&
        isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
        })
        ? repository.Projects.getChannels(project).then((c) => keyBy(c.Items, "Id"))
        : Promise.resolve(null);
    const tagSets = tenantTagsets.getAll();
    const workerPools = repository.WorkerPools.all();
    const triggers = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && isAllowed({ permission: Permission.TriggerView, project: project.Id })
        ? repository.Projects.getTriggers(project, 0, 30, undefined, TriggerActionCategory.Deployment)
        : Promise.resolve({ Items: [] });
    const userOnboarding = repository.UserOnboarding.getForCurrentUser();
    const projectVariables = getProjectVariables(project.Id, gitRef, project.SpaceId, project.PersistenceSettings);
    const libraryVariableSets = getLibraryVariables(project.IncludedLibraryVariableSetIds);
    return {
        environmentsById: keyBy(await environments, "Id"),
        includedScriptModules: await includedScriptModules,
        lifecyclePreview: await lifecyclePreview,
        channelsById: await channelsById,
        tagSets: await tagSets,
        workerPoolsById: keyBy(await workerPools, "Id"),
        machineRoles: await repository.MachineRoles.all(),
        tagIndex: await tenantTagsets.getTagIndex(),
        userOnboarding: (await userOnboarding)?.UserOnboardingResource ?? null,
        workerPoolsSummary: await repository.WorkerPools.summary(),
        projectTriggers: (await triggers).Items,
        processVariableSet: await projectVariables,
        libraryVariableSets: await libraryVariableSets,
        templates: project.Templates,
    };
};
function useIsBuiltInWorkerEnabled() {
    return useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
}
const PageLoader = ProcessContextFormPage<ProcessStepsLayoutLoaderLookupData>();
const ProcessStepsLayoutDataLoader: React.FC<ProcessPageProps> = (props) => {
    const { stepSlug } = useParams<{
        stepSlug?: string;
    }>();
    const isBuiltInWorkerEnabled = useIsBuiltInWorkerEnabled();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    return (<PageLoader processType={props.processIdentifier.type} title={props.processIdentifier.type === ProcessType.Deployment ? "Process" : runbookName} titleAccessory={props.titleAccessory} load={() => loadData(props.project, props.processIdentifier.type, props.gitRef)} renderWhenLoaded={(data) => <ProcessStepsLayoutLoader isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} initialLookups={data} stepSlug={stepSlug} {...props}/>}/>);
};
ProcessStepsLayoutDataLoader.displayName = "ProcessStepsLayoutDataLoader"
interface InitialLookupData {
    initialLookups: ProcessStepsLayoutLoaderLookupData;
}
function ProcessStepsLayoutLoader(props: ProcessStepsLayoutLoaderProps & InitialLookupData) {
    const [lookups, setLookups] = useState(props.initialLookups);
    const { doBusyTask, status: doBusyTaskStatus } = useLegacyDoBusyTask();
    const { isInProgress, errors } = useAggregateAPIOperationStatus(doBusyTaskStatus);
    const refreshLookupData = async () => {
        await doBusyTask(async () => setLookups(await loadData(props.project, props.processIdentifier.type, props.gitRef)));
    };
    const refreshVariables = async () => {
        await doBusyTask(async () => {
            setLookups({
                ...lookups,
                processVariableSet: await getProjectVariables(props.project.Id, props.gitRef, props.project.SpaceId, props.project.PersistenceSettings),
                libraryVariableSets: await getLibraryVariables(props.project.IncludedLibraryVariableSetIds),
            });
        });
    };
    return (<PersistenceSettingsContextProvider {...props.project.PersistenceSettings}>
            <ProcessController layoutActions={{ refreshLookupData, refreshVariables }} doBusyTask={doBusyTask} processIdentifier={props.processIdentifier} process={props.process} reloadProcess={props.reloadProcess} modifyProcess={props.modifyProcess}>
                {() => {
            return (<ProcessQueryStringController initialQueryFilter={createDefaultFilter()}>
                            {() => {
                    return (<ProcessStepsLayout lookups={lookups} gitRef={props.gitRef} gitRefResource={props.gitRefResource} titleAccessory={props.titleAccessory} errors={errors} busy={isInProgress} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={props.isBuiltInWorkerEnabled ?? false} stepSlug={props.stepSlug} setShowK8sStatusItem={props.setShowK8sStatusItem} isTenanted={props.project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted} onProcessSave={props.onProcessSave} changeGitRef={props.changeGitRef} refreshModel={props.refreshModel} onValidationError={props.onValidationError} projectStatus={props.projectStatus} includedLibraryVariableSetIds={props.includedLibraryVariableSetIds} releaseCreationPackageStepId={props.releaseCreationPackageStepId}/>);
                }}
                        </ProcessQueryStringController>);
        }}
            </ProcessController>
        </PersistenceSettingsContextProvider>);
}
// We export our data-loader wrapper, as that wraps out layout and supplies the necessary data to the layout.
export default ProcessStepsLayoutDataLoader;
