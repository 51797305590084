import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { Moment } from "moment";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "~/areas/projects/components/Releases/Deployments/NowOrLater/DatePicker/DatePicker";
import TimePicker from "~/areas/projects/components/Releases/Deployments/NowOrLater/TimePicker/TimePicker";
interface FreezeDateTimePickerProps {
    updateScheduledDatesOnModel(startDate: Moment, endDate: Moment): void;
    initialStartDate: Moment;
    initialEndDate: Moment;
    dateFormat?: string;
}
export const FreezeDateTimePicker = ({ updateScheduledDatesOnModel, initialStartDate, initialEndDate, dateFormat }: FreezeDateTimePickerProps) => {
    const currentTime = moment.now();
    const userTimezone = moment(currentTime).format("[GMT] Z");
    const userUtcOffset = moment(currentTime).utcOffset();
    const [startDate, setStartDate] = useState<Moment>(moment(initialStartDate));
    const [endDate, setEndDate] = useState<Moment>(moment(initialEndDate));
    const handleStartDateTimeChange = (newStart: Moment) => {
        // adjust end date to keep the diff the same
        const previousDiff = moment.duration(endDate.diff(startDate));
        const adjustedEndTime = moment(newStart).add(previousDiff);
        setStartDate(newStart);
        setEndDate(adjustedEndTime);
        updateScheduledDatesOnModel(newStart, adjustedEndTime);
    };
    const handleEndDateTimeChange = (newEnd: Moment) => {
        setEndDate(newEnd);
        updateScheduledDatesOnModel(startDate, newEnd);
    };
    const calculateDuration = (): string => {
        const duration = moment.duration(endDate.diff(startDate));
        const mins = duration.minutes();
        const hours = duration.hours();
        const days = Math.floor(duration.asDays());
        let result = mins === 1 ? "1 minute" : mins + " minutes";
        result = (hours !== 0 ? (hours === 1 ? "1 hour" : hours + " hours") + " and " : "") + result;
        result = (days !== 0 ? (days === 1 ? "1 day" : days + " days") + ", " : "") + result;
        return result;
    };
    return (<div>
            <h4>Time</h4>
            <div className={rowStyles}>
                <DatePicker date={startDate} onDateChanged={(newStartDate) => handleStartDateTimeChange(newStartDate)} utcOffset={userUtcOffset} dateFormat={dateFormat}/>
                <TimePicker time={startDate} onTimeChanged={(newStartTime) => handleStartDateTimeChange(newStartTime)}/>
                <span style={{ marginRight: "0px" }}>to</span>
                <TimePicker time={endDate} onTimeChanged={(newEndTime) => handleEndDateTimeChange(newEndTime)}/>
                <DatePicker date={endDate} onDateChanged={(newEndDate) => handleEndDateTimeChange(newEndDate)} utcOffset={userUtcOffset} dateFormat={dateFormat}/>
                {userTimezone}
            </div>

            <h4>Duration</h4>
            <div className={rowStyles}>{calculateDuration()}</div>
        </div>);
};
const rowStyles = css({
    display: "flex",
    alignItems: "center",
    paddingBottom: space[16],
    columnGap: space[12],
});
