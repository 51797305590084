import { css } from "@emotion/css";
import { space, text, themeTokens, borderWidth, letterSpacing, lineHeight, fontSize, fontWeight } from "@octopusdeploy/design-system-tokens";
export const timeline = css({
    marginTop: 0,
});
export const timelineRow = css({
    display: "grid",
    gridTemplateColumns: "181px 46px auto auto",
    alignContent: "center",
});
export const liveStatusRow = css({
    marginTop: "16px",
    display: "grid",
    gridTemplateColumns: "181px 46px auto auto",
    alignContent: "center",
});
export const timestamp = css({
    padding: space[16],
    borderRightStyle: "solid",
    borderRightColor: themeTokens.color.border.primary,
    borderRightWidth: borderWidth[1],
    font: text.regular.default.xSmall,
    letterSpacing: letterSpacing.wide,
    color: themeTokens.color.text.secondary,
});
export const liveStatusTimestamp = css({
    padding: space[16],
    font: text.regular.default.xSmall,
    letterSpacing: letterSpacing.wide,
    color: themeTokens.color.text.secondary,
});
export const statusIcon = css({
    gridColumn: 2,
    marginLeft: "-14px",
    alignContent: "inherit",
});
export const menu = css({
    gridColumn: 4,
    justifySelf: "end",
    marginRight: space[16],
    alignContent: "inherit",
});
export const statusColumn = css({
    gridColumn: 3,
    alignContent: "inherit",
});
export const deploymentStatus = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.medium,
    lineHeight: lineHeight.xSmall,
});
export const deploymentTitle = css({
    font: fontSize.base,
    fontWeight: fontWeight[700],
    letterSpacing: letterSpacing.wide,
    color: themeTokens.color.text.link.default,
});
export const historicalDeploymentTitle = css({
    font: fontSize.base,
    fontWeight: fontWeight[700],
    letterSpacing: letterSpacing.wide,
    color: themeTokens.color.text.primary,
});
export const loadMoreButton = css({
    display: "flex",
    justifyContent: "center",
    marginBottom: space[16],
});
