import { BookIcon, FeedbackIcon, InfoIcon, RoadmapIcon, SlackIcon } from "@octopusdeploy/design-system-components";
import { MessageIcon } from "@octopusdeploy/design-system-icons";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useLocalStorage from "~/hooks/useLocalStorage";
import styles from "./HelpSidebarResources.module.less";
import { ResourceLink } from "./ResourceLink";
export const viewportHeightBreakpoint = 900;
export function HelpSidebarResources() {
    /*
     * At a glance it might look like userPreferencesExpanded and resourcesExpanded represent the same piece of state
     * and could be combined. We need to keep them separate, because we're automatically collapsing the footer for small height
     * viewports, and this behaviour should not be treated as a user preference. We only update the preference in local storage
     * when the user has actually clicked the show {more,less} button.
     */
    const [userPreferenceExpanded, setUserPreferenceExpanded] = useLocalStorage<boolean | undefined>(`Octopus.HelpSidebar.Resources.Expanded`, undefined);
    const [resourcesExpanded, setResourcesExpanded] = React.useState<boolean | undefined>(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const customHelpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const customHelpSidebarSupportLinkLabel = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLinkLabel);
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        if (userPreferenceExpanded !== undefined) {
            setResourcesExpanded(userPreferenceExpanded);
        }
        else {
            setResourcesExpanded(viewportHeight >= viewportHeightBreakpoint);
        }
        setIsLoading(false);
    }, [userPreferenceExpanded]);
    if (isLoading) {
        return null;
    }
    return (<div className={styles.resources}>
            <div className={styles.resourcesHeader}>
                <h4 className={styles.resourcesHeading}>Resources</h4>
                <button className={styles.showHideButton} onClick={() => {
            setUserPreferenceExpanded(!resourcesExpanded);
            setResourcesExpanded(!resourcesExpanded);
        }}>
                    Show {resourcesExpanded ? "less" : "more"}
                </button>
            </div>
            <div className={styles.resourceLinks}>
                <ResourceLink icon={<FeedbackIcon />} label="Support" href="https://oc.to/support"/>
                <ResourceLink icon={<BookIcon />} label="Docs" href="https://oc.to/docs"/>
                <div className={styles.supportForumTop}>
                    <ResourceLink icon={<RoadmapIcon />} label="Roadmap" href="https://oc.to/roadmap"/>
                </div>
            </div>

            <div className={classNames(styles.moreResources)} style={resourcesExpanded ? { gridTemplateRows: "1fr" } : {}}>
                <div className={classNames(styles.moreResourcesInner, styles.fullWidthSm)}>
                    <div className={styles.supportForumBottom}>
                        <ResourceLink icon={<RoadmapIcon />} label="Roadmap" href="https://oc.to/roadmap"/>
                    </div>
                    <ResourceLink icon={<MessageIcon size={24}/>} label="Feedback" href="https://oc.to/feedback"/>
                    <div className={styles.fullWidthSm}>
                        <ResourceLink icon={<SlackIcon />} label="Octopus Community Slack" href="https://oc.to/community-slack"/>
                    </div>

                    {customHelpSidebarSupportLink && (<div className={styles.customSupportLink}>
                            <ResourceLink icon={<InfoIcon />} label={customHelpSidebarSupportLinkLabel || "Your organization's internal support"} href={customHelpSidebarSupportLink} info/>
                        </div>)}
                </div>
            </div>
        </div>);
}
