import { css, cx, keyframes } from "@emotion/css";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { getErrorsFromError, isErrors } from "~/components/DataBaseComponent/Errors";
import type { Errors } from "../DataBaseComponent";
import { ErrorPanel } from "../form";
interface SkeletonLoadingLayoutProps {
    errors: Errors | Error[] | undefined;
}
export function SkeletonLoadingLayout({ errors }: SkeletonLoadingLayoutProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const convertedErrors = errors === undefined ? undefined : isErrors(errors) ? [errors] : errors.map(getErrorsFromError);
    if (convertedErrors && convertedErrors.length > 0) {
        return (<>
                {convertedErrors.map((error, index) => (<ErrorPanel key={index} message={error.message} errors={error.errors} parsedHelpLinks={error.parsedHelpLinks} helpText={error.helpText} helpLink={error.helpLink} statusCode={error.statusCode}/>))}
            </>);
    }
    return (<header role="progressbar" className={cx(headerPrimaryStyles, { [headerPrimaryMobileStyles]: !isLargerThanIpad })}>
            <div className={breadcrumbContainerStyles}>
                <div className={cx(skeletonStyles, breadcrumbPlaceholderStyles)}/>
            </div>
            <div className={cx(headerContentStyles, { [headerContentMobileStyles]: !isLargerThanIpad })}>
                <div className={cx(skeletonStyles, titlePlaceholderStyles)}/>
                <div className={cx(skeletonStyles, pageActionsPlaceholderStyles)}/>
            </div>
        </header>);
}
const pulseKeyframes = keyframes({
    "0%": { opacity: 1 },
    "50%": { opacity: 0.24 },
    "100%": { opacity: 1 },
});
const skeletonStyles = css({
    // This animation has a 0.5s delay sa that a quick chain of redirects doesn't show a constantly resetting animation
    animation: `${pulseKeyframes} 2s linear 0.5s infinite`,
    borderRadius: borderRadius.small,
    backgroundColor: themeTokens.color.background.tertiary,
});
const headerPrimaryStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: `${space["16"]} ${space["32"]}`,
    gap: space["12"],
});
const breadcrumbContainerStyles = css({
    display: "flex",
    alignItems: "flex-end",
    height: "2rem",
});
const breadcrumbPlaceholderStyles = css({
    height: "1.25rem",
    width: "3.25rem",
});
const titlePlaceholderStyles = css({
    height: "2.25rem",
    width: "14.25rem",
});
const pageActionsPlaceholderStyles = css({
    height: "2.25rem",
    width: "8rem",
});
const headerPrimaryMobileStyles = css({
    padding: `${space["24"]} ${space["16"]} ${space["12"]}`,
});
const headerContentStyles = css({
    display: "flex",
    justifyContent: "space-between",
    gap: space["48"],
});
const headerContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "unset",
});
