import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionProperties, BlueprintResource, ProcessTemplateVersionResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, Select, Summary } from "~/components/form/index";
interface ProcessTemplateVersionSelectorProps {
    actionProperties: ActionProperties;
    doBusyTask: DoBusyTask;
    onProcessTemplateOptionSelected: (slug: string, version: string, commit: string) => void;
    isNew: boolean;
    currentProcessTemplate: BlueprintResource | undefined;
    busy?: boolean;
}
export function ProcessTemplateVersionSelector({ onProcessTemplateOptionSelected, actionProperties, doBusyTask, isNew, currentProcessTemplate, busy }: ProcessTemplateVersionSelectorProps) {
    const selectedSlug = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Slug"]);
    const selectedVersion = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Version"]);
    const selectedCommit = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Commit"]);
    const selectedProcessTemplateOption: ProcessTemplateOptionValue | undefined = selectedVersion && selectedSlug ? `${selectedSlug}|${selectedVersion}|${selectedCommit}` : undefined;
    const [processTemplateOptions, setProcessTemplateOptions] = useState<ProcessTemplateOption[]>([]);
    const isExpandedByDefault = isNew || !currentProcessTemplate || !busy;
    const onProcessTemplateOptionChanged = (value: string | undefined) => {
        if (value) {
            const { version, slug, commit } = extractSelectedProcessTemplate(value);
            onProcessTemplateOptionSelected(slug, version, commit);
        }
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        const options = await loadProcessTemplateVersions();
        setProcessTemplateOptions(options);
    }, []);
    const title = selectedProcessTemplateOption ? "Change process template" : "Select process template";
    const summary = selectedProcessTemplateOption ? Summary.placeholder("Change process template by selecting one from the list") : null;
    return (<ExpandableFormSection isExpandedByDefault={isExpandedByDefault} errorKey="selectedProcessTemplate" title={title} summary={summary}>
            <Select value={selectedProcessTemplateOption} allowClear={false} onChange={onProcessTemplateOptionChanged} items={processTemplateOptions} label="Select process template"/>
            {selectedProcessTemplateOption && (<>
                    <ProcessTemplateNotFoundCallout processTemplate={currentProcessTemplate} busy={busy}/>
                </>)}
        </ExpandableFormSection>);
}
type ProcessTemplateOptionValue = `${string}|${string}`;
function createProcessTemplateOptionValue(processTemplateVersion: ProcessTemplateVersionResource): ProcessTemplateOptionValue {
    return `${processTemplateVersion.ProcessTemplateSlug}|${processTemplateVersion.Version}|${processTemplateVersion.GitCommit}`;
}
function extractSelectedProcessTemplate(value: string): {
    slug: string;
    version: string;
    commit: string;
} {
    const extracted = value.split(`|`);
    return {
        slug: extracted[0],
        version: extracted[1],
        commit: extracted[2],
    };
}
interface ProcessTemplateOption {
    value: ProcessTemplateOptionValue;
    text: string;
}
async function loadProcessTemplateVersions(): Promise<ProcessTemplateOption[]> {
    const processTemplateVersions = await repository.Blueprints.getPublishedVersions();
    return processTemplateVersions.map((p) => ({ value: createProcessTemplateOptionValue(p), text: `${p.ProcessTemplateSlug} (${p.Version})` }));
}
interface ProcessTemplateNotFoundCalloutProps {
    processTemplate: BlueprintResource | undefined;
    busy?: boolean;
}
function ProcessTemplateNotFoundCallout({ processTemplate, busy }: ProcessTemplateNotFoundCalloutProps) {
    const shouldDisplay = !processTemplate && !busy;
    return (<>
            {shouldDisplay && (<Callout type={"danger"} title={"Process template not found"}>
                    <div>The selected version of the process template cannot be found.</div>
                </Callout>)}
        </>);
}
