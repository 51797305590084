import { useMemo } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import type { ProjectStatus } from "~/areas/projects/components/ProjectStatus/useProjectStatus";
import { session } from "~/clientInstance";
import { NotFound, stateFor404Redirect } from "~/components/NotFound/NotFound";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { AllPageRoutes } from "~/routing/AllPageRoutes";
import type { StandardLoaderContext } from "~/routing/pageRegistrations/StandardLoaderContext";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
interface RootRoutesProps extends RouteComponentProps<{}> {
    spaceContext: SpaceContext;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
    projectStatus?: ProjectStatus;
}
function RootRoutes({ spaceContext, scrollAreaRef, projectStatus }: RootRoutesProps) {
    const standardLoaderContext: StandardLoaderContext = useMemo(() => {
        if (session.currentUser === null) {
            throw new Error("Can't setup page loader context because the user is unexpectedly null");
        }
        return {
            currentUser: session.currentUser,
        };
    }, []);
    const standardPageContext: StandardPageContext = useMemo(() => {
        if (session.currentUser === null) {
            throw new Error("Can't setup page context because the user is unexpectedly null");
        }
        return {
            currentUser: session.currentUser,
        };
    }, []);
    return (<Route render={({ location }) => {
            if (location.state === stateFor404Redirect) {
                return <NotFound />;
            }
            return <AllPageRoutes spaceContext={spaceContext} loaderContext={standardLoaderContext} pageContext={standardPageContext} scrollAreaRef={scrollAreaRef} projectStatus={projectStatus}/>;
        }}/>);
}
export default withRouter(RootRoutes);
