import type { PopoverSequentialProps, PopoverPlacement } from "@octopusdeploy/design-system-components";
import { Beacon, PopoverSequential } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Action } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { SampleProjectTourStepPlacement, SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { useRequiredContext } from "~/hooks/index";
type SampleProjectTourPopoverPropsType = Omit<PopoverSequentialProps, "title" | "description" | "placement" | "infoCallout">;
interface SampleProjectTourPopoverProps extends SampleProjectTourPopoverPropsType {
    step: SampleProjectTourStep;
}
function SampleProjectTourPopover(props: SampleProjectTourPopoverProps) {
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const breakpointWidth = 1500;
    const isWideScreen = useMediaQuery({ query: `(min-width:${breakpointWidth}px)` });
    const breakpointHeight = 700;
    const isLongScreen = useMediaQuery({ query: `(min-height:${breakpointHeight}px)` });
    const getPopoverPlacement = (popoverPlacement: SampleProjectTourStepPlacement): PopoverPlacement => {
        const defaultPlacement = "bottom-start";
        const customPlacement = popoverPlacement?.placementWideLongScreen ?? defaultPlacement;
        if (isWideScreen && isLongScreen)
            return customPlacement;
        if (isWideScreen && !isLongScreen)
            return popoverPlacement?.placementWideShortScreen ?? customPlacement;
        if (!isWideScreen && isLongScreen)
            return popoverPlacement?.placementNarrowLongScreen ?? customPlacement;
        if (!isWideScreen && !isLongScreen)
            return popoverPlacement?.placementNarrowShortScreen ?? customPlacement;
        return defaultPlacement;
    };
    const popoverPlacement = getPopoverPlacement(props.step.popoverPlacement);
    const onBeaconClicked = () => {
        sampleProjectTourContext.dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click Beacon", Action.Resume, "Sample Project Tour Beacon");
        sampleProjectTourContext.focusTour();
    };
    return sampleProjectTourContext.tourState === "Unfocused" ? (<Beacon placement={popoverPlacement} anchorElement={props.anchorEl} open={true} beaconOffset={props.step.beaconOffset()} onClick={() => onBeaconClicked()}/>) : (<PopoverSequential title={props.step.title} description={props.step.content} placement={popoverPlacement} infoCallout={props.step.infoCallout} {...props}/>);
}
export default SampleProjectTourPopover;
