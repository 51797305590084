import { ActionExecutionLocation, type ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { getProcessTemplateIdFromActionProperties } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
function Summary(action: ActionProperties) {
    const processTemplates = useProcessBlueprintsFromContext();
    const processTemplateId = getProcessTemplateIdFromActionProperties(action);
    const processTemplate = processTemplates.find((b) => b.Id === processTemplateId);
    if (!processTemplateId) {
        return <div>Run a process template. No template has been selected.</div>;
    }
    if (!processTemplate) {
        const selectedGitRef = convertPropertyValueResourceToString(action["Octopus.Action.ProcessTemplate.Reference.Commit"]).substring(0, 7);
        const selectedSlug = convertPropertyValueResourceToString(action["Octopus.Action.ProcessTemplate.Reference.Slug"]);
        return (<div>
                Run a process template. The selected process template <strong>{selectedSlug}</strong> from <strong>{selectedGitRef}</strong> could not be found.
            </div>);
    }
    // Shouldn't ever show since we have a specific list item for blueprint steps that do exist, but using some sensible default.
    return <div>Run process template {processTemplate.Name}</div>;
}
// Register plugin to satisfy contract of displaying deployment process step
// Blueprint steps will be displayed in separate component
pluginRegistry.registerAction({
    actionType: "Octopus.Blueprint",
    summary: Summary,
    editSections: {
        default: () => <></>,
        top: () => <></>,
    },
    canHaveChildren: (step) => false,
    canBeChild: false,
    executionLocation: ActionExecutionLocation.TargetOrServer,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: [],
    },
});
