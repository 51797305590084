import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
interface DeploymentFreezeDataRecurrenCadenceCellProps {
    isRecurrent?: boolean;
    recurrenceCadence: string;
}
export const DeploymentFreezeDataRecurrenCadenceCell = ({ isRecurrent, recurrenceCadence }: DeploymentFreezeDataRecurrenCadenceCellProps) => {
    return (<DataTableRowColumn className={cellStyles}>
            <div className={innerCellStyles}>
                <ThirdPartyIcon className={iconStyles} iconType={isRecurrent ? ThirdPartyIconType.Sync : ThirdPartyIconType.SyncDisabled} color={isRecurrent ? themeTokens.color.icon.success : themeTokens.color.icon.secondary}/>
                <div className={cadenceStyles}>{recurrenceCadence}</div>
            </div>
        </DataTableRowColumn>);
};
const cadenceStyles = css({ padding: "1rem 0.5rem" });
const innerCellStyles = css({
    display: "flex",
    alignContent: "center",
});
const iconStyles = css({
    alignSelf: "center",
    transform: "rotate(90deg)",
});
const cellStyles = css({ width: "20%" });
