import type { Moment } from "moment";
import moment from "moment";
export default class DayOfWeekOrdinalHelper {
    getDayOfWeekOrdinal(startDate: Moment): number {
        return Math.ceil(moment(startDate).date() / 7);
    }
    getDayOfWeekOrdinalDescriptor(startDate: Moment): string {
        switch (this.getDayOfWeekOrdinal(startDate)) {
            case 1:
                return "1";
            case 2:
                return "2";
            case 3:
                return "3";
            case 4:
                return "4";
            default:
                return "L";
        }
    }
    getDayOfWeekOrdinalDescription(startDate: Moment): string {
        switch (this.getDayOfWeekOrdinal(startDate)) {
            case 1:
                return "first";
            case 2:
                return "second";
            case 3:
                return "third";
            case 4:
                return "fourth";
            default:
                return "last";
        }
    }
}
