import type { GitRef, Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import { GitRefSelectorInternal } from "~/areas/projects/components/GitRefSelector/GenericGitRefSelectorInternal";
import type { GitRefSelectorProps } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
export type GenericGitRefSelectorLoadedItems = {
    items: GitRefOption[];
    totalCount: number;
};
export type GenericGitRefSelectorProps = Omit<GitRefSelectorProps, "projectId" | "busy" | "items" | "totalItemCount" | "loadItems" | "searchItems" | "defaultBranch"> & {
    onError?: (error: Error) => void;
    onLoadItems(refType: RefTypes, selectedGitRef: GitRef | undefined): Promise<GenericGitRefSelectorLoadedItems>;
    onSearchItems(refType: RefTypes, searchTerm: string): Promise<GitRefOption[]>;
    defaultBranch: GitRef | string;
    permission: Permission;
};
interface GenericGitRefSelectorState extends DataBaseComponentState {
    items: GitRefOption[];
    totalCount: number;
}
export class GenericGitRefSelector extends DataBaseComponent<GenericGitRefSelectorProps, GenericGitRefSelectorState> {
    constructor(props: GenericGitRefSelectorProps) {
        super(props);
        this.state = {
            items: [],
            totalCount: 0,
        };
    }
    loadItems = (refType: RefTypes, selectedGitRef: GitRef | undefined): void => {
        this.doBusyTask(async () => {
            try {
                const { items, totalCount } = await this.props.onLoadItems(refType, selectedGitRef);
                this.setState({
                    ...this.state,
                    items,
                    totalCount,
                });
            }
            catch (e) {
                if (this.props.onError)
                    this.props.onError(e);
            }
        });
    };
    searchItems = async (refType: RefTypes, searchTerm: string): Promise<GitRefOption[]> => {
        try {
            return await this.props.onSearchItems(refType, searchTerm);
        }
        catch (e) {
            if (this.props.onError)
                this.props.onError(e);
            return [];
        }
    };
    render() {
        return <GitRefSelectorInternal items={this.state.items} totalItemCount={this.state.totalCount} loadItems={this.loadItems} busy={this.state.busy} searchItems={this.searchItems} {...this.props}/>;
    }
    static displayName = "GenericGitRefSelector";
}
