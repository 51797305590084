/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { PopoverBasicWithTrigger } from "@octopusdeploy/design-system-components";
import { fontWeight, space } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { ReactNode } from "react";
import React from "react";
import Chip from "~/components/Chips/Chip";
import { DeploymentTargetsTable } from "../Common/DeploymentTargetsTable";
import { createConnectedDeploymentTargetsPopoverOpenedEvent } from "./ConnectedDeploymentTargetsPopover.analytics";
import { UnassignedTargetTagsWarningCallout } from "./UnassignedTargetTagsWarningCallout";
interface ConnectedDeploymentTargetsPopoverProps {
    targetTags: string[];
    triggerElement: ReactNode;
    deploymentTargetsTotal: number;
    deploymentTargets: DeploymentTargetResource[];
    environments: EnvironmentResource[];
    projectId: string;
    clearWarnings: () => void;
}
export const getUnassignedTargetTags = (targetTags: string[], deploymentTargets: DeploymentTargetResource[]) => {
    return targetTags.filter((tag) => {
        const isTargetTagAssigned = deploymentTargets.some((target) => target.Roles.includes(tag));
        return !isTargetTagAssigned;
    });
};
export const ConnectedDeploymentTargetsPopover = (props: ConnectedDeploymentTargetsPopoverProps) => {
    const trackEvent = useTrackEvent();
    const onOpen = () => trackEvent(createConnectedDeploymentTargetsPopoverOpenedEvent({ stepHasUnassignedTargetTags: unassignedTargetTags.length > 0 }));
    const unassignedTargetTags = getUnassignedTargetTags(props.targetTags, props.deploymentTargets);
    const description = (<div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.titleStyles}>Deployment Targets</div>
                <div className={styles.chipStyles}>
                    <Chip noMargin={true} noTooltip={true}>
                        <span className={styles.chipTextStyles}>{props.deploymentTargetsTotal}</span>
                    </Chip>
                </div>
            </div>
            <UnassignedTargetTagsWarningCallout unassignedTargetTags={unassignedTargetTags} clearWarnings={props.clearWarnings} projectId={props.projectId}/>
            {props.deploymentTargetsTotal > 0 && props.environments && props.deploymentTargets && <div>Based on the selected target tags, this step can deploy to:</div>}
            {props.deploymentTargetsTotal > 0 && props.environments && props.deploymentTargets && (<DeploymentTargetsTable targetTags={props.targetTags} deploymentTargetsTotal={props.deploymentTargetsTotal} deploymentTargets={props.deploymentTargets} environments={props.environments} showTargetTagColumn={true} targetTagColumnSecondaryTitle="(used by this step)" size="large"/>)}
        </div>);
    return <PopoverBasicWithTrigger description={description} placement={"bottom-start"} onOpen={onOpen} width={"extra-wide"} triggerElelment={props.triggerElement}/>;
};
const styles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    }),
    titleContainer: css({
        display: "flex",
        alignItems: "center",
    }),
    titleStyles: css({
        fontWeight: fontWeight["700"],
    }),
    chipStyles: css({
        marginLeft: space["8"],
        ".MuiChip-label": {
            paddingRight: space[8],
            paddingLeft: space[8],
        },
    }),
    chipTextStyles: css({
        fontWeight: 600,
    }),
};
