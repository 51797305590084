import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function HealthyIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#00874D"/>
            <path d="M12.2775 26.212L22.4419 35.7014C22.8637 36.0951 23.4206 36.3145 24 36.3145C24.5794 36.3145 25.1362 36.0951 25.5581 35.7014L35.7225 26.212C37.4325 24.6201 38.4 22.387 38.4 20.0526V19.7264C38.4 15.7945 35.5594 12.442 31.6837 11.7951C29.1187 11.3676 26.5087 12.2058 24.675 14.0395L24 14.7145L23.325 14.0395C21.4912 12.2058 18.8812 11.3676 16.3162 11.7951C12.4406 12.442 9.59998 15.7945 9.59998 19.7264V20.0526C9.59998 22.387 10.5675 24.6201 12.2775 26.212Z" fill="white"/>
        </svg>);
}
