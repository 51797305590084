/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ActionButton, Callout, Checkbox, List, Tooltip } from "@octopusdeploy/design-system-components";
import type { LifecycleResource, PhaseResource, RetentionPeriod, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { isNotNull } from "@octopusdeploy/type-utils";
import cn from "classnames";
import * as React from "react";
import AddEnvironment from "~/areas/library/components/Lifecycle/AddEnvironment/AddEnvironment";
import ChangePhaseProgression from "~/areas/library/components/Lifecycle/ChangePhaseProgression/ChangePhaseProgression";
import SelectRetentionPolicy from "~/areas/library/components/Lifecycle/RetentionPolicy/SelectRetentionPolicy";
import ActionList from "~/components/ActionList";
import BorderedListItem, { ListItemContentWithButtons } from "~/components/BorderedListItem";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import ExternalLink from "~/components/Navigation/ExternalLink";
import iconStyles from "~/components/PhaseStatusIcon/style.module.less";
import { Note, Text } from "~/components/form";
import envCount from "../LifecycleMap/envCount";
import { RetentionPolicyText } from "../RetentionPolicy/RetentionPolicySummary";
import styles from "./style.module.less";
interface PhaseProps {
    phase: PhaseResource;
    index: number;
    lifecycle: LifecycleResource;
    environmentsById: EnvironmentLookup;
    onPhaseDeleteClick(): void;
    onPhaseNameChange(value: string): void;
    onAutomaticEnvironmentDeleteClick: DeleteEnvironmentCallback;
    onOptionalEnvironmentDeleteClick: DeleteEnvironmentCallback;
    onAddEnvironment: AddEnvironmentCallback;
    onPhaseProgressionChange(isOptional: boolean, minimumEnvironments: number): void;
    onPhasePriorityChange(isPriority: boolean): void;
    onChangeRetentionPolicy: ChangeRetentionPolicyCallback;
}
function getAllPhasesUpToPhase(phases: PhaseResource[], phase: PhaseResource) {
    return phases.slice(0, phases.indexOf(phase));
}
function Phase({ phase, lifecycle, onChangeRetentionPolicy, onAutomaticEnvironmentDeleteClick, onOptionalEnvironmentDeleteClick, environmentsById, onAddEnvironment, onPhaseDeleteClick, onPhaseProgressionChange, onPhaseNameChange, onPhasePriorityChange, }: PhaseProps) {
    return (<div>
            <PhaseSection>
                <Text className={styles.cardTitle} id="phaseName" value={phase.Name} onChange={onPhaseNameChange} label="Phase name" style={{ width: "100%" }} autoFocus={true}/>
            </PhaseSection>
            <PhaseSection>
                <PhaseEnvironments lifecycle={lifecycle} phase={phase} environmentsById={environmentsById} onAddEnvironment={onAddEnvironment} onAutomaticEnvironmentDeleteClick={onAutomaticEnvironmentDeleteClick} onOptionalEnvironmentDeleteClick={onOptionalEnvironmentDeleteClick}/>
            </PhaseSection>
            <PhaseSection>
                <h4>Required to progress</h4>
                <div>
                    <ChangePhaseProgression onOk={onPhaseProgressionChange} environmentCount={envCount(phase)} isOptionalPhase={phase.IsOptionalPhase} minimumEnvironmentsBeforePromotion={phase.MinimumEnvironmentsBeforePromotion} hasAutomaticDeploymentTargets={phase.AutomaticDeploymentTargets.length > 0}/>
                </div>
            </PhaseSection>
            <PhaseSection>
                <PhaseReleaseRetention lifecycle={lifecycle} phase={phase} onChangeRetentionPolicy={onChangeRetentionPolicy}/>
            </PhaseSection>
            {isFeatureToggleEnabled("LifecyclePhasePriorityDeploymentFeatureToggle") && (<PhaseSection>
                    <h4>Phase priority</h4>
                    <div>
                        <Callout title="Early Access" type={"warning"}>
                            This feature is still in development and may be removed pending feedback. We'd love to hear <ExternalLink href={"priority-deployments-feedback"}> your feedback</ExternalLink> after using the Priority Deployments feature.
                        </Callout>
                        <Checkbox label="Priority" value={phase.IsPriorityPhase} onChange={onPhasePriorityChange}/>
                        <Note>
                            Deployments will be prioritized in this phase according to <ExternalLink href="LifecyclePhasesWithPriority">phase priority</ExternalLink>.
                        </Note>
                    </div>
                </PhaseSection>)}
        </div>);
}
type AddEnvironmentCallback = (environmentId: EnvironmentId, automatic: boolean) => void;
type DeleteEnvironmentCallback = (index: number) => void;
interface PhaseEnvironmentsProps {
    lifecycle: LifecycleResource;
    phase: PhaseResource;
    environmentsById: EnvironmentLookup;
    onAddEnvironment: AddEnvironmentCallback;
    onAutomaticEnvironmentDeleteClick: DeleteEnvironmentCallback;
    onOptionalEnvironmentDeleteClick: DeleteEnvironmentCallback;
}
function PhaseEnvironments({ phase, lifecycle, environmentsById, onAddEnvironment, onOptionalEnvironmentDeleteClick, onAutomaticEnvironmentDeleteClick }: PhaseEnvironmentsProps) {
    return (<>
            <h4>Environments</h4>
            <div>
                {envCount(phase) === 0 && <PhaseWithNoEnvironments environmentsById={environmentsById} onAddEnvironment={onAddEnvironment} lifecycle={lifecycle}/>}
                {envCount(phase) > 0 && (<PhaseWithEnvironments environmentsById={environmentsById} onAddEnvironment={onAddEnvironment} phase={phase} lifecycle={lifecycle} onAutomaticEnvironmentDeleteClick={onAutomaticEnvironmentDeleteClick} onOptionalEnvironmentDeleteClick={onOptionalEnvironmentDeleteClick}/>)}
            </div>
        </>);
}
interface PhaseWithNoEnvironmentsProps {
    lifecycle: LifecycleResource;
    environmentsById: EnvironmentLookup;
    onAddEnvironment: AddEnvironmentCallback;
}
function PhaseWithNoEnvironments({ lifecycle, environmentsById, onAddEnvironment }: PhaseWithNoEnvironmentsProps) {
    return (<>
            <strong className={styles.anyEnvironment}>Any environment</strong>
            <p>
                When the release is in this phase, any environment {lifecycle.Phases.length > 1 && <span>that is not explicitly named in another phase</span>} can be deployed to. Use the <em>Add environment</em> button to limit the phase to a
                specific list of environments.
            </p>
            <ActionList actions={[<AddPhaseEnvironmentButton onAddEnvironment={onAddEnvironment} environmentsById={environmentsById} lifecycle={lifecycle}/>]}/>
        </>);
}
type PhaseWithEnvironmentsProps = {
    lifecycle: LifecycleResource;
    phase: PhaseResource;
    onAutomaticEnvironmentDeleteClick: DeleteEnvironmentCallback;
    onOptionalEnvironmentDeleteClick: DeleteEnvironmentCallback;
    environmentsById: EnvironmentLookup;
    onAddEnvironment: AddEnvironmentCallback;
};
function PhaseWithEnvironments({ lifecycle, phase, environmentsById, onAddEnvironment, onAutomaticEnvironmentDeleteClick, onOptionalEnvironmentDeleteClick }: PhaseWithEnvironmentsProps) {
    return (<>
            <ActionList actions={[<AddPhaseEnvironmentButton onAddEnvironment={onAddEnvironment} environmentsById={environmentsById} lifecycle={lifecycle}/>]}/>
            <PhaseEnvironmentList phase={phase} onAutomaticEnvironmentDeleteClick={onAutomaticEnvironmentDeleteClick} onOptionalEnvironmentDeleteClick={onOptionalEnvironmentDeleteClick} environmentsById={environmentsById}/>
        </>);
}
interface PhaseReleaseRetentionProps {
    phase: PhaseResource;
    lifecycle: LifecycleResource;
    onChangeRetentionPolicy: ChangeRetentionPolicyCallback;
}
function PhaseReleaseRetention({ phase, lifecycle, onChangeRetentionPolicy }: PhaseReleaseRetentionProps) {
    if (isPhaseWithCustomReleaseRetentionPolicy(phase)) {
        return <PhaseSpecificReleaseRetention phase={phase} onChangeRetentionPolicy={onChangeRetentionPolicy}/>;
    }
    return <InheritedPhaseReleaseRetention phase={phase} lifecycle={lifecycle} onChangeRetentionPolicy={onChangeRetentionPolicy}/>;
}
type ChangeRetentionPolicyCallback = (releaseRetentionPolicy: RetentionPeriod | null, tentacleRetentionPolicy: RetentionPeriod | null) => void;
interface InheritedPhaseReleaseRetention {
    phase: PhaseResource;
    lifecycle: LifecycleResource;
    onChangeRetentionPolicy: ChangeRetentionPolicyCallback;
}
function InheritedPhaseReleaseRetention({ phase, lifecycle, onChangeRetentionPolicy }: InheritedPhaseReleaseRetention) {
    return (<>
            <h4>Retention policy (inherited)</h4>
            <div>
                <p>
                    <RetentionPolicyText releaseRetentionPolicy={getReleaseRetentionPolicy(lifecycle, phase)} tentacleRetentionPolicy={getTentacleRetentionPolicy(lifecycle, phase)}/>
                </p>
                <ActionList actions={[<ActionButton label={"Override retention policy"} onClick={() => onChangeRetentionPolicy(getReleaseRetentionPolicy(lifecycle, phase), getTentacleRetentionPolicy(lifecycle, phase))}/>]}/>
            </div>
        </>);
}
function isPhaseWithCustomReleaseRetentionPolicy(resource: PhaseResource): resource is PhaseResourceWithCustomReleaseRetentionPolicy {
    return Boolean(resource.ReleaseRetentionPolicy);
}
interface PhaseResourceWithCustomReleaseRetentionPolicy extends PhaseResource {
    ReleaseRetentionPolicy: RetentionPeriod;
}
interface PhaseSpecificReleaseRetention {
    phase: PhaseResourceWithCustomReleaseRetentionPolicy;
    onChangeRetentionPolicy: ChangeRetentionPolicyCallback;
}
function PhaseSpecificReleaseRetention({ phase, onChangeRetentionPolicy }: PhaseSpecificReleaseRetention) {
    return (<>
            <h4>Retention policy</h4>
            <div>
                <SelectRetentionPolicy releaseRetentionPolicy={phase.ReleaseRetentionPolicy} tentacleRetentionPolicy={phase.TentacleRetentionPolicy!} onOk={(r, t) => onChangeRetentionPolicy(r, t)}/>
            </div>
            <ActionList actions={[<ActionButton label={"Reset retention policy"} onClick={() => onChangeRetentionPolicy(null, null)}/>]}/>
        </>);
}
function getTentacleRetentionPolicy(lifecycle: LifecycleResource, currentPhase: PhaseResource) {
    const allPhasesUpToThisPhase = getAllPhasesUpToPhase(lifecycle.Phases, currentPhase);
    const inheritedTentacleRetentionPolicy = lifecycle.TentacleRetentionPolicy;
    return allPhasesUpToThisPhase.reverse().find((x) => isNotNull(x.TentacleRetentionPolicy))?.TentacleRetentionPolicy ?? inheritedTentacleRetentionPolicy;
}
function getReleaseRetentionPolicy(lifecycle: LifecycleResource, currentPhase: PhaseResource) {
    const allPhasesUpToThisPhase = getAllPhasesUpToPhase(lifecycle.Phases, currentPhase);
    const inheritedTentacleRetentionPolicy = lifecycle.ReleaseRetentionPolicy;
    return allPhasesUpToThisPhase.reverse().find((x) => isNotNull(x.ReleaseRetentionPolicy))?.ReleaseRetentionPolicy ?? inheritedTentacleRetentionPolicy;
}
interface AddPhaseEnvironmentButtonProps {
    onAddEnvironment(environmentId: string, automatic: boolean): void;
    environmentsById: EnvironmentLookup;
    lifecycle: LifecycleResource;
}
function AddPhaseEnvironmentButton({ onAddEnvironment, environmentsById, lifecycle }: AddPhaseEnvironmentButtonProps) {
    return (<OpenDialogButton label="Add Environment">
            <AddEnvironment onOk={onAddEnvironment} environmentsById={environmentsById} lifecycle={lifecycle}/>
        </OpenDialogButton>);
}
interface PhaseEnvironmentListProps {
    phase: PhaseResource;
    onAutomaticEnvironmentDeleteClick: (index: number) => void;
    onOptionalEnvironmentDeleteClick: (index: number) => void;
    environmentsById: EnvironmentLookup;
}
function PhaseEnvironmentList({ phase, onAutomaticEnvironmentDeleteClick, onOptionalEnvironmentDeleteClick, environmentsById }: PhaseEnvironmentListProps) {
    return (<>
            <List items={phase.AutomaticDeploymentTargets} renderRow={({ item: targetEnvironmentId, index }) => <AutomaticEnvironmentTargetListItem environmentsById={environmentsById} environment={targetEnvironmentId} onRemove={() => onAutomaticEnvironmentDeleteClick(index)}/>} rowKey={(item) => item} includeRowGaps includeVerticalPadding/>
            <List items={phase.OptionalDeploymentTargets} renderRow={({ item: targetEnvironment, index }) => (<OptionalEnvironmentTargetListItem environmentsById={environmentsById} environment={targetEnvironment} onRemove={() => onOptionalEnvironmentDeleteClick(index)} isOptionalPhase={phase.IsOptionalPhase}/>)} rowKey={(item) => item} includeRowGaps includeVerticalPadding/>
        </>);
}
interface AutomaticEnvironmentTargetListItem {
    environmentsById: EnvironmentLookup;
    environment: EnvironmentId;
    onRemove: () => void;
}
function AutomaticEnvironmentTargetListItem({ environment, onRemove, environmentsById }: AutomaticEnvironmentTargetListItem) {
    return (<BorderedListItem>
            <ListItemContentWithButtons buttons={<IconButtonWithTooltip onClick={onRemove} toolTipContent="Remove this environment" icon={"Cancel"}/>} content={<div>
                        <span className={cn(iconStyles.phaseIcon, iconStyles.automatic)} title="When the release enters this phase, this environment will be automatically deployed to"/>
                        <EnvironmentName environmentsById={environmentsById} environmentId={environment}/>
                    </div>}/>
        </BorderedListItem>);
}
interface OptionalEnvironmentTargetListItemProps {
    environmentsById: EnvironmentLookup;
    environment: EnvironmentId;
    onRemove: () => void;
    isOptionalPhase: boolean;
}
function OptionalEnvironmentTargetListItem({ environment, onRemove, environmentsById, isOptionalPhase }: OptionalEnvironmentTargetListItemProps) {
    return (<BorderedListItem>
            <ListItemContentWithButtons buttons={<IconButtonWithTooltip onClick={onRemove} toolTipContent="Remove this environment" icon="Cancel"/>} content={<div>
                        <span className={cn(iconStyles.phaseIcon, { [iconStyles.skipped]: isOptionalPhase })} title="When the release enters this phase, this environment can be manually deployed to"/>
                        <EnvironmentName environmentId={environment} environmentsById={environmentsById}/>
                    </div>}/>
        </BorderedListItem>);
}
function PhaseSection({ children }: React.PropsWithChildren<{}>) {
    return <div className={styles.sectionControl}>{children}</div>;
}
type EnvironmentId = string;
type EnvironmentLookup = Record<EnvironmentId, EnvironmentResource>;
function EnvironmentName({ environmentId, environmentsById }: {
    environmentId: EnvironmentId;
    environmentsById: EnvironmentLookup;
}) {
    const text = "Missing Resource";
    // There are cases where this isn't right, but will just have 1 message to simplify
    // e.g. Variable Snapshots will show this for deleted environments, it's not an issue in that case, but good to show this.
    const description = `The environment document '${environmentId}' referenced by this phase is no longer available or ` +
        "you do not have permissions to see this resource. Please check with your Octopus Administrator regarding your " +
        "permissions. If you believe the resource is missing (and this is not permissions-related), please let Octopus " +
        "support know so that we can prevent this from happening in the future.";
    return (<>
            {environmentsById[environmentId] ? (environmentsById[environmentId].Name) : (<Tooltip content={description}>
                    <span className={styles.missingResource}>{text}</span>
                </Tooltip>)}
        </>);
}
export default Phase;
