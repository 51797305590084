import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import OkDialogLayout from "../DialogLayout/OkDialogLayout";
interface ActionTemplateParameterSorterProps {
    title: string;
    parameters: ActionTemplateParameterResource[];
    onOk(parameters: ActionTemplateParameterResource[]): void;
    okButtonLabel?: string;
}
interface SortableActionTemplateParameterResource extends ActionTemplateParameterResource {
    DbId: string;
}
interface ActionTemplateParameterSorterState {
    sortedParameters: SortableActionTemplateParameterResource[];
}
export class ActionTemplateParameterSorter extends BaseComponent<ActionTemplateParameterSorterProps, ActionTemplateParameterSorterState> {
    constructor(props: ActionTemplateParameterSorterProps) {
        super(props);
        this.state = {
            sortedParameters: props.parameters.map((p) => ({
                ...p,
                DbId: p.Id,
                Id: Math.random() + "",
            })),
        };
    }
    render() {
        return (<OkDialogLayout title={this.props.title} onOkClick={this.onOk} okButtonLabel={this.props.okButtonLabel} busy={false} errors={null}>
                <SortableList items={this.state.sortedParameters} onOrderChanged={(sortedParameters) => this.setState({ sortedParameters })}/>
            </OkDialogLayout>);
    }
    private onOk = () => {
        this.props.onOk(this.state.sortedParameters.map((sp) => ({
            ...sp,
            Id: sp.DbId,
        })));
        return true;
    };
    static displayName = "ActionTemplateParameterSorter";
}
