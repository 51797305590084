import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import type { DeploymentStepResource } from "@octopusdeploy/octopus-server-client";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
export function retrieveStepsWithKubernetesActions(deploymentStepResources: DeploymentStepResource[], includeHelmSteps: boolean): StepWithKubernetesAction[] {
    const helmActionType = "Octopus.HelmChartUpgrade";
    const containerActionTypes = [
        "Octopus.KubernetesDeployContainers",
        "Octopus.KubernetesDeployRawYaml",
        "Octopus.KubernetesDeployService",
        "Octopus.KubernetesDeployIngress",
        "Octopus.KubernetesDeployConfigMap",
        "Octopus.KubernetesDeploySecret",
        "Octopus.Kubernetes.Kustomize",
        "kustomize",
    ];
    if (includeHelmSteps) {
        containerActionTypes.push(helmActionType);
    }
    return deploymentStepResources.reduce<StepWithKubernetesAction[]>((steps, step, stepIndex) => {
        for (let actionIdx = 0; actionIdx < step.Actions.length; actionIdx++) {
            const action = step.Actions[actionIdx];
            if (!action.IsDisabled && containerActionTypes.includes(action.ActionType)) {
                let stepName: string = "";
                if (step.Actions.length > 1) {
                    stepName = `Step ${stepIndex + 1}.${actionIdx + 1}: ${action.Name}`;
                }
                else {
                    stepName = `Step ${stepIndex + 1}: ${step.Name}`;
                }
                let isAtomicHelmStep = false;
                if (action.ActionType === helmActionType) {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const helmUpgradeProperties = action.Properties as unknown as HelmChartUpgradeProperties;
                    const additionalArgs = helmUpgradeProperties["Octopus.Action.Helm.AdditionalArgs"];
                    if (additionalArgs) {
                        isAtomicHelmStep = additionalArgs.indexOf("--atomic") !== -1;
                    }
                }
                steps.push({
                    stepName,
                    isAtomicHelmStep,
                });
            }
        }
        return steps;
    }, []);
}
