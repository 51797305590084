import { Tooltip } from "@octopusdeploy/design-system-components";
import type { GetLiveStatusRollupBffResponse } from "@octopusdeploy/octopus-server-client/src/resources/getLiveStatusRollupBffResponse";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import DateFormatter from "~/utils/DateFormatter";
import { LiveStatusIcon } from "../../../Observability/LiveStatusIcon";
import { deploymentTitle, statusColumn, statusIcon, liveStatusRow, deploymentStatus, liveStatusTimestamp } from "./styles";
interface LiveStatusRowProps {
    data: GetLiveStatusRollupBffResponse;
}
export const LiveStatusRow = (props: LiveStatusRowProps) => {
    const getTitle = () => {
        const content = (<Tooltip content={DateFormatter.dateToShortFormat(props.data.LastUpdated)} position="top">
                <span className={deploymentTitle}>Live Status</span>
            </Tooltip>);
        const pageUrl = props.data.TenantId === null
            ? links.untenantedLiveStatusPage.generateUrl({ spaceId: props.data.SpaceId, projectSlug: props.data.ProjectId, environmentId: props.data.EnvironmentId })
            : links.tenantedLiveStatusPage.generateUrl({ spaceId: props.data.SpaceId, projectSlug: props.data.ProjectId, environmentId: props.data.EnvironmentId, tenantId: props.data.TenantId });
        return <InternalLink to={pageUrl}>{content}</InternalLink>;
    };
    return (<div className={liveStatusRow}>
            <div className={liveStatusTimestamp}>
                <div>Last Updated</div>
                <div>{DateFormatter.momentAgo(props.data.LastUpdated.toString())}</div>
            </div>
            <div className={statusIcon}>
                <LiveStatusIcon status={props.data.Status} size={28}/>
            </div>
            <div className={statusColumn}>
                {getTitle()}
                <div className={deploymentStatus}>
                    <em className="fa-solid fa-bolt" style={{ marginRight: "4px" }}/> {props.data.Status}
                </div>
            </div>
        </div>);
};
