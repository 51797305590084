/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import { RetentionUnit, type RunbookRetentionPeriod } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import React from "react";
import ComponentRow from "~/components/ComponentRow";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Text, Summary, ExpandableFormSection, minimum, Note, Select } from "~/components/form";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import ParseHelper from "~/utils/ParseHelper";
interface RunbookRetentionPolicyControlInternalProps {
    retentionPolicy: RunbookRetentionPeriod;
    onRetentionPolicyChange(policy: RunbookRetentionPeriod): void;
    isGitRunbooksMigrationFeatureToggleEnabled: boolean;
}
class RunbookRetentionPolicyControlInternal extends React.Component<RunbookRetentionPolicyControlInternalProps> {
    retentionPolicySummary() {
        let result = "";
        if (this.props.retentionPolicy.ShouldKeepForever) {
            result = "Keep all";
        }
        else {
            if (this.props.retentionPolicy.Unit === RetentionUnit.Days) {
                result = this.props.retentionPolicy.QuantityToKeep === 1 ? "Keep 1 day per environment" : "Keep " + this.props.retentionPolicy.QuantityToKeep + " days per environment";
            }
            else {
                result = this.props.retentionPolicy.QuantityToKeep === 1 ? "Keep 1 run per environment" : "Keep " + this.props.retentionPolicy.QuantityToKeep + " runs per environment";
            }
        }
        return Summary.summary(result);
    }
    updateRetentionPolicy(updater: (policy: RunbookRetentionPeriod) => void) {
        const policy = cloneDeep(this.props.retentionPolicy);
        updater(policy);
        this.props.onRetentionPolicyChange(policy);
    }
    render() {
        return (<ExpandableFormSection errorKey="retentionPolicy" title="Retention Policy" summary={this.retentionPolicySummary()} help="Change the retention policy.">
                <BooleanRadioButtonGroup value={this.props.retentionPolicy.ShouldKeepForever} onChange={(val) => this.updateRetentionPolicy((pol) => {
                pol.ShouldKeepForever = val;
                pol.QuantityToKeep = val ? 0 : 100;
            })} title="How many runs should we keep per environment?">
                    <BooleanRadioButton value={true} label="Keep all"/>
                    <BooleanRadioButton value={false} label="Keep a limited number" isDefault={true}/>
                </BooleanRadioButtonGroup>
                {!this.props.retentionPolicy.ShouldKeepForever && (<ComponentRow>
                        <span>
                            <Text type="number" min={1} value={this.props.retentionPolicy.QuantityToKeep ? this.props.retentionPolicy.QuantityToKeep.toString() : ""} onChange={(x) => this.updateRetentionPolicy((pol) => (pol.QuantityToKeep = ParseHelper.safeParseInt(x)))} validate={minimum("Please provide a value greater than zero.", 1)}/>
                        </span>
                        {this.props.isGitRunbooksMigrationFeatureToggleEnabled && (<span>
                                <Select value={this.props.retentionPolicy.Unit} onChange={(val) => this.updateRetentionPolicy((pol) => (pol.Unit = val as RetentionUnit))} items={[
                        { value: RetentionUnit.Days, text: "Days" },
                        { value: RetentionUnit.Items, text: "Runs" },
                    ]}/>
                            </span>)}
                    </ComponentRow>)}
                <br />
                <Note>
                    Retention policies dictate how long runs are kept for. For more information please see <ExternalLink href="RetentionPolicies">retention policies</ExternalLink> documentation.
                </Note>
            </ExpandableFormSection>);
    }
    static displayName = "RunbookRetentionPolicyControlInternal";
}
interface RunbookRetentionPolicyControlProps {
    retentionPolicy: RunbookRetentionPeriod;
    onRetentionPolicyChange(policy: RunbookRetentionPeriod): void;
}
export function RunbookRetentionPolicyControl(props: RunbookRetentionPolicyControlProps) {
    const isGitRunbooksMigrationFeatureToggle = useOctopusFeatureToggle("git-runbooks-migration", false);
    return <RunbookRetentionPolicyControlInternal {...props} isGitRunbooksMigrationFeatureToggleEnabled={isGitRunbooksMigrationFeatureToggle}/>;
}
