import type { GitRef, GitRefResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DeploymentProcessPageProps } from "~/areas/projects/components/Process/DeploymentProcessListPage";
import useDeploymentProcessState from "~/areas/projects/components/Process/useDeploymentProcessState";
import type { SetRequiresKubernetesTarget } from "../ProjectLayout/ProjectLayout";
import type { ProjectStatus } from "../ProjectStatus/useProjectStatus";
import ProcessStepsLayoutLoader from "./ProcessStepsLayoutLoader";
type DeploymentProcessStepsPageProps = DeploymentProcessPageProps & {
    setShowK8sStatusItem?: SetRequiresKubernetesTarget;
    projectStatus?: ProjectStatus;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
};
export function DeploymentProcessStepsPage({ projectStatus, setShowK8sStatusItem, projectContext, loaderData, scrollAreaRef }: DeploymentProcessStepsPageProps) {
    const { model: project, gitRef } = projectContext.state;
    const { deploymentProcessIdentifier, deploymentProcess, reloadDeploymentProcess, modifyDeploymentProcess } = useDeploymentProcessState(project, loaderData.process, gitRef?.CanonicalName);
    const onProcessSave = (gitRef: GitRefResource | string | undefined) => projectContext.actions.onProjectUpdated(project, gitRef);
    const changeGitRef = (gitRef: string) => projectContext.actions.changeGitRef(gitRef);
    const refreshModel = (gitRef?: GitRef) => projectContext.actions.refreshModel(gitRef);
    return (<ProcessStepsLayoutLoader setShowK8sStatusItem={setShowK8sStatusItem} project={projectContext.state.model} gitRef={gitRef?.CanonicalName} gitRefResource={gitRef} onProcessSave={onProcessSave} changeGitRef={changeGitRef} refreshModel={refreshModel} projectStatus={projectStatus} processIdentifier={deploymentProcessIdentifier} process={deploymentProcess} reloadProcess={reloadDeploymentProcess} modifyProcess={modifyDeploymentProcess} includedLibraryVariableSetIds={project.IncludedLibraryVariableSetIds} releaseCreationPackageStepId={project.ReleaseCreationStrategy.ReleaseCreationPackageStepId} onValidationError={() => scrollAreaRef?.current?.scrollTo({ top: 0, behavior: "smooth" })}/>);
}
