import type { GitRef, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useProjectRunbooksGitRef } from "~/areas/projects/components/BranchSelector/useProjectGitRef";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import { useRunbookContext } from "~/areas/projects/components/Runbooks/RunbookContext";
import type { RunbookProcessPageProps } from "~/areas/projects/components/Runbooks/RunbookProcessListPage";
import useRunbookProcessState from "~/areas/projects/components/Runbooks/useRunbookProcessState";
import { PageHeaderBranchSelectorVNext } from "../BranchSelector/PageHeaderBranchSelector";
import ProcessStepsLayoutLoader from "../Process/ProcessStepsLayoutLoader";
type RunbookProcessStepsPageProps = RunbookProcessPageProps & {
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
};
export function RunbookProcessStepsPage({ queryParams, setQueryParams, projectContext, loaderData, spaceId, processId, scrollAreaRef }: RunbookProcessStepsPageProps) {
    const project = projectContext.state.model;
    const { state: { runbook }, } = useRunbookContext();
    const [gitRef, setGitRef, gitRefValidationResult] = useProjectRunbooksGitRef(project, queryParams, setQueryParams, loaderData.gitRefValidationResult);
    const { runbookProcessIdentifier, runbookProcess, reloadRunbookProcess, modifyRunbookProcess } = useRunbookProcessState(project, runbook, spaceId, processId, gitRef, loaderData.process);
    const branchSelector = <PageHeaderBranchSelectorVNext project={project} gitRef={gitRef} setGitRef={setGitRef}/>;
    const onProcessSave = (gitRef: GitRefResource | string | undefined) => projectContext.actions.onProjectUpdated(project, gitRef);
    const changeGitRef = (gitRef: string) => setGitRef(gitRef);
    const refreshModel = (gitRef?: GitRef) => projectContext.actions.refreshModel(gitRef);
    if (!runbook || !runbookProcessIdentifier) {
        return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true}/>;
    }
    return (<ProcessStepsLayoutLoader project={project} gitRef={gitRef} gitRefResource={gitRefValidationResult?.GitRef} titleAccessory={branchSelector} onProcessSave={onProcessSave} changeGitRef={changeGitRef} refreshModel={refreshModel} processIdentifier={runbookProcessIdentifier} process={runbookProcess} reloadProcess={reloadRunbookProcess} modifyProcess={modifyRunbookProcess} includedLibraryVariableSetIds={project.IncludedLibraryVariableSetIds} onValidationError={() => scrollAreaRef?.current?.scrollTo({ top: 0, behavior: "smooth" })}/>);
}
