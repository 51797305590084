import { css } from "@emotion/css";
import { Button, Divider } from "@octopusdeploy/design-system-components";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeTenantScopeDetail, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import { useState } from "react";
import { TenantNameCell } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import { EnvironmentChip } from "~/components/Chips";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import type { TenantProjectEnvironmentRowData } from "../DeploymentFreezeTenantScopeTable";
interface SelectProjectEnvironmentsProps {
    selectedTenants: DeploymentFreezeTenantScopeDetail[];
    getAvailableEnvironments: () => Promise<ReferenceDataItem[]>;
    updateSelectedEnvironments: (environmentIds: string[]) => void;
    doBusyTask: DoBusyTask;
}
export default function SelectProjectEnvironments({ selectedTenants, getAvailableEnvironments, updateSelectedEnvironments, doBusyTask }: SelectProjectEnvironmentsProps) {
    const [availableEnvironments, setAvailableEnvironments] = useState<ReferenceDataItem[] | null>(null);
    const [selectedEnvironmentIds, setSelectedEnvironments] = useState<string[]>(_.uniq(selectedTenants.flatMap((pe) => pe.ProjectEnvironments.flatMap((p) => p.Environments.map((e) => e.Id)))));
    useDoBusyTaskEffect(doBusyTask, async () => {
        setAvailableEnvironments(await getAvailableEnvironments());
        updateSelectedEnvironments(selectedEnvironmentIds);
    }, []);
    const onEnvironmentChange = (environmentIdIds: string[]) => {
        setSelectedEnvironments(environmentIdIds);
        updateSelectedEnvironments(environmentIdIds);
    };
    if (availableEnvironments === null)
        return null;
    return (<div className={styles.container}>
            <div className={styles.panelContainer}>
                <SelectProjectEnvironmentsPanel availableEnvironments={availableEnvironments} selectedEnvironmentIds={selectedEnvironmentIds} onEnvironmentChange={onEnvironmentChange} selectedTenants={selectedTenants}/>
                <Divider />
                <ConnectionPreview items={selectedTenants}/>
            </div>
        </div>);
}
interface SelectProjectEnvironmentsPanelProps {
    selectedTenants: DeploymentFreezeTenantScopeDetail[];
    selectedEnvironmentIds: string[];
    onEnvironmentChange: (environmentIds: string[]) => void;
    availableEnvironments: ReferenceDataItem[];
}
function SelectProjectEnvironmentsPanel({ selectedTenants, selectedEnvironmentIds, onEnvironmentChange, availableEnvironments }: SelectProjectEnvironmentsPanelProps) {
    const onAssignAll = () => {
        onEnvironmentChange(availableEnvironments.map((e) => e.Id));
    };
    return (<>
            <div className={styles.panelHeadingContainer}>
                <div className={styles.panelHeading}>Select environments for {pluralize("tenants", selectedTenants.length, true)}</div>
            </div>
            <div className={styles.panelHeadingContainer}>
                <div>
                    <EnvironmentMultiSelect onChange={onEnvironmentChange} value={selectedEnvironmentIds} items={availableEnvironments}/>
                </div>
                <div>
                    <Button label={"Assign all available environments"} onClick={onAssignAll} importance={"tertiary"}/>
                </div>
            </div>
        </>);
}
interface ConnectionPreviewProps {
    items: DeploymentFreezeTenantScopeDetail[];
}
function ConnectionPreview({ items }: ConnectionPreviewProps) {
    const pageSize = 30;
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [paginatedScopeItems, setPaginatedItems] = useState<DeploymentFreezeTenantScopeDetail[]>(items);
    React.useEffect(() => {
        const pagedItems = items.slice(pageSize * currentPageIndex, pageSize * (currentPageIndex + 1));
        setPaginatedItems(pagedItems);
    }, [items, currentPageIndex]);
    const mapToRowData = (items: DeploymentFreezeTenantScopeDetail[]): TenantProjectEnvironmentRowData[] => {
        return items.flatMap((item) => {
            if (!item.ProjectEnvironments?.length) {
                return [
                    {
                        Id: item.Id,
                        Name: item.Name,
                        LogoLink: item.LogoLink,
                        SpaceId: item.SpaceId,
                        RowKey: `${item.Id}`,
                        IsChild: false,
                        Space: item.Space,
                        IsDisabled: item.IsDisabled,
                        ProjectEnvironment: {
                            Id: item.Id,
                            Name: "No projects",
                            LogoLink: "",
                            SpaceId: item.SpaceId,
                            Environments: [],
                        },
                    },
                ];
            }
            return item.ProjectEnvironments.map((projectEnv, index) => ({
                Id: item.Id,
                Name: index === 0 ? item.Name : "",
                LogoLink: item.LogoLink,
                SpaceId: item.SpaceId,
                Space: item.Space,
                IsDisabled: item.IsDisabled,
                RowKey: `${item.Id}-${projectEnv.Id}-${index}`,
                IsChild: index !== 0,
                ProjectEnvironment: projectEnv,
            }));
        });
    };
    const rowDatas = mapToRowData(paginatedScopeItems);
    return (<>
            <div className={styles.previewTitle}>Connection preview</div>
            <div className={styles.tableContainer}>
                <GroupedDataTable data={rowDatas} columns={[
            {
                title: "Tenant",
                render: (item) => (item.IsChild ? null : <TenantNameCell tenantName={item.Name} tenantLogoLink={item.LogoLink} space={item.Space} isDisabled={item.IsDisabled}/>),
                width: "30%",
                isChild: (item) => item.IsChild,
            },
            {
                title: "Project",
                render: (item) => <TenantNameCell tenantName={item.ProjectEnvironment.Name} tenantLogoLink={item.ProjectEnvironment.LogoLink}/>,
                width: "30%",
            },
            {
                title: "Environment",
                render: (data) => (data.ProjectEnvironment.Environments.length === 0 ? <div>No environments</div> : data.ProjectEnvironment.Environments.map((e) => <EnvironmentChip environmentName={e.Name} key={e.Id}/>)),
                width: "30%",
            },
        ]} getRowKey={(data) => data.RowKey}/>
                <NumberedPagingBar totalItems={items.length} currentPageIndex={currentPageIndex} pageSize={pageSize} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>
            </div>
        </>);
}
const styles = {
    container: css({
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: themeTokens.color.background.secondary.default,
    }),
    panelContainer: css({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: "0.25rem",
        backgroundColor: themeTokens.color.background.primary.default,
        overflowY: "hidden",
    }),
    panelHeadingContainer: css({
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gap: "10rem",
        alignItems: "center",
        padding: "1rem 1rem",
    }),
    panelHeading: css({
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
    }),
    previewTitle: css({
        padding: "1rem 1rem",
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
    }),
    tableContainer: css({
        overflowY: "auto",
    }),
};
