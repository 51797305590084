import { css, cx } from "@emotion/css";
import type { IconButtonElement } from "@octopusdeploy/design-system-components";
import { IconButton, MenuItemButton, MenuList } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import stylesheet from "./style.module.less";
type DropdownMenuOptionVariant = "danger";
export interface DropdownMenuOption {
    value: string | undefined | null;
    text: string;
    icon?: JSX.Element;
    disabled?: boolean;
    getDisabledText?: (text: string) => string;
    onChange?: () => void;
    variant?: DropdownMenuOptionVariant;
}
const controlStyles = {
    root: css({
        cursor: "pointer",
        height: "100%",
        position: "relative",
        width: "100%",
    }),
    disabled: css({
        cursor: "not-allowed",
    }),
};
const labelStyles = {
    root: css({
        color: themeTokens.color.textField.text.primary,
        font: text.regular.default.large,
        // We need to hack the labelStyle to stop MaterialUI from overflowing other controls that may be sitting underneath this.
        height: "40px",
        lineHeight: "40px",
        overflow: "hidden",
        opacity: 1,
        position: "relative",
        paddingLeft: "0px",
        paddingRight: space[48],
        textOverflow: "ellipsis",
        top: "0.7rem",
        whiteSpace: "nowrap",
    }),
    error: css({
        color: themeTokens.color.textField.text.primary,
    }),
    open: css({
        opacity: 0,
    }),
    disabled: css({
        color: themeTokens.color.textField.text.disabled,
    }),
};
const dropdownMenuStyles = {
    root: css({
        display: "block",
        height: space[48],
        font: text.regular.default.medium,
        outline: "none",
        position: "relative",
        borderBottom: `1px solid ${themeTokens.color.textField.border.default}`,
        "&:hover": {
            borderBottomColor: themeTokens.color.textField.border.hover,
        },
    }),
    open: css({
        opacity: 1,
    }),
    error: css({
        borderBottomColor: themeTokens.color.textField.border.danger,
        borderBottomWidth: "2px",
        "&:hover": {
            borderBottomColor: themeTokens.color.textField.border.danger,
        },
    }),
};
const buttonStyles = css({
    position: "absolute",
    right: 0,
    top: "1rem",
    bottom: 0,
    display: "flex",
    alignItems: "center",
});
const inlineButtonStyles = css({
    margin: `0 ${space[8]}`,
    padding: `0 ${space[8]} ${space[4]}`,
});
const withDivider = css({
    borderTop: `1px solid ${themeTokens.color.menuList.border.primary}`,
    paddingTop: space[12],
});
const menuItemContentTextStyles = css({
    flex: "1",
    minHeight: "1.5rem",
    font: text.regular.default.medium,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
});
interface DropDownMenuProps {
    allowClear: boolean;
    /**
     * This is the point on the anchor that the popover"s
     * `targetOrigin` will attach to.
     * Options:
     * vertical: [top, center, bottom]
     * horizontal: [left, middle, right].
     */
    anchorOrigin: Origin;
    autoFocus: boolean;
    /**
     * Disables the menu.
     */
    disabled: boolean;
    /**
     * Callback function fired when a menu item is clicked, other than the one currently selected.
     * @param {string} value If `multiple` is true, the menu"s `value`
     * array with either the menu item"s `value` added (if
     * it wasn"t already selected) or omitted (if it was already selected).
     * Otherwise, the `value` of the menu item.
     */
    onChange: (value: string) => void;
    /**
     * Callback function fired when the menu is closed.
     */
    onClose: () => void;
    /**
     * Callback function fired when a menu item is clicked, other than the one currently selected.
     * @param {string} The `value` of the menu item.
     * @param {any} menuItem The selected `MenuItemButton`.
     * If `multiple` is true, this will be an array with the `MenuItemButton`s matching the `value`s parameter.
     */
    selectionRenderer: ((value: string, menuItem: DropdownMenuOption) => React.ReactNode) | undefined;
    transformOrigin: Origin;
    value: string | undefined;
    filter: React.ReactNode;
    /**
     * If provided, adds a value to a visually hidden and accessible input
     * as an alternative to the display value
     */
    selectedValueAccessibleName: string;
    items: DropdownMenuOption[];
    empty?: string;
    hasError?: boolean;
    inlineButton?: React.ReactNode;
}
interface DropDownMenuState {
    open: boolean;
    anchorEl: HTMLElement | null;
}
export class DropDownMenu extends React.Component<DropDownMenuProps, DropDownMenuState> {
    static muiName = "DropDownMenu";
    static defaultProps: Partial<DropDownMenuProps> = {
        disabled: false,
        allowClear: false,
        anchorOrigin: {
            vertical: "top",
            horizontal: "left",
        },
        items: [],
        empty: "No Items",
    };
    state: DropDownMenuState = {
        open: false,
        anchorEl: null,
    };
    componentDidMount() {
        if (this.props.autoFocus) {
            // this focuses
            this.close();
        }
    }
    rootNode = React.createRef<HTMLDivElement>();
    downArrowIconButton = React.createRef<IconButtonElement>();
    firstMenuNode = React.createRef<HTMLButtonElement>();
    filterContainerRef = React.createRef<HTMLDivElement>();
    handleTouchTapControl = (event: React.MouseEvent) => {
        event.preventDefault();
        if (!this.props.disabled) {
            this.setState({
                open: !this.state.open,
                anchorEl: this.rootNode.current,
            });
        }
    };
    handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    open: true,
                    anchorEl: this.rootNode.current,
                });
                break;
        }
    };
    handleClearTouchTap = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.props.onChange) {
            this.props.onChange("");
        }
    };
    handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case "Escape":
                this.close();
                break;
            case "Tab":
            case "ArrowDown":
                if (this.state.open && this.props.items.length > 0 && this.firstMenuNode.current) {
                    this.firstMenuNode.current.focus();
                    event.preventDefault();
                }
                break;
        }
    };
    close = () => {
        this.setState({
            open: false,
        }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
            requestAnimationFrame(() => {
                this.downArrowIconButton.current?.focus();
            });
        });
    };
    handleCloseMenuWhenPressingTab: React.KeyboardEventHandler = (event) => {
        if (event.key === "Tab" && !this.props.filter) {
            this.close();
        }
    };
    render() {
        const { children, disabled, selectionRenderer, value, anchorOrigin, transformOrigin, allowClear, onChange, selectedValueAccessibleName, empty, autoFocus = true, filter, items, hasError } = this.props;
        const { anchorEl, open } = this.state;
        const currentOption = items.find((x) => x.value === value);
        const displayValue: React.ReactNode = selectionRenderer && currentOption && currentOption.value ? selectionRenderer(currentOption.value, currentOption) : currentOption?.text ?? currentOption?.value;
        let menuStyle = undefined;
        if (anchorEl) {
            const filterContainerRefWidth = this.filterContainerRef.current?.clientWidth ?? 0;
            const largestWidth = anchorEl.clientWidth > filterContainerRefWidth ? anchorEl.clientWidth : filterContainerRefWidth;
            menuStyle = Object.assign({
                width: largestWidth,
            });
        }
        // TODO: We need to pass a better accessible name in
        const accessibleName = "drop down menu";
        return (<div ref={this.rootNode} className={cx(dropdownMenuStyles.root, { [dropdownMenuStyles.open]: open, [dropdownMenuStyles.error]: hasError })}>
                <div className={cx(controlStyles.root, { [controlStyles.disabled]: disabled })} onClick={this.handleTouchTapControl}>
                    <div className={cx(labelStyles.root, { [labelStyles.open]: open, [labelStyles.error]: hasError, [labelStyles.disabled]: disabled })}>{displayValue}</div>
                    <input type="text" readOnly className={stylesheet.visuallyHidden} value={selectedValueAccessibleName ?? ""} tabIndex={-1}/>
                    <div className={buttonStyles}>
                        {allowClear && value && (<div onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                                <IconButton icon="Cancel" disabled={disabled} onClick={this.handleClearTouchTap} accessibleName={"ClearSelection"}/>
                            </div>)}
                        <div onKeyDown={disabled ? noOp : this.handleKeyDown}>
                            <IconButton customIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.ArrowDropDown}/>} disabled={disabled} ref={this.downArrowIconButton} accessibleName={"ToggleDropDown"}/>
                        </div>
                    </div>
                </div>
                <Popover anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={this.close} className={stylesheet.popover}>
                    {filter && (<div onKeyDown={this.handleFilterKeyDown} ref={this.filterContainerRef}>
                            {filter}
                        </div>)}
                    {this.props.items.length === 0 && empty && !this.props.inlineButton && <span className={stylesheet.empty}>{empty}</span>}
                    <div onKeyDown={this.handleCloseMenuWhenPressingTab} tabIndex={-1} className={stylesheet.menuListScrollContainer} style={menuStyle}>
                        <MenuList accessibleName={accessibleName}>
                            {items.map((menuItem, index) => {
                const refProps = index === 0 ? { ref: this.firstMenuNode } : {};
                return (<MenuItemButton key={menuItem.value ?? index} {...refProps} onClick={() => {
                        if (menuItem.disabled) {
                            return;
                        }
                        onChange?.(menuItem.value ?? "");
                        this.close();
                        menuItem.onChange?.();
                    }} isSelected={menuItem.value === this.props.value} autoFocus={autoFocus && !Boolean(filter) && ((index === 0 && !Boolean(value)) || menuItem.value === value)} disabled={menuItem.disabled} compact={true}>
                                        <MenuItemContentWithIcon icon={menuItem.icon} isDisabled={menuItem.disabled} text={menuItem.text} variant={menuItem.variant} getDisabledText={menuItem.getDisabledText}/>
                                    </MenuItemButton>);
            })}
                            {this.props.inlineButton && <div className={cx(inlineButtonStyles, { [withDivider]: items.length > 0 })}>{this.props.inlineButton}</div>}
                        </MenuList>
                    </div>
                </Popover>
            </div>);
    }
    static displayName = "DropDownMenu";
}
interface MenuItemContentWithIconProps {
    isDisabled: boolean | undefined;
    getDisabledText?: (text: string) => string;
    text: string;
    icon: React.ReactNode | undefined;
    variant: DropdownMenuOptionVariant | undefined;
}
function MenuItemContentWithIcon(props: MenuItemContentWithIconProps) {
    const { icon, variant } = props;
    return (<div className={cx(stylesheet.menuItemContent, { [stylesheet.danger]: variant === "danger" })}>
            {icon && <div className={stylesheet.menuItemContentIcon}>{icon}</div>}
            <div className={menuItemContentTextStyles}>{getMenuItemContentText(props)}</div>
        </div>);
}
const getMenuItemContentText = ({ text, isDisabled, getDisabledText }: MenuItemContentWithIconProps): string => {
    if (isDisabled) {
        return getDisabledText ? getDisabledText(text) : `${text} (disabled)`;
    }
    return text;
};
