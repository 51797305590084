import { css } from "@emotion/css";
import type { DeploymentFreezeScopeOverview, SummaryView } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectChip, TenantChip } from "~/components/Chips/index";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeDataTableScopesCellProps {
    tenants: SummaryView<DeploymentFreezeScopeOverview>;
    projects: SummaryView<DeploymentFreezeScopeOverview>;
}
export const DeploymentFreezeDataTableScopesCell = ({ tenants, projects }: DeploymentFreezeDataTableScopesCellProps) => {
    const hasTenants = tenants.Items.length > 0;
    const hasProjects = projects.Items.length > 0;
    if (!hasTenants && !hasProjects) {
        return (<DataTableRowColumn className={cellStyles}>
                <div className={noScopesStyles}>No scopes</div>
            </DataTableRowColumn>);
    }
    return (<DataTableRowColumn className={cellStyles}>
            <div className={scopesContainerStyles}>
                {hasProjects && (<div className={scopeLineStyles}>
                        {projects.Items.length === 1 ? <ProjectChip projectName={projects.Items[0].Name}/> : <ProjectChip projectName={`${projects.Items.length + (projects.MoreItems || 0)} projects`} noTooltip={true}/>}
                    </div>)}
                {hasTenants && (<div className={scopeLineStyles}>
                        {tenants.Items.length === 1 ? (<TenantChip tenantName={tenants.Items[0].Name} isDisabled={tenants.Items[0].IsDisabled}/>) : (<TenantChip tenantName={`${tenants.Items.length + (tenants.MoreItems || 0)} tenants`} noTooltip={true}/>)}
                    </div>)}
            </div>
        </DataTableRowColumn>);
};
const noScopesStyles = css({
    margin: "4px",
});
const scopeCellStyles = css({
    display: "flex",
    justifyContent: "flex-start",
    verticalAlign: "middle",
});
const cellStyles = css({ width: "15%" });
const scopesContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
});
const scopeLineStyles = css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});
