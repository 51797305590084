import React from "react";
import type { LiveStatusIconSize } from ".";
import { svgStyle } from ".";
export function MissingIcon(title: string, size: LiveStatusIconSize) {
    return (<svg width={size} height={size} className={svgStyle} viewBox="0 0 48 48" fill="none" role="img" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg">
            <title id="title">{title}</title>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#757575"/>
            <path d="M15.4556 35.8744L14.8256 36.3806C14.6456 36.5213 14.4262 36.6 14.2012 36.6C13.65 36.6 13.2 36.15 13.2 35.5988V20.4C13.2 14.4375 18.0375 9.60001 24 9.60001C29.9625 9.60001 34.8 14.4375 34.8 20.4V35.5988C34.8 36.15 34.35 36.6 33.7987 36.6C33.5737 36.6 33.3543 36.5213 33.1743 36.3806L32.5443 35.8744C31.7906 35.2725 30.6993 35.3681 30.0637 36.0938L28.3481 38.0625C28.1625 38.2763 27.8868 38.4 27.6 38.4C27.3131 38.4 27.0431 38.2763 26.8518 38.0625L25.3556 36.3469C24.6412 35.5256 23.3643 35.5256 22.6443 36.3469L21.1481 38.0625C20.9625 38.2763 20.6868 38.4 20.4 38.4C20.1131 38.4 19.8431 38.2763 19.6518 38.0625L17.9362 36.0938C17.3006 35.3681 16.2093 35.2725 15.4556 35.8744ZM22.2 20.4C22.2 19.9226 22.0103 19.4648 21.6727 19.1272C21.3352 18.7896 20.8773 18.6 20.4 18.6C19.9226 18.6 19.4647 18.7896 19.1272 19.1272C18.7896 19.4648 18.6 19.9226 18.6 20.4C18.6 20.8774 18.7896 21.3352 19.1272 21.6728C19.4647 22.0104 19.9226 22.2 20.4 22.2C20.8773 22.2 21.3352 22.0104 21.6727 21.6728C22.0103 21.3352 22.2 20.8774 22.2 20.4ZM27.6 22.2C28.0773 22.2 28.5352 22.0104 28.8727 21.6728C29.2103 21.3352 29.4 20.8774 29.4 20.4C29.4 19.9226 29.2103 19.4648 28.8727 19.1272C28.5352 18.7896 28.0773 18.6 27.6 18.6C27.1226 18.6 26.6647 18.7896 26.3272 19.1272C25.9896 19.4648 25.8 19.9226 25.8 20.4C25.8 20.8774 25.9896 21.3352 26.3272 21.6728C26.6647 22.0104 27.1226 22.2 27.6 22.2Z" fill="white"/>
        </svg>);
}
