import { css } from "@emotion/css";
import type { BeaconOffset, PopoverPlacement } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import { useContext, useEffect } from "react";
import * as React from "react";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
export type SampleProjectStepId = "DeploymentOverview" | "Variable" | "DeploymentProcess" | "DeploymentProcessEnvironments" | "Runbooks";
export interface SampleProjectTourStepPlacement {
    placementWideLongScreen: PopoverPlacement;
    placementWideShortScreen?: PopoverPlacement;
    placementNarrowLongScreen?: PopoverPlacement;
    placementNarrowShortScreen?: PopoverPlacement;
}
export interface SampleProjectTourStep {
    title: string;
    content: string;
    infoCallout?: ReactNode;
    scrollOffset: () => number;
    beaconOffset: () => BeaconOffset;
    popoverPlacement: SampleProjectTourStepPlacement;
    target: SampleProjectStepId;
    route: LinkHref;
}
interface SampleProjectTourStepBaseProps {
    stepId: SampleProjectStepId;
}
interface SampleProjectTourStepProps extends SampleProjectTourStepBaseProps {
    children?: React.ReactNode;
}
export function SampleProjectTourStep(props: SampleProjectTourStepProps): JSX.Element {
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    useEffect(() => {
        if (sampleProjectTourContext === undefined)
            return;
        if (stepRef.current !== null)
            sampleProjectTourContext?.registerStep(props.stepId, stepRef.current);
        return () => sampleProjectTourContext?.removeRegisteredStep(props.stepId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (sampleProjectTourContext === undefined)
        return <>{props.children}</>;
    const stepRef = React.createRef<HTMLDivElement>();
    return (<div ref={stepRef} id={`SampleProjectStep-${props.stepId}`}>
            {props.children}
        </div>);
}
export function SampleProjectTourAbsoluteFullContainerHighlightStep(props: SampleProjectTourStepProps): JSX.Element {
    const sampleProjectTourContext = useContext(SampleProjectTourContext);
    const stepRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        if (stepRef.current !== null)
            sampleProjectTourContext?.registerStep(props.stepId, stepRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<div ref={stepRef} id={`SampleProjectStep-${props.stepId}`} className={css({ position: "absolute", height: "100%", width: "100%", pointerEvents: "none" })}>
            {props.children}
        </div>);
}
