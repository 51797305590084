import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import React, { Fragment } from "react";
import { environmentChipList, tenantChipList } from "~/components/Chips";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { Text } from "~/components/form";
import type { TenantEnvironmentMapping } from "./DeploymentFreezes";
import styles from "./style.module.less";
interface OverrideDeploymentFreezeDialogProps {
    show: boolean;
    activeFreezes: {
        Name: string;
        Environments: string[];
        TenantEnvironments: TenantEnvironmentMapping[];
    }[];
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    onClosed(): void;
    onContinueClick(reason: string): Promise<void>;
}
interface OverrideDeploymentFreezeDialogState {
    reason: string;
}
export class OverrideDeploymentFreezeDialog extends React.Component<OverrideDeploymentFreezeDialogProps, OverrideDeploymentFreezeDialogState> {
    constructor(props: OverrideDeploymentFreezeDialogProps) {
        super(props);
        this.state = {
            reason: "",
        };
    }
    render() {
        const cont = <ActionButton key="Continue" label={"Override and deploy"} type={ActionButtonType.Save} onClick={() => this.props.onContinueClick(this.state.reason)}/>;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.onClosed()}/>;
        return (<Dialog open={this.props.show} dialogWidth="800px">
                <DialogLayout actions={[cancel, cont]} title={"Override deployment freezes"} closeDialog={this.props.onClosed}>
                    You will override <b>{this.props.activeFreezes.length}</b> active deployment {pluralize("freeze", this.props.activeFreezes.length)} with this deployment:
                    <div>
                        <ul className={styles.freezeList}>
                            {this.props.activeFreezes.map((f, ix) => (<Fragment key={f.Name + ix + "Fragment"}>
                                    <li key={f.Name + ix}>
                                        {f.Name} has frozen {environmentChipList(this.props.allEnvironments, f.Environments)}
                                    </li>
                                    {isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle") &&
                    f.TenantEnvironments.map((tenantEnvironments) => (<li key={f.Name + ix + tenantEnvironments.TenantId}>
                                                {f.Name} has frozen {tenantChipList(this.props.allTenants, [tenantEnvironments.TenantId])} in {environmentChipList(this.props.allEnvironments, tenantEnvironments.Environments)}
                                            </li>))}
                                </Fragment>))}
                        </ul>
                    </div>
                    <Text key="reason" id="reason" name="Reason" label="Provide a reason for overriding the deployment freezes" autoFocus={true} accessibleName={"Reason"} customMargins="0 0 0.5rem 0" value={this.state.reason} onChange={(newValue: string) => this.setState({ reason: newValue })}/>
                </DialogLayout>
            </Dialog>);
    }
    static displayName = "OverrideDeploymentFreezeDialog";
}
