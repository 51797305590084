import { css, cx } from "@emotion/css";
import { tableMobileStyles, tableStyles } from "@octopusdeploy/design-system-components";
import type { ReactNode } from "react";
import React from "react";
import { useMediaQuery } from "react-responsive";
export interface DataTableColumn<TData> {
    title: string;
    render: (item: TData) => ReactNode;
    isChild?: (item: TData) => boolean;
    accessibleName?: string;
    width?: string;
    padding?: string;
    backgroundColor?: (item: TData) => string | undefined;
}
export interface GroupedDataTableProps<TData> {
    columns: DataTableColumn<TData>[];
    data: TData[];
    getRowKey: (item: TData) => React.Key;
    accessibleName?: string;
    groupBy?: GroupBy<TData>[];
}
export interface GroupBy<TData> {
    key: string;
    selector: (item: TData) => string;
    header: React.ReactElement;
}
export function GroupedDataTable<TData>({ columns, data, getRowKey, accessibleName, groupBy }: GroupedDataTableProps<TData>) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    function groupData<TData>(data: TData[], groupBy: GroupBy<TData>[]) {
        const groupedData: Record<string, {
            items: TData[];
            header?: React.ReactElement;
        }> = {};
        groupBy.forEach((group) => {
            groupedData[group.key] = {
                items: [],
                header: group.header,
            };
        });
        data.forEach((item) => {
            const group = groupBy.find((g) => g.selector(item) === g.key);
            if (group === undefined) {
                return;
            }
            groupedData[group.key].items.push(item);
        });
        return groupedData;
    }
    const groupedData = groupBy ? groupData(data, groupBy) : { "": { items: data } };
    const headings = columns.map((c) => (<th key={c.title} aria-label={c.accessibleName} style={{ width: c.width }}>
            {c.title}
        </th>));
    const rows = Object.entries(groupedData).flatMap(([groupKey, { items, header }]) => [
        groupKey !== "" && items.length > 0 && header,
        ...items.map((d: TData) => (<tr key={getRowKey(d)}>
                {columns.map((c, i) => {
                const cellCssClass = c.isChild && c.isChild(d) ? childColumnStyles : undefined;
                const cellStyle = {
                    width: c.width,
                    padding: c.padding,
                    backgroundColor: c.backgroundColor && c.backgroundColor(d),
                };
                return (<td key={i} className={cellCssClass} style={cellStyle} aria-label={c.accessibleName}>
                            {c.render(d)}
                        </td>);
            })}
            </tr>)),
    ]);
    return (<table className={cx(tableStyles, fixedStyle, { [tableMobileStyles]: !isLargerThanIpad })} aria-label={accessibleName}>
            <thead>
                <tr>{headings}</tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>);
}
const childColumnStyles = css({
    borderTop: "0 !important",
});
const fixedStyle = css({
    tableLayout: "fixed",
});
