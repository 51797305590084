import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { ActionTemplateParameterResource, GetProjectTemplatesWithValuesBffResponse, ProjectResource, ProjectTemplateTenantValue, VariableType, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import * as React from "react";
import { useState } from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import { ProjectTemplatesFiltersBar } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import { MissingValuesCallout } from "~/areas/projects/components/Variables/TenantVariables/MissingValuesCallout";
import NoTemplatesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoTemplatesOnboarding";
import NoValuesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoValuesOnboarding";
import ProjectTemplateValueInlineCell from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplateValueInlineCell";
import ProjectTemplatesSidebarContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplatesSidebarContent";
import { TemplateNameCell } from "~/areas/projects/components/Variables/TenantVariables/TemplateNameCell";
import { getVariableTypeFromDisplaySettings } from "~/areas/projects/components/Variables/TenantVariables/getVariableTypeFromDisplaySettings";
import { pagingBarStyles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import type { ProjectTenantVariableValueChangeTracker, ProjectTenantVariableValueKey } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { createProjectTenantVariableKey } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import type { TenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { TenantNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { EnvironmentChip } from "~/components/Chips/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout, { SidebarSide } from "~/components/SidebarLayout/index";
interface ProjectTemplatesInlineTabContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    filterState: ProjectTenantVariablesFilterState;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    filterData: TenantVariableFiltersData;
    pageSize: number;
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    projectTemplatesWithValues: GetProjectTemplatesWithValuesBffResponse;
    onAddTemplateFromOnboarding: (text: string) => void;
    resourceOptions: TenantVariableResourceOptions;
    variableValueTracker: ProjectTenantVariableValueChangeTracker;
}
export default function ProjectTemplatesInlineTabContent({ project, doBusyTask, filterState, filterData, setFilterState, pageSize, setPageState, projectTemplatesWithValues, onAddTemplateFromOnboarding, resourceOptions, variableValueTracker, }: ProjectTemplatesInlineTabContentProps) {
    if (project.Templates.length === 0) {
        return <NoTemplatesOnboarding project={project} doBusyTask={doBusyTask} onAddTemplate={onAddTemplateFromOnboarding}/>;
    }
    const missingValuesTemplateIds = new Set<string>(projectTemplatesWithValues.MissingValueProjectTemplateIds);
    if (projectTemplatesWithValues.TotalValuesCount === 0) {
        return (<div style={{ height: "100%" }}>
                <SidebarLayout sideBar={<ProjectTemplatesSidebarContent project={project} pageSize={pageSize} doBusyTask={doBusyTask} filterState={filterState} setFilterState={setFilterState} missingValueTemplateIds={missingValuesTemplateIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
                    <NoValuesOnboarding project={project}/>
                </SidebarLayout>
            </div>);
    }
    const variableValues = buildProjectTemplateRowData(project, projectTemplatesWithValues);
    const unsavedChanges = variableValueTracker.values().length;
    return (<SidebarLayout sideBar={<ProjectTemplatesSidebarContent project={project} pageSize={pageSize} doBusyTask={doBusyTask} filterState={filterState} setFilterState={setFilterState} missingValueTemplateIds={missingValuesTemplateIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
            <div>
                {projectTemplatesWithValues.AnyMissingValues && <MissingValuesCallout filterState={filterState} setFilterState={setFilterState}/>}
                <ProjectTemplatesFiltersBar totalVariablesCount={projectTemplatesWithValues.TotalValuesCount} filteredVariablesCount={projectTemplatesWithValues.FilteredValuesCount} filterState={filterState} onFilterChange={setFilterState} filterData={filterData} unsavedChangesCount={unsavedChanges}/>
                <ProjectTemplatesDataTable values={variableValues} project={project} accounts={projectTemplatesWithValues.Accounts} certificates={projectTemplatesWithValues.Certificates} workerPools={projectTemplatesWithValues.WorkerPools} resourceOptions={resourceOptions} doBusyTask={doBusyTask} variableValueTracker={variableValueTracker}/>
                <div className={pagingBarStyles}>
                    <NumberedPagingBar totalItems={projectTemplatesWithValues.FilteredValuesCount} pageNumber={filterState.pageNumber} pageSize={pageSize} pageSizeOptions={[30, 50, 100]} onPagingSelectionChange={setPageState}/>
                </div>
            </div>
        </SidebarLayout>);
}
interface ProjectTemplatesDataTableProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    values: ProjectTemplateRowData[];
    resourceOptions: TenantVariableResourceOptions;
    doBusyTask: DoBusyTask;
    variableValueTracker: ProjectTenantVariableValueChangeTracker;
}
function ProjectTemplatesDataTable({ values, project, accounts, certificates, workerPools, resourceOptions, doBusyTask, variableValueTracker }: ProjectTemplatesDataTableProps) {
    const [focusedValueKey, setFocusedValueKey] = useState<ProjectTenantVariableValueKey | null>(null);
    const bindableTemplateNames = project.Templates.map((t) => t.Name);
    const editingPermissions = {
        permission: Permission.VariableEdit,
        project: project.Id,
        wildcard: true,
    };
    const hasEditPermissions = isAllowed(editingPermissions);
    const renderValueCell = (data: ProjectTemplateRowData) => {
        const tenantId = data.Variable.TenantId;
        const sourceItems = resourceOptions.get(tenantId);
        const valueKey = createProjectTenantVariableKey(project.Id, data.Variable.TemplateId, data.Variable.TenantId, data.Variable.EnvironmentId);
        const hasValueChanged = variableValueTracker.has(valueKey);
        const value = hasValueChanged ? variableValueTracker.get(valueKey) : data.Variable.Value;
        // Show enabled tenants first
        values.sort((a, b) => Number(a.Variable.TenantIsDisabled) - Number(b.Variable.TenantIsDisabled));
        const handleOnBlur = () => {
            setFocusedValueKey((previous: ProjectTenantVariableValueKey | null) => (previous === valueKey ? null : previous));
        };
        return (<ProjectTemplateValueInlineCell template={data.Template} accounts={accounts} certificates={certificates} workerPools={workerPools} value={value} valueType={data.VariableType} sourceItems={sourceItems} doBusyTask={doBusyTask} valueTracker={variableValueTracker} valueKey={valueKey} focused={focusedValueKey === valueKey} onFocus={() => setFocusedValueKey(valueKey)} onBlur={handleOnBlur} bindableTemplateNames={bindableTemplateNames} hasEditPermissions={hasEditPermissions} hasValueChanged={hasValueChanged} initialValue={data.Variable.Value}/>);
    };
    return (<GroupedDataTable data={values} columns={[
            {
                title: "Name",
                render: (data) => (data.IsChild ? null : <TemplateNameCell template={data.Template}/>),
                width: "25%",
                isChild: (data) => data.IsChild,
            },
            {
                title: "Tenant",
                render: (data) => (<div onFocus={() => setFocusedValueKey(null)}>
                            <TenantNameCellWithLink spaceId={project.SpaceId} tenantId={data.Variable.TenantId} tenantName={data.Variable.TenantName} tenantLogoLink={data.Variable.TenantLogoLink} isDisabled={data.Variable.TenantIsDisabled} linkToPage="Variables"/>
                        </div>),
                width: "20%",
            },
            { title: "Environment", render: (data) => <EnvironmentChip environmentName={data.Variable.EnvironmentName} key={data.Variable.EnvironmentId} tooltipDisplay="grid"/>, width: "20%" },
            {
                title: "Value",
                render: renderValueCell,
                width: "35%",
                padding: "0",
                backgroundColor: (data) => {
                    const valueKey = createProjectTenantVariableKey(project.Id, data.Variable.TemplateId, data.Variable.TenantId, data.Variable.EnvironmentId);
                    const hasValueChanged = variableValueTracker.has(valueKey);
                    return hasValueChanged ? themeTokens.color.callout.background.attention.default : undefined;
                },
            },
        ]} getRowKey={(data) => data.RowKey}/>);
}
interface ProjectTemplateRowData {
    Variable: ProjectTemplateTenantValue;
    Template: ActionTemplateParameterResource;
    VariableType: VariableType;
    RowKey: string;
    IsChild: boolean;
}
function buildProjectTemplateRowData(project: ProjectResource, projectTemplatesWithValues: GetProjectTemplatesWithValuesBffResponse) {
    const templatesVariableType: Dictionary<VariableType> = {};
    const variableValues: ProjectTemplateRowData[] = [];
    const projectTemplates = new Map(project.Templates.map((pt) => [pt.Id, pt]));
    for (const variable of projectTemplatesWithValues.Variables) {
        const projectTemplate = projectTemplates.get(variable.TemplateId);
        if (!projectTemplate) {
            logger.warn("Could not find project template with ID {id}", { id: variable.TemplateId });
            continue;
        }
        const hasTemplateBeenAdded = templatesVariableType[variable.TemplateId] !== undefined;
        if (!hasTemplateBeenAdded) {
            templatesVariableType[variable.TemplateId] = getVariableTypeFromDisplaySettings(projectTemplate.DisplaySettings["Octopus.ControlType"]);
        }
        variableValues.push({
            Template: projectTemplate,
            VariableType: templatesVariableType[variable.TemplateId],
            Variable: variable,
            RowKey: `${projectTemplate.Id}${variable.TenantId}${variable.EnvironmentId}`,
            IsChild: hasTemplateBeenAdded,
        });
    }
    return variableValues;
}
