import type { TenantProjectEnvironment } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezes, GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { Moment } from "moment";
import moment from "moment";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { TenantEnvironmentMapping } from "./DeploymentFreezes";
function isActive(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[], queueTime: Moment | undefined) {
    const targetDeployTime = queueTime ?? moment();
    const startMoment = moment(freeze.Start);
    const endMoment = moment(freeze.End);
    return targetDeployTime.isAfter(startMoment) && targetDeployTime.isBefore(endMoment) && freeze.ProjectEnvironmentScope[projectId] && freeze.ProjectEnvironmentScope[projectId].some((environment) => selectedEnvironmentIds.includes(environment));
}
function isActiveForProjectsOrTenants(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[], tenantIds: string[], queueTime: Moment | undefined) {
    const targetDeployTime = queueTime ?? moment();
    const startMoment = moment(freeze.Start);
    const endMoment = moment(freeze.End);
    if (targetDeployTime.isBefore(startMoment) || targetDeployTime.isAfter(endMoment)) {
        return false;
    }
    return isActiveForEnvironment(freeze, projectId, selectedEnvironmentIds) || isActiveForTenants(freeze, projectId, tenantIds, selectedEnvironmentIds);
}
function isActiveForEnvironment(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[]): boolean {
    return freeze.ProjectEnvironmentScope[projectId] && freeze.ProjectEnvironmentScope[projectId].some((environment) => selectedEnvironmentIds.includes(environment));
}
function isActiveForTenants(freeze: GetDeploymentFreezes, projectId: string, selectedTenantIds: string[], selectedEnvironmentIds: string[]): boolean {
    return freeze.TenantProjectEnvironmentScope?.some((tpe) => selectedTenantIds.includes(tpe.TenantId) && selectedEnvironmentIds.includes(tpe.EnvironmentId) && tpe.ProjectId === projectId);
}
function transformToTenantEnvironments(tenantProjectEnvironmentScope: TenantProjectEnvironment[]) {
    const tenantMap = new Map<string, {
        projectId: string;
        environments: Set<string>;
    }>();
    tenantProjectEnvironmentScope.forEach((item) => {
        if (!tenantMap.has(item.TenantId)) {
            tenantMap.set(item.TenantId, { projectId: item.ProjectId, environments: new Set<string>() });
        }
        // Get the environment set for this tenant
        const tenantData = tenantMap.get(item.TenantId);
        tenantData?.environments.add(item.EnvironmentId);
    });
    return Array.from(tenantMap.entries()).map(([tenantId, { projectId, environments }]) => ({
        TenantId: tenantId,
        ProjectId: projectId,
        Environments: Array.from(environments),
    }));
}
export function getActiveFreezes(freezes: GetDeploymentFreezesResponse | null, projectId: string, selectedEnvironmentIds: string[], selectedTenantIds: string[], queueTime: Moment | undefined): {
    Id: string;
    Name: string;
    Environments: string[];
    TenantEnvironments: TenantEnvironmentMapping[];
}[] {
    if (freezes === null) {
        return [];
    }
    if (isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle")) {
        return freezes.DeploymentFreezes.filter((f) => isActiveForProjectsOrTenants(f, projectId, selectedEnvironmentIds, selectedTenantIds, queueTime)).map((freeze) => ({
            Id: freeze.Id,
            Name: freeze.Name,
            Environments: freeze.ProjectEnvironmentScope[projectId] ? freeze.ProjectEnvironmentScope[projectId].filter((environment) => selectedEnvironmentIds.includes(environment)) : [],
            TenantEnvironments: transformToTenantEnvironments(freeze.TenantProjectEnvironmentScope),
        }));
    }
    return freezes.DeploymentFreezes.filter((f) => isActive(f, projectId, selectedEnvironmentIds, queueTime)).map((freeze) => ({
        Id: freeze.Id,
        Name: freeze.Name,
        Environments: freeze.ProjectEnvironmentScope[projectId] ? freeze.ProjectEnvironmentScope[projectId].filter((environment) => selectedEnvironmentIds.includes(environment)) : [],
        TenantEnvironments: [],
    }));
}
