import { css } from "@emotion/css";
import { useAggregateAPIOperationStatus, useMutation } from "@octopusdeploy/octopus-react-client";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { MarkdownEditor, Text } from "~/components/form/index";
export function AddProcessTemplateDialog({ gitRef }: {
    gitRef: GitRef;
}) {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const navigation = useSpaceAwareNavigation();
    const { execute: addProcessTemplate } = useMutation({
        name: "Add Process Template",
        action: async (repository) => await repository.Blueprints.add({ Name: name, Description: description, ChangeDescription: `Add process template '${name}'` }, gitRef),
        afterComplete: async (_, output) => {
            navigation.navigate(links.editProcessTemplatePage.generateUrl({ spaceId: repository.spaceId ?? "", slug: output.Slug }, { gitRef: output.GitRef }));
        },
    });
    const { isInProgress, errors } = useAggregateAPIOperationStatus();
    const hasMissingValues = !name;
    return (<SaveDialogLayout title={"Add Process Template"} busy={isInProgress} errors={errors} onSaveClick={async () => {
            await addProcessTemplate();
            return true;
        }} saveButtonLabel={"Add"} busyButtonLabel={"Adding..."} saveButtonDisabled={hasMissingValues}>
            <div className={styles.content}>
                <Text value={name} onChange={(value) => setName(value)} label="Name"/>
                <MarkdownEditor value={description} label="Description" onChange={(value) => setDescription(value)}/>
            </div>
        </SaveDialogLayout>);
}
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: "0.6rem",
    }),
};
