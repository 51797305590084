import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { GitRepositorySourceProperties } from "@octopusdeploy/legacy-action-properties/src/GitRepositorySourceProperties";
import type { GitCredentialResource, PersistenceSettings, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { CreatePrimaryGitDependency, Permission, ProcessType, RemovePrimaryGitDependency } from "@octopusdeploy/octopus-server-client";
import type { GitDependencyReference } from "@octopusdeploy/octopus-server-client/dist/src/resources/gitDependencyReference";
import * as React from "react";
import { isVersionControlledProcess } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import { RelatedGitTriggerCallout } from "~/areas/projects/components/Triggers/Git/RelatedGitTriggerCallout";
import type { RelatedTriggersDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { useNonPrimaryGitDependencySupport } from "~/components/GitRepositorySource/useNonPrimaryGitDependencySupport";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { Note, required, Summary } from "~/components/form/index";
import type { SummaryNode } from "~/components/form/index";
import { ExternalGitRepositorySourceContextualHelp, ProjectGitRepositorySourceContextualHelp } from "./GitRepositorySourceContextualHelp";
export interface GitRepositorySourceSelectorProps {
    properties: GitRepositorySourceProperties;
    setProperties(properties: Partial<GitRepositorySourceProperties>, initialise?: boolean, callback?: () => void): void;
    localNames: string[] | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    gitDependencies: GitDependencyReference[] | undefined;
    setGitDependencies: ((gitDependencies: GitDependencyReference[], initialise?: boolean) => void) | undefined;
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<unknown>;
    project: ProjectResource | undefined;
    processType: ProcessType | undefined;
    filePathFilters?: string;
    enableFilePathFilters?: boolean;
    onFilePathFilterChanged?: (filePathFilters: string) => void;
    filePathOverrides?: {
        customPropertyName?: string;
        accessibleName?: string;
        label?: string;
        validation?: (filePaths: string, notSetValidationMessage: string) => string;
        help?: React.ReactNode;
        summary?: (filePaths: string | undefined) => SummaryNode;
    };
    relatedTriggerDependencies?: RelatedTriggersDependencies;
    showAvailableTriggerCallout?: boolean;
}
export function isProjectRepositoryGitSourceSupported(persistenceSettings?: PersistenceSettings, processType?: ProcessType) {
    return persistenceSettings && processType && isVersionControlledProcess(persistenceSettings, processType);
}
function GetFilePathsFromString(filePathsString?: string | null): string[] {
    if (!filePathsString)
        return [];
    return filePathsString
        .split(/[\r|\n]/)
        .map((i) => i.trim())
        .filter(Boolean);
}
const showAvailableCallout = (props: GitRepositorySourceSelectorProps) => (props.showAvailableTriggerCallout && props.processType !== ProcessType.Runbook) ?? false;
export function GitRepositorySourceSelector(props: GitRepositorySourceSelectorProps) {
    const primaryGitDependencyFromProps: GitDependencyReference = props.gitDependencies?.find((r) => r.Name === "") ?? {
        Name: "",
        RepositoryUri: "",
        DefaultBranch: "main",
        GitCredentialType: "Library",
        FilePathFilters: GetFilePathsFromString(props.properties["Octopus.Action.GitRepository.FilePathFilters"]),
    };
    const [primaryGitDependency, setPrimaryGitDependency] = React.useState<GitDependencyReference>(primaryGitDependencyFromProps);
    const supportNonPrimaryGitDependencies = useNonPrimaryGitDependencySupport();
    const setResourceProperty = (property: keyof GitDependencyReference, value: string) => {
        const updated = {
            ...primaryGitDependency,
            [property]: value,
        };
        setPrimaryGitDependency(updated);
        updateGitDependencies(updated);
    };
    const setFilePathFilterInGitDependency = (filePathsString: string) => {
        const filePaths = GetFilePathsFromString(filePathsString);
        const updated = {
            ...primaryGitDependency,
            FilePathFilters: filePaths,
        };
        setPrimaryGitDependency(updated);
        updateGitDependencies(updated);
    };
    const setRepositoryUri = (val: string) => setResourceProperty("RepositoryUri", val);
    const setCredentialType = (val: string) => {
        if (val === "Anonymous") {
            const updated = {
                ...primaryGitDependency,
                GitCredentialType: val,
                GitCredentialId: undefined,
            };
            setPrimaryGitDependency(updated);
            updateGitDependencies(updated);
        }
        else {
            setResourceProperty("GitCredentialType", val);
        }
    };
    const setCredentialId = (val: string) => setResourceProperty("GitCredentialId", val);
    const setDefaultBranch = (val: string) => setResourceProperty("DefaultBranch", val);
    const updateGitDependencies = (gitDependency: GitDependencyReference) => {
        let updatedGitDependencies: GitDependencyReference[] = [];
        //if we are supporting non-primary git dependencies, we need to only update this dependency, not clear out the entire collection
        if (supportNonPrimaryGitDependencies) {
            updatedGitDependencies = [...(props.gitDependencies || [])];
            const index = updatedGitDependencies.findIndex((gd) => gd.Name === gitDependency.Name);
            if (index >= 0) {
                updatedGitDependencies[index] = gitDependency;
            }
            else {
                updatedGitDependencies.push(gitDependency);
            }
        }
        else {
            updatedGitDependencies = [gitDependency];
        }
        props.setGitDependencies?.(updatedGitDependencies);
    };
    const setFilePaths = (val: string) => {
        if (props.properties["Octopus.Action.GitRepository.Source"] === "Project") {
            props.setProperties({ ["Octopus.Action.GitRepository.FilePathFilters"]: val });
        }
        else {
            setFilePathFilterInGitDependency(val);
        }
        props.onFilePathFilterChanged?.(val);
    };
    const isUsingLibraryAuth = (): boolean => primaryGitDependency.GitCredentialType === "Library";
    const authSummary = (): SummaryNode => {
        if (isUsingLibraryAuth()) {
            const id = primaryGitDependency.GitCredentialId;
            const credential = props.gitCredentials.find((c) => c.Id === id);
            return Summary.summary(React.Children.toArray([
                <span>Using Git credential</span>,
                <span>
                        <strong>{credential ? ` ${credential.Name}` : ""}</strong>
                    </span>,
            ]));
        }
        else {
            return Summary.summary(React.Children.toArray([
                <span>
                        Authenticated as an <strong>Anonymous</strong> user
                    </span>,
            ]));
        }
    };
    const repoUrlSummary = (): SummaryNode => (primaryGitDependency.RepositoryUri ? Summary.summary(primaryGitDependency.RepositoryUri) : Summary.placeholder("Enter a Git repository URL"));
    const branchSummary = (): SummaryNode => primaryGitDependency.DefaultBranch
        ? Summary.summary(<span>
                      Default branch is <strong>{primaryGitDependency.DefaultBranch}</strong>
                  </span>)
        : Summary.placeholder("Configure default branch");
    const getPathsNote = () => (<Note>
            This field supports glob patterns syntax e.g. <code>my/folder/*</code> will include all files in a folder.
            <br />
            Separate multiple paths with new lines. See our <ExternalLink href={"glob-patterns-cheat-sheet"}>glob patterns cheat sheet</ExternalLink> for more info.
        </Note>);
    const filePathsSummary = (): SummaryNode => {
        const filePaths = getFilePathFilterValue()
            ?.split(/[\r|\n]/)
            .map((i) => i.trim())
            .filter(Boolean)
            .join(", ");
        if (props.filePathOverrides?.summary) {
            return props.filePathOverrides?.summary(filePaths);
        }
        return filePaths ? Summary.summary(filePaths) : Summary.placeholder("No path filters specified");
    };
    const filePathsNotSetValidationMessage = () => "Enter paths to filter the files used during deployment";
    const gitRepositorySourceSummary = () => {
        if (props.properties["Octopus.Action.GitRepository.Source"] === "Project") {
            return Summary.default("The repository is configured in version control settings");
        }
        else {
            return Summary.summary("The repository is configured below");
        }
    };
    const handleGitRepositorySourceChanged = (val: string, initialise: boolean = false) => {
        const properties: Partial<GitRepositorySourceProperties> = { ["Octopus.Action.GitRepository.Source"]: val };
        if (val === "Project") {
            // Copy the primary git dependency file path filter to the properties
            properties["Octopus.Action.GitRepository.FilePathFilters"] = primaryGitDependency.FilePathFilters?.join("\n");
            props.setGitDependencies?.(RemovePrimaryGitDependency(props.gitDependencies), initialise);
        }
        else {
            //create a new primary git dependency
            const primaryGitDependency = {
                ...CreatePrimaryGitDependency(),
                FilePathFilters: GetFilePathsFromString(props.properties["Octopus.Action.GitRepository.FilePathFilters"]),
            };
            properties["Octopus.Action.GitRepository.FilePathFilters"] = "";
            //set the state
            setPrimaryGitDependency(primaryGitDependency);
            //update the dependencies with the new primary dependency
            const updatedDependencies = [...RemovePrimaryGitDependency(props.gitDependencies), primaryGitDependency];
            props.setGitDependencies?.(updatedDependencies, initialise);
        }
        props.setProperties(properties, initialise);
    };
    const getFilePathFilterValue = (): string | undefined => {
        if (props.properties["Octopus.Action.GitRepository.Source"] === "Project") {
            return props.properties["Octopus.Action.GitRepository.FilePathFilters"];
        }
        else {
            return primaryGitDependency.FilePathFilters?.join("\n");
        }
    };
    const supportsProjectRepository = isProjectRepositoryGitSourceSupported(props.project?.PersistenceSettings, props.processType);
    const gitRepositorySource = props.properties["Octopus.Action.GitRepository.Source"] ? props.properties["Octopus.Action.GitRepository.Source"] : "External";
    return (<div>
            {supportsProjectRepository && (<ExpandableFormSection errorKey="GitRepositorySource" title="Repository Source" summary={gitRepositorySourceSummary()} help={"Select where the git repository is configured"} isExpandedByDefault={props.expandedByDefault}>
                    <RadioButtonGroup accessibleName={"GitRepositorySource"} value={gitRepositorySource} onChange={(val) => handleGitRepositorySourceChanged(val)} error={props.getFieldError("Octopus.Action.GitRepository.Source")}>
                        <RadioButton accessibleName="Project" value="Project" label={<div>
                                    Project <ProjectGitRepositorySourceContextualHelp project={props.project}/>
                                </div>}/>
                        <RadioButton accessibleName="External" value="External" label={<div>
                                    External <ExternalGitRepositorySourceContextualHelp />
                                </div>}/>
                    </RadioButtonGroup>
                    {props.relatedTriggerDependencies && <RelatedGitTriggerCallout dependencies={props.relatedTriggerDependencies} showAvailableCallout={showAvailableCallout(props)}/>}
                </ExpandableFormSection>)}
            {gitRepositorySource === "External" && (<>
                    <ExpandableFormSection errorKey="Url" title="Repository URL" summary={repoUrlSummary()} help="Specify the URL of the Git repository" isExpandedByDefault={props.expandedByDefault}>
                        <VariableLookupText key="Url" localNames={props.localNames} value={primaryGitDependency.RepositoryUri} onChange={(Url) => setRepositoryUri(Url)} label="Repository HTTPS URL" error={props.getFieldError("RepositoryUri") || props.getFieldError("GitDependencies[0].RepositoryUri")} validate={required("Enter a Git repository URL.")} accessibleName={"URL for Git repository"}/>
                        <Note>
                            E.g.{" "}
                            <code>
                                https://
                                <wbr />
                                github.com/
                                <wbr />
                                OctopusSamples/
                                <wbr />
                                OctoPetShop.git
                            </code>
                        </Note>
                        {props.relatedTriggerDependencies && <RelatedGitTriggerCallout dependencies={props.relatedTriggerDependencies} showAvailableCallout={showAvailableCallout(props)}/>}
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Authentication" isExpandedByDefault={props.expandedByDefault} title="Authentication" summary={authSummary()} help={"Specify how to authenticate to the Git repository"}>
                        <RadioButtonGroup value={primaryGitDependency.GitCredentialType} onChange={(val) => setCredentialType(val)} error={props.getFieldError("GitCredentialType")} accessibleName="GitCredentialType">
                            <RadioButton value={"Library"} label="Git credentials" accessibleName="GitCredentialTypeLibrary"/>
                            {isUsingLibraryAuth() && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>You need the 'GitCredentialView' permission to change the Git credential.</Note>}>
                                    <GitCredentialSelect items={props.gitCredentials} onChange={(Id) => setCredentialId(Id)} value={primaryGitDependency.GitCredentialId ?? ""} onRequestRefresh={props.refreshGitCredentials} error={props.getFieldError("GitCredentialId")} helperText="Select the Git credential to use" data-testid="gitCredentialSelect"/>
                                </PermissionCheck>)}
                            <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                        </RadioButtonGroup>
                        {props.relatedTriggerDependencies && <RelatedGitTriggerCallout dependencies={props.relatedTriggerDependencies} showAvailableCallout={showAvailableCallout(props)}/>}
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={branchSummary()} help="Specify the default branch you want to use" isExpandedByDefault={props.expandedByDefault}>
                        <VariableLookupText key="DefaultBranch" localNames={props.localNames} value={primaryGitDependency.DefaultBranch} onChange={(DefaultBranch) => setDefaultBranch(DefaultBranch)} label="Default Branch" error={props.getFieldError("DefaultBranch")} validate={required("Enter the default branch for your Git repository.")} accessibleName={"Name of the default branch on the Git repository"}/>
                        {props.relatedTriggerDependencies && <RelatedGitTriggerCallout dependencies={props.relatedTriggerDependencies} showAvailableCallout={showAvailableCallout(props)}/>}
                    </ExpandableFormSection>
                </>)}
            {props.enableFilePathFilters && (<ExpandableFormSection errorKey="Path" title="File Paths" summary={filePathsSummary()} help={props.filePathOverrides?.help ?? "Paths to filter the files used during deployment"} isExpandedByDefault={props.expandedByDefault}>
                    <VariableLookupText key="Path" localNames={props.localNames} value={getFilePathFilterValue() ?? ""} onChange={(filePaths) => setFilePaths(filePaths)} label={props.filePathOverrides?.label ?? "File Path Filters"} error={props.getFieldError(props.filePathOverrides?.customPropertyName ?? "Octopus.Action.GitRepository.FilePathFilters")} validate={(p) => props.filePathOverrides?.validation?.(p, filePathsNotSetValidationMessage()) ?? ""} accessibleName={props.filePathOverrides?.accessibleName ?? "File path filters"} multiline={true}/>
                    {getPathsNote()}
                    {props.relatedTriggerDependencies && <RelatedGitTriggerCallout dependencies={props.relatedTriggerDependencies} showAvailableCallout={showAvailableCallout(props)}/>}
                </ExpandableFormSection>)}
        </div>);
}
