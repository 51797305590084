import type { GitPersistenceSettings, GitRef, ProjectResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import type { GitRefQueryParams, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useEffect, useRef, useState } from "react";
import { repository } from "~/clientInstance";
import { useProjectContext } from "../../context";
import { lastAccessedGitRef } from "../../context/LastAccessedGitRef";
export interface GitRefQueryParamsProps {
    queryParams: GitRefQueryParams;
    setQueryParams: QueryParamValuesSetter<GitRefQueryParams>;
}
export function useProjectRunbooksGitRef(project: ProjectResource, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>, initialValidationResult?: ValidateGitRefV2Response) {
    return useProjectGitRef(project, queryParams, setQueryParams, (settings) => settings.ConversionState.RunbooksAreInGit, initialValidationResult);
}
export function useProjectGitRef(project: ProjectResource, queryParams: GitRefQueryParams, setQueryParams: QueryParamValuesSetter<GitRefQueryParams>, isGitEnabled?: (settings: GitPersistenceSettings) => boolean, initialValidationResult?: ValidateGitRefV2Response): [
    GitRef | undefined,
    (gitRef: string) => void,
    ValidateGitRefV2Response | undefined
] {
    const projectContext = useProjectContext();
    const validatedGitRef = useValidatedGitRef(project, queryParams.gitRef, initialValidationResult);
    useEffect(() => {
        if (validatedGitRef && validatedGitRef.GitRef) {
            // If we do have a GitRef in the query parameters, but it's different to our validated one, update it.
            const gitRef = validatedGitRef.GitRef.CanonicalName;
            setQueryParams((prev) => ({ ...prev, gitRef }));
            // Keep the last accessed GitRef up to date
            lastAccessedGitRef.save(project, validatedGitRef.GitRef.CanonicalName);
            projectContext.setState((previous) => ({ ...previous, gitRef: validatedGitRef.GitRef }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, validatedGitRef]);
    if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
        return [undefined, () => { }, undefined];
    }
    if (isGitEnabled && !isGitEnabled(project.PersistenceSettings)) {
        // Caller can pass a callback to see if the project is actually Git
        // enabled or not. If they've given us one, evaluate it.
        return [undefined, () => { }, undefined];
    }
    if (!queryParams.gitRef) {
        // If we don't have the GitRef in the query parameter, fill it with the last accessed one.
        setQueryParams((prev) => ({ ...prev, gitRef: lastAccessedGitRef.get(project) }));
    }
    return [
        // Hierarchy of GitRef preference:
        //   1. If we have a validated result, that is the best answer we're going to get
        //   2. If we have a GitRef in the query parameters, they've provided it, so use that
        //   3. If we don't have anything yet, use the last accessed GitRef (because that will be getting put
        //   in the query parameter on the next load)
        validatedGitRef?.GitRef?.CanonicalName ?? queryParams.gitRef ?? lastAccessedGitRef.get(project),
        (gitRef: string) => {
            setQueryParams((prev) => ({ ...prev, gitRef }));
        },
        validatedGitRef,
    ];
}
export function useValidatedGitRef(project: ProjectResource, gitRef: string, initialValidationResult: ValidateGitRefV2Response | undefined) {
    const [validationResult, setValidationResult] = useState<ValidateGitRefV2Response | undefined>(initialValidationResult);
    const isInitialLoad = useRef(true);
    useEffect(() => {
        const execEffect = () => {
            const fetch = async () => {
                const validationResult = await repository.Projects.validateGitRef(project, gitRef);
                if (!stale) {
                    setValidationResult(validationResult);
                }
            };
            let stale = false;
            fetch();
            return () => {
                stale = true;
            };
        };
        if (!gitRef) {
            return;
        }
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
            if (initialValidationResult) {
                return;
            }
            return execEffect();
        }
        return execEffect();
    }, [project, gitRef, initialValidationResult]);
    return validationResult;
}
