import { css } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Avatar, NavigationButton, NavigationButtonType, ActionButtonType, Pagination } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { useAggregateAPIOperationStatus, useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { Repository, PlatformHubConnectionConfigurationResource, ValidateGitRefV2Response, GitRef, ProcessTemplateSummary } from "@octopusdeploy/octopus-server-client";
import type { GetProcessTemplatesBffResponse } from "@octopusdeploy/octopus-server-client/dist/src/repositories/blueprintRepository";
import { links, type QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { AddProcessTemplateDialog } from "~/areas/blueprints/AddProcessTemplateDialog";
import DeleteProcessTemplate from "~/areas/blueprints/DeleteProcessTemplate";
import { lastAccessedPlatformHubGitRef } from "~/areas/blueprints/LastAccessedPlatformHubGitRef";
import { PlatformHubPageHeaderBranchSelector } from "~/areas/blueprints/PlatformHubPageHeaderBranchSelector";
import { usePlatformHubGitRef } from "~/areas/blueprints/usePlatformHubGitRef";
import { repository } from "~/clientInstance";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import InternalLink from "~/components/Navigation/InternalLink/index";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { getValueInStorage } from "~/hooks/localStorageHelpers";
import useLocalStorage from "~/hooks/useLocalStorage";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter/index";
interface LoaderData {
    processTemplatesResponse: GetProcessTemplatesBffResponse;
    platformHubConnectionConfiguration: PlatformHubConnectionConfigurationResource;
    initialValidationResult: ValidateGitRefV2Response | undefined;
}
interface ProcessTemplatesPageProps {
    loaderData: LoaderData;
    queryParams: ProcessTemplatesPageQueryParams;
    setQueryParams: QueryParamValuesSetter<ProcessTemplatesPageQueryParams>;
    spaceId: string;
}
export interface ProcessTemplatesPageQueryParams {
    searchValue: string;
    page: number | undefined;
    pageSize: number | undefined;
    gitRef: string;
}
const defaultPageSize = 30;
const emptyProcessTemplatesResponse: GetProcessTemplatesBffResponse = {
    ProcessTemplates: [],
    TotalResults: 0,
    ItemsPerPage: 0,
    TotalNoOfProcessTemplates: 0,
};
export async function processTemplatesPageLoader(repository: Repository, queryParams: ProcessTemplatesPageQueryParams): Promise<LoaderData> {
    const platformHubConnectionConfiguration = await repository.PlatformHubConnectionRepository.get();
    if (!platformHubConnectionConfiguration.PersistenceSettings.Url) {
        return {
            processTemplatesResponse: emptyProcessTemplatesResponse,
            platformHubConnectionConfiguration,
            initialValidationResult: undefined,
        };
    }
    let gitRef = queryParams.gitRef;
    if (!gitRef) {
        gitRef = lastAccessedPlatformHubGitRef.get(platformHubConnectionConfiguration.PersistenceSettings);
    }
    const initialValidationResult = await repository.PlatformHubRepository.validateGitRef(gitRef);
    const userPageSize = getValueInStorage(`Octopus.ProcessTemplatesList.PageSize`);
    const pageSize = userPageSize ? parseInt(userPageSize) : defaultPageSize;
    const processTemplatesResponse = await repository.Blueprints.getPaginatedTemplates({
        skip: ((queryParams.page ?? 1) - 1) * (queryParams.pageSize ?? pageSize),
        take: queryParams.pageSize ?? pageSize,
        partialName: queryParams.searchValue,
        gitRef: initialValidationResult.GitRef?.CanonicalName ?? gitRef,
    });
    return {
        processTemplatesResponse,
        platformHubConnectionConfiguration,
        initialValidationResult,
    };
}
//using current space id temporarily while we figure the Platform Hub (used for routing)
export function ProcessTemplatesPage({ loaderData, queryParams, setQueryParams, spaceId }: ProcessTemplatesPageProps) {
    const [gitRef, setGitRef] = usePlatformHubGitRef(loaderData.platformHubConnectionConfiguration.PersistenceSettings, queryParams, setQueryParams, loaderData.initialValidationResult);
    const { isInProgress, errors } = useAggregateAPIOperationStatus();
    const [userDefaultPageSize, setUserDefaultPageSize] = useLocalStorage(`Octopus.ProcessTemplatesList.PageSize`, defaultPageSize);
    const { result: pagingData, refetch: reload } = useQuery((repository) => {
        if (!gitRef) {
            return Promise.resolve(emptyProcessTemplatesResponse);
        }
        return repository.Blueprints.getPaginatedTemplates({
            skip: ((queryParams.page ?? 1) - 1) * (queryParams.pageSize ?? userDefaultPageSize),
            take: queryParams.pageSize ?? userDefaultPageSize,
            partialName: queryParams.searchValue,
            gitRef,
        });
    }, [gitRef, queryParams.page, queryParams.pageSize, queryParams.searchValue, userDefaultPageSize], "Get paginated process templates", {
        initialResult: loaderData.processTemplatesResponse,
    });
    const onPageChange = (page: number) => {
        setQueryParams({ ...queryParams, page });
    };
    const onPageSizeChange = (pageSize: number) => {
        setQueryParams({ ...queryParams, pageSize: pageSize, page: 1 });
        setUserDefaultPageSize(pageSize);
    };
    const onSearch = (value: string) => {
        setQueryParams({ ...queryParams, searchValue: value, page: 1 });
    };
    const { execute: deleteProcessTemplate } = useMutation({
        name: "Delete Process Template",
        action: (repository: Repository, processTemplate: ProcessTemplateSummary) => repository.Blueprints.del({ Id: processTemplate.Id, ChangeDescription: `Delete Process Template '${processTemplate.Name}'` }),
        afterComplete: async () => reload(),
    });
    if (!gitRef || !loaderData.platformHubConnectionConfiguration.PersistenceSettings.Url) {
        return (<PaperLayoutVNext title="Templates" busy={isInProgress} errors={errors}>
                <ConfigureVersionControlOnboarding />
            </PaperLayoutVNext>);
    }
    const addProcessTemplateAction: PrimaryPageAction = {
        type: "custom",
        key: "Add Process Template",
        hasPermissions: true,
        content: <AddProcessTemplateButton gitRef={gitRef}/>,
    };
    const showOnboarding = pagingData.TotalNoOfProcessTemplates === 0;
    const titleAccessory = <PlatformHubPageHeaderBranchSelector setGitRef={setGitRef} gitRef={gitRef} gitPersistenceSettings={loaderData.platformHubConnectionConfiguration.PersistenceSettings}/>;
    return (<PaperLayoutVNext title="Templates" busy={isInProgress} errors={errors} primaryAction={showOnboarding ? undefined : addProcessTemplateAction} titleAccessory={titleAccessory}>
            <>
                {showOnboarding ? (<AddProcessTemplatesOnboarding gitRef={gitRef}/>) : (<div>
                        <div className={styles.searchBoxContainer}>
                            <FilterSearchBox value={queryParams.searchValue} onChange={onSearch} placeholder={"Filter by name..."} autoFocus={false} debounceDelay={500}/>
                        </div>
                        <DataTable>
                            <DataTableHeader>
                                <DataTableRow>
                                    <DataTableHeaderColumn>Name</DataTableHeaderColumn>
                                    <DataTableHeaderColumn className={cn(styles.descriptionColumn)}>Description</DataTableHeaderColumn>
                                    <DataTableHeaderColumn>Last Published Version</DataTableHeaderColumn>
                                </DataTableRow>
                            </DataTableHeader>
                            <DataTableBody>
                                {pagingData.ProcessTemplates.map((pt) => (<ProcessTemplateRow processTemplate={pt} deleteProcessTemplate={deleteProcessTemplate} key={pt.Id} spaceId={spaceId}/>))}
                            </DataTableBody>
                        </DataTable>
                        <div className={styles.paginationContainer}>
                            <Pagination label="Process Templates" totalResults={pagingData.TotalResults} itemsPerPageOptions={[30, 50, 100]} selectedItemsPerPage={pagingData.ItemsPerPage} currentPage={queryParams.page ?? 1} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>
                        </div>
                    </div>)}
            </>
        </PaperLayoutVNext>);
}
//TODO put correct link to docs in callout
const AddProcessTemplatesOnboarding = ({ gitRef }: {
    gitRef: GitRef;
}) => (<OnboardingPage title="Create your first process template" intro="Process templates are a group of steps that are reusable across projects." learnMore={null} actionButtons={<AddProcessTemplateButton gitRef={gitRef}/>}/>);
const ConfigureVersionControlOnboarding = () => (<OnboardingPage title="Create your first process template" intro="Process templates are a group of steps that are reusable across projects. Process templates are stored in a Git repository. To add a process template, you'll first need to set up a Git connection in the Platform Hub." learnMore={null} actionButtons={<NavigationButton label={"Configure Version Control"} href={links.platformHubConnectionPage.generateUrl({ spaceId: repository.spaceId ?? "" })} type={NavigationButtonType.Primary}/>}/>);
interface ProcessTemplateRowProps {
    processTemplate: ProcessTemplateSummary;
    deleteProcessTemplate: (processTemplate: ProcessTemplateSummary) => Promise<void>;
    spaceId: string;
}
function ProcessTemplateRow({ processTemplate, deleteProcessTemplate, spaceId }: ProcessTemplateRowProps) {
    return (<DataTableRow className={styles.middle}>
            <DataTableRowColumn className={styles.middle}>
                <ProcessTemplateNameCell processTemplate={processTemplate} spaceId={spaceId}/>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>
                <div className={styles.rowCellPadding}>{processTemplate.Description}</div>
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>
                {processTemplate.Version && processTemplate.PublishedDate ? (<div className={styles.rowCellPadding}>
                        {processTemplate.Version} ({DateFormatter.dateToLongFormat(processTemplate.PublishedDate)})
                    </div>) : null}
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.right}>
                <div className={styles.rowCellPadding}>
                    <ProcessTemplateOverflowMenu processTemplate={processTemplate} deleteProcessTemplate={deleteProcessTemplate}/>
                </div>
            </DataTableRowColumn>
        </DataTableRow>);
}
interface ProcessTemplateNameCellProps {
    processTemplate: ProcessTemplateSummary;
    spaceId: string;
}
function ProcessTemplateNameCell({ processTemplate, spaceId }: ProcessTemplateNameCellProps) {
    const linkTarget = links.editProcessTemplatePage.generateUrl({ spaceId, slug: processTemplate.Slug }, { gitRef: processTemplate.GitRef });
    return (<div>
            <InternalLink className={styles.nameContainer} to={linkTarget}>
                <div>
                    <Avatar src={getProcessTemplateIconUrl(processTemplate)} shape={"squared"} size={32} alt={"Process template icon"}/>
                </div>
                <div className={styles.name}>{processTemplate.Name}</div>
            </InternalLink>
        </div>);
}
export function getProcessTemplateIconUrl(processTemplate: ProcessTemplateSummary) {
    const processTemplateId = encodeURIComponent(processTemplate.Id);
    return processTemplate.Icon ? `bff/processtemplates/${processTemplateId}/icon?cb=${processTemplate.Icon.Id}-${processTemplate.Icon.Color}` : `bff/processtemplates/${processTemplateId}/icon`;
}
interface ProcessTemplateOverflowProps {
    processTemplate: ProcessTemplateSummary;
    deleteProcessTemplate: (processTemplate: ProcessTemplateSummary) => Promise<void>;
}
function ProcessTemplateOverflowMenu({ processTemplate, deleteProcessTemplate }: ProcessTemplateOverflowProps) {
    const overFlowActions: Array<MenuItem | MenuItem[]> = [];
    overFlowActions.push(OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this Process Template?", () => deleteProcessTemplate(processTemplate), <DeleteProcessTemplate processTemplateName={processTemplate.Name}/>));
    return <OverflowMenu menuItems={overFlowActions}/>;
}
function AddProcessTemplateButton({ gitRef }: {
    gitRef: GitRef;
}) {
    return (<OpenDialogButton disabled={false} label={"Add Process Template"} title={"Create new process template"} type={ActionButtonType.Primary}>
            <AddProcessTemplateDialog gitRef={gitRef}/>
        </OpenDialogButton>);
}
const styles = {
    rowCellPadding: css({ paddingLeft: space[1] }),
    middle: css({
        verticalAlign: "middle !important",
    }),
    right: css({
        verticalAlign: "middle !important",
        float: "right",
    }),
    nameContainer: css({ display: "flex", alignItems: "center", gap: space[12] }),
    name: css({ paddingLeft: space[6] }),
    searchBoxContainer: css({ paddingLeft: space[16] }),
    paginationContainer: css({
        verticalAlign: "middle !important",
        float: "right",
        paddingTop: space[4],
    }),
    descriptionColumn: css({
        width: "40%",
    }),
};
