import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, LicenseStatusResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import { repository } from "~/clientInstance";
import { DefaultSpaceChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { SpaceLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout";
import { PageContent } from "~/components/PageContent/PageContent";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Note } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import Logo from "../../../../components/Logo/Logo";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import ListTitle from "../../../../primitiveComponents/dataDisplay/ListTitle/ListTitle";
import isWithinLicenceLimit from "../License/isWithinLicenceLimit";
import AddSpaceDialogLayout from "./AddSpaceDialogLayout";
import styles from "./style.module.less";
interface SpacesState extends DataBaseComponentState {
    spacesResponse?: ResourceCollection<SpaceResource>;
    licenseStatus?: LicenseStatusResource;
    openUpgradeDialog: boolean;
    redirectTo?: LinkHref;
}
interface SpacesInternalPageProps {
    addSpaceDialogControls: DialogControls;
}
class SpaceList extends List<SpaceResource> {
}
export function SpacesPage() {
    const addSpaceDialogControls = useDialogTrigger();
    return <SpacesInternalPage addSpaceDialogControls={addSpaceDialogControls}/>;
}
class SpacesInternalPage extends DataBaseComponent<SpacesInternalPageProps, SpacesState> {
    constructor(props: SpacesInternalPageProps) {
        super(props);
        this.state = {
            openUpgradeDialog: false,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const getSpacesResponse = repository.Spaces.list();
            const getLicenseStatus = repository.Licenses.getCurrentStatus();
            this.setState({
                spacesResponse: await getSpacesResponse,
                licenseStatus: await getLicenseStatus,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const isWithinSpaceLimit = isWithinLicenceLimit(this.state.licenseStatus, "Spaces");
        const addSpacePageAction: PrimaryPageAction = isWithinSpaceLimit
            ? {
                type: "button",
                hasPermissions: isAllowed({ permission: Permission.SpaceCreate }),
                label: "Add Space",
                onClick: this.props.addSpaceDialogControls.openDialog,
            }
            : {
                type: "custom",
                key: "Unlock more spaces",
                content: (<div className={styles.spaceLimitHit}>
                          <ActionButton label="Unlock more spaces" type={ActionButtonType.Primary} onClick={() => this.showUpgradeSpacesDialog()}/>
                          <Note>You've hit the maximum number of Spaces.</Note>
                      </div>),
            };
        return (<PageContent header={{ title: "Spaces", primaryAction: this.state.spacesResponse && addSpacePageAction }} busy={this.state.busy} errors={this.errors}>
                {this.state.spacesResponse && (<SpaceList initialData={this.state.spacesResponse} onRow={(item: SpaceResource) => this.buildSpaceRow(item)} onRowRedirectUrl={(item: SpaceResource) => links.editSpacePage.generateUrl({ currentSpaceId: item.Id })} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>)}
                {this.renderDialogs()}
            </PageContent>);
    }
    private showUpgradeSpacesDialog() {
        this.setState({ openUpgradeDialog: true });
    }
    private buildSpaceRow(space: SpaceResource): ReactNode {
        return (<div className={styles.row}>
                <div className={styles.logo}>
                    <Logo url={space.Links.Logo}/>
                </div>
                <div>
                    <ListTitle>
                        {space.Name}
                        {space.IsDefault && <DefaultSpaceChip />}
                    </ListTitle>
                    <div>{space.Description}</div>
                </div>
            </div>);
    }
    private filter(filter: string | null | undefined, resource: SpaceResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase()) || (resource.Description ? resource.Description.toLowerCase().includes(filter.toLowerCase()) : false);
    }
    private renderDialogs() {
        return (<>
                <Dialog open={this.state.openUpgradeDialog}>
                    <SpaceLimitReachedDialogLayout onActionClick={() => this.setState({ openUpgradeDialog: false })}/>
                </Dialog>
                <Dialog open={this.props.addSpaceDialogControls.isOpen}>
                    <AddSpaceDialogLayout onSpaceCreated={(space) => this.setState({ redirectTo: links.editSpacePage.generateUrl({ currentSpaceId: space.Id }) })}/>
                </Dialog>
            </>);
    }
    static displayName = "SpacesInternalPage";
}
