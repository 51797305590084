import React from "react";
import * as LiveAppStatusIcon from "./LiveAppStatusIcons";
interface LiveStatusIconProps {
    status: string;
    size: LiveAppStatusIcon.LiveStatusIconSize;
}
const CreateDescription = (status: string) => {
    return `Application Health Status is '${status}'`;
};
const LiveStatusToVisuals = (status: string, size: LiveAppStatusIcon.LiveStatusIconSize): JSX.Element => {
    switch (status) {
        case "OutOfSync":
            return LiveAppStatusIcon.OutOfSyncIcon(CreateDescription("Out of Sync"), size);
        case "Progressing":
            return LiveAppStatusIcon.ProgressingIcon(CreateDescription("Progressing"), size);
        case "Degraded":
            return LiveAppStatusIcon.DegradedIcon(CreateDescription("Degraded"), size);
        case "Missing":
            return LiveAppStatusIcon.MissingIcon(CreateDescription("Missing"), size);
        case "Healthy":
            return LiveAppStatusIcon.HealthyIcon(CreateDescription("Healthy"), size);
        case "Unavailable":
            return LiveAppStatusIcon.UnavailableIcon(CreateDescription("Unavailable"), size);
        case "Unknown":
        default:
            return LiveAppStatusIcon.UnknownIcon(CreateDescription("Unknown"), size);
    }
};
export const LiveStatusIcon = (props: LiveStatusIconProps) => {
    if (props.status === "NotSupported") {
        return <></>;
    }
    const visuals = LiveStatusToVisuals(props.status, props.size);
    return <span>{visuals}</span>;
};
