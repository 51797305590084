import { css } from "@emotion/css";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { GreenTickIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/GreenTickIcon";
import { PauseIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseIcon";
import { DisabledStatusIcon } from "~/components/ActiveStatusIcon/DisabledStatusIcon";
type AuditStreamStatus = "active" | "paused" | "inactive";
interface AuditActionWithStatusProps {
    status: AuditStreamStatus;
}
export function AuditActionWithStatus({ status, children }: PropsWithChildren<AuditActionWithStatusProps>) {
    return (<div className={containerStyles}>
            {getStatusIcon(status)}
            {children}
        </div>);
}
const containerStyles = css({
    display: "flex",
    alignItems: "center",
});
function getStatusIcon(status: AuditStreamStatus) {
    switch (status) {
        case "active":
            return <GreenTickIcon />;
        case "inactive":
            return <DisabledStatusIcon />;
        case "paused":
            return <PauseIcon />;
    }
}
