import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Chip from "~/components/Chips/Chip";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
interface DeploymentFreezeDataTableStatusCellProps {
    status: DeploymentFreezeStatus;
    colSpan?: number;
    totalItems?: number;
}
export const DeploymentFreezeDataTableStatusCell = ({ status, colSpan, totalItems }: DeploymentFreezeDataTableStatusCellProps) => {
    switch (status) {
        case "Scheduled":
            return (<DataTableRowColumn colSpan={colSpan} className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon className={iconStyles} iconType={ThirdPartyIconType.Clock} color={themeTokens.color.icon.info}/>
                        <div className={statusWordStyles}>Scheduled</div>
                        {totalItems && <Chip>{totalItems}</Chip>}
                    </div>
                </DataTableRowColumn>);
        case "Active":
            return (<DataTableRowColumn colSpan={colSpan} className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon className={iconStyles} iconType={ThirdPartyIconType.CheckCircle} color={themeTokens.color.icon.success}/>
                        <div className={statusWordStyles}>Active</div>
                        {totalItems && <Chip>{totalItems}</Chip>}
                    </div>
                </DataTableRowColumn>);
        default:
            return (<DataTableRowColumn colSpan={colSpan} className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon className={iconStyles} iconType={ThirdPartyIconType.Clock} color={themeTokens.color.icon.secondary}/>
                        <div className={statusWordStyles}>Expired</div>
                        {totalItems && <Chip>{totalItems}</Chip>}
                    </div>
                </DataTableRowColumn>);
    }
};
const statusCellStyles = css({
    display: "flex",
    alignContent: "center",
});
const statusWordStyles = css({
    margin: "4px",
    alignContent: "center",
});
const iconStyles = css({
    alignSelf: "center",
});
const cellStyles = css({ width: "100%" });
