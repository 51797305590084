import { css } from "@emotion/css";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeScopeDetail, DeploymentFreezeScopeType, DeploymentFreezeTenantScopeDetail, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useEffect, useState } from "react";
import { TenantNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import DeploymentFreezeScopeCell from "./EditScopeTable/DeploymentFreezeScopeCell";
interface DeploymentFreezeTenantScopeTableProps {
    scopeDetails: DeploymentFreezeTenantScopeDetail[];
    onRemove: (item: DeploymentFreezeScopeDetail, tenantId: string) => void;
    onEnvironmentUpdate: (item: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[], tenantId?: string) => void;
    scopeType: DeploymentFreezeScopeType;
    doBusyTask: DoBusyTask;
    focussedScopeCellId: string;
    onFocusedScopeCell: (id: string) => void;
    onScopeCellLostFocus: () => void;
}
export interface TenantProjectEnvironmentRowData {
    Id: string;
    Name: string;
    LogoLink: string;
    IsDisabled: boolean;
    SpaceId: string;
    RowKey: string;
    IsChild: boolean;
    ProjectEnvironment: DeploymentFreezeScopeDetail;
    Space?: SpaceResource;
}
export const DeploymentFreezeTenantScopeTable = ({ scopeDetails, scopeType, onRemove, onEnvironmentUpdate, doBusyTask, focussedScopeCellId, onScopeCellLostFocus, onFocusedScopeCell }: DeploymentFreezeTenantScopeTableProps) => {
    const pageSize = 30;
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [paginatedScopeItems, setPaginatedItems] = useState<DeploymentFreezeTenantScopeDetail[]>(scopeDetails);
    const mapToRowData = (scopeDetails: DeploymentFreezeTenantScopeDetail[]): TenantProjectEnvironmentRowData[] => {
        return scopeDetails.flatMap((scopeDetail) => {
            // If there are no project environments, return empty array to skip this tenant
            if (!scopeDetail.ProjectEnvironments?.length) {
                return [];
            }
            return scopeDetail.ProjectEnvironments.map((projectEnv, index) => ({
                Id: scopeDetail.Id,
                IsDisabled: scopeDetail.IsDisabled,
                Name: index === 0 ? scopeDetail.Name : "",
                LogoLink: scopeDetail.LogoLink,
                SpaceId: scopeDetail.SpaceId,
                RowKey: `${scopeDetail.Id}-${projectEnv.Id}-${index}`,
                IsChild: index !== 0,
                ProjectEnvironment: projectEnv,
            }));
        });
    };
    const rowDatas = mapToRowData(paginatedScopeItems);
    useEffect(() => {
        const pagedItems = scopeDetails.slice(pageSize * currentPageIndex, pageSize * (currentPageIndex + 1));
        setPaginatedItems(pagedItems);
    }, [scopeDetails, currentPageIndex]);
    return (<div>
            <GroupedDataTable data={rowDatas} columns={[
            {
                title: "Tenant",
                render: (data) => (data.IsChild ? null : <TenantNameCellWithLink spaceId={data.SpaceId} tenantId={data.Id} tenantName={data.Name} tenantLogoLink={data.LogoLink} isDisabled={data.IsDisabled}/>),
                width: "30%",
                isChild: (data) => data.IsChild,
            },
            {
                title: "Project",
                render: (data) => (<InternalLink to={links.deploymentsPage.generateUrl({ spaceId: data.ProjectEnvironment.SpaceId, projectSlug: data.ProjectEnvironment.Id })}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginRight: "12px" }}>
                                        <Logo url={data.ProjectEnvironment.LogoLink} size="2.5em"/>
                                    </div>
                                    <div style={{ overflowWrap: "anywhere", alignSelf: "center" }}>{data.ProjectEnvironment.Name}</div>
                                </div>
                            </InternalLink>),
                width: "30%",
            },
            {
                title: "Environment",
                render: (data) => (<DeploymentFreezeScopeCell scope={data.ProjectEnvironment} tenantId={data.Id} scopeType={scopeType} onEnvironmentUpdate={onEnvironmentUpdate} doBusyTask={doBusyTask} isEditing={focussedScopeCellId === `${data.Id}-${data.ProjectEnvironment.Id}`} onFocusedScopeCell={onFocusedScopeCell} onScopeCellLostFocus={onScopeCellLostFocus}/>),
                width: "30%",
            },
            {
                title: "",
                render: (data) => (<div className={overflowMenu}>
                                <OverflowMenu accessibleName="deploymentFreezesScopeOverflowMenu" menuItems={[
                        OverflowMenuItems.deleteItem("Remove", `Are you sure you want to disassociate ${scopeType.toLocaleLowerCase()} '${data.Name} - ${data.ProjectEnvironment.Name}'?`, async () => onRemove(data.ProjectEnvironment, data.Id), <div>
                                                <p>Do you wish to continue?</p>
                                            </div>),
                    ]}/>
                            </div>),
            },
        ]} getRowKey={(data) => data.RowKey}/>
            <NumberedPagingBar totalItems={scopeDetails.length} currentPageIndex={currentPageIndex} pageSize={pageSize} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>
        </div>);
};
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.regular.default.medium,
        position: "relative",
        width: "auto",
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "top",
    },
});
const overflowMenu = css({
    display: "flex",
    justifyContent: "flex-end",
});
