/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction } from "@octopusdeploy/design-system-components";
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { Repository, SmtpConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import SendTestEmail from "~/areas/configuration/components/Smtp/SendEmail";
import Dialog from "~/components/Dialog/Dialog";
import { actions as dialogActions, selectors as dialogSelectors } from "~/components/Dialog/store";
import Markdown from "~/components/Markdown";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, required, Sensitive, Summary, Text } from "~/components/form";
import { Form, useForm } from "~/components/form/Form/Form";
import ParseHelper from "~/utils/ParseHelper";
import styles from "./style.module.less";
export async function smtpPageLoader(repository: Repository): Promise<SmtpPageLoaderData> {
    const smtpConfiguration = repository.SmtpConfiguration.get();
    return {
        smtpConfiguration: await smtpConfiguration,
    };
}
interface SmtpPageLoaderData {
    smtpConfiguration: SmtpConfigurationResource;
}
interface SmtpPageProps {
    loaderData: SmtpPageLoaderData;
}
export function SmtpPage({ loaderData }: SmtpPageProps) {
    const dispatch = useDispatch();
    const testEmailDialogOpen = useSelector((state: GlobalState) => dialogSelectors.createDialogOpenSelector("testEmail")(state));
    const { model, isSubmitting, setModel, formProps, createSaveAction, submit } = useForm({
        formName: "Save SMTP Configuration",
        initialModel: loaderData.smtpConfiguration,
        onSubmit: (repository, smtpConfiguration) => repository.SmtpConfiguration.modify(smtpConfiguration),
        snackbarSaveText: "SMTP details updated",
        submitPermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    });
    const pageActions: PageAction[] = [
        {
            type: "button",
            buttonType: "secondary",
            label: "Save and test",
            busyLabel: "Saving...",
            disabled: isSubmitting,
            onClick: async () => {
                await submit();
                dispatch(dialogActions.open("testEmail"));
            },
            hasPermissions: isAllowed({ permission: Permission.ConfigureServer }),
        },
    ];
    return (<PageContent header={{ primaryAction: createSaveAction(), title: smtpPageTitle, pageActions }}>
            <Dialog open={testEmailDialogOpen || false}>
                <SendTestEmail />
            </Dialog>
            <Form {...formProps}>
                <div className={styles.fullWidth}>
                    <ExpandableFormSection errorKey="SmtpHost" title="SMTP Host" focusOnExpandAll summary={model.SmtpHost ? Summary.summary(model.SmtpHost) : Summary.placeholder("No DNS hostname specified")} help="Enter the DNS hostname for your SMTP server.">
                        <Text value={model.SmtpHost} onChange={(SmtpHost) => setModel((prev) => ({ ...prev, SmtpHost }))} label="SMTP host" autoFocus={true}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="SmtpPort" title="SMTP Port" summary={model.SmtpPort ? Summary.summary(model.SmtpPort) : Summary.placeholder("No port specified")} help="Enter the TCP port for your SMTP server.">
                        <Text value={model.SmtpPort === null ? "" : model.SmtpPort!.toString()} onChange={(x) => setModel((prev) => ({ ...prev, SmtpPort: ParseHelper.safeParseInt(x, null!) }))} label="SMTP port" validate={required("Please enter an SMTP port")}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="Timeout" title="Timeout" summary={model.Timeout === null ? Summary.default("12 seconds") : Summary.summary(`${model.Timeout! / 1000} seconds`)} help="Enter timeout for SMTP operations. Value is in miliseconds.">
                        <Text value={model.Timeout === null ? "" : model.Timeout!.toString()} onChange={(x) => setModel((prev) => ({ ...prev, Timeout: ParseHelper.safeParseInt(x, null!) }))} label="Timeout" placeholder="Default value is 12000 miliseconds (12 seconds)"/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="EnableSsl" title="Use SSL/TLS" summary={model.EnableSsl ? Summary.summary("Yes") : Summary.default("No")} help={<Markdown markup="&#xA;This option controls whether or not Octopus enforces using an SSL/TLS-wrapped connection.&#xA;If this is not selected, SSL/TLS will still be used if your email server supports the `STARTTLS` extension, but it is not mandatory"/>}>
                        <Checkbox value={model.EnableSsl} onChange={(EnableSsl) => setModel((prev) => ({ ...prev, EnableSsl }))} label="Use SSL/TLS" className={styles.rememberMe}/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="SendEmailFrom" title="From Address" summary={model.SendEmailFrom ? Summary.summary(model.SendEmailFrom) : Summary.placeholder("No from address specified")} help="All emails will be sent 'From' this address.">
                        <Text value={model.SendEmailFrom} onChange={(SendEmailFrom) => setModel((prev) => ({ ...prev, SendEmailFrom }))} label="From address"/>
                    </ExpandableFormSection>

                    <ExpandableFormSection errorKey="Credentials" title="Credentials" summary={model.SmtpLogin ? Summary.summary(`Credentials have been entered; username is ${model.SmtpLogin}`) : Summary.placeholder("Add authentication details if your SMTP server requires authentication")} help="Leave blank if your SMTP server does not require authentication.">
                        <Text value={model.SmtpLogin!} onChange={(SmtpLogin) => setModel((prev) => ({ ...prev, SmtpLogin }))} label="SMTP login"/>
                        <br />
                        <Sensitive value={model.SmtpPassword} onChange={(SmtpPassword) => setModel((prev) => ({ ...prev, SmtpPassword }))} label="SMTP password"/>
                    </ExpandableFormSection>
                </div>
            </Form>
        </PageContent>);
}
export const smtpPageTitle = "SMTP Configuration";
