// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { DeploymentFreezeTenantScopeDetail, ReferenceDataItem, SpaceResource, TagSetResource, TenantSummary } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { TenantConnectedProjectSummary } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/GetTenantConnectedProjectBffResponse";
import { cloneDeep } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import type { DeploymentFreezeModel } from "~/areas/configuration/components/DeploymentFreezes/EditDeploymentFreeze";
import type { ConnectionWizardDialogLayoutProps } from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import { ConnectionWizardDialogLayoutInternal } from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import type { NamedItemWithLogo } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import PanelSelector, { SelectItemType } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import type { NamedItemWithLogoAndEnvironments } from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import type { Filter, FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { createSpaceFilter, createTagSetFilters, FilterMultiplicity, getExcludedSpaceValue, getExcludedTagSetValues, getIncludedSpaceValue, getIncludedTagSetValues } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import DialogLayoutConnect from "~/components/Dialog/DialogLayoutConnect";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import * as tenantTagsets from "~/components/tenantTagsets";
import SelectProjectEnvironments from "./SelectProjectEnvironments";
import SelectProjects from "./SelectProjects";
const AssignTenantsToFreezeWizardDialogLayout = DialogLayoutConnect.to<ConnectionWizardDialogLayoutProps>(ConnectionWizardDialogLayoutInternal);
AssignTenantsToFreezeWizardDialogLayout.displayName = "AssignTenantsToFreezeWizardDialogLayout";
interface ConnectFreezeTenantsDialogProps {
    alreadyConnectedTenantIds: string[];
    deploymentFreeze: DeploymentFreezeModel;
    onConnected: (deploymentFreeze: DeploymentFreezeModel, numberOfTenantsConnected: number) => void;
}
export default function ConnectFreezeTenantsDialog(props: ConnectFreezeTenantsDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null)} operationName="AssignTenants" renderWhenLoaded={(data) => <ConnectFreezeTenants {...data} {...props}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title="Assign Tenants" busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    spaces: SpaceResource[];
    tenants: TenantSummaryResourceWithSpace[];
    tenantTagSets: TagSetResource[];
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (): Promise<InitialDataProps> => {
    const tenants = await repository.Tenants.summariesAcrossAllSpaces();
    const spaces = await repository.Spaces.all();
    const tenantTagSets = (await tenantTagsets.getAllTagSetsAcrossAllSpacesBff()).TagSets;
    return { tenants, spaces, tenantTagSets };
};
const appendSpaceContext = (tenant: DeploymentFreezeTenantScopeDetail, spaces: SpaceResource[]): DeploymentFreezeTenantScopeDetail => {
    if (spaces.length === 1)
        return { ...tenant, Space: undefined };
    const space = spaces.find((s) => s.Id === tenant.SpaceId);
    return { ...tenant, Space: space };
};
type ConnectFreezeTenantsProps = ConnectFreezeTenantsDialogProps & InitialDataProps;
export interface TenantSummaryResourceWithSpace extends TenantSummary {
    Space?: SpaceResource;
}
interface ConnectFreezeTenantsState extends DataBaseComponentState {
    availableTenants: DeploymentFreezeTenantScopeDetail[];
    selectedTenants: DeploymentFreezeTenantScopeDetail[];
    selectedProject: NamedItemWithLogoAndEnvironments[];
    availableProjectsPerTenant: Map<string, string[]>;
    availableTenantProjectEnvironment: TenantConnectedProjectSummary[];
    filter: SelectTenantsFilterParameters;
    totalAvailableTenantCount: number;
    spaces: SpaceResource[];
    tenantTagSets: TagSetResource[];
}
class ConnectFreezeTenants extends DataBaseComponent<ConnectFreezeTenantsProps, ConnectFreezeTenantsState> {
    constructor(props: ConnectFreezeTenantsProps) {
        super(props);
        const availableTenants: DeploymentFreezeTenantScopeDetail[] = props.tenants
            .filter((t) => !props.alreadyConnectedTenantIds.includes(t.Id))
            .map((t) => appendSpaceContext({
            Id: t.Id,
            LogoLink: t.Logo,
            Name: t.Name,
            SpaceId: t.SpaceId,
            IsDisabled: t.IsDisabled,
            ProjectEnvironments: [],
        }, props.spaces));
        const selectedTenants = cloneDeep(props.deploymentFreeze.tenantScopes).map((t) => appendSpaceContext(t, props.spaces));
        this.state = {
            availableTenants,
            totalAvailableTenantCount: availableTenants.length,
            selectedTenants: selectedTenants,
            filter: {
                name: "",
                filterByTags: [],
                filterByExcludedTags: [],
            },
            spaces: this.props.spaces,
            tenantTagSets: this.props.tenantTagSets,
            selectedProject: [],
            availableTenantProjectEnvironment: [],
            availableProjectsPerTenant: new Map<string, string[]>(),
        };
    }
    canConnect = () => this.state.selectedTenants.length > 0 && !this.state.selectedTenants.some((t) => t.ProjectEnvironments.length == 0 || t.ProjectEnvironments.some((pe) => pe.Environments.length == 0));
    connect = async () => {
        return this.doBusyTask(async () => {
            const freeze = cloneDeep(this.props.deploymentFreeze);
            freeze.tenantScopes = [...this.state.selectedTenants];
            setTimeout(() => this.props.onConnected(freeze, this.state.selectedTenants.length), 0);
        });
    };
    //#region Tenant Selection Step
    nameFilterChanged = async (name: string): Promise<boolean> => {
        return await this.reloadTenants({ ...this.state.filter, name });
    };
    getFilters = (): Filter[] => {
        const tagSetFilters = createTagSetFilters(Array.from(this.state.tenantTagSets), this.state.filter.filterByTags, this.state.filter.filterByExcludedTags);
        return [createSpaceFilter(this.state.spaces, this.state.filter.filterBySpace, this.state.filter.filterByExcludedSpace, FilterMultiplicity.Always), ...tagSetFilters];
    };
    filtersChanged = async (filterValues: FilterValue[]): Promise<boolean> => {
        const filterByTags = getIncludedTagSetValues(filterValues);
        const filterByExcludedTags = getExcludedTagSetValues(filterValues);
        const filterBySpace = getIncludedSpaceValue(filterValues);
        const filterByExcludedSpace = getExcludedSpaceValue(filterValues);
        return await this.reloadTenants({ ...this.state.filter, filterByTags, filterByExcludedTags, filterBySpace, filterByExcludedSpace });
    };
    reloadTenants = async (filter: SelectTenantsFilterParameters) => this.doBusyTask(async () => {
        const filteredTenants = await repository.Tenants.summariesAcrossAllSpaces({
            filterByName: filter.name,
            filterBySpace: filter.filterBySpace,
            filterByExcludedSpace: filter.filterByExcludedSpace,
            filterByTags: filter.filterByTags,
            filterByExcludedTags: filter.filterByExcludedTags,
        });
        const currentSelectedTenantIds = this.state.selectedTenants.map((p) => p.Id);
        const availableTenants = filteredTenants
            .filter((p) => !currentSelectedTenantIds.includes(p.Id))
            .map((t) => appendSpaceContext({
            Id: t.Id,
            LogoLink: t.Logo,
            Name: t.Name,
            SpaceId: t.SpaceId,
            IsDisabled: t.IsDisabled,
            ProjectEnvironments: [],
        }, this.props.spaces));
        this.setState({ availableTenants, filter });
    });
    onTenantsSelected = async (tenants: NamedItemWithLogo[]) => {
        const selectedTenantIds = tenants.map((p) => p.Id);
        // Find newly added tenants (ones that exist in tenants but not in this.state.selectedTenants)
        const newlyAddedTenants = tenants
            .filter((tenant) => !this.state.selectedTenants.some((existingTenant) => existingTenant.Id === tenant.Id))
            .map((t) => {
            const tenantResource = this.state.availableTenants.find((tenant) => tenant.Id === t.Id);
            return appendSpaceContext({
                Id: t.Id,
                LogoLink: t.LogoLink,
                Name: t.Name,
                IsDisabled: tenantResource?.IsDisabled ?? false,
                SpaceId: t.SpaceId || "",
                ProjectEnvironments: [],
            }, this.props.spaces);
        });
        // Find tenants that were previously selected but are now removed
        const removedTenants: DeploymentFreezeTenantScopeDetail[] = this.state.selectedTenants.filter((tenant) => !selectedTenantIds.includes(tenant.Id));
        // Update availableTenants to include both unselected tenants and newly removed tenants
        const availableTenants: DeploymentFreezeTenantScopeDetail[] = [...this.state.availableTenants.filter((p) => !selectedTenantIds.includes(p.Id)), ...removedTenants].map((t) => appendSpaceContext(t, this.props.spaces));
        const remainingSelectedTenants = this.state.selectedTenants.filter((tenant) => selectedTenantIds.includes(tenant.Id));
        const selectedTenants: DeploymentFreezeTenantScopeDetail[] = [...remainingSelectedTenants, ...newlyAddedTenants];
        this.setState({
            selectedTenants,
            availableTenants,
            totalAvailableTenantCount: availableTenants.length,
        });
    };
    hasFilteredApplied = () => {
        if (this.state.filter.name !== "") {
            return true;
        }
        if (this.state.filter.filterBySpace || this.state.filter.filterByExcludedSpace) {
            return true;
        }
        if (this.state.filter.filterByTags || this.state.filter.filterByExcludedTags) {
            return true;
        }
        return false;
    };
    //#endregion;
    //#region Project selection step
    getAvailableProjects = async (): Promise<NamedItemWithLogoAndEnvironments[]> => {
        const selectedTenantIds = this.state.selectedTenants.map((t) => t.Id);
        const response = await repository.DeploymentFreezes.getTenantConnectedProjects(selectedTenantIds);
        const projectsMap = new Map<string, NamedItemWithLogoAndEnvironments>();
        const availableProjectsPerTenant = new Map<string, string[]>();
        response.TenantConnectedProjectSummaries.forEach((summary) => {
            const { TenantId, Project, Environment } = summary;
            if (!projectsMap.has(Project.Id)) {
                projectsMap.set(Project.Id, {
                    Id: Project.Id,
                    LogoLink: Project.Logo || "",
                    Name: Project.Name,
                    SpaceId: Project.SpaceId,
                    Environments: [],
                });
            }
            const currentProject = projectsMap.get(Project.Id);
            const environmentExists = currentProject?.Environments.some((env) => env.Id === Environment.Id);
            if (!environmentExists) {
                currentProject?.Environments.push({
                    Id: Environment.Id,
                    Name: Environment.Name,
                });
            }
            if (!availableProjectsPerTenant.has(TenantId)) {
                availableProjectsPerTenant.set(TenantId, []);
            }
            const projectIds = availableProjectsPerTenant.get(TenantId);
            if (!projectIds?.includes(Project.Id)) {
                projectIds?.push(Project.Id);
            }
        });
        const availableProjects = Array.from(projectsMap.values());
        const distinctProjects = Array.from(new Map(cloneDeep(availableProjects).map((project) => [project.Id, project])).values()).map((p) => {
            const space = this.props.spaces.find((s) => s.Id === p.SpaceId);
            p.Name = `${p.Name} (${space?.Name})`;
            return p;
        });
        this.setState({ availableProjectsPerTenant, availableTenantProjectEnvironment: response.TenantConnectedProjectSummaries });
        return distinctProjects;
    };
    onProjectsSelected = (projectIds: string[]) => {
        const updatedSelectedTenants = this.state.selectedTenants.map((tenant) => {
            const newProjectEnvironments = projectIds
                .filter((projectId) => this.state.availableProjectsPerTenant.get(tenant.Id)?.includes(projectId))
                .map((projectId) => {
                const existingProjectEnv = tenant.ProjectEnvironments.find((pe) => pe.Id === projectId);
                const project = this.state.availableTenantProjectEnvironment.find((pe) => pe.Project.Id === projectId)?.Project;
                return {
                    Id: projectId,
                    Name: project?.Name || "",
                    LogoLink: project?.Logo || "",
                    SpaceId: project?.SpaceId || "",
                    Environments: existingProjectEnv?.Environments || [],
                };
            });
            return {
                ...tenant,
                ProjectEnvironments: newProjectEnvironments,
            };
        });
        this.setState({
            selectedTenants: updatedSelectedTenants,
        });
    };
    //#endregion
    //#region environment selection step
    getAvailableEnvironments = async (): Promise<ReferenceDataItem[]> => {
        const projectIds = this.state.selectedTenants.flatMap((t) => t.ProjectEnvironments.map((pe) => pe.Id));
        const environments = this.state.availableTenantProjectEnvironment
            .filter((p) => projectIds.includes(p.Project.Id))
            .map((p) => {
            const space = this.props.spaces.find((s) => s.Id === p.Project.SpaceId);
            return {
                Name: `${p.Environment.Name} (${space?.Name})`,
                Id: p.Environment.Id,
            };
        });
        const distinctEnvs = Array.from(new Map(environments.map((e) => [e.Id, e])).values());
        return distinctEnvs;
    };
    onEnvironmentsSelected = (environmentIds: string[]) => {
        const updatedSelectedTenants = this.state.selectedTenants.map((tenant) => {
            const updatedProjectEnvironments = tenant.ProjectEnvironments.map((projectEnv) => {
                const availableProject = this.state.availableTenantProjectEnvironment.filter((p) => p.Project.Id === projectEnv.Id && p.TenantId == tenant.Id);
                const availableEnvironments = availableProject?.map((p) => p.Environment) || [];
                const selectedEnvironments = availableEnvironments
                    .filter((env) => environmentIds.includes(env.Id))
                    .map((env) => ({
                    Id: env.Id,
                    Name: env.Name,
                }));
                return {
                    ...projectEnv,
                    Environments: selectedEnvironments,
                };
            });
            return {
                ...tenant,
                ProjectEnvironments: updatedProjectEnvironments,
            };
        });
        this.setState({
            selectedTenants: updatedSelectedTenants,
        });
    };
    //#endregion
    render() {
        const disabled = !this.canConnect();
        const availableTenants = {
            records: this.state.availableTenants,
            totalCount: this.state.availableTenants.length,
        };
        return (<AssignTenantsToFreezeWizardDialogLayout title={this.props.deploymentFreeze.name.length > 0 ? `Assign Tenants to ${this.props.deploymentFreeze.name}` : "Assign tenants"} onSaveClick={this.connect} saveButtonDisabled={disabled} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.DeploymentFreezeAdminister }} saveButtonLabel={`Assign ${pluralize("Tenant", this.state.selectedTenants.length, true)}`} wizardStepNames={["Select tenants", "Select projects", "Select environments"]} selectItemType={SelectItemType.Tenant}>
                <PanelSelector items={{ ...availableTenants, totalCount: this.state.totalAvailableTenantCount }} selectedItems={this.state.selectedTenants} updateSelectedItems={this.onTenantsSelected.bind(this)} filters={this.getFilters()} onFilterChanged={this.filtersChanged.bind(this)} onNameFilterChanged={this.nameFilterChanged.bind(this)} selectItemType={SelectItemType.Tenant} isFiltered={this.hasFilteredApplied()} filteredName={this.state.filter.name}/>
                <SelectProjects selectedTenants={this.state.selectedTenants} getAvailableProjects={this.getAvailableProjects} updateSelectedProjects={this.onProjectsSelected.bind(this)} doBusyTask={this.doBusyTask}/>
                <SelectProjectEnvironments selectedTenants={this.state.selectedTenants} getAvailableEnvironments={this.getAvailableEnvironments} updateSelectedEnvironments={this.onEnvironmentsSelected.bind(this)} doBusyTask={this.doBusyTask}/>
            </AssignTenantsToFreezeWizardDialogLayout>);
    }
    static displayName = "ConnectFreezeTenants";
}
export interface SelectTenantsFilterParameters {
    name: string;
    filterBySpace?: string;
    filterByExcludedSpace?: string;
    filterByTags: string[];
    filterByExcludedTags: string[];
}
