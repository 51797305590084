/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip } from "@octopusdeploy/design-system-components";
import humanizeDuration from "humanize-duration";
import moment from "moment";
import * as React from "react";
import type { SFC } from "react";
import DateFormatter from "~/utils/DateFormatter";
interface DurationBetweenLabelProps {
    from: string;
    to: string;
    titleMode?: DurationBetweenLabelTitleMode;
    short?: boolean;
}
export enum DurationBetweenLabelTitleMode {
    Both,
    From,
    To
}
const getTitle = (props: DurationBetweenLabelProps) => {
    switch (props.titleMode || DurationBetweenLabelTitleMode.Both) {
        case DurationBetweenLabelTitleMode.From:
            return DateFormatter.dateToLongFormat(props.from);
        case DurationBetweenLabelTitleMode.To:
            return DateFormatter.dateToLongFormat(props.from);
        default:
            return DateFormatter.dateToLongFormat(props.from) + " -> " + DateFormatter.dateToLongFormat(props.to);
    }
};
const DurationBetweenLabel: SFC<DurationBetweenLabelProps> = (props) => {
    const from = moment(props.from);
    const to = moment(props.to);
    if (props.short) {
        const duration = moment.duration(to.diff(from));
        const tooltip = (<div style={{ textAlign: "right" }}>
                From: {from.format("LL HH:mm:ss.SSS Z")}
                <br />
                To: {to.format("LL HH:mm:ss.SSS Z")}
                <br />
                Duration: {duration.asSeconds().toFixed(3)} seconds
            </div>);
        return (<Tooltip content={tooltip}>
                {duration.asSeconds() < 1 ? ("<1s") : (<>
                        {duration.asHours() > 1 ? Math.floor(duration.asHours()).toLocaleString() + "h" : ""}&nbsp;{duration.asMinutes() > 1 ? duration.minutes() + "m" : ""}&nbsp;{duration.seconds()}s
                    </>)}
            </Tooltip>);
    }
    return (<span title={getTitle(props)!}>
            {humanizeDuration(to.diff(from), {
            round: true,
            largest: 2,
            conjunction: " and ",
            serialComma: false,
        })}
        </span>);
};
export default DurationBetweenLabel;
