import type { DeploymentTargetResource, ResourceCollection, TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { PageContent } from "~/components/PageContent/PageContent";
import List from "~/components/PagingList";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
class RunbookRunTasksList extends List<TaskResource<{}>> {
}
interface MachineRunbookRunsPageProps {
    machineId: string;
}
interface MachineRunbookRunsInternalProps {
    initialData: InitialData;
}
interface InitialData {
    machine: DeploymentTargetResource;
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
interface MachineRunbookRunsState extends DataBaseComponentState {
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
const Title = "Runbook Runs";
const MachineRunbookRunsLayoutFormPage = FormPage<InitialData>();
export function MachineRunbookRunsPage({ machineId }: MachineRunbookRunsPageProps) {
    return (<MachineRunbookRunsLayoutFormPage title={Title} load={async () => {
            const machine = await repository.Machines.get(machineId);
            const tasksResponse = await repository.Machines.getRunbookRuns(machine, { skip: 0 });
            return { machine, tasksResponse };
        }} renderWhenLoaded={(data) => <MachineRunbookRunsLayoutInner initialData={data}/>}/>);
}
class MachineRunbookRunsLayoutInner extends DataBaseComponent<MachineRunbookRunsInternalProps, MachineRunbookRunsState> {
    constructor(props: MachineRunbookRunsInternalProps) {
        super(props);
        this.state = {
            tasksResponse: props.initialData.tasksResponse,
        };
    }
    render() {
        return (<PageContent header={{ title: Title }} busy={this.state.busy} errors={this.errors}>
                <RunbookRunTasksList initialData={this.state.tasksResponse} onRow={(item: TaskResource<{}>) => this.buildRunbookRunTaskRow(item)} onRowRedirectUrl={(task: TaskResource<{}>) => links.taskPage.generateUrl({ taskId: task.Id })} showPagingInNumberedStyle={true} onPageSelected={async (skip: number, p: number) => {
                await this.loadRunbookRunTasks(skip);
            }}/>
            </PageContent>);
    }
    private async loadRunbookRunTasks(skip: number) {
        const tasksResponse = await repository.Machines.getRunbookRuns(this.props.initialData.machine, { skip });
        this.setState({ tasksResponse });
    }
    private buildRunbookRunTaskRow(task: TaskResource<{}>) {
        return <TaskDetails task={task} stripTopBottomPadding={true}/>;
    }
    static displayName = "MachineRunbookRunsLayoutInner";
}
