import { ActionButton, ActionButtonType, Drawer } from "@octopusdeploy/design-system-components";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import React, { useState } from "react";
import { Timeline } from "./Timeline/Timeline";
interface DeploymentTimelineDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    deploymentName: string;
    environmentId: string;
    projectId: string;
    tenantId?: string;
    spaceId: string;
}
const pageSize = 30;
export const DeploymentTimelineDrawer = (props: DeploymentTimelineDrawerProps) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [refreshLiveStatus, setRefreshLiveStatus] = useState(true);
    const timelineResponse = useInlineStatusQuery((repository) => {
        return repository.Deployments.getDeploymentTimeline(props.projectId, props.environmentId, props.tenantId, 0, (currentPage + 1) * pageSize);
    }, [props.projectId, props.environmentId, props.tenantId, currentPage], "Timeline Data Query", {
        refetchIntervalInMs: props.isOpen ? 3000 : undefined, //No need to refresh if the Drawer is not showing.
    }).result;
    const liveStatus = useInlineStatusQuery(async (repo) => {
        const response = await repo.KubernetesLiveStatus.getRollupForApplicationInstance(props.projectId, props.environmentId, props.tenantId);
        setRefreshLiveStatus(response.Status !== "NotSupported");
        return response;
    }, [props.projectId, props.environmentId, props.tenantId], "Live Status Rollup", {
        refetchIntervalInMs: props.isOpen && refreshLiveStatus ? 3000 : undefined, //No need to refresh if the Drawer is not showing.
    }).result;
    const loadMore = () => {
        setCurrentPage((prev) => prev + 1);
    };
    const items = timelineResponse?.TimelineItems.Items ?? [];
    const hasMore = (timelineResponse?.TimelineItems?.TotalResults ?? 0) > (timelineResponse?.TimelineItems?.Items?.length ?? 0);
    return (<Drawer isOpen={props.isOpen} onClose={() => {
            return;
        }} title="Deployment Timeline" subtitle={`${props.deploymentName} deployments to ${timelineResponse?.EnvironmentName}`} variant="customActions" layout="basic" customActions={[<ActionButton onClick={props.onClose} type={ActionButtonType.Secondary} label="Close"/>]} width="narrow">
            <Timeline liveStatusData={liveStatus} timelineData={items} loadMore={loadMore} hasMore={hasMore}/>
        </Drawer>);
};
