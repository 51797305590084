import { ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { VariableSelectProps } from "~/components/form/VariableSelect/ProjectVariableSelect";
import { ProjectVariableSelect } from "~/components/form/VariableSelect/ProjectVariableSelect";
import { loadProjectVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";
type ProjectAccountVariableSelectProps = Omit<VariableSelectProps, "fetchVariables">;
function ProjectAwsAccountVariableSelect(props: ProjectAccountVariableSelectProps) {
    return <ProjectVariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.AmazonWebServicesAccount], [ControlType.AmazonWebServicesAccount])} {...props}/>;
}
function ProjectAzureAccountVariableSelect(props: ProjectAccountVariableSelectProps) {
    return <ProjectVariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.AzureAccount], [ControlType.AzureAccount])} {...props}/>;
}
export function ProjectGoogleCloudAccountVariableSelect(props: ProjectAccountVariableSelectProps) {
    return <ProjectVariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.GoogleCloudAccount], [ControlType.GoogleCloudAccount])} {...props}/>;
}
function ProjectUsernamePasswordAccountVariableSelect(props: ProjectAccountVariableSelectProps) {
    return <ProjectVariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.UsernamePasswordAccount], [ControlType.UsernamePasswordAccount])} {...props}/>;
}
export const ProjectAwsBoundAccountVariableSelect = withBoundField(ProjectAwsAccountVariableSelect);
export const ProjectAzureBoundAccountVariableSelect = withBoundField(ProjectAzureAccountVariableSelect);
export const ProjectUsernamePasswordBoundAccountVariableSelect = withBoundField(ProjectUsernamePasswordAccountVariableSelect);
