import { css, injectGlobal } from "@emotion/css";
import { borderRadius, fontSize, space, themeTokens, fontFamily } from "@octopusdeploy/design-system-tokens";
export const hideCodeEditorToolbarButtonLabelsBreakpoint = 724;
const background = themeTokens.color.codeEditor.background;
export const codeEditorOuterContainerStyles = css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
});
export const codeEditorNoToolbarStyles = css({}); // this is just so we can reference the class name in the selector below
export const codeEditorContainerStyles = css({
    position: "relative",
    minHeight: "15rem",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderBottomLeftRadius: borderRadius.small,
    borderBottomRightRadius: borderRadius.small,
    transition: "0.3s",
    borderBottom: `1px solid ${themeTokens.color.border.primary}`,
    borderLeft: `1px solid ${themeTokens.color.border.primary}`,
    borderRight: `1px solid ${themeTokens.color.border.primary}`,
    flexGrow: 1,
    [`&.${codeEditorNoToolbarStyles}`]: {
        borderRadius: borderRadius.small,
    },
});
export const codeEditorContainerFocusedStyles = css({
    minHeight: "26rem",
});
export const codeEditorToolbarStyles = css({
    border: `1px solid ${themeTokens.color.border.primary}`,
    borderTopLeftRadius: borderRadius.small,
    borderTopRightRadius: borderRadius.small,
    zIndex: 9,
    backgroundColor: themeTokens.color.codeEditor.toolbar.background,
    display: "flex",
    justifyContent: "space-between",
    maxHeight: "50px",
    gap: space[12],
    padding: `${space[8]} ${space[12]}`,
    "&:last-child": {
        marginRight: 0,
    },
    "@container (max-width: 434px)": {
        flexDirection: "column",
        maxHeight: "95px",
        gap: "0.5rem",
        alignItems: "flex-start",
    },
});
export const codeEditorContainerInADialogStyles = css({
    [`&.${codeEditorContainerStyles}`]: {
        borderRadius: 0,
        border: 0,
    },
    [`&.${codeEditorToolbarStyles}`]: {
        borderRadius: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },
});
export const codeEditorToolbarButtonsContainerStyles = css({
    display: "flex",
    justifyContent: "flex-end",
    gap: space["12"],
    flexDirection: "row",
    alignItems: "center",
    "&:only-child": {
        marginLeft: "auto",
        "@container (max-width: 434px)": {
            marginLeft: "unset",
        },
    },
});
export const codeEditorTitleSectionStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: space[12],
    fontSize: "18px",
    color: themeTokens.color.text.primary,
});
export const codeEditorAutocompleteNoteContainerStyles = css({
    marginTop: "0px",
});
export const codeEditorAutocompleteNoteStyles = css({
    fontSize: fontSize["medium"],
    marginBottom: "0 !important",
    marginTop: "0.5rem",
    position: "relative",
    "& > div": {
        position: "absolute",
        bottom: "-1px",
    },
});
export const codeEditorCustomDialogActionsStyles = css({
    backgroundColor: background,
    borderTop: `1px solid ${themeTokens.color.codeEditor.text.gutter}`,
    alignItems: "center",
    [`.${codeEditorAutocompleteNoteStyles}`]: {
        color: themeTokens.color.codeEditor.text.default,
    },
});
export const codeEditorDivTextStyling = css({
    lineHeight: "24px",
});
export const codeEditorToolbarIconButtonNoLabelStyles = css({
    "&.MuiButton-root": {
        "& .MuiButton-label": {
            fontSize: 0,
        },
    },
});
export const codeEditorToolbarIconButtonStyles = css({
    "&&": {
        // increase specificity https://stackoverflow.com/a/64486501
        color: `${themeTokens.color.text.primary}`,
        opacity: 1,
        fontSize: fontSize["medium"],
        fontWeight: "normal",
        padding: "0",
        width: "auto",
        height: "2rem",
    },
    "&.MuiButton-root": {
        minWidth: "1.5rem",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "transparent",
            color: `${themeTokens.color.text.primary}`,
        },
        [`@container (max-width: ${hideCodeEditorToolbarButtonLabelsBreakpoint}px)`]: {
            "& .MuiButton-label": {
                fontSize: 0,
            },
        },
    },
    "&.MuiIconButton-root": {
        "&:hover": {
            backgroundColor: "transparent",
            color: `${themeTokens.color.text.primary}`,
        },
    },
    "& .MuiButton-iconSizeMedium > :first-child": {
        fontSize: fontSize["base"],
        width: "24px",
        textAlign: "center",
        justifyItems: "center",
        paddingLeft: "4px",
    },
    "& span": {
        padding: "0 !important",
    },
    "& svg": {
        "&&": {
            fill: `${themeTokens.color.text.primary}`,
            color: `${themeTokens.color.text.primary}`,
        },
        height: "24px",
        width: "24px",
    },
});
export const codeEditorToolbarButtonStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: themeTokens.color.text.primary,
    fontSize: fontSize["medium"],
    paddingLeft: space[8],
    paddingRight: space[8],
    cursor: "pointer",
    borderRadius: borderRadius.small,
    gap: space[4],
    height: "30px",
    backgroundColor: themeTokens.color.codeEditor.toolbar.background,
    userSelect: "none",
    "&:hover": {
        backgroundColor: themeTokens.color.codeEditor.toolbar.button.hover,
    },
});
export const codeEditorToolbarSelectStyles = css({
    margin: 0,
    height: "34px",
    "& > div": {
        color: themeTokens.color.text.primary,
        fontSize: fontSize["medium"],
        borderRadius: borderRadius.small,
        margin: "0px",
        cursor: "pointer",
    },
});
export const codeEditorCodeMirrorStyles = css({
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: fontSize["base"],
    position: "absolute",
    ".CodeMirror-fullscreen.CodeMirror-fullscreen": {
        top: 0,
        height: "100%",
    },
    ".cm-editor.cm-editor": {
        height: "inherit",
        fontFamily: fontFamily.code,
        fontSize: "1rem",
    },
    ".cm-scroller": {
        fontFamily: fontFamily.code,
    },
    ".cm-line.cm-activeLine": {
        background: "transparent",
    },
    ".cm-focused": {
        ".cm-line.cm-activeLine": {
            background: themeTokens.color.codeEditor.code.activelineBackground,
        },
        ".cm-placeholder": {
            visibility: "hidden",
        },
    },
    ".cm-gutters": {
        background: themeTokens.color.codeEditor.background,
        ".cm-gutterElement": {
            padding: "0 6px 0 6px",
        },
        ".cm-gutterElement.cm-activeLineGutter": {
            background: "transparent",
        },
    },
});
export const codeEditorCustomDialogStyles = css({
    "&&": {
        background: `${themeTokens.color.codeEditor.toolbar.background}`,
    },
});
injectGlobal({
    ".cm-tooltip.cm-tooltip-autocomplete": {
        zIndex: 99999,
        background: themeTokens.color.codeEditor.code.hintsBackground,
        fontFamily: fontFamily.code,
        ul: {
            "&[role='listbox']": {
                maxWidth: "800px",
                maxHeight: "288px",
                li: {
                    "&[role='option']": {
                        color: themeTokens.color.codeEditor.code.hint,
                        "&[aria-selected='true']": {
                            background: themeTokens.color.background.selected,
                            color: themeTokens.color.codeEditor.code.hint,
                        },
                    },
                },
            },
        },
    },
});
export const codeEditorSettingsPopoverStyles = css({
    padding: "10px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
});
export const codeEditorSettingsSwitchStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
});
