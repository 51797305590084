import { css } from "@emotion/css";
import { borderRadius } from "@octopusdeploy/design-system-tokens";
export { DegradedIcon } from "./DegradedIcon";
export { HealthyIcon } from "./HealthyIcon";
export { MissingIcon } from "./MissingIcon";
export { OutOfSyncIcon } from "./OutOfSyncIcon";
export { ProgressingIcon } from "./ProgressingIcon";
export { UnavailableIcon } from "./UnavailableIcon";
export { UnknownIcon } from "./UnknownIcon";
export type LiveStatusIconSize = 28 | 48;
export const svgStyle = css({
    borderRadius: borderRadius.small,
});
