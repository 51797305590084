import type { DeploymentTargetResource, EnvironmentResource, ProjectResource, Repository } from "@octopusdeploy/octopus-server-client";
import type { KubernetesLiveStatusResource } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveStatusResource";
import { useState } from "react";
import * as React from "react";
import type { LiveObjectStatusFilter } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { defaultFilter, filterSections } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { RefreshingLiveStatusTable } from "~/areas/projects/components/Observability/LiveStatusTable";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/index";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { NotFound } from "~/components/NotFound/NotFound";
export async function liveStatusPageLoader(repository: Repository, environmentId: string, projectContext: Promise<ProjectContextProps>, tenantId?: string): Promise<LoaderData> {
    const { state: { model: project }, } = await projectContext;
    const kubernetesLiveStatusResources = await repository.KubernetesLiveStatus.getForApplicationInstance(project.Id, environmentId, tenantId);
    const deploymentTargets = await repository.Machines.all({ ids: kubernetesLiveStatusResources.Resources.map((r) => r.MachineId) });
    return {
        environment: await repository.Environments.get(environmentId),
        kubernetesLiveStatusResources,
        deploymentTargets,
    };
}
interface LoaderData {
    environment: EnvironmentResource;
    kubernetesLiveStatusResources: KubernetesLiveStatusResource;
    deploymentTargets: DeploymentTargetResource[];
}
type LiveStatusPageProps = {
    loaderData: LoaderData;
    project: ProjectResource;
    tenantId?: string;
};
class FilterLayout extends AdvancedFilterLayout<LiveObjectStatusFilter> {
}
export function LiveStatusPage(props: LiveStatusPageProps) {
    const [filter, setFilter] = useState<LiveObjectStatusFilter>(defaultFilter);
    function updateFilterContent<K extends keyof LiveObjectStatusFilter>(updates: Pick<LiveObjectStatusFilter, K>) {
        setFilter({ ...defaultFilter, ...updates });
    }
    return (<FeatureToggleVisibility toggle="KubernetesLiveObjectStatusFeatureToggle" disabledContent={<NotFound />}>
            <ProjectPaperLayout title={`${props.project.Name} ${props.loaderData.environment.Name} Live Status`} fullWidth={true}>
                <FilterLayout filterSections={filterSections(filter, setFilter)} filter={filter} defaultFilter={defaultFilter} onFilterReset={(filter: LiveObjectStatusFilter) => {
            setFilter(defaultFilter);
        }} renderContent={() => (<RefreshingLiveStatusTable kubernetesLiveStatusResources={props.loaderData.kubernetesLiveStatusResources} deploymentTargets={props.loaderData.deploymentTargets} environmentId={props.loaderData.environment.Id} projectId={props.project.Id} tenantId={props.tenantId} filter={filter}/>)} additionalHeaderFilters={[<FilterSearchBox placeholder={"Search by resource name..."} value={filter.name} onChange={(x) => updateFilterContent({ name: x })} autoFocus={true}/>]}/>
            </ProjectPaperLayout>
        </FeatureToggleVisibility>);
}
