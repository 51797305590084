/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { LetsEncryptConfigurationResource, ConfigureLetsEncryptArguments, TaskResource } from "@octopusdeploy/octopus-server-client";
import { TaskName } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { OctoLink } from "@octopusdeploy/utilities";
import { sortBy, last } from "lodash";
import * as React from "react";
import { client, repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import ErrorPanel from "~/components/ErrorPanel";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface LetsEncryptState extends DataBaseComponentState {
    config?: LetsEncryptConfigurationResource;
    latestTasks?: Array<TaskResource<ConfigureLetsEncryptArguments>>;
    redirectToTask?: string;
    octopusServerIsInACluster?: boolean;
}
interface LetsEncryptInternalPageProps {
    disableConnectionDialogControls: DialogControls;
}
export const isLetsEncryptSupported = () => {
    return client.getSystemLink((l) => l.LetsEncryptConfiguration);
};
export const letsEncryptNotSupportedErrorMessage = `Let's Encrypt is not currently available on your server running inside a container. See ${OctoLink.Create("LetsEncryptIntegration")} for more details.`;
export function LetsEncryptPage() {
    const disableConnectionDialogControls = useDialogTrigger();
    return <LetsEncryptInternalPage disableConnectionDialogControls={disableConnectionDialogControls}/>;
}
class LetsEncryptInternalPage extends DataBaseComponent<LetsEncryptInternalPageProps, LetsEncryptState> {
    constructor(props: LetsEncryptInternalPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        if (isLetsEncryptSupported())
            return this.doBusyTask(async () => {
                const getLetsEncryptConfiguration = repository.LetsEncryptConfiguration.get();
                const nodes = repository.OctopusServerNodes.all();
                const getLetsEncryptTasks = repository.Tasks.list({ name: TaskName.ConfigureLetsEncrypt, take: 30 });
                this.setState({
                    config: await getLetsEncryptConfiguration,
                    latestTasks: (await getLetsEncryptTasks).Items,
                    octopusServerIsInACluster: (await nodes).length > 1,
                });
            }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    getMostRecentCompleted() {
        const completed = this.state.latestTasks!.filter((t) => !!t.CompletedTime);
        return last(sortBy(completed, (t) => t.CompletedTime));
    }
    getMostRecentQueued() {
        return last(sortBy(this.state.latestTasks, (t) => t.QueueTime));
    }
    getEnabledStatusMessage() {
        const mostRecentCompleted = this.getMostRecentCompleted();
        if (!mostRecentCompleted) {
            const mostRecentQueued = this.getMostRecentQueued();
            return mostRecentQueued ? (<Section>
                    Let's Encrypt integration is currently enabled, but has not yet completed it's initial configuration. The <InternalLink to={links.taskPage.generateUrl({ taskId: mostRecentQueued.Id })}>task</InternalLink> was queued
                    <TimeFromNowLabel time={mostRecentQueued.QueueTime!}/> and is currently {mostRecentQueued.State}
                </Section>) : (<Section>Let's Encrypt integration is currently enabled, but no Let's Encrypt renewal task can be found. It may have been deleted.</Section>);
        }
        const taskLink = (<InternalLink to={links.taskPage.generateUrl({ taskId: mostRecentCompleted.Id })}>
                <TimeFromNowLabel time={mostRecentCompleted.CompletedTime!}/>
            </InternalLink>);
        const expiryLabel = <TimeFromNowLabel time={this.state.config!.CertificateExpiryDate}/>;
        return mostRecentCompleted.FinishedSuccessfully ? (<Section>
                <p>
                    Let's Encrypt integration is currently enabled{";"} the SSL certificate was last renewed {taskLink} and is due to expire {expiryLabel}.
                </p>
                <p>Octopus will automatically request a new certificate 21 days before this certificate expires.</p>
            </Section>) : (<Callout type={"danger"} title={"Unable to renew certificate"}>
                Let's Encrypt integration is currently enabled, but we have been unable to renew the certificate successfully. The last attempt was {taskLink}. The certificate is due to expire {expiryLabel}.
            </Callout>);
    }
    render() {
        if (this.state.redirectToTask) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTask })} push={true}/>;
        }
        const title = "Let's Encrypt";
        if (!isLetsEncryptSupported()) {
            return (<PageContent header={{ title }}>
                    <ErrorPanel message={letsEncryptNotSupportedErrorMessage}/>
                </PageContent>);
        }
        const config = this.state.config;
        const disablePageAction: PageAction = {
            type: "button",
            buttonType: "destructive",
            label: "Disable",
            onClick: this.props.disableConnectionDialogControls.openDialog,
        };
        const renewNowPageAction: PrimaryPageAction = {
            type: "button",
            label: "Renew Now",
            onClick: () => this.renewNow(),
        };
        const configurePageAction: PrimaryPageAction = {
            type: "navigate",
            label: "Configure",
            path: links.configureLetsEncryptPage.generateUrl(),
        };
        const pageActions: PageAction[] = [];
        let primaryAction: PrimaryPageAction | undefined = undefined;
        if (config && config.Enabled) {
            pageActions.push(disablePageAction);
            primaryAction = !!config.CertificateExpiryDate ? renewNowPageAction : undefined;
        }
        else if (!this.state.octopusServerIsInACluster) {
            primaryAction = configurePageAction;
        }
        const body = config && (<div>
                {this.renderDialog()}
                {this.state.octopusServerIsInACluster ? (<Callout type={"warning"} title={"Not supported in High Availability configurations"}>
                        Let's Encrypt is only supported in single node Octopus Server configurations. <ExternalLink href="LetsEncryptIntegration#high-availability-configurations-not-supported">Learn more</ExternalLink>
                    </Callout>) : null}
                {this.state.config!.Enabled ? (this.getEnabledStatusMessage()) : (<SectionNote>
                        Octopus can integrate with <ExternalLink href="LetsEncryptOrg">Let's Encrypt</ExternalLink> to automatically manage the TLS/SSL certificate for the Octopus Portal.{" "}
                        <ExternalLink href="LetsEncryptIntegration">Learn more</ExternalLink>.
                    </SectionNote>)}
            </div>);
        return (<PageContent header={{ title, pageActions, primaryAction }} busy={this.state.busy} errors={this.errors}>
                {this.state.config && body}
            </PageContent>);
    }
    private renderDialog() {
        return (<DeleteDialog open={this.props.disableConnectionDialogControls.isOpen} onClose={this.props.disableConnectionDialogControls.closeDialog} title="Disable Let's Encrypt" deleteButtonLabel="Disable" deleteButtonBusyLabel="Disabling..." onDeleteClick={() => this.disable()} renderContent={() => <div>Are you sure you want to disable Let's Encrypt?</div>}/>);
    }
    private async disable() {
        const config = await repository.LetsEncryptConfiguration.get();
        config.Enabled = false;
        const updated = await repository.LetsEncryptConfiguration.modify(config);
        this.setState({ config: updated });
        return true;
    }
    private renewNow() {
        return this.doBusyTask(async () => {
            const config = this.state.config;
            const task = await repository.Tasks.createRenewLetsEncryptTask({
                DnsName: config!.DnsName,
                RegistrationEmailAddress: config!.RegistrationEmailAddress,
                AcceptLetsEncryptTermsOfService: config!.AcceptLetsEncryptTermsOfService,
                HttpsPort: config!.HttpsPort,
                IPAddress: config!.IPAddress,
                Path: config!.Path,
            });
            this.setState({ redirectToTask: task.Id });
        });
    }
    static displayName = "LetsEncryptInternalPage";
}
