import { css, cx } from "@emotion/css";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { DeploymentFreezeStatus, type DeploymentFreezeSummary, type PagingCollection } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DeploymentFreezeDataTableEnvironmentsCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableEnvironmentsCell";
import { DeploymentFreezeDataTableNameCell } from "~/areas/configuration/components/DeploymentFreezes/DataTable/Cells/DeploymentFreezeDataTableNameCell";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { DataTable, DataTableBody, DataTableFooter, DataTableHeader, DataTableHeaderColumn, DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { DeploymentFreezeDataRecurrenCadenceCell } from "./Cells/DeploymentFreezeDataRecurrenCadenceCell";
import { DeploymentFreezeDataTableProjectsCell } from "./Cells/DeploymentFreezeDataTableProjectsCell";
import { DeploymentFreezeDataTableScopesCell } from "./Cells/DeploymentFreezeDataTableScopesCell";
import { DeploymentFreezeDataTableStatusCell } from "./Cells/DeploymentFreezeDataTableStatusCell";
interface DeploymentFreezeTableContentProps {
    deploymentFreezes: PagingCollection<DeploymentFreezeSummary>;
    currentPage: number;
    onPageSelected: (pageNumber: number) => void;
}
export function DeploymentFreezeTableContent({ deploymentFreezes, currentPage, onPageSelected }: DeploymentFreezeTableContentProps) {
    const isDeploymentFreezeByTenantEnabled = isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle");
    const isRecurringDeploymentFreezesEnabled = isFeatureToggleEnabled("RecurringDeploymentFreezesFeatureToggle");
    const freezeStatusGroups = Object.values(DeploymentFreezeStatus).map((status) => ({
        key: status,
        selector: (item: DeploymentFreezeSummary) => item.Status === status,
    }));
    const groupedFreezeData = freezeStatusGroups.reduce((acc, group) => {
        const groupItems = deploymentFreezes.Items.filter(group.selector);
        acc[group.key] = {
            items: groupItems,
            header: (<DataTableRow key={`header-${group.key}`} className={groupHeaderStyles}>
                        <DeploymentFreezeDataTableStatusCell totalItems={groupItems.length} colSpan={isRecurringDeploymentFreezesEnabled ? 4 : 3} status={group.key}/>
                    </DataTableRow>),
        };
        return acc;
    }, {
        [DeploymentFreezeStatus.Active]: createEmptyGroupedData(DeploymentFreezeStatus.Active),
        [DeploymentFreezeStatus.Scheduled]: createEmptyGroupedData(DeploymentFreezeStatus.Scheduled),
        [DeploymentFreezeStatus.Expired]: createEmptyGroupedData(DeploymentFreezeStatus.Expired),
    });
    const rows = Object.entries(groupedFreezeData)
        .flatMap(([_, { items, header }]) => [
        items.length > 0 && header,
        ...items.map((item) => (<DataTableRow key={item.Id}>
                    <DeploymentFreezeDataTableNameCell id={item.Id} name={item.Name} start={item.Start} end={item.End} status={item.Status}/>
                    {isRecurringDeploymentFreezesEnabled && <DeploymentFreezeDataRecurrenCadenceCell recurrenceCadence={item.RecurrenceCadence} isRecurrent={item.IsRecurrence}/>}
                    {isDeploymentFreezeByTenantEnabled ? <DeploymentFreezeDataTableScopesCell tenants={item.Tenants} projects={item.Projects}/> : <DeploymentFreezeDataTableProjectsCell projects={item.Projects}/>}
                    <DeploymentFreezeDataTableEnvironmentsCell tenants={item.Tenants} projects={item.Projects}/>
                </DataTableRow>)),
    ])
        .filter(Boolean);
    return (<DataTable className={cx(tableStyles)} title={"Deployment Freezes Overview"}>
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Deployment Freezes</DataTableHeaderColumn>
                    {isRecurringDeploymentFreezesEnabled && <DataTableHeaderColumn>Recurrence Cadence</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>{isDeploymentFreezeByTenantEnabled ? "Scopes" : "Projects"}</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environments</DataTableHeaderColumn>
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>{rows}</DataTableBody>
            <DataTableFooter>
                <NumberedPagingBar currentPageIndex={currentPage} totalItems={deploymentFreezes.TotalResults} pageSize={deploymentFreezes.ItemsPerPage} onPageSelected={(_, currentPageIndex) => onPageSelected(currentPageIndex)}/>
            </DataTableFooter>
        </DataTable>);
    function createEmptyGroupedData(status: DeploymentFreezeStatus): {
        items: DeploymentFreezeSummary[];
        header: React.ReactElement;
    } {
        return {
            items: [],
            header: (<DataTableRow key={`header-${status}`} className={groupHeaderStyles}>
                    <DeploymentFreezeDataTableStatusCell colSpan={isRecurringDeploymentFreezesEnabled ? 4 : 3} status={status}/>
                </DataTableRow>),
        };
    }
}
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    border: tableBorder,
    borderRadius: borderRadius.large,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.regular.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "middle",
    },
});
const groupHeaderStyles = css({
    backgroundColor: themeTokens.color.background.secondary.default,
    fontWeight: "bold",
    td: {
        padding: `${space[8]} ${space[12]}`,
    },
});
