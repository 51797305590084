/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Checkbox, Dialog, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { DayOfWeek, MonthlyScheduleType } from "@octopusdeploy/octopus-server-client";
import type { AnnuallyRecurringSchedule, DaysPerMonthRecurringSchedule, DaysPerWeekRecurringSchedule, OnceDailyRecurringSchedule, RecurringScheduleResource, } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { RecurringScheduleEndType, RecurringScheduleType } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import cn from "classnames";
import type { Moment } from "moment";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import DatePicker from "~/areas/projects/components/Releases/Deployments/NowOrLater/DatePicker/DatePicker";
import ComponentRow from "~/components/ComponentRow";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import { minimum, Select, Text } from "~/components/form";
import Number from "~/primitiveComponents/form/Number/Number";
import DayOfWeekOrdinalHelper from "~/utils/DeploymentFreezeScheduleHelper/DayOfWeekOrdinalHelper";
import ParseHelper from "~/utils/ParseHelper";
import styles from "./style.module.less";
interface DeploymentFreezeScheduleDialogProps {
    show: boolean;
    schedule?: RecurringScheduleResource;
    startDate: Moment;
    onClosed(): void;
    onScheduleChange(recurringSchedule: RecurringScheduleResource | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule): void;
}
interface ScheduleDays {
    Monday?: boolean;
    Tuesday?: boolean;
    Wednesday?: boolean;
    Thursday?: boolean;
    Friday?: boolean;
    Saturday?: boolean;
    Sunday?: boolean;
}
interface DeploymentFreezeScheduleDialogState {
    unit: number;
    scheduleType: RecurringScheduleType;
    scheduleDays?: ScheduleDays;
    monthlyScheduleType?: MonthlyScheduleType;
    dateOfMonth?: string;
    dayNumberOfMonth?: string;
    dayOfWeek?: DayOfWeek;
    scheduleEndType: RecurringScheduleEndType;
    scheduleEndOn?: Moment;
    scheduleEndAfter?: number;
}
const defaultScheduleDays: ScheduleDays = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
};
export function isDaysPerWeekRecurringSchedule(resource: OnceDailyRecurringSchedule | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule | AnnuallyRecurringSchedule): resource is DaysPerWeekRecurringSchedule {
    if (resource && (resource as DaysPerWeekRecurringSchedule).DaysOfWeek) {
        return true;
    }
    return false;
}
export function isDaysPerMonthRecurringSchedule(resource: OnceDailyRecurringSchedule | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule | AnnuallyRecurringSchedule): resource is DaysPerMonthRecurringSchedule {
    if (resource && (resource as DaysPerMonthRecurringSchedule).MonthlyScheduleType) {
        return true;
    }
    return false;
}
// eslint-disable-next-line react/no-unsafe
export class DeploymentFreezeScheduleDialog extends React.Component<DeploymentFreezeScheduleDialogProps, DeploymentFreezeScheduleDialogState> {
    constructor(props: DeploymentFreezeScheduleDialogProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }
    render() {
        const save = <ActionButton key="Save" label="Save" type={ActionButtonType.Save} onClick={() => this.raiseChange()}/>;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.onClosed()}/>;
        const monthlyScheduleTypeOptions = [
            { value: MonthlyScheduleType.DateOfMonth, text: `Monthly on day ${this.props.startDate.date()}` },
            { value: MonthlyScheduleType.DayOfMonth, text: `Monthly on the ${new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescription(this.props.startDate)} ${this.props.startDate.format("dddd")}` },
        ];
        // TODO @henrik: if fourth day of week in a month with five add option for last
        // eslint-disable-next-line no-constant-condition
        if (false) {
            monthlyScheduleTypeOptions.push({ value: MonthlyScheduleType.LastDayOfMonth, text: `Monthly on the last ${this.props.startDate.format("dddd")}` });
        }
        return (<Dialog open={this.props.show} dialogWidth="480px">
                <DialogLayout actions={[cancel, save]} title="Custom Recurrence" closeDialog={this.props.onClosed}>
                    <ComponentRow>
                        <Text type="number" min={1} value={this.state.unit.toString()} onChange={(val) => this.setState({ unit: ParseHelper.safeParseInt(val, 1) })} validate={minimum("Please provide a valid value", 1)} label={"Repeat Every"}/>
                        <Select value={this.state.scheduleType} onChange={(value) => this.onScheduleTypeChange(value as RecurringScheduleType)} items={[
                { value: RecurringScheduleType.OnceDaily, text: pluralize("Day", this.state.unit) },
                { value: RecurringScheduleType.DaysPerWeek, text: pluralize("Week", this.state.unit) },
                { value: RecurringScheduleType.DaysPerMonth, text: pluralize("Month", this.state.unit) },
                { value: RecurringScheduleType.Annually, text: pluralize("Year", this.state.unit) },
            ]} sortItems={false}/>
                    </ComponentRow>
                    {this.state.scheduleType === RecurringScheduleType.DaysPerWeek && (<ComponentRow>
                            <div className={cn(styles.checkboxGroup, styles.horizontal)}>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Monday" value={this.state.scheduleDays?.Monday || false} onChange={(checked) => this.onScheduleDaysChange({ Monday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Tuesday" value={this.state.scheduleDays?.Tuesday || false} onChange={(checked) => this.onScheduleDaysChange({ Tuesday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Wednesday" value={this.state.scheduleDays?.Wednesday || false} onChange={(checked) => this.onScheduleDaysChange({ Wednesday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Thursday" value={this.state.scheduleDays?.Thursday || false} onChange={(checked) => this.onScheduleDaysChange({ Thursday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Friday" value={this.state.scheduleDays?.Friday || false} onChange={(checked) => this.onScheduleDaysChange({ Friday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Saturday" value={this.state.scheduleDays?.Saturday || false} onChange={(checked) => this.onScheduleDaysChange({ Saturday: checked })}/>
                                </div>
                                <div className={styles.checkbox}>
                                    <Checkbox label="Sunday" value={this.state.scheduleDays?.Sunday || false} onChange={(checked) => this.onScheduleDaysChange({ Sunday: checked })}/>
                                </div>
                            </div>
                        </ComponentRow>)}
                    {this.state.scheduleType === RecurringScheduleType.DaysPerMonth && (<ComponentRow>
                            <Select label={"Repeat On"} value={this.state.monthlyScheduleType || MonthlyScheduleType.DateOfMonth} onChange={this.onMonthlyScheduleTypeChange} items={monthlyScheduleTypeOptions} sortItems={false}/>
                        </ComponentRow>)}
                    <span>End Recurrence</span>
                    <RadioButtonGroup value={this.state.scheduleEndType} onChange={this.onScheduleEndTypeChange}>
                        <RadioButton value={RecurringScheduleEndType.Never} label="Never" key={RecurringScheduleEndType.Never}/>
                        <RadioButton value={RecurringScheduleEndType.OnDate} label="On" key={RecurringScheduleEndType.OnDate}/>
                        {this.state.scheduleEndType === RecurringScheduleEndType.OnDate && (<div>
                                <DatePicker date={this.state.scheduleEndOn!} onDateChanged={(val) => this.setState({ scheduleEndOn: val })} utcOffset={moment(moment.now()).utcOffset()}/>
                            </div>)}
                        <RadioButton value={RecurringScheduleEndType.AfterOccurrences} label="After" key={RecurringScheduleEndType.AfterOccurrences}/>
                        {this.state.scheduleEndType === RecurringScheduleEndType.AfterOccurrences && (<div>
                                <Number label="Number of occurrences" value={this.state.scheduleEndAfter || 1} onChange={(val) => this.setState({ scheduleEndAfter: val })} min={1}/>
                            </div>)}
                    </RadioButtonGroup>
                </DialogLayout>
            </Dialog>);
    }
    initState<T>(value?: T) {
        const schedule = value as unknown as OnceDailyRecurringSchedule | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule | AnnuallyRecurringSchedule;
        return {
            unit: schedule?.Unit || 1,
            scheduleType: schedule?.Type || RecurringScheduleType.OnceDaily,
            scheduleEndType: schedule?.EndType || RecurringScheduleEndType.Never,
            scheduleEndOn: schedule?.EndType === RecurringScheduleEndType.OnDate ? schedule?.EndOnDate : undefined,
            scheduleEndAfter: schedule?.EndType === RecurringScheduleEndType.AfterOccurrences ? schedule?.EndAfterOccurrences : undefined,
            ...(isDaysPerWeekRecurringSchedule(schedule)
                ? {
                    scheduleDays: schedule.DaysOfWeek.reduce((scheduleDays: ScheduleDays, day) => {
                        scheduleDays[day] = true;
                        return scheduleDays;
                    }, defaultScheduleDays),
                }
                : {}),
            ...(isDaysPerMonthRecurringSchedule(schedule)
                ? {
                    monthlyScheduleType: schedule.MonthlyScheduleType,
                    dateOfMonth: schedule.DateOfMonth || schedule.MonthlyScheduleType === MonthlyScheduleType.DateOfMonth ? this.props.startDate.date().toString() : undefined,
                    dayNumberOfMonth: schedule.DayNumberOfMonth || schedule.MonthlyScheduleType === MonthlyScheduleType.DayOfMonth ? new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescriptor(this.props.startDate) : undefined,
                    dayOfWeek: schedule.DayOfWeek || schedule.MonthlyScheduleType === MonthlyScheduleType.DayOfMonth ? (this.props.startDate.format("dddd") as DayOfWeek) : undefined,
                }
                : {}),
        };
    }
    private onScheduleEndTypeChange = (value: RecurringScheduleEndType) => {
        this.setState({
            scheduleEndType: value,
            scheduleEndOn: value === RecurringScheduleEndType.OnDate ? moment() : undefined,
            scheduleEndAfter: value === RecurringScheduleEndType.AfterOccurrences ? 1 : undefined,
        });
    };
    private onScheduleTypeChange = (value: RecurringScheduleType) => {
        let newSchedule: RecurringScheduleResource | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule = {
            Type: value,
            Unit: 1,
        };
        switch (value) {
            case RecurringScheduleType.DaysPerWeek:
                newSchedule = {
                    ...newSchedule,
                    DaysOfWeek: undefined,
                };
                break;
            case RecurringScheduleType.DaysPerMonth:
                newSchedule = {
                    ...newSchedule,
                    MonthlyScheduleType: MonthlyScheduleType.DateOfMonth,
                    DateOfMonth: undefined,
                    DayNumberOfMonth: undefined,
                    DayOfWeek: undefined,
                };
                break;
        }
        this.setState(this.initState(newSchedule));
    };
    private onScheduleDaysChange = (value: ScheduleDays) => {
        this.setState((prev) => ({
            scheduleDays: {
                ...prev.scheduleDays,
                ...value,
            },
        }));
    };
    private onMonthlyScheduleTypeChange = (value: string | undefined) => {
        this.setState({
            monthlyScheduleType: value as MonthlyScheduleType,
            dateOfMonth: value === MonthlyScheduleType.DateOfMonth ? this.props.startDate.date().toString() : undefined,
            dayNumberOfMonth: value === MonthlyScheduleType.DayOfMonth ? new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescriptor(this.props.startDate) : undefined,
            dayOfWeek: value === MonthlyScheduleType.DayOfMonth ? (this.props.startDate.format("dddd") as DayOfWeek) : undefined,
        });
    };
    private getDaysScheduledToRunOn(): DayOfWeek[] {
        const daysToRunOn = [];
        if (this.state.scheduleDays?.Monday) {
            daysToRunOn.push(DayOfWeek.Monday);
        }
        if (this.state.scheduleDays?.Tuesday) {
            daysToRunOn.push(DayOfWeek.Tuesday);
        }
        if (this.state.scheduleDays?.Wednesday) {
            daysToRunOn.push(DayOfWeek.Wednesday);
        }
        if (this.state.scheduleDays?.Thursday) {
            daysToRunOn.push(DayOfWeek.Thursday);
        }
        if (this.state.scheduleDays?.Friday) {
            daysToRunOn.push(DayOfWeek.Friday);
        }
        if (this.state.scheduleDays?.Saturday) {
            daysToRunOn.push(DayOfWeek.Saturday);
        }
        if (this.state.scheduleDays?.Sunday) {
            daysToRunOn.push(DayOfWeek.Sunday);
        }
        return daysToRunOn;
    }
    private raiseChange() {
        const scheduleType = this.state.scheduleType;
        let recurringSchedule: RecurringScheduleResource | OnceDailyRecurringSchedule | AnnuallyRecurringSchedule | DaysPerWeekRecurringSchedule | DaysPerMonthRecurringSchedule = {
            Type: scheduleType,
            Unit: this.state.unit,
            EndType: this.state.scheduleEndType,
            EndOnDate: this.state.scheduleEndType === RecurringScheduleEndType.OnDate ? this.state.scheduleEndOn : undefined,
            EndAfterOccurrences: this.state.scheduleEndType === RecurringScheduleEndType.AfterOccurrences ? this.state.scheduleEndAfter : undefined,
        };
        switch (scheduleType) {
            case RecurringScheduleType.DaysPerWeek:
                recurringSchedule = {
                    ...recurringSchedule,
                    DaysOfWeek: this.getDaysScheduledToRunOn(),
                };
                break;
            case RecurringScheduleType.DaysPerMonth:
                recurringSchedule = {
                    ...recurringSchedule,
                    MonthlyScheduleType: this.state.monthlyScheduleType,
                    DateOfMonth: this.state.dateOfMonth,
                    DayNumberOfMonth: this.state.dayNumberOfMonth,
                    DayOfWeek: this.state.dayOfWeek!,
                };
                break;
        }
        console.log(recurringSchedule);
        this.props.onScheduleChange(recurringSchedule);
    }
    static displayName = "DeploymentFreezeScheduleDialog";
}
