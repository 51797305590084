/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButton } from "@octopusdeploy/design-system-components";
import type { AWSScriptProperties } from "@octopusdeploy/legacy-action-properties";
import { AllAWSScriptProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary, Note } from "~/components/form";
import { ProjectAwsBoundAccountVariableSelect } from "~/components/form/AccountSelect/ProjectAccountVariableSelect";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import type { ActionEditProps } from "../pluginRegistry";
/**
 * A component that displays the connection details for an AWS account
 * @param props
 */
export default abstract class AwsLoginComponent extends BaseComponent<ActionEditProps<AWSScriptProperties, any>, never> {
    static Fields: React.FC<ActionEditProps<AWSScriptProperties, any>> = (props) => {
        return (<React.Fragment>
                <BoundRadioButtonGroup resetValue={"False"} value={props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]} onChange={(x) => {
                props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: x });
                props.setProperties({ ["Octopus.Action.AwsAccount.Variable"]: "" });
            }} title="Execute using the AWS service role for an EC2 instance">
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No"/>
                </BoundRadioButtonGroup>
                {props.properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "False" &&
                (props.projectId ? (<ProjectAwsBoundAccountVariableSelect projectId={props.projectId} gitRef={props.gitRef} resetValue={""} allowClear={true} value={props.properties["Octopus.Action.AwsAccount.Variable"] as string} error={props.getFieldError("Octopus.Action.AwsAccount.Variable")} onChange={(val) => props.setProperties({ ["Octopus.Action.AwsAccount.Variable"]: val })}/>) : (<VariableLookupText label="AWS Account variable" localNames={props.localNames} value={props.properties["Octopus.Action.AwsAccount.Variable"] as string} onChange={(val) => props.setProperties({ ["Octopus.Action.AwsAccount.Variable"]: val })}/>))}
                <BoundRadioButtonGroup resetValue={"False"} value={props.properties["Octopus.Action.Aws.AssumeRole"]} onChange={(x) => props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: x })} title="Assume a different AWS service role">
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No"/>
                </BoundRadioButtonGroup>
                {props.properties["Octopus.Action.Aws.AssumeRole"] === "True" && (<div>
                        <VariableLookupText label="Assumed role ARN" localNames={props.localNames} value={props.properties["Octopus.Action.Aws.AssumedRoleArn"]} onChange={(val) => props.setProperties({ ["Octopus.Action.Aws.AssumedRoleArn"]: val })} error={props.getFieldError("Octopus.Action.Aws.AssumedRoleArn")}/>
                        <VariableLookupText label="Assumed role session name" localNames={props.localNames} value={props.properties["Octopus.Action.Aws.AssumedRoleSession"]} onChange={(val) => props.setProperties({ ["Octopus.Action.Aws.AssumedRoleSession"]: val })} error={props.getFieldError("Octopus.Action.Aws.AssumedRoleSession")}/>
                        <VariableLookupText label="Assumed role session duration (in seconds)" localNames={props.localNames} value={props.properties["Octopus.Action.Aws.AssumeRoleSessionDurationSeconds"]} onChange={(val) => props.setProperties({ ["Octopus.Action.Aws.AssumeRoleSessionDurationSeconds"]: val })} error={props.getFieldError("Octopus.Action.Aws.AssumeRoleSessionDurationSeconds")}/>
                        <Note>If blank, defaults to 3600 seconds (1 hour). AWS requires a session duration between 900 seconds (15 minutes) and 43200 seconds (12 hours).</Note>
                        <VariableLookupText label="Assumed role external ID" localNames={props.localNames} value={props.properties["Octopus.Action.Aws.AssumeRoleExternalId"]} onChange={(val) => props.setProperties({ ["Octopus.Action.Aws.AssumeRoleExternalId"]: val })} error={props.getFieldError("Octopus.Action.Aws.AssumeRoleExternalId")}/>
                        <Note>
                            {" "}
                            Learn more about <ExternalLink href="AwsDocsRolesTermsAndConcepts">Roles Terms and Concepts</ExternalLink>.
                        </Note>
                    </div>)}
            </React.Fragment>);
    };
    static summary(properties: AWSScriptProperties): SummaryNode {
        const accountVariable = properties["Octopus.Action.AwsAccount.Variable"];
        const assumedRoleArn = properties["Octopus.Action.Aws.AssumedRoleArn"];
        const assumedRole = properties["Octopus.Action.Aws.AssumeRole"] === "True";
        const useInstanceRole = properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "True";
        if (useInstanceRole) {
            return Summary.summary(<span>
                    The AWS service role for an EC2 instance will be used{" "}
                    {assumedRole && assumedRoleArn && (<span>
                            {" "}
                            to assume the AWS service role <strong>{assumedRoleArn}</strong>
                        </span>)}
                </span>);
        }
        return accountVariable
            ? Summary.summary(<span>
                      The AWS account <strong>{accountVariable}</strong> will be used
                      {assumedRole && assumedRoleArn && (<span>
                              {" "}
                              to assume the AWS service role <strong>{assumedRoleArn}</strong>
                          </span>)}
                  </span>)
            : Summary.placeholder("The account variable has not been provided");
    }
    shouldComponentUpdate(newProps: ActionEditProps<AWSScriptProperties, any>): boolean {
        return ValueInPropertiesOrErrorsHasChanged(AllAWSScriptProperties, newProps, this.props);
    }
    render() {
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.AwsAccount.Variable|Octopus.Action.Aws.AssumedRoleArn|Octopus.Action.Aws.AssumedRoleSession" isExpandedByDefault={this.props.expandedByDefault} title="AWS Account" help="Enter the AWS account details" summary={AwsLoginComponent.summary(this.props.properties)}>
                    <AwsLoginComponent.Fields {...this.props}/>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "AwsLoginComponent";
}
