import { Callout } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import React, { Fragment } from "react";
import { environmentChipList, projectChipList, tenantChipList } from "~/components/Chips";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { DataTable, DataTableHeader, DataTableRow, DataTableHeaderColumn, DataTableBody, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import styles from "./style.module.less";
export interface TenantEnvironmentMapping {
    TenantId: string;
    ProjectId: string;
    Environments: string[];
}
interface DeploymentFreezesProps {
    activeFreezes: {
        Name: string;
        Environments: string[];
        TenantEnvironments: TenantEnvironmentMapping[];
    }[];
    project: ProjectResource;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
}
export function DeploymentFreezes({ activeFreezes, project, allEnvironments, allTenants }: DeploymentFreezesProps) {
    return (<>
            {activeFreezes.length > 0 && (<Callout title="We found frozen environments in your selection" type={"information"}>
                    The following environments will be affected by a deployment freeze during your deployment:
                    <div className={styles.freezeTableContainer}>
                        <DataTable className={styles.freezeTable}>
                            <DataTableHeader>
                                <DataTableRow className={styles.freezeTableHeaderRow}>
                                    <DataTableHeaderColumn className={styles.freezeTableCell}>Deployment Freeze</DataTableHeaderColumn>
                                    <DataTableHeaderColumn className={styles.freezeTableCell}>Scope</DataTableHeaderColumn>
                                    <DataTableHeaderColumn className={styles.freezeTableCell}>Environments</DataTableHeaderColumn>
                                </DataTableRow>
                            </DataTableHeader>
                            <DataTableBody>
                                {activeFreezes.map((f, ix) => (<Fragment key={f.Name + ix + "Fragment"}>
                                        {f.Environments.length > 0 && (<DataTableRow key={f.Name + ix} displayNoBorder>
                                                <DataTableRowColumn key={f.Name + ix} className={styles.freezeTableCell}>
                                                    <div className={styles.freezeTableText}>{f.Name}</div>
                                                </DataTableRowColumn>
                                                <DataTableRowColumn key={f.Name + ix + "Scope"} className={styles.freezeTableCell}>
                                                    <div className={styles.freezeTableText}>{projectChipList([project], [project.Id])}</div>
                                                </DataTableRowColumn>
                                                <DataTableRowColumn key={f.Name + ix + "Environments"} className={styles.freezeTableCell}>
                                                    {environmentChipList(allEnvironments, f.Environments)}
                                                </DataTableRowColumn>
                                            </DataTableRow>)}
                                        {isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle") &&
                    f.TenantEnvironments.filter((tenantEnvironments) => tenantEnvironments.ProjectId === project.Id).map((tenantEnvironments, ix) => (<DataTableRow key={f.Name + ix + tenantEnvironments.TenantId} displayNoBorder>
                                                    <DataTableRowColumn key={f.Name + ix + tenantEnvironments.TenantId} className={styles.freezeTableCell}>
                                                        {f.Environments.length === 0 && <div className={styles.freezeTableText}>{f.Name}</div>}
                                                    </DataTableRowColumn>
                                                    <DataTableRowColumn key={f.Name + ix + "Scope"} className={styles.freezeTableCell}>
                                                        <div className={styles.freezeTableText}>{tenantChipList(allTenants, [tenantEnvironments.TenantId])}</div>
                                                    </DataTableRowColumn>
                                                    <DataTableRowColumn key={f.Name + ix + "Environments"} className={styles.freezeTableCell}>
                                                        {environmentChipList(allEnvironments, Object.values(tenantEnvironments.Environments))}
                                                    </DataTableRowColumn>
                                                </DataTableRow>))}
                                    </Fragment>))}
                            </DataTableBody>
                        </DataTable>
                    </div>
                    Please remove these environments from the deployment or modify the scheduled start time.
                </Callout>)}
        </>);
}
