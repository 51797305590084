import type { AnalyticSession } from "@octopusdeploy/portal-analytics";
import * as React from "react";
import { type RouteComponentProps, type RouteProps } from "react-router-dom";
import { session } from "~/clientInstance";
import { authRoutes } from "~/components/AuthenticationRoutes/authRoutes";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { createFromLocationState } from "./createFromLocationState";
interface SecureRouteProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (props: RouteComponentProps<any>) => React.ReactNode;
    analyticsSession: AnalyticSession | undefined;
}
export default function SecureRoute({ render, analyticsSession, ...rest }: SecureRouteProps) {
    const [isAuthenticated, setIsAuthenticated] = React.useState(session.isAuthenticated());
    React.useEffect(() => session.subscribe(() => {
        setIsAuthenticated(session.isAuthenticated());
    }), []);
    return (<ReloadableRoute {...rest} render={(props) => {
            // Test for analyticsSession is for debug mode recompile relaunches, otherwise it causes the user to be authenticated (session is a global statid), but the session to not be started (analyticsSession is in context)
            return isAuthenticated && analyticsSession !== undefined ? render(props) : <InternalRedirect to={{ pathname: authRoutes.signIn, state: createFromLocationState(rest.location) }}/>;
        }}/>);
}
