/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { CertificateResource, GitRefResource, PropertyValueResource, SensitiveValue, WorkerPoolResource, FeedResource, PackageReference, ActionTemplateParameterResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, getFeedTypesForPackageParameter, IsPrimaryPackageReference, SetNamedPackageReference, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { isEqual } from "lodash";
import * as React from "react";
import type { FailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import AmazonWebServicesAccountInput from "~/components/ControlInputs/AmazonWebServicesAccountInput";
import AzureAccountInput from "~/components/ControlInputs/AzureAccountInput";
import CertificateInput from "~/components/ControlInputs/CertificateInput";
import CheckboxInput from "~/components/ControlInputs/CheckboxInput";
import MultiLineTextInput from "~/components/ControlInputs/MultiLineTextInput";
import SelectInput from "~/components/ControlInputs/SelectInput";
import SensitiveInput from "~/components/ControlInputs/SensitiveInput";
import SingleLineTextInput from "~/components/ControlInputs/SingleLineTextInput";
import UsernamePasswordAccountInput from "~/components/ControlInputs/UsernamePasswordAccountInput";
import WorkerPoolInput from "~/components/ControlInputs/WorkerPoolInput";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import ExternalLink from "~/components/Navigation/ExternalLink";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { AwsAccountVariableInput, AzureAccountVariableInput, CertificateVariableInput, GoogleCloudAccountVariableInput, UsernamePasswordAccountVariableInput, WorkerPoolVariableInput } from "~/components/form/VariableSelect/VariableInput";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import { JsonUtils } from "~/utils/jsonUtils";
import selectOptionsToItems from "../../primitiveComponents/form/Select/Options";
import GoogleCloudAccountInput from "../ControlInputs/GoogleCloudAccountInput";
import type FormFieldProps from "../form/FormFieldProps";
import getSensitiveResetValue from "../form/Sensitive/getSensitiveResetValue";
import styles from "./style.module.less";
export interface PackageSourceItems {
    items: PackageReference[];
    feeds: FeedResource[];
    onRequestRefresh(): Promise<void>;
    setPackages(packages: PackageReference[], initialise?: boolean): void;
}
export interface ProjectSourceItems {
    projectId: string;
    processIdentifier: ProcessIdentifier;
    processVariableSet: VariableSetResource | FailedPermissionCheck;
    libraryVariableSets: LoadedLibraryVariableSets[] | FailedPermissionCheck;
    refreshVariables: (() => Promise<void>) | undefined;
    gitRef: GitRefResource | undefined;
    stepNames: string[];
    packages: PackageSourceItems;
}
export type SourceItems = ProjectSourceItems | PackageSourceItems;
export function isProjectSourceItems(sourceItems: SourceItems): sourceItems is ProjectSourceItems {
    return !!(sourceItems as ProjectSourceItems).projectId;
}
interface ActionTemplateParameterInputProps extends FormFieldProps<PropertyValueResource | undefined> {
    parameter: ActionTemplateParameterResource;
    localNames?: string[];
    sourceItems: SourceItems;
    disabled?: boolean;
    error?: string;
    warning?: string;
    projectId?: string;
    gitRef?: GitRefResource;
    actionType?: string;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
interface PackageDetails {
    PackageId: string;
    Package: PackageReference;
}
class ActionTemplateParameterInput extends React.Component<ActionTemplateParameterInputProps, never> {
    render() {
        return <div className={styles.container}>{this.getInputControl()}</div>;
    }
    private getInputControl = () => {
        const { warning, sourceItems, doBusyTask, value, localNames, projectId, error, actionType, ...rest } = this.props;
        const resetValue = getResetValueFromParameter(this.props.parameter);
        const type = this.props.parameter.DisplaySettings["Octopus.ControlType"];
        const label = this.props.parameter.Label || this.props.parameter.Name;
        const formProps = { ...rest, label };
        const evaluatedResetValue = typeof resetValue === "function" ? resetValue() : resetValue;
        const defaultValueIndicator = isUsingDefaultValue(value, evaluatedResetValue) ? (<Note>Using default value.</Note>) : (<Note>
                <a href="#" onClick={(e) => {
                e.preventDefault();
                if (this.props.onChange) {
                    this.props.onChange(evaluatedResetValue);
                }
            }}>
                    Reset to default
                </a>
            </Note>);
        switch (type) {
            case ControlType.SingleLineText:
                return <SingleLineTextInput value={value as string} localNames={localNames} warning={warning} defaultValueIndicator={defaultValueIndicator} {...formProps}/>;
            case ControlType.MultiLineText:
                return <MultiLineTextInput value={value as string} localNames={localNames} warning={warning} defaultValueIndicator={defaultValueIndicator} {...formProps}/>;
            case ControlType.Select: {
                const options = selectOptionsToItems(this.props.parameter.DisplaySettings["Octopus.SelectOptions"]);
                const allowClear = this.props.parameter.AllowClear !== undefined ? this.props.parameter.AllowClear : true;
                return (<SelectInput value={value as string} resetValue={resetValue as string} options={options} localNames={localNames} warning={warning} allowClear={allowClear} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask} {...formProps}/>);
            }
            case ControlType.Checkbox:
                return <CheckboxInput value={value as string} resetValue={resetValue as string} localNames={localNames} warning={warning} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask} {...formProps}/>;
            case ControlType.Sensitive:
                return <SensitiveInput value={value as SensitiveValue} resetValue={resetValue} localNames={localNames} warning={warning} {...formProps}/>;
            case ControlType.StepName:
                const items = isProjectSourceItems(sourceItems) ? sourceItems.stepNames.map((s) => ({ value: s, text: s })) : [];
                return (<React.Fragment>
                        <BoundSelect value={value as string} resetValue={resetValue as string} items={items} variableLookup={{
                        localNames,
                    }} warning={warning} {...formProps}/>
                        {defaultValueIndicator}
                    </React.Fragment>);
            case ControlType.Certificate:
                return isProjectSourceItems(sourceItems) ? (<CertificateVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (
                // This feels like a bug or oversight: Why shouldn't we be able to select certificates here?
                <CertificateInput value={value as string} items={() => Promise.resolve<CertificateResource[]>([])} warning={warning} tenantId={undefined} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={() => Promise.resolve<boolean>(true)} doBusyTask={doBusyTask} {...formProps}/>);
            case ControlType.WorkerPool:
                return isProjectSourceItems(sourceItems) ? (<WorkerPoolVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (
                // This feels like a bug or oversight: Why shouldn't we be able to select worker pools here?
                <WorkerPoolInput value={value as string} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask} items={() => Promise.resolve<WorkerPoolResource[]>([])} onRequestRefresh={() => Promise.resolve<boolean>(true)} {...formProps}/>);
            case ControlType.AmazonWebServicesAccount:
                return isProjectSourceItems(sourceItems) ? (<AwsAccountVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (
                // This feels like a bug or oversight: Why shouldn't we be able to select accounts here?
                <AmazonWebServicesAccountInput value={value as string} resetValue={resetValue as string} items={[]} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={this.emptyPromise} {...formProps}/>);
            case ControlType.GoogleCloudAccount:
                return isProjectSourceItems(sourceItems) ? (<GoogleCloudAccountVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (
                // This feels like a bug or oversight: Why shouldn't we be able to select accounts here?
                <GoogleCloudAccountInput value={value as string} resetValue={resetValue as string} items={[]} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={this.emptyPromise} {...formProps}/>);
            case ControlType.AzureAccount:
                return isProjectSourceItems(sourceItems) ? (<AzureAccountVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (
                // This feels like a bug or oversight: Why shouldn't we be able to select accounts here?
                <AzureAccountInput value={value as string} resetValue={resetValue as string} items={[]} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={this.emptyPromise} {...formProps}/>);
            case ControlType.UsernamePasswordAccount:
                return isProjectSourceItems(sourceItems) ? (<UsernamePasswordAccountVariableInput value={value as string} processIdentifier={sourceItems.processIdentifier} processVariableSet={sourceItems.processVariableSet} libraryVariableSets={sourceItems.libraryVariableSets} refreshVariables={sourceItems.refreshVariables} gitRef={sourceItems.gitRef} allowClear={true} defaultValueIndicator={defaultValueIndicator} {...formProps}/>) : (<UsernamePasswordAccountInput value={value as string} resetValue={resetValue as string} items={[]} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={this.emptyPromise} {...formProps}/>);
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            case ControlType.Package:
                const packages = isProjectSourceItems(sourceItems) ? sourceItems.packages : sourceItems;
                const isPackageParameterReferenced = packages.items.find((p) => p.Properties["PackageParameterName"] === this.props.parameter.Name);
                if (isPackageParameterReferenced) {
                    const { PackageId, Package } = this.getPackageDetails(value as string, packages.items);
                    if (Package) {
                        const feedIdValue = Package.FeedId;
                        return (<React.Fragment>
                                <PackageSelector packageId={PackageId} feedId={feedIdValue} onPackageIdChange={(packageId) => {
                                IsPrimaryPackageReference(Package)
                                    ? packages.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, packages.items))
                                    : packages.setPackages(SetNamedPackageReference(Package.Name!, { PackageId: packageId }, packages.items));
                                this.notifyChange(JSON.stringify({ PackageId: packageId, FeedId: feedIdValue }));
                            }} onFeedIdChange={(feedId) => {
                                IsPrimaryPackageReference(Package)
                                    ? packages.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, packages.items))
                                    : packages.setPackages(SetNamedPackageReference(Package.Name!, { FeedId: feedId }, packages.items));
                                this.notifyChange(JSON.stringify({ PackageId, FeedId: feedId }));
                            }} projectId={this.props.projectId!} feeds={packages.feeds} localNames={this.props.localNames!} feedType={getFeedTypesForPackageParameter(actionType!, Package)} refreshFeeds={packages.onRequestRefresh} {...formProps}/>
                                {Package.Name && (<Note>
                                        The name used to identify this package reference is <code>{Package.Name}</code>. Learn more about{" "}
                                        <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">Accessing Package References from a Custom Script</ExternalLink>.
                                    </Note>)}
                            </React.Fragment>);
                    }
                }
                return (<Callout type={"warning"} title={"This parameter is not used by the step"}>
                        <p>This parameter is not currently used by the step, it might have been replaced by another parameter.</p>
                    </Callout>);
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
            default:
                return <DebounceText warning={warning} {...formProps} value={value as string}/>;
        }
    };
    private notifyChange = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    private getPackageDetails = (value: string, packages: PackageReference[]): PackageDetails => {
        let packageIdValue = value;
        let pkg = _.find(packages, (p) => p.Properties["PackageParameterName"] === this.props.parameter.Name)!;
        if (!!value && JsonUtils.tryParseJson(value)) {
            const { PackageId, FeedId }: Partial<PackageReference> = JSON.parse(value);
            pkg = { ...pkg, PackageId: PackageId!, FeedId: FeedId! };
            packageIdValue = pkg.PackageId;
        }
        if (!pkg && packages.length > 0) {
            const hasPackageParameters = _.some(packages, (p) => p.Properties["PackageParameterName"]);
            if (!hasPackageParameters) {
                // If there's no PackageParameterName the action is the old style. Use the first item as the package.
                pkg = packages[0];
                // Pre-populate existing value
                packageIdValue = pkg.PackageId;
            }
        }
        return { PackageId: packageIdValue, Package: pkg! };
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    private emptyPromise = () => {
        return Promise.resolve();
    };
    static displayName = "ActionTemplateParameterInput";
}
const DefaultValueIsNotProvided = undefined;
function getResetValueFromParameter(parameter: ActionTemplateParameterResource) {
    const isSensitiveParameter = parameter.DisplaySettings && parameter.DisplaySettings["Octopus.ControlType"] === "Sensitive";
    if (isSensitiveParameter) {
        if (isSensitiveValue(parameter.DefaultValue)) {
            return getSensitiveResetValue(parameter.DefaultValue);
        }
        // Sensitive parameters can have non-sensitive default values. You can have
        // - Plain string values (What does this even mean?)
        // - Variable expressions (also just a string, but with #{...})
        // - empty values (i.e. ""). This is the same as not providing a default value at all
        return parameter.DefaultValue ?? DefaultValueIsNotProvided;
    }
    if (isSensitiveValue(parameter.DefaultValue)) {
        throw new Error("SensitiveValue is only supported as the default value for Sensitive parameter types");
    }
    return parameter.DefaultValue ?? DefaultValueIsNotProvided;
    function isSensitiveValue(value: PropertyValueResource | undefined): value is SensitiveValue {
        return typeof value !== "string" && value !== undefined && value !== null;
    }
}
function isUsingDefaultValue(value: PropertyValueResource | undefined, evaluatedResetValue: string | SensitiveValue | undefined) {
    // If no default value is entered, its value ends up being ""
    // While the typing suggests that the value could also be `undefined` or `null`, I'm not sure this can actually occur in practice
    const defaultValueIsEmptyString = evaluatedResetValue === "";
    const noValueProvided = value === undefined || value === null;
    // Consider the scenario where `value` === undefined, and `evaluatedResetValue` === "";
    // The API interprets an empty string as the absence of a value, and will clear the property value, leaving us with `undefined` after a round trip
    // Therefore we should interpret `undefined` as equivalent to "".
    // If we don't interpret it this way, then you could "reset to default", yet clicking the button would have no effect
    const noValueProvidedAndDefaultIsEmptyString = noValueProvided && defaultValueIsEmptyString;
    // I don't think this case can actually occur - I think the DefaultValue must always be populated (non-undefined, non-null), even if its value ends up being just ""
    const noDefaultValueProvided = evaluatedResetValue !== DefaultValueIsNotProvided;
    return noDefaultValueProvided && (isEqual(value, evaluatedResetValue) || noValueProvidedAndDefaultIsEmptyString);
}
export default DebounceValue(ActionTemplateParameterInput);
export { ActionTemplateParameterInputProps };
