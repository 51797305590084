import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { SummaryView, TenantsBffProject, TenantsOverviewProject } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import LogoGroup from "~/areas/tenants/components/LogoGroup/LogoGroup";
import Logo from "~/components/Logo/Logo";
interface TenantDataTableProjectCellProps {
    project: TenantsBffProject | undefined;
}
export function TenantsDataTableProjectCell({ project }: TenantDataTableProjectCellProps) {
    if (project) {
        return (<div className={cellStyles}>
                <div>
                    <Logo url={project.LogoLink} size={"2.2rem"}/>
                </div>
                <div>{project.Name}</div>
            </div>);
    }
    return <div>No projects</div>;
}
interface TenantDataTableProjectsCellProps {
    projects: SummaryView<TenantsOverviewProject>;
}
export function TenantsDataTableProjectsCell({ projects }: TenantDataTableProjectsCellProps) {
    if (projects.Items.length === 0) {
        return <div>No projects</div>;
    }
    if (projects.Items.length === 1) {
        return <TenantsDataTableProjectCell project={projects.Items[0]}/>;
    }
    return (<div className={cellStyles}>
            <div>
                <LogoGroup logoLinks={projects.Items.map((p) => p.LogoLink)} size={"2.2rem"}/>
            </div>
            <Leftovers count={projects.MoreItems} name={"project"}/>
        </div>);
}
const cellStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space[8],
});
