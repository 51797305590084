import type { GitPersistenceSettings, GitRef, SpecifiedGitRef } from "@octopusdeploy/octopus-server-client";
import { toGitBranch } from "@octopusdeploy/octopus-server-client";
function lastAccessedPlatformHubGitRefStore() {
    const LocalStorageKey = "octoLastAccessedPlatformHubGitRef";
    const buildKey = () => `${LocalStorageKey}`;
    const save = (gitRef: SpecifiedGitRef): void => {
        localStorage.setItem(buildKey(), gitRef);
    };
    const get = (gitPersistenceSettings: GitPersistenceSettings): GitRef => {
        const key = buildKey();
        const storedGitRef = localStorage.getItem(key) ?? toGitBranch(gitPersistenceSettings.DefaultBranch);
        return storedGitRef;
    };
    return {
        save,
        get,
    };
}
const lastAccessedPlatformHubGitRef = lastAccessedPlatformHubGitRefStore();
export { lastAccessedPlatformHubGitRef };
