/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import type { PopoverPlacement } from "@octopusdeploy/design-system-components";
import { Callout, PopoverBasicHelp } from "@octopusdeploy/design-system-components";
import { fontSize, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import { createUnassignedTargetTagsWarningCalloutClosedEvent } from "~/areas/projects/components/Process/ConnectedDeploymentTargetsStatus/UnassignedTargetTagsWarningCallout.analytics";
import { repository } from "~/clientInstance";
import { CopyToClipboardChip } from "~/components/Chips/CopyToClipboardChip";
import InternalLink from "~/components/Navigation/InternalLink";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import useLocalStorage from "~/hooks/useLocalStorage";
import contextualHelpImageDark from "./assets/img-feature-unassignedtargettags-dark.png";
import contextualHelpImageLight from "./assets/img-feature-unassignedtargettags-light.png";
interface TargetTagsWarningCalloutProps {
    unassignedTargetTags: string[];
    projectId: string;
    showCloseLink?: boolean;
    clearWarnings?: () => void;
}
export const UnassignedTargetTagsWarningCallout = (props: TargetTagsWarningCalloutProps) => {
    if (props.unassignedTargetTags.length === 0) {
        return <></>;
    }
    return (<Callout hideTitle={true} type="warning">
            <div className={containerStyles}>
                <UnassignedTargetTagsWarningCalloutContent {...props}/>
            </div>
        </Callout>);
};
export const UnassignedTargetTagsWarningCalloutContent = ({ unassignedTargetTags, showCloseLink, projectId, clearWarnings }: TargetTagsWarningCalloutProps) => {
    const trackEvent = useTrackEvent();
    const [, setShowUnassignedTargetTagWarning] = useLocalStorage(`Octopus.Project.${projectId}.ShowUnassignedTargetTagWarning`, true);
    const onClose = () => {
        trackEvent(createUnassignedTargetTagsWarningCalloutClosedEvent());
        clearWarnings?.();
        setShowUnassignedTargetTagWarning(false);
    };
    return (<>
            <div>{`${unassignedTargetTags.length} target ${pluralize("tag", unassignedTargetTags.length)} ${unassignedTargetTags.length === 1 ? "isn't" : "aren't"} assigned to deployment targets:`}</div>
            <div className={targetTagContainerStyles}>
                {unassignedTargetTags.map((targetTag) => (<CopyToClipboardChip key={targetTag} text={targetTag} noMargin={true}/>))}
            </div>
            <div>
                Assign in{" "}
                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                    Deployment Targets
                </InternalLink>
                .
            </div>
            <div className={secondaryContentStyles}>
                If this is intentional, no action is required.{" "}
                {showCloseLink && (<>
                        <span className={closeLinkStyles} onClick={onClose}>
                            Disable the warning for this project.
                        </span>
                        <DisableUnassignedTargetTagsWarningContextualHelp />
                    </>)}
            </div>
        </>);
};
const DisableUnassignedTargetTagsWarningContextualHelp = ({ position = "right-start" }: {
    position?: PopoverPlacement;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const isDarkMode = useThemePaletteType() === "dark";
    const description = (<>
            <div>
                <img src={isDarkMode ? contextualHelpImageDark : contextualHelpImageLight} alt="" style={{ width: "100%" }}/>
            </div>

            <div>Identify when a target tag in your deployment process isn't assigned to a deployment target.</div>
            <div>You can toggle the warning on or off from the ellipsis menu.</div>
        </>);
    const onOpen = () => {
        dispatchAction("View Contextual Help Popover", { context: "Unassigned Target Tags Warning" });
    };
    return <PopoverBasicHelp placement={position} onOpen={onOpen} description={description}/>;
};
const TOTAL_CALLOUT_WIDTH = 732;
const CALLOUT_PADDING = 24;
const containerStyles = css({
    width: `${TOTAL_CALLOUT_WIDTH - CALLOUT_PADDING}px`,
});
const targetTagContainerStyles = css({
    display: "flex",
    gap: "6px",
    marginTop: space["8"],
    marginBottom: space["8"],
    flexWrap: "wrap",
});
const secondaryContentStyles = css({
    marginTop: space["8"],
    color: themeTokens.color.text.secondary,
    fontSize: fontSize.small,
    lineHeight: lineHeight.xSmall,
});
const closeLinkStyles = css({
    color: themeTokens.color.text.link.default,
    cursor: "pointer",
});
