/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { ButtonClassKey } from "@material-ui/core/Button";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import * as React from "react";
import type { Theme } from "~/theme";
import type { PickClassNameKeys } from "../../primitiveComponents/types/PickClassNameKeys";
import type { OctopusTheme } from "../Theme";
import { useOctopusTheme } from "../Theme";
import { StyledMaterialChip } from "./StyledMaterialChip";
import type { CommonChipProps } from "./types";
export interface DeletableChipProps extends CommonChipProps {
    onRequestDelete: () => void;
    deleteButtonAccessibleName: string;
}
//We require the onClick to be named onClick as Material will assign the onClick handler to the component when it clones it internally
//if we used something like onDelete/onRequestDelete then we would have to duplicate on assigning the click handler, which can cause
//some problems if anything changes in how material handles things.
interface DeleteChipButtonProps {
    onClick?: (event: object) => void;
    accessibleName: string;
}
const useDeleteChipButtonStyles = makeStyles<Theme, {
    octopusTheme: OctopusTheme;
}, PickClassNameKeys<ButtonClassKey, "root">>({
    root: ({ octopusTheme }) => ({
        margin: 0,
        padding: 0,
        minWidth: "auto",
        "&:hover": {
            backgroundColor: "initial",
        },
        color: octopusTheme.iconNeutral,
    }),
});
function DeleteChipButton(props: DeleteChipButtonProps) {
    const octopusTheme = useOctopusTheme();
    const classes = useDeleteChipButtonStyles({ octopusTheme });
    return (<Button classes={classes} aria-label={props.accessibleName} onClick={props.onClick} variant={"text"}>
            <CancelIcon />
        </Button>);
}
export const DeletableChip: React.FC<DeletableChipProps> = (props) => {
    return (<StyledMaterialChip accessibilityRole={props.accessibilityRole ?? "presentation"} //We override the default role since material-ui will assign a role of button by default to the parent
     fullWidth={props.fullWidth} borderColor={props.borderColor} markAsRemoved={props.markAsRemoved} noMargin={props.noMargin} labelColor={props.labelColor} backgroundColor={props.backgroundColor} tabIndex={props.tabIndex} onDelete={props.onRequestDelete} icon={props.icon} label={props.children} tooltipContent={props.description ?? props.children} variant={props.variant} deleteIcon={<DeleteChipButton accessibleName={props.deleteButtonAccessibleName}/>} opacity={props.opacity}/>);
};
DeletableChip.displayName = "DeletableChip"
export default DeletableChip;
