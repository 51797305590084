/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import type { CodeEditorElement } from "~/components/CodeEditor/CodeEditor";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomFlexDialogContent, CustomSaveDialogActions, CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom";
import CustomDialogActions from "~/components/DialogLayout/Custom/CustomDialogActions";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { AzureTemplateHelper } from "./AzureTemplateHelper";
interface AzureResourceGroupSourceCodeDialogProps extends RenderProps {
    template: string;
    saveDone(newTemplate: string): void;
}
interface AzureResourceGroupSourceCodeDialogState {
    template: string;
    errors?: Errors;
}
class AzureResourceGroupSourceCodeDialog extends BaseComponent<AzureResourceGroupSourceCodeDialogProps, AzureResourceGroupSourceCodeDialogState> {
    private editor?: CodeEditorElement | null;
    constructor(props: AzureResourceGroupSourceCodeDialogProps) {
        super(props);
        this.state = {
            errors: undefined,
            template: this.props.template,
        };
    }
    async save(): Promise<boolean> {
        const result = AzureTemplateHelper.extractParameters(this.state.template);
        if (result.errors.length > 0) {
            this.setState({
                errors: {
                    message: "The source code contains errors",
                    errors: [...result.errors],
                    fieldErrors: {},
                    details: {},
                },
            });
        }
        else {
            await this.props.saveDone(this.state.template);
            return true;
        }
        return false;
    }
    onFocusedEditorEscapePressed() {
        if (this.editor) {
            this.editor.blur();
        }
    }
    render() {
        return (<CustomDialog open={this.props.open} close={this.props.close} render={(props) => (<CustomSaveDialogLayout {...props} close={this.props.close} open={this.props.open} renderTitle={() => <CustomSaveDialogTitleBar title="Edit ARM Template"/>} errors={this.state.errors} onSaveClick={() => this.save()} renderActions={(renderProps) => <CustomDialogActions actions={<CustomSaveDialogActions close={renderProps.close} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission}/>}/>} renderContent={(renderProps) => (<CustomFlexDialogContent>
                                <CodeEditor value={this.state.template} ref={(e) => (this.editor = e)} language={TextFormat.JSON} allowFullScreen={false} onChange={(template) => this.setState({ template })} onEscPressed={() => this.onFocusedEditorEscapePressed()} showCopyButton={true}/>
                            </CustomFlexDialogContent>)}/>)}/>);
    }
    static displayName = "AzureResourceGroupSourceCodeDialog";
}
export default AzureResourceGroupSourceCodeDialog;
