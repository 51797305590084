import { Callout, type CalloutType } from "@octopusdeploy/design-system-components";
import { LicenseMessageDisposition } from "@octopusdeploy/octopus-server-client";
import type { LicenseLimitStatus, LicenseUsageResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import Section from "~/components/Section";
import { withTheme } from "~/components/Theme";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { UsageDetailTable } from "./UsageDetailTable";
import { UsageOverviewCard } from "./UsageOverviewCard";
import styles from "./style.module.less";
interface LicenseUsagePageState extends OptionalFormBaseComponentState<LicenseUsageResource> {
    licenseUsage?: LicenseUsageResource;
}
const displayLimit = ["projects", "tenants", "targets", "machines", "tasks", "task cap"];
const dispositionSeverityOrder = [LicenseMessageDisposition.Error, LicenseMessageDisposition.Warning, LicenseMessageDisposition.Notice];
export class LicenseUsagePage extends FormBaseComponent<{}, LicenseUsagePageState, LicenseUsageResource> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        this.doBusyTask(async () => {
            const licenseUsage = await repository.Licenses.getCurrentUsage();
            this.setState({ licenseUsage });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    determineCalloutMessage(limits: LicenseLimitStatus[] | undefined): {
        severity: CalloutType | "";
        title: string;
    } {
        const hasError = limits?.some((l) => l.Disposition === LicenseMessageDisposition.Error);
        if (hasError)
            return { severity: "danger", title: "License Limit Exceeded" };
        const hasWarningOrNotice = limits?.some((l) => [LicenseMessageDisposition.Warning, LicenseMessageDisposition.Notice].includes(l.Disposition));
        if (hasWarningOrNotice)
            return { severity: "warning", title: "Approaching License Limit" };
        return { severity: "", title: "" };
    }
    render() {
        const { licenseUsage } = this.state;
        const limits = licenseUsage?.Limits.filter((l) => displayLimit.includes(l.Name.toLowerCase()));
        const { severity, title } = this.determineCalloutMessage(limits);
        const limitMessages = limits?.filter((l) => dispositionSeverityOrder.some((d) => d == l.Disposition)).map((l) => l && <p key={l.Name}>{l.Message}</p>);
        return withTheme((theme) => {
            const licenseUsage = this.state.licenseUsage;
            const spaceUsageData = licenseUsage?.SpacesUsage ?? [];
            const filteredAndOrderedUsageOverviews = displayLimit.map((limitName) => licenseUsage?.Limits.find((l) => l.Name.toLowerCase() === limitName)).filter((item) => item !== undefined);
            return (<PageContent header={{ title: "License Usage Dashboard" }} busy={this.state.busy} errors={this.errors}>
                    {licenseUsage && severity && (<Section>
                            <Callout title={title} type={severity}>
                                {limitMessages}
                            </Callout>
                        </Section>)}
                    {<>
                            <Section>
                                <div className="license-overview">
                                    <h2>Overall License Usage</h2>
                                    <div className={styles.cardList}>
                                        {filteredAndOrderedUsageOverviews?.map((limit, index) => (<UsageOverviewCard licenseLimitStatus={limit} theme={theme} key={index}/>))}
                                    </div>
                                </div>
                            </Section>
                            <Section>
                                <UsageDetailTable spacesUsage={spaceUsageData} isPtm={licenseUsage?.IsPtm ?? false}/>
                            </Section>
                        </>}
                </PageContent>);
        });
    }
    static displayName = "LicenseUsagePage";
}
