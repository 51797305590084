import type { KubernetesLiveStatusResourceObject } from "@octopusdeploy/octopus-server-client/dist/src/resources/kubernetesLiveStatusResource";
import * as React from "react";
import KubernetesResourceTypeMultiSelect from "~/areas/projects/components/Observability/Filters/KubernetesResourceTypeMultiselect";
import KubernetesStatusMultiselect from "~/areas/projects/components/Observability/Filters/KubernetesStatusMultiselect";
import { KnownKubernetesResources } from "~/areas/projects/components/Observability/KubernetesResources";
import { Statuses } from "~/areas/projects/components/Observability/KubernetesStatuses";
import { AdvancedFilterCheckbox, AdvancedFilterTextInput } from "~/components/AdvancedFilterLayout/index";
export type LiveObjectStatusFilter = {
    kind: string[];
    namespace: string;
    status: string[];
    name: string;
    showNamespacedResources: boolean;
    showClusterResources: boolean;
};
export function filterResources(input: KubernetesLiveStatusResourceObject[], filter: LiveObjectStatusFilter): KubernetesLiveStatusResourceObject[] {
    return input
        .map((r: KubernetesLiveStatusResourceObject) => {
        const matchingChildren = filterResources(r.Children, filter);
        if (matchingChildren.length > 0 || resourceMatchesFilters(r, filter)) {
            return {
                Name: r.Name,
                Namespace: r.Namespace,
                Kind: r.Kind,
                Status: r.Status,
                MachineId: r.MachineId,
                Children: matchingChildren,
            };
        }
        return undefined;
    })
        .filter((item) => item !== undefined);
}
function resourceMatchesFilters(input: KubernetesLiveStatusResourceObject, resourceFilter: LiveObjectStatusFilter) {
    const shouldDisplayResource = fieldMatches(input.Name, resourceFilter.name) && fieldInRange(input.Kind, resourceFilter.kind) && fieldInRange(input.Status, resourceFilter.status);
    if (input.Namespace) {
        if (resourceFilter.showNamespacedResources) {
            return shouldDisplayResource && fieldMatches(input.Namespace, resourceFilter.namespace);
        }
        return false;
    }
    if (!resourceFilter.showClusterResources) {
        return false;
    }
    return shouldDisplayResource;
}
function fieldInRange(input: string, comparison?: string[]): boolean {
    return !comparison || comparison.length === 0 || comparison.includes(input);
}
function fieldMatches(input: string, comparison: string): boolean {
    return comparison === "" || input.includes(comparison);
}
export function filterSections(filter: LiveObjectStatusFilter, setFilter: (value: LiveObjectStatusFilter) => void) {
    return [
        {
            render: (<div>
                    <KubernetesResourceTypeMultiSelect items={KnownKubernetesResources} value={filter.kind} onChange={(x) => {
                    const updatedFilter = { ...filter, kind: x };
                    setFilter(updatedFilter);
                }}/>
                    <AdvancedFilterTextInput fieldName={"namespace"} value={filter.namespace || ""} onChange={(x) => {
                    const updatedFilter = { ...filter, namespace: x };
                    setFilter(updatedFilter);
                }}/>
                    <AdvancedFilterCheckbox label="Show namespaced resources" value={filter.showNamespacedResources} onChange={(x) => {
                    const updatedFilter = { ...filter, showNamespacedResources: x };
                    setFilter(updatedFilter);
                }}/>
                    <AdvancedFilterCheckbox label="Show cluster-level resources" value={filter.showClusterResources} onChange={(x) => {
                    const updatedFilter = { ...filter, showClusterResources: x };
                    setFilter(updatedFilter);
                }}/>
                    <KubernetesStatusMultiselect items={Statuses} value={filter.status} onChange={(x) => {
                    const updatedFilter = { ...filter, status: x };
                    setFilter(updatedFilter);
                }}/>
                </div>),
        },
    ];
}
export const defaultFilter: LiveObjectStatusFilter = {
    kind: [],
    status: [],
    namespace: "",
    name: "",
    showNamespacedResources: true,
    showClusterResources: true,
};
